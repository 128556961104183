import { Button, TableCell, TableRow, Tooltip, Grid, Chip, Link } from '@mui/material';
import { FunctionComponent } from 'react';
import { TableAction } from 'models/table-models/TableAction';
import Order from '../../models/Order';
import { convert } from '../../dateConverter';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

export interface OrderRowProps {
	order: Order;
	actions: {
		onClaimOrder: TableAction;
		onReassignOrder: TableAction;
		onDeleteOrder: TableAction;
	};
	userId: string;
}

export const OrderRow: FunctionComponent<OrderRowProps> = (props: OrderRowProps) => {

	const findActiveMail = (order: any) => {
		let res;
		let active = false;

		//@ts-ignore
		if (order.targetUser && Object.keys(order.targetUser).length !== 0) {
			order.targetUser.emails.forEach(single => {
				if (single.state === "active") {
					active = true;
					res = single.email
				}
			});
			if (!active)
				res = order.targetUser.emails[0].email
		}

		return res;
	}

	const expirationDate = (date: string) => {
		let newDate = new Date(date);
		const copy = new Date(Number(newDate))
		copy.setDate(newDate.getDate() + 365)
		return format(parseISO(new Date(copy).toISOString()), 'dd-MM-yyyy')
	}

	const formatValidFromDate = (date: string) => {
		let newDate = new Date(date);
		return format(parseISO(new Date(newDate).toISOString()), 'dd-MM-yyyy')
	}

	const calculateNumOfDays = (order: Order) => {
		let newDate = new Date();
		newDate.setHours(0, 0, 0, 0);
		let validFromDate = new Date(order.details.validFrom);

		if (newDate.getTime() > validFromDate.getTime()) {
			let difference = newDate.getTime() - new Date(order.details.validFrom).getTime();
			return order.details.numOfDaysToClaim - Math.ceil(difference / (1000 * 3600 * 24));
		}

		return order.details.numOfDaysToClaim;
	}

	const findVoucherOrigin = (ownerType: string) => {
		if (ownerType && ownerType === 'user') {
			return "No"
		} else if (ownerType && ownerType === 'organisation') {
			return (
				<Link href={`/organizations/${props.order.ownerID}`}>
					{props.order.ownerShortName}
				</Link>
			)
		} else {
			return "N/A"
		}

	}

	return (
		<TableRow key={props.order.id} hover>
			<TableCell>
				{props.order.state !== 'claimed' &&
					<Grid container spacing={1} direction="column">
						<Grid item>
							{props.actions.onClaimOrder && (
								<Tooltip title={props.actions.onClaimOrder.title}>
									<span>
										<Button
											variant="outlined"
											color="primary"
											disabled={props.order.ownerID !== props.order.targetID}
											aria-label={props.actions.onClaimOrder.title}
											onClick={() => props.actions.onClaimOrder.onClick(props.order.id)}

										>
											{<props.actions.onClaimOrder.icon
												sx={{
													color: props.order.ownerID !== props.order.targetID ? 'primary' : 'secondary'
												}}
											/>}
										</Button>
									</span>
								</Tooltip>
							)}
						</Grid>
						{/* {props.order && props.order.details && !props.order.details.disableForwarding && */}
						<Grid item>
							{props.actions.onReassignOrder && (
								<Tooltip title={props.actions.onReassignOrder.title}>
									<Button
										variant="outlined"
										color="primary"
										aria-label={props.actions.onReassignOrder.title}
										onClick={() => props.actions.onReassignOrder.onClick(props.order)}
									>
										{<props.actions.onReassignOrder.icon color="primary" />}
									</Button>
								</Tooltip>
							)}
						</Grid >
						<Grid item>
							{props.actions.onDeleteOrder && (
								<Tooltip title={props.actions.onDeleteOrder.title}>
									<Button
										variant="outlined"
										color="primary"
										aria-label={props.actions.onDeleteOrder.title}
										onClick={() => props.actions.onDeleteOrder.onClick(props.order)}
									>
										{<props.actions.onDeleteOrder.icon color="primary" />}
									</Button>
								</Tooltip>
							)}
						</Grid >
						{/* } */}
					</Grid>}
			</TableCell>
			<TableCell>
				<Tooltip title={'ID: ' + props.order.id}>
					<span>
						{props.order.details.invoiceNumber}
					</span>
				</Tooltip>
			</TableCell>
			<TableCell>
				<Tooltip title={"ExamSetId: " + props.order.exam.id + " fixedID: " + props.order.exam.fixedID}>
					<span>
						{props.order.exam.externalName}
					</span>
				</Tooltip>

			</TableCell>
			<TableCell>
				{findVoucherOrigin(props.order.ownerType)}
			</TableCell>
			<TableCell>
				{props.order.details.invoiceNumber && <><Chip style={{ margin: '0.0625rem' }} variant="outlined" label={"Invoice Number: " + props.order.details.invoiceNumber}></Chip> <br /> </>}
				{typeof props.order.details.disableForwarding == "boolean" && <><Chip style={{ margin: '0.0625rem' }} variant="outlined" label={"Disable Forwarding: " + (props.order.details.disableForwarding ? "Yes" : "No")}></Chip><br /></>}
				{props.order.details.numOfDaysToClaim != undefined && props.order.details.numOfDaysToClaim != null && calculateNumOfDays(props.order) >= 0 && <><Chip style={{ margin: '0.0625rem' }} variant="outlined" label={"Nb of days to claim: " + calculateNumOfDays(props.order)}></Chip><br /></>}
				{props.order.details.validFrom && <><Chip style={{ margin: '0.0625rem' }} variant="outlined" label={"Valid From: " + formatValidFromDate(props.order.details.validFrom)}></Chip></>}
				{/* {props.order.details.validFrom && props.order.state === 'claimed' && <><Chip style={{ margin: '0.0625rem' }} variant="outlined" label={"Valid Until: " + expirationDate(props.order.details.validFrom)}></Chip></>} */}
			</TableCell>
			<TableCell>{props.order.createdAt
				? convert(props.order.createdAt, false)
				: ''}</TableCell>
			{/* <TableCell>{expirationDate(props.order.createdAt)}</TableCell> */}

			<TableCell>
				<Tooltip title={props.order.ownerID}>
					<span>{props.order.ownerEmail ? props.order.ownerEmail : props.order.ownerID}</span>
				</Tooltip></TableCell>
			<TableCell>{findActiveMail(props.order)}</TableCell>
			<TableCell>{props.order.state}</TableCell>
		</TableRow >
	);
};
