import { ALIGNMENT_STATUS } from 'models/enums'
import React, { useContext, useState } from 'react';
import { AlertContext } from "App";
import { Controller, useForm } from "react-hook-form";
import { Alignment, AlignmentCreateRequest } from "models/Alignments";
import Moment from "@date-io/moment";
import { alignmentsService } from "services/alignments/alignmentsService";
import { DialogContent, TextField, FormHelperText, Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import ButtonWrapper from "components/atoms/buttons/ButtonWrapper";
import Button from "@mui/material/Button";
import CircularProgress from "components/atoms/StyledCircularProgress";
import { DialogActions } from "customized-components";

interface EditAlignmentFormProps {
  alignment: Alignment;
  cancel: () => void;
  createAlignment?: boolean;
  onAlignmentUpdate: (arg0?: any) => void;
}

const EditAlignmentForm = (props: EditAlignmentFormProps) => {
  //@ts-ignore
  const { handleAlert } = useContext(AlertContext);
  const momentum = new Moment();

  //@ts-ignore
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: props.createAlignment ? {
      targetFramework: '',
      targetDescription: '',
      targetName: '',
      targetUrl: ''
    } : { ...props.alignment }
  });
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    let body;
    if (props.createAlignment) {
      body = {
        targetFramework: data.targetFramework,
        targetDescription: data.targetDescription,
        targetName: data.targetName,
        targetUrl: data.targetUrl,
        status: ALIGNMENT_STATUS.NEW
      };

      alignmentsService.createAlignment(body as AlignmentCreateRequest).then(() => {
        handleAlert('Alignment created successfully!', 'success');
        props.cancel();
        setLoading(false);
        props.onAlignmentUpdate && props.onAlignmentUpdate();
      }).catch((error) => {
        handleAlert('Ups! Something went wrong!', error)
      }
      );

    } else {
      const updatedAtNow = momentum.moment(new Date()).utcOffset(0).format();
      body = {
        ...props.alignment,
        targetFramework: data.targetFramework,
        targetDescription: data.targetDescription,
        targetName: data.targetName,
        targetUrl: data.targetUrl,
        id: data.id,
        createdAt: data.createdAt,
        updatedAt: updatedAtNow
      };

      alignmentsService.updateAlignment(body, props.alignment.id).then(() => {
        handleAlert('Alignment updated successfully!', 'success');
        props.cancel();
        setLoading(false);
        props.onAlignmentUpdate();
      }).catch((error) => {
        props.cancel();
        handleAlert(error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error')
      });
    }
  };



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent sx={{
        borderLeft: '3px solid #4765A0',
        borderRight: '3px solid #4765A0'
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="targetName"
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  data-testid="targetName"
                  id="targetName"
                  size="small"
                  label="Name"
                  variant="outlined"
                  error={!!errors.targetName}
                  helperText={errors.targetName && errors.targetName.message}
                />
              )}
              control={control}
              defaultValue=""
              rules={{
                required: 'Name is required field',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="targetDescription"
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  data-testid="targetDescription"
                  id="targetDescription"
                  size="small"
                  label="Description"
                  variant="outlined"
                  error={!!errors.targetDescription}
                  helperText={errors.targetDescription && errors.targetDescription.message}
                />
              )}
              control={control}
              defaultValue=""
              rules={{
                required: 'Description is required field',
              }}
            />
            <FormHelperText id="component-helper-text">
              <Link target="_blank"
                href={"https://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/index.html#Alignment"}>
                Alignment details
              </Link>
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="targetFramework"
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  data-testid="targetFramework"
                  id="targetFramework"
                  size="small"
                  label="Framework"
                  variant="outlined"
                  error={!!errors.targetFramework}
                  helperText={errors.targetFramework && errors.targetFramework.message}
                />
              )}
              control={control}
              defaultValue=""
              rules={{
                required: 'Framework is required field',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="targetUrl"
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  data-testid="targetUrl"
                  id="targetUrl"
                  size="small"
                  label="targetUrl"
                  variant="outlined"
                  error={!!errors.targetUrl}
                  helperText={errors.targetUrl && errors.targetUrl.message}
                />
              )}
              control={control}
              defaultValue=""
              rules={{
                required: 'URL is required field',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonWrapper>
          <Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
            Save
          </Button>
          {loading && <CircularProgress isCentered thickness={5} size={24} />}
        </ButtonWrapper>
        <Button
          disabled={loading}
          onClick={() => {
            //@ts-ignore
            reset({
              targetFramework: '',
              targetDescription: '',
              targetName: '',
            });
            props.cancel();
          }}
          data-testid=""
          color="primary"
          type="submit"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </form>
  );
};

export default EditAlignmentForm;
