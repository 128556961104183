import React, { useState, useRef, useEffect, useContext } from 'react';
import { AlertContext } from 'App';
import { Alignment, SortObject } from 'models/Alignments';
import { UserContext } from 'context/UserContext';
import { authenticationService } from 'services/authentication/authentication.service';
import { alignmentsHeaders } from "models/table-headers/alignments-headers";
import { alignmentsService } from 'services/alignments/alignmentsService';
import { ModalDialog } from 'customized-components/modal-dialog/modal-dialog';
import { Button, Grid } from "@mui/material";
import { TableAction } from 'models/table-models/TableAction';
import EditIcon from "@mui/icons-material/Edit";
import { AlignmentRow } from "./AlignmentRow";
import EditAlignmentForm from "./EditAlignmentForm";
import DeleteAlignmentForm from "./DeleteAlignmentForm";
import DeleteIcon from "@mui/icons-material/Delete";
import ComplexTable from 'components/organisms/ComplexTable';
import TablePageSortFilter from 'models/table-models/TablePageSortFilter';
import PaginationSortingFilteringRequest from 'models/table-models/PaginationSortingFilteringRequest';
import { defaultAlignmentFilters } from 'models/table-filters.ts/alignments-table-filters';

const AlignmentsPage = () => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData, setUserData } = useContext(UserContext);
    const mounted = useRef(false);

    const [alignments, setAlignments] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAlignment, setSelectedAlignments] = useState();
    const [alignmentDialog, setAlignmentDialog] = useState<boolean>(false);
    const [editAlignmentDialog, setEditAlignmentDialog] = useState<boolean>(false);
    const [deleteAlignmentDialog, setDeleteAlignmentDialog] = useState<boolean>(false);

    const defaultRequest = { order: 'desc', orderBy: 'targetName', page: 0, rowsPerPage: 10, filters: defaultAlignmentFilters };
    const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);

    const loadAlignments = (request: PaginationSortingFilteringRequest) => {
        setLoading(true)
        alignmentsService
            .getAlignmentsAndFilterWithPagination(request)
            .then((res) => {
                if (mounted.current) {
                    setAlignments(res);
                    setLoading(false);
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const toggleRegularDialog = () => {
        setAlignmentDialog(!alignmentDialog)
    }

    const toggleEditDialog = (id: string) => {
        setSelectedAlignments(alignments?.results?.find((row: Alignment) => row.id === id));
        setEditAlignmentDialog(!editAlignmentDialog)
    }

    const toggleDeleteDialog = (id: string) => {
        setSelectedAlignments(alignments?.results?.find((row: Alignment) => row.id === id));
        setDeleteAlignmentDialog(!deleteAlignmentDialog)
    }

    const alignmentActions: TableAction[] = [
        {
            title: 'Edit',
            icon: EditIcon,
            onClick: toggleEditDialog,
        },
        {
            title: 'Delete',
            icon: DeleteIcon,
            onClick: toggleDeleteDialog,
        }
    ];

    useEffect(() => {
        mounted.current = true;
        if (!userData) {
            authenticationService.getLoggedInUserData().then((res: any) => {
                if (mounted.current) {
                    //@ts-ignore
                    setUserData(res);
                }
            });
            loadAlignments(paginationData);
        } else {
            loadAlignments(paginationData);
        }

        return () => {
            mounted.current = false;
        }

    }, []);

    const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
        setPaginationData(newValue)
    };

    return (
        <>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => setAlignmentDialog(true)}>Create Badge Alignment</Button>
                </Grid>
            </Grid>

            <ComplexTable
                title="Alignments"
                loading={loading}
                addAction={() => { }}
                addIcon={undefined}
                headerCells={alignmentsHeaders}
                updateTableData={loadAlignments}
                tableData={alignments as TablePageSortFilter}
                onPaginationUpdate={trackPagination}>

                {alignments
                    ? alignments.results?.map((alignment: Alignment) =>
                        <AlignmentRow key={alignment.id} row={alignment} actions={alignmentActions} />)
                    : null}
            </ComplexTable>

            {alignmentDialog ? (
                <ModalDialog
                    dialogIsOpen={alignmentDialog}
                    //@ts-ignore
                    setDialogOpen={toggleRegularDialog}
                    title="Create Alignment"
                >
                    <EditAlignmentForm
                        alignment={selectedAlignment as any}
                        cancel={() => toggleRegularDialog()}
                        createAlignment
                        onAlignmentUpdate={() => loadAlignments(paginationData)}
                    />
                </ModalDialog>
            ) : null}
            {editAlignmentDialog ? (
                <ModalDialog
                    dialogIsOpen={editAlignmentDialog}
                    //@ts-ignore
                    setDialogOpen={toggleEditDialog}
                    title="Edit Alignment"
                >
                    <EditAlignmentForm
                        alignment={selectedAlignment as any}
                        cancel={() => setEditAlignmentDialog(false)}
                        onAlignmentUpdate={() => loadAlignments(paginationData)}
                    />
                </ModalDialog>
            ) : null}
            {deleteAlignmentDialog ? (
                <ModalDialog
                    dialogIsOpen={deleteAlignmentDialog}
                    //@ts-ignore
                    setDialogOpen={toggleDeleteDialog}
                    title="Delete Alignment"
                >
                    <DeleteAlignmentForm
                        alignment={selectedAlignment as any}
                        cancel={() => setDeleteAlignmentDialog(false)}
                        onAlignmentDelete={() => loadAlignments(paginationData)}
                    />
                </ModalDialog>
            ) : null}
        </ >
    )
};

export default AlignmentsPage;
