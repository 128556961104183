import React from 'react'
import {FallbackProps} from 'react-error-boundary'

export const ErrorFallbackComponent = ({error, resetErrorBoundary}: FallbackProps) => {
  return (
    <div style={{position: 'absolute', top: '30%', left: '30%', width: '50%', color: 'red'}}>
      <h1>Something went wrong, please refresh the application and try again</h1>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
