import { FunctionComponent, useEffect, useState, useContext, useRef } from 'react';
import ComplexTable from 'components/organisms/ComplexTable';
import CertificateRow from '../certificates/certificates-issuing-row';
import { certificateHeaderCells } from '../../models/table-headers/certificates-issuing-headers';
import { certificatesService } from '../../services/certificates/certificates.service';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { AlertContext } from '../../App';
import { UserContext } from '../../context/UserContext';
import { defaultUserCertificatesFilters } from '../../models/table-filters.ts/certificates-table-filters';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ModalDialog } from '../../customized-components';
import { IssueUserCertificate } from '../certificates-details/forms/user-certificate-form';
import { participantsService } from '../../services/participants/participants.service';
import { CertificateIssuing } from 'models/Certificate';
import { CreateBadgeAssertion } from 'features/certificates-details/forms/badge-assertion-form';
import { assertionsService } from 'services/assertions/AssertionsService';
import { Participant } from 'models/Participant';
import { Email } from 'models/Email';

export interface CertificatesSectionProps {
    id?: string;
    isComplete: boolean;
    onCreateAssertion: any;
}

export const CertificatesSection: FunctionComponent<CertificatesSectionProps> = (props: CertificatesSectionProps) => {
    const defaultRequest = {
        order: 'desc',
        orderBy: 'createdAt',
        page: 0,
        rowsPerPage: 10,
        filters: defaultUserCertificatesFilters(),
    };
    const mounted = useRef(false);

    const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);
    const [certificates, setCertificates] = useState<TablePageSortFilter>();
    const [loading, setLoading] = useState<boolean>(false);
    const [userCertificateDialog, setUserCertificateDialog] = useState<boolean>(false);

    const [createAssertionDialog, toggleCreateAssertionDialog] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateIssuing>();

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData } = useContext(UserContext);

    const [selectedParticipant, setSelectedParticipant] = useState<Participant>();

    const loadCertificates = (request: PaginationSortingFilteringRequest) => {
        setLoading(true);
        certificatesService
            .getUsersCertificates(props.id as string, request)
            .then((res) => {
                if (mounted.current) {
                    setCertificates(res);
                    setLoading(false);
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );

        participantsService
            .getParticipant(props.id as string)
            .then((res) => {
                if (mounted.current) {
                    setSelectedParticipant(res);
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const onCertificateStatusChange = (updatedState: string, id: string) => {
        setLoading(true);

        if (updatedState === 'issued') {
            certificatesService
                .issueCertificate(id)
                .then(() => {
                    loadCertificates(paginationData);
                    setLoading(false);
                    handleAlert('Certificate issued successfully!', 'success');
                })
                .catch((error) => {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    );
                    setLoading(false);
                });
        } else {
            participantsService
                .changeUserCertificateStatus(id, updatedState, props?.id)
                .then(() => {
                    loadCertificates(paginationData);
                    setLoading(false);
                    handleAlert('Certificate revoked successfully!', 'success');
                })
                .catch((error) => {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    );
                    setLoading(false);
                });
        }
    };

    const handleToggleUserCertificateDialog = () => {
        setUserCertificateDialog(!userCertificateDialog);
    };

    const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
        setPaginationData(newValue);
    };

    useEffect(() => {
        mounted.current = true;
        if (props.id && userData) {
            loadCertificates(defaultRequest);
        }
        return () => {
            mounted.current = false;
        };
    }, [props.id, userData]);

    const handleSelectedCertificate = (certificate: CertificateIssuing) => {
        setSelectedCertificate(certificate);
    };

    const handleToggleCreateAssertionDialog = () => {
        toggleCreateAssertionDialog(!createAssertionDialog);
    };

    const handlerOnAssignmentCreated = () => {
        loadCertificates(defaultRequest);
    };
    const handleCreateAssertion = (data) => {
        setLoading(true);
        data.badgeClass = selectedCertificate ? selectedCertificate.details.badgeClass[0].id : null;
        data.expires = selectedCertificate ? selectedCertificate.details.expirationDate : null;
        data.certUserRelationId = selectedCertificate ? selectedCertificate.id : null;
        data.certificateID = selectedCertificate ? selectedCertificate.sourceID : null;

        console.log('data', data);

        assertionsService
            .createAssertion(data)
            .then(() => {
                toggleCreateAssertionDialog(false);
                handleAlert('Badge created successfully', 'success');
                setLoading(false);
                loadCertificates(paginationData);
                props.onCreateAssertion();
            })
            .catch((err) => {
                handleAlert(err.response.data.message, 'error');
                setLoading(false);
            });
    };

    return (
        <div>
            <ComplexTable
                tableData={certificates as TablePageSortFilter}
                headerCells={certificateHeaderCells}
                title="Certificates & Badges"
                addAction={handleToggleUserCertificateDialog}
                addIcon={AddBoxIcon}
                onPaginationUpdate={trackPagination}
                updateTableData={loadCertificates}
                loading={loading}
            >
                {certificates?.results
                    ? certificates.results.map((c, index) => (
                          <CertificateRow
                              loading={loading}
                              key={c.id + index}
                              certificate={c}
                              handlerOnAssignmentCreated={handlerOnAssignmentCreated}
                              onCertificateStatusChange={onCertificateStatusChange}
                              isComplete={props.isComplete}
                              handleSelectedCertificate={handleSelectedCertificate}
                              handleToggleCreateAssertionDialog={handleToggleCreateAssertionDialog}
                          />
                      ))
                    : null}

                {selectedCertificate && (
                    <ModalDialog
                        dialogIsOpen={createAssertionDialog}
                        setDialogOpen={handleToggleCreateAssertionDialog}
                        title="Create Badge"
                    >
                        <CreateBadgeAssertion
                            cancel={() => toggleCreateAssertionDialog(false)}
                            emails={selectedParticipant ? selectedParticipant.emails : []}
                            createAssertion={handleCreateAssertion}
                        />
                    </ModalDialog>
                )}
            </ComplexTable>
            {userCertificateDialog ? (
                <ModalDialog
                    dialogIsOpen={userCertificateDialog}
                    setDialogOpen={handleToggleUserCertificateDialog}
                    title=" Issue an user certificate"
                >
                    <IssueUserCertificate
                        cancel={() => setUserCertificateDialog(false)}
                        id={props.id}
                        onCertificateStatusChange={() => loadCertificates(paginationData)}
                        isComplete={props.isComplete}
                    />
                </ModalDialog>
            ) : null}
        </div>
    );
};
