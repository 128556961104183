export const defaultSOESettings: any = {
    tokenvalidfor: '365',
    Photo_Time_Interval: '15',
    Stopping_Time_Photo_Interval: '2',
    allowRecreationExam: 1,
    allowRecreationSampleExam: 1,
    shuffleQuestions: true,
    shuffleAnswers: true,
    fullScreenMode: true,
    mouseMovement: true,
    numberOfMaxMouseExit: 5,
    mouseAllowedSecondsPerLeave: 10,
    sendKeyLogDataToServerLimit: 5,
    photoDisplayTimeInterval: 15,
    cameraAndCognitoServices: 1,
    displayAndCognitoServices: 1,
    ExamEvent_GenerationTime: 'now',
    ExamVersion_plannedDuration: '45',
    sampleSet: false,
    identificationProcedure: true,
    activeTabStopThreshold: 15,
    notAllowedKeyCombinationThreshold: 15,
    monitoringActiveTab: true,
    monitoringKeyPresses: true,
    examObjectLanguage: 'de',
    examGeneratedInSoe: false
};
