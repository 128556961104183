import { TableCell, TableRow, Tooltip } from '@mui/material'
import { Exam, DemoData } from 'models/Exam'
import { TableAction } from 'models/table-models/TableAction'
import { FunctionComponent } from 'react'
import { Checkbox, Button } from '@mui/material';


export interface ExamsRowProps {
  row: Exam;
  onHandleExamStatusChange: (changeExamStatus: boolean, examId: string) => void;
  actions?: TableAction[];
  onEditExamBooking: (examId: string) => void;
}

export const ExamRow: FunctionComponent<ExamsRowProps> = (props: ExamsRowProps) => {
  const handleChange = (event) => {
    props.onHandleExamStatusChange(event.target.checked, props.row.id);
  };

  let nameOfCertificate = (props.row?.examCertificate && props.row?.examCertificate[0]?.name) ? props.row.examCertificate[0].name : '';
  let sampleSetExam = (props.row.details.sampleSet === true) ? true : false
  return (
    <TableRow key={props.row.id} hover>
      <TableCell size="medium">
        <Tooltip title={props.row.id}>
          <span>
            {props.row.externalName}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell size="medium">{(props.row.details?.SQMS2_ExamSet_ID) ? props.row.details?.SQMS2_ExamSet_ID + " - " + props.row?.fixedID : props.row?.fixedID}</TableCell>
      <TableCell size="medium">{props.row.createdAt}</TableCell>
      <TableCell size="medium">{props.row.updatedAt}</TableCell>
      <TableCell size="medium">
        <Button
          variant="outlined"
          color="primary"
          //disabled={sampleSetExam}
          //@ts-ignore
          onClick={() => props.onEditExamBooking(props.row.id)}
        >
          {(sampleSetExam) ? 'Sample set' : 'Change Certificate'}
        </Button>

      </TableCell>
      <TableCell size="small">{nameOfCertificate}</TableCell>
      <TableCell size="small">{props.row.status.toString() === 'true' ? 'ACTIVE' : 'INACTIVE'}</TableCell>
      <TableCell size="small"><Checkbox
        checked={props.row.status}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      /></TableCell>
    </TableRow >
  );
};
