import { HeadCell } from 'components/molecules/ComplexTableHead';

export const examEventUsers: HeadCell[] = [
    // { id: 'id', numeric: false, label: '', disablePadding: false },
    { id: 'firstName', numeric: false, label: 'First Name', disablePadding: false, disableSorting: true },
    { id: 'lastName', numeric: false, label: 'Last Name', disablePadding: false, disableSorting: true },
    { id: 'status', numeric: false, label: 'Status', disablePadding: false, disableSorting: true },
    { id: 'statusExam', numeric: false, label: 'Status per exam', disablePadding: false, disableSorting: true },
    { id: 'remove', numeric: false, label: 'Remove', disablePadding: false, disableSorting: true },
];

export const examEventExams: HeadCell[] = [
    // { id: 'id', numeric: false, label: '', disablePadding: false },
    { id: 'externalName', numeric: false, label: 'Name', disablePadding: false, disableSorting: true },
    { id: 'sampleSet', numeric: false, label: 'Sample', disablePadding: false, disableSorting: true },
    { id: 'plannedDuration', numeric: false, label: 'Duration', disablePadding: false, disableSorting: true },
];
