import { HeadCell } from 'components/molecules/ComplexTableHead';

export const examsHeaders: HeadCell[] = [
    { id: 'externalName', numeric: false, label: 'Name', disablePadding: false, disableSorting: false },
    { id: 'sqms2examsetId', numeric: false, label: 'SQMS set Id', disablePadding: false, disableSorting: true },
    { id: 'createdAt', numeric: false, label: 'Created at', disablePadding: false, disableSorting: false },
    { id: 'updatedAt', numeric: false, label: 'Updated at', disablePadding: false, disableSorting: false },
    { id: 'assignedCertificate', numeric: false, label: 'Modify Certificate', disablePadding: false, disableSorting: true },
    { id: 'currentCertificate', numeric: false, label: 'Certificate', disablePadding: false, disableSorting: true },
    { id: 'status', numeric: false, label: 'Status', disablePadding: false, disableSorting: false },
    { id: 'checkStatus', numeric: false, label: '', disablePadding: false, disableSorting: false }
];
