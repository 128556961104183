import TableFilter from '../table-models/TableFilter';

export const defaultCertificatesFilters = (): TableFilter[] => [
    { field: 'name', label: 'Name', value: '' },
    { field: 'state', label: 'State', value: '', availableStates: ['active', 'inactive', 'new'] },
];

export const defaultUserCertificatesFilters = (): TableFilter[] => [
    { field: 'name', label: 'Name', value: '' },
    { field: 'state', label: 'State', value: '', availableStates: ['revoked', 'issued', 'cancelled', 'can_issue'] },
];
