import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 240;

export const appShellStyles = makeStyles((theme) => ({
    color: {
        color: theme.palette.primary.main,
    },
    root: {
        display: 'flex',
        backgroundColor: '#ededed',
        minHeight: '100vh',
        height: '100%',
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginTop: theme.spacing(1),
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderRight: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '0px 5px 5px 0px',
        height: '98%',
        boxShadow: '0 0 1rem 0 rgba(0, 0, 0, 0.2)',
        '&:before': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            backdropFilter: 'blur(10px) saturate(100%) contrast(45%) brightness(130%)',
            content: '""',
            height: '100%',
            position: 'absolute',
            width: '100%',
        },
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: parseInt(theme.spacing(7)) + 1,
        [theme.breakpoints.up('sm')]: {
            width: parseInt(theme.spacing(8)) + 1,
        },
        marginTop: theme.spacing(1),
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderRight: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '0px 5px 5px 0px',
        height: '98%',
    },
    toolbar: {
        padding: theme.spacing(1, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        height: '100%',
    },
    avatar: {
        marginRight: theme.spacing(3),
    },
    avatarPoper: {
        zIndex: 999999999,
    },
    background: {
        // height: '100%',
        width: '100%',
        margin: '0px',
        padding: '0px',
    },
    rowHover: {
        '&:hover': {
            backgroundColor: 'rgb(225,225,225)',
        },
    },
}));
