export const certIssueTransitions = new Map<string, string[]>([
    ['can_issue', ['issued', 'revoked', 'cancelled']],
    ['issued', ['revoked']],
    ['external', ['revoked']],
    ['revoked', ['cancelled']]
]);

export const allBookingStates = ['can_issue', 'issued', 'external', 'revoked', 'cancelled'];

export const certIssueStatusOptionsGetter = (currentStatus?: string): string[] => {
    return certIssueTransitions.get(currentStatus ? currentStatus : '') as string[];
};
