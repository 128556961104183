import React, { useContext } from 'react';
import { AlertContext } from "App";
import { Badge } from "models/Badges";
import { badgesService } from "services/badges/badgesService";
import {
    DialogContent
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ButtonWrapper from "components/atoms/buttons/ButtonWrapper";
import Button from "@mui/material/Button";
import { DialogActions } from "customized-components";

interface DeleteBadgeFormProps {
    badge: Badge;
    cancel: () => void;
    onBadgeDelete: (arg0?: any) => void;
}

const DeleteBadgeForm = (props: DeleteBadgeFormProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const handleDelete = () => {
        badgesService.deleteBadge(props.badge.id).then(() => {
            handleAlert('Badge deleted successfully!', 'success');
            props.cancel();
            props.onBadgeDelete && props.onBadgeDelete();
            location.reload();
        }).catch((error) => {
            handleAlert('Ups! Something went wrong!', error)
        }
        );

    }

    // @ts-ignore
    return (
        // @ts-ignore
        <>
            <DialogContent sx={{
                borderLeft: '3px solid #4765A0',
                borderRight: '3px solid #4765A0'
            }}>
                <Grid container spacing={3}>
                    <Grid item>
                        <h4>Are you sure you want to delete [{props.badge.title}] badge?</h4>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button data-testid="delete" onClick={handleDelete} color="primary" type="submit" variant="contained">
                        Delete
                    </Button>
                </ButtonWrapper>
                <Button
                    data-testid="cancel"
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default DeleteBadgeForm;
