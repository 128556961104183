import { TableCell, TableRow, Grid, Chip, Tooltip, Button, Checkbox } from '@mui/material';
import { FunctionComponent } from 'react';
import { Certificate } from '../../models/Certificate';
import EditIcon from '@mui/icons-material/Edit';



export interface CertificateDetailsRowProps {
	certificate: Certificate;
	onEditCertificate: (id: string) => void;
	onHandleCertificateStatusChange: (changeCertStatus: boolean, certId: string) => void;
	// onUsageChange: () => {};
	// onPrivacyChange: () => {};
	//TODO: needs also to have title/aria-label value passed in here
}

export const CertificateDetailsRow: FunctionComponent<CertificateDetailsRowProps> = (props: CertificateDetailsRowProps) => {
	const handleChange = (event) => {
		props.onHandleCertificateStatusChange(event.target.checked, props.certificate.id);
	};
	return (
		<TableRow key={props.certificate.id}>
			<TableCell>
				{/* { props.certificate.computed && } */}
				<Tooltip title={"Edit certificate"}>
					<Button
						variant="outlined"
						color="primary"
						//@ts-ignore
						onClick={() => props.onEditCertificate(props.certificate.id)}
					>
						{<EditIcon color="primary" />}
					</Button>
				</Tooltip>
			</TableCell>
			<TableCell>{props.certificate.name}</TableCell>
			<TableCell>{props.certificate.id}</TableCell>
			<TableCell>{props.certificate.computed ? "Yes" : "No"}</TableCell>
			<TableCell>{props.certificate.autoIssue ? "Yes" : "No"}</TableCell>
			<TableCell>
				<Grid container spacing={1}>
					{props.certificate.iRequireFollowingExams &&
						props.certificate.iRequireFollowingExams.map((single) => (
							<Grid item key={single.id}>
								<Chip label={single.name}></Chip>
							</Grid>
						))}
				</Grid>
			</TableCell>
			<TableCell>
				<Grid container spacing={1}>
					{props.certificate.examsThatDependOnMe &&
						props.certificate.examsThatDependOnMe.map((single) => (
							<Grid item key={single.id}>
								<Chip label={single.name}></Chip>
							</Grid>
						))}
				</Grid>
			</TableCell>
			<TableCell sx={{ backgroundColor: !props.certificate.computed && !props.certificate.exam ? "yellow" : "" }}>
				{props.certificate?.exam?.map((single) => {
					let status = (single.status) ? "active" : "not active"
					let fixedID = single.fixedID;
					let SQMS2_ExamSet_ID = (single.details?.SQMS2_ExamSet_ID) ? single.details.SQMS2_ExamSet_ID : '';
					let showNumber = (!SQMS2_ExamSet_ID) ? " : " + fixedID + " " + single.details?.externalId + " " + status : " : " + SQMS2_ExamSet_ID + " - " + single.details?.externalId + " - " + status;

					return (<Grid item key={single.id}>
						<Chip label={single.externalName + showNumber}></Chip>
					</Grid>)
				})}
			</TableCell>
			<TableCell size="small">{props.certificate.status.toString().toUpperCase()}</TableCell>
			<TableCell size="small">
				{
					(props.certificate?.organization && props.certificate?.organization.length) ?
						props.certificate?.organization[0].name : ""
				}
			</TableCell>
			<TableCell size="small">
				<Checkbox
					checked={props.certificate.status === 'active'}
					onChange={handleChange}
					inputProps={{ 'aria-label': 'controlled' }}
				/></TableCell>
		</TableRow>
	);
};
