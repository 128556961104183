import { HeadCell } from 'components/molecules/ComplexTableHead';

export const alignmentsHeaders: HeadCell[] = [
    { id: 'Id', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'targetName', numeric: false, label: 'Name', disablePadding: false, disableSorting: false },
    { id: 'targetDescription', numeric: false, label: 'Description', disablePadding: false, disableSorting: false },
    { id: 'targetFramework', numeric: false, label: 'Framework', disablePadding: false, disableSorting: false },
    { id: 'targetUrl', numeric: false, label: 'Target Url', disablePadding: false, disableSorting: false },
    { id: 'status', numeric: false, label: 'Status', disablePadding: false, disableSorting: false },
    { id: '', numeric: false, label: '', disablePadding: false, disableSorting: true }
];
