import { HeadCell } from 'components/molecules/ComplexTableHead';

export const examEventGridHeaderCells: HeadCell[] = [
    { id: '', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'id', numeric: false, label: 'ID', disablePadding: false, disableSorting: true },
    { id: 'trainingOrgUID', numeric: false, label: 'ATO', disablePadding: false, disableSorting: true },
    { id: 'name', numeric: false, label: 'Exam Name', disablePadding: false, disableSorting: false },
    { id: 'startDate', numeric: false, label: 'Start Date', disablePadding: false, disableSorting: false },
    { id: 'details', numeric: false, label: 'Summary', disablePadding: false, disableSorting: true },
    { id: 'createdAt', numeric: false, label: 'Created at', disablePadding: false, disableSorting: false },
    { id: 'state', numeric: false, label: 'State', disablePadding: false, disableSorting: false },
];
