import makeStyles from '@mui/styles/makeStyles';

export const participantCardStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        padding: 20,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '10px',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    buttongroup: {
        margin: theme.spacing(3),
    },
    green: {
        backgroundColor: 'green',
    },
    orange: {
        backgroundColor: 'orange',
    },
}));
