import { createContext, Dispatch, SetStateAction } from "react";

interface IUserContext {
	isAuthenticated: boolean;
	setAuthenticated?: Dispatch<SetStateAction<boolean>>;
	userData: any;
	setUserData?: Dispatch<SetStateAction<any>>;
}
const defaultState = {
	isAuthenticated: false,
	userData: undefined,
}
export const UserContext = createContext<IUserContext>(defaultState);
