import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Badge } from 'models/Badges';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from 'customized-components';
import { FunctionComponent, useEffect, useState, useContext } from 'react';
import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { certificatesService } from 'services/certificates/certificates.service';
import { AlertContext } from 'App';
import { Certificate } from 'models/Certificate';
import { participantsService } from 'services/participants/participants.service';
import { defaultCertificatesFilters } from 'models/table-filters.ts/certificates-table-filters';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import MaskedInput from 'react-text-mask';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import { convertOnlyDate } from 'dateConverter';
import { BADGE_STATUS } from 'models/enums';

export interface IvanFormProps {
    cancel: () => void;
    id?: string;
    onCertificateStatusChange: (data: any) => void;
    isComplete: boolean;
}

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'_'}
            // showMask
            guide={false}
        />
    );
}

export const IssueUserCertificate: FunctionComponent<IvanFormProps> = (props: IvanFormProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'validFrom') {
            setValidFrom(event.target.value);
        } else if (event.target.name === 'validTo') {
            setValidTo(event.target.value);
        }
    };

    const defaultRequest = {
        order: 'asc',
        orderBy: 'createdAt',
        page: 0,
        rowsPerPage: 10,
        filters: defaultCertificatesFilters(),
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [certificates, setCertificates] = useState<Certificate[]>([]);
    const [force, setForce] = useState<boolean>(false);
    const [currentBadgeClass, setCurrentBadgeClass] = useState<Badge[]>([]);

    const [validFrom, setValidFrom] = useState<string>('');
    const [validTo, setValidTo] = useState<string>('');

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    //@ts-ignore
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        register,
        clearErrors,
    } = useForm({
        defaultValues: {
            certID: '',
            validFrom: '',
            validTo: '',
            force: false,
            badgeClass: '',
        },
    });

    const renderValidationMessage = (prop: any) => {
        // @ts-ignore
        if (errors[prop] && errors[prop].message) {
            // @ts-ignore
            let msg = errors[prop].message;
            return (
                <FormHelperText error={true} variant="outlined">
                    {msg}
                </FormHelperText>
            );
        }
    };

    useEffect(() => {
        certificatesService
            .getCertificates()
            .then((res) => {
                setCertificates(res);
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    }, []);

    const onSubmit = (data: any) => {
        if (!data.badgeClass) {
            data.badgeClass = null;
        }

        let validFromParsed = convertOnlyDate(validFrom);

        let payload: any = {
            certID: data.certID,
            force: data.force,
            validFrom: validFromParsed + 'T00:00:00Z',
            badgeClass: currentBadgeClass.length && currentBadgeClass[0].id ? currentBadgeClass[0].id : null,
        };

        if (data.validTo !== '') {
            let validToParsed = convertOnlyDate(validTo);
            payload.validTo = validToParsed + 'T00:00:00Z';
        }

        setLoading(true);
        participantsService
            .issueUserCertificate(payload, props.id)
            .then((res) => {
                handleAlert('User certificate successfully issued!', 'success');
                setLoading(false);
                props.cancel();
                props.onCertificateStatusChange(defaultRequest);
            })
            .catch((error) => {
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                );
                setLoading(false);
                props.cancel();
                props.onCertificateStatusChange(defaultRequest);
            });
    };

    const dateRegexPattern = /((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/;

    // const renderCertificateName = (selected: string): string => {
    // 	let name: string = "";
    // 	const certificate = certificates.find((e) => e.id === selected);
    // 	name = certificate?.name as string;
    // 	return name;
    // };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            size="small"
                            options={certificates}
                            // getOptionLabel={(option) => option.name}
                            getOptionLabel={(option) =>
                                option.computed ? option.name + ' - ICO role' : option.name + ' - ICO exam'
                            }
                            onChange={(e, option) => {
                                if (option === null) {
                                    setValue('certID', '');
                                } else {
                                    //@ts-ignore
                                    const filteredBadgeClass = option.badgeClass.filter(
                                        (badge) =>
                                            badge.status === BADGE_STATUS.ACTIVE ||
                                            badge.status === BADGE_STATUS.PUBLISHED
                                    );
                                    setCurrentBadgeClass(filteredBadgeClass);
                                    if (option.badgeClass) {
                                        clearErrors('badgeClass');
                                    }
                                    setValue('certID', option.id);
                                }
                            }}
                            // renderOption={(props, option) => (
                            //     <li {...props}>
                            //         <strong>{option.name}</strong>
                            //         {option.computed ? ' - ICO role' : ' - ICO exam'}
                            //     </li>
                            // )}
                            renderInput={(params) => (
                                <TextField
                                    variant="outlined"
                                    label="Certificates"
                                    {...params}
                                    error={Boolean(errors?.certID)}
                                    helperText={errors.certID && 'Exams are required field'}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {props.isComplete ? (
                            <FormControlLabel
                                disabled={!props.isComplete}
                                control={
                                    <Checkbox
                                        checked={force}
                                        onChange={(e) => {
                                            setForce(e.target.checked);
                                            setValue('force', e.target.checked);
                                        }}
                                        name="jason"
                                    />
                                }
                                label="Issue right away"
                            />
                        ) : (
                            <Tooltip title="Certificate can not be issued for incomplete user">
                                <FormControlLabel
                                    disabled={!props.isComplete}
                                    control={
                                        <Checkbox
                                            checked={force}
                                            onChange={(e) => {
                                                setForce(e.target.checked);
                                                setValue('force', e.target.checked);
                                            }}
                                            name="jason"
                                        />
                                    }
                                    label="Issue right away"
                                />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel htmlFor="validFrom">Valid From</InputLabel>
                            <OutlinedInput
                                value={validFrom}
                                id="validFrom"
                                label="Valid From"
                                placeholder={'DD-MM-YYYY'}
                                inputComponent={TextMaskCustom as any}
                                {...register('validFrom', {
                                    required: {
                                        value: true,
                                        message: 'Valid from is required field',
                                    },
                                    pattern: {
                                        value: dateRegexPattern,
                                        message:
                                            'Field must be in valid format, use 2 digit format for days and months, eg. 03 for March',
                                    },
                                    onChange: handleChange,
                                })}
                                error={!!errors.validFrom}
                            />
                            {renderValidationMessage('validFrom')}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel htmlFor="validTo">Valid Until</InputLabel>
                            <OutlinedInput
                                value={validTo}
                                id="validTo"
                                label="Valid Until"
                                placeholder={'DD-MM-YYYY'}
                                inputComponent={TextMaskCustom as any}
                                {...register('validTo', {
                                    // pattern: {
                                    //   value: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
                                    //   message: 'Field must be in valid format, use 2 digit format for days and months, eg. 03 for March'
                                    // },
                                    // validate: (value) => !!value && value.match(dateRegexPattern),
                                    onChange: handleChange,
                                })}
                            />
                        </FormControl>
                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="validTo"
                render={({ field }) => (
                  <KeyboardDatePicker
                    {...field}
                    fullWidth
                    data-testid="valid-until"
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    format="dd/MM/yyyy"
                    margin="none"
                    id="birthday"
                    label="Valid Until"
                    value={field.value ? field.value : null}
                    // onChange={(date: MaterialUiPickersDate) => {
                    //   setValue('validTo', date as any, {
                    //     shouldValidate: true,
                    //     shouldDirty: true,
                    //   });
                    // }}
                    onChange={handleFNSDateSave('validTo')}
                    error={errors.validTo ? true : false}
                    helperText={errors.validTo && errors.validTo.message}
                  />
                )}
                control={control}
                defaultValue={undefined}
              />
            </MuiPickersUtilsProvider>*/}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {currentBadgeClass.length > 0 &&
                            currentBadgeClass.map((item) => (
                                <Typography variant="body1" key={item.id}>
                                    {item.title}
                                </Typography>
                            ))}

                        {/* <FormControl size="small" variant="outlined" fullWidth>
                            <Autocomplete
                                size="small"
                                options={currentBadgeClass}
                                getOptionLabel={(option) =>
                                    option.title ? option.title + ' - Status: ' + option.status : option.id
                                }
                                onChange={(e, option) => {
                                    if (option === null) {
                                        setValue('badgeClass', '');
                                    } else {
                                        setValue('badgeClass', option?.id);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="outlined"
                                        label="Badge Class"
                                        {...params}
                                    />
                                )}
                            />
                        </FormControl> */}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button
                        disabled={loading}
                        data-testid="savenewparticipant"
                        color="primary"
                        type="submit"
                        variant="contained"
                    >
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        reset();
                        props.cancel();
                    }}
                    data-testid="cancelnewparticipant"
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
