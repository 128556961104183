import { Link, TableCell, TableRow, Collapse, Box, Typography, Table, TableBody, TableHead, IconButton, Tooltip, Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { TableAction } from 'models/table-models/TableAction';
import { ParticipantExamEvent } from '../../models/Participant';
import { StatusButton } from '../../customized-components/status-button/status-button';
import { bookingStatusOptionsGetter } from '../../status-transition-rules/booking.transitions';
import { examBookingStatusOptionsGetter } from '../../status-transition-rules/booking-exam-transitions';
import StatusChangeInfo from '../../utils/StatusChangeInfo';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { convert } from '../../dateConverter'
import { DataForSoeProglongExam } from 'models/soeProlongExam';



export interface BookingOnExamEventRowProps {
    handleClickOpenDialog: (dataForSoe: DataForSoeProglongExam) => void;
    booking: ParticipantExamEvent;
    participantId: string;
    actions: {
        oneEditParticipantBooking: TableAction;
        onChangeParticipantBookingStatus: TableAction;
        onChangeParticipantExamBookingStatus: TableAction;
        onExamBookingDetails: TableAction;
    };
}

export const BookingOnExamEventRow: FunctionComponent<BookingOnExamEventRowProps> = (
    props: BookingOnExamEventRowProps
) => {
    const [open, setOpen] = useState<boolean>(false);

    const statusOptions = bookingStatusOptionsGetter(props.booking.relationDetails.status);
    const singleStatusOptions = (status: string) => {
        return examBookingStatusOptionsGetter(status);
    }
    const checkEnabled = () => {
        if (statusOptions?.length > 0 && props.booking.relationDetails.status !== 'prepared' && props.booking.relationDetails.status !== 'taken')
            return true
        else return false
    }
    const checkEnableBookingExam = (status: string) => {
        const options = singleStatusOptions(status);
        if (options?.length > 0) {
            return true
        } else {
            return false
        }
    }

    const handleMenuItemClickStatus = (updateData: StatusChangeInfo) => {
        props.actions.onChangeParticipantBookingStatus.onClick(props.booking.id, updateData.newState);
    };
    const handleExamBookingClickStatus = (updateData: StatusChangeInfo) => {

        props.actions.onChangeParticipantExamBookingStatus.onClick(props.booking.id, updateData.props.ID, updateData.newState, updateData.examSetId);

    };
    const computeParticipationDate = (startDate: string, status: string, finishDate?: string) => {
        if (status === "not_participated" || status === "prepared") {
            return null
        }
        let date: string = ''
        if (finishDate) {
            date = convert(finishDate, false)
        } else date = convert(startDate, false)
        return date
    };

    return (
        <>
            <TableRow key={props.booking.id}>
                <TableCell>
                    {props.booking.relationDetails.exams ?
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton> : <div></div>}
                </TableCell>
                <TableCell>
                    <Link href={`/examevents/${props.booking.id}`}>
                        {props.booking.id}
                    </Link>
                </TableCell>
                <TableCell>
                    {props.booking.startDate
                        ? convert(props.booking.startDate, true)
                        : ''}
                </TableCell>
                {/* <TableCell>{props.booking.trainingOrgUID}</TableCell> */}
                <TableCell>{props.booking.trainingOrg[0].name} ({props.booking.trainingOrg[0].shortName})</TableCell>
                <TableCell>{props.booking.name}</TableCell>
                <TableCell>{props.booking.state}</TableCell>
                <TableCell>
                    <StatusButton
                        enabled={() => checkEnabled()}
                        status={props.booking.relationDetails.status}
                        statusOptions={statusOptions}
                        onStatusChange={handleMenuItemClickStatus}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Exams
                            </Typography>
                            <Table size="small" aria-label="exams">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Action</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Valid until</TableCell>
                                        <TableCell>Prolong counter</TableCell>
                                        <TableCell>Exam Info</TableCell>
                                        <TableCell>Exam Lang</TableCell>
                                        <TableCell>Participation Date</TableCell>
                                        <TableCell>Percent</TableCell>
                                        <TableCell><Tooltip title="Hide result for user in CFE"><span>Hide Results</span></Tooltip></TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.booking.relationDetails.exams?.map((single, index) => (
                                        <TableRow key={single.linktoexam + index}>
                                            <TableCell>
                                                <Tooltip title={props.actions.onExamBookingDetails.title}>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        //sx={{ mb: 1 }}
                                                        aria-label={props.actions.onExamBookingDetails.title}
                                                        onClick={() => { props.actions.onExamBookingDetails.onClick(props.booking.id, single.examId, single.edumsID, single.info, single.finishTime, single.percent, single.status, single.hideResults) }}
                                                    >
                                                        {<props.actions.onExamBookingDetails.icon color="primary" />}
                                                    </Button>
                                                </Tooltip>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => props.handleClickOpenDialog({
                                                        examEventId: props.booking.id,
                                                        edumsID: props.participantId,
                                                        examID: single.edumsID,
                                                        admin: true
                                                    })}
                                                >
                                                    Prolong Exam
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title={"EdumsID: " + single.edumsID + " SQMS2_ExamSet_ID: " + single?.SQMS2_ExamSet_ID + " fixedID: " + single?.fixedID}>
                                                    <span>
                                                        {single.name_of_exam}
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>{single.valid_until}</TableCell>
                                            <TableCell>{
                                                (props.booking?.relationDetails
                                                    && props.booking.relationDetails.hasOwnProperty('prolongExamBooking')
                                                    && props.booking.relationDetails?.prolongExamBooking !== null
                                                    && props.booking.relationDetails?.prolongExamBooking !== '') ? props.booking.relationDetails.prolongExamBooking : 0
                                            }</TableCell>
                                            <TableCell>{single.info ? single.info : ''}</TableCell>
                                            <TableCell>{single.examLanguage}</TableCell>
                                            <TableCell>{computeParticipationDate(props.booking.startDate, single.status, single.finishTime)}</TableCell>
                                            <TableCell>{single.percent ? single.percent : ''}</TableCell>
                                            <TableCell>{single.hideResults ? single.hideResults == true ? "Yes" : "No" : "No"}</TableCell>
                                            <TableCell>
                                                <StatusButton
                                                    enabled={() => checkEnableBookingExam(single.status)}
                                                    status={single.status}
                                                    statusOptions={singleStatusOptions(single.status)}
                                                    onStatusChange={handleExamBookingClickStatus}
                                                    ID={single.examId}
                                                    examSetId={single.edumsID}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </>
    );
};
