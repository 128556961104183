import { FC } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';

export interface EmptyProps {
    text?: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
}

const EmptyTable: FC<EmptyProps> = (props: EmptyProps) => (
    <div style={{ textAlign: 'center', fontSize: 20, padding: 30 }}>
        <props.icon style={{ fontSize: 60 }} />
        <div>{props.text ? props.text : 'No data to show'}</div>
    </div>
);

export default EmptyTable;
