import {
    Button,
    TableCell,
    TableRow,
    Tooltip,
    Chip,
    Grid,
    IconButton,
    Collapse,
    Box,
    Typography,
    Table,
    TableBody,
    TableHead,
    Link,
    CardActionArea,
    Card,
    CardMedia
} from '@mui/material';
import { FunctionComponent, useContext, useState } from 'react';
import { AlertContext } from '../../App';
import { TableAction } from 'models/table-models/TableAction';
import { Organization } from '../../models/Organization';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { organizationsService } from 'services/organizations/organizations.service';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import ImageCard from 'components/atoms/ImageCard';
import PlaceholderImage from 'assets/no-image-icon-23485.png'

export interface OrganizationRowProps {
    row: Organization;
    actions: TableAction[];
    //TODO: needs also to have title/aria-label value passed in here
}

export const OrganizationRow: FunctionComponent<OrganizationRowProps> = (props: OrganizationRowProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const [expandRow, setExpandRow] = useState<boolean>(false);
    const [organization, setOrganization] = useState<any>();
    const [loading, setLoading] = useState<boolean>();

    const handleOnClickRow = () => {
        if (!expandRow) {
            setLoading(true);
            organizationsService
                .getExamEventsPerOrganization(props.row.id)
                .then((res) => {
                    setOrganization(res);
                    setExpandRow(true);
                    setLoading(false);
                })
                .catch((error) => {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    );
                    setLoading(false);
                });
        } else {
            setExpandRow(false);
        }
    };

    let imageUrl = `${process.env.REACT_APP_BASE_BLOB_URL}/badge/organisation/${props.row.id}/logo/${props.row.id}.jpg`;

    console.log("imageUrl", props.row.id, imageUrl);

    return (
        <>
            <TableRow key={props.row.id} hover>
                <TableCell>
                    {loading && <CircularProgress thickness={5} size={24} />}
                    {!loading && true ? (
                        <IconButton aria-label="expand row" size="small" onClick={handleOnClickRow}>
                            {expandRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    ) : (
                        <div></div>
                    )}
                </TableCell>
                <TableCell>
                    <Grid container spacing={1}>
                        {props.actions &&
                            props.actions.map((single, index) => (
                                <Grid item key={index}>
                                    <Tooltip title={single.title}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            aria-label="filter list"
                                            //@ts-ignore
                                            onClick={() => single.onClick(props.row.id)}
                                        >
                                            {<single.icon color="primary" />}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            ))}
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container spacing={1}>
                        {props.row.emails &&
                            props.row.emails.map((e) => (
                                <Grid item key={e.id}>
                                    <Chip color={e.state === 'active' ? 'primary' : 'default'} label={e.email}></Chip>
                                </Grid>
                            ))}
                    </Grid>
                </TableCell>
                <TableCell>{props.row.name}</TableCell>
                <TableCell>{props.row.shortName}</TableCell>
                <TableCell>{props.row.organizationUrl}</TableCell>
                <TableCell>{props.row.type}</TableCell>
                <TableCell>
                    <CardMedia
                        component="img"
                        image={props.row.logo != "" ? props.row.logo : PlaceholderImage}
                        onError={(e: any) => {
                            e.target.src = PlaceholderImage
                        }}
                        sx={{
                            width: 70,
                            height: 70
                        }}
                    />
                    {/* <ImageCard imageUrl={imageUrl} /> */}
                </TableCell>

                <TableCell>{props.row.status === true ? 'active' : 'inactive'}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={expandRow} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Exam events
                            </Typography>
                            <Table size="small" aria-label="exams">
                                <TableHead>
                                    {organization && organization.examEvents && organization.examEvents.length === 0 ? (
                                        <></>
                                    ) : (
                                        <TableRow>
                                            <TableCell>ExamEvent ID</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>State</TableCell>
                                        </TableRow>
                                    )}
                                </TableHead>
                                {
                                    <TableBody>
                                        {organization &&
                                            organization.examEvents &&
                                            organization.examEvents.length === 0 ? (
                                            <TableRow>
                                                <TableCell>Exam events for this organization are not found</TableCell>
                                            </TableRow>
                                        ) : (
                                            organization?.examEvents.map((examEvent, index) => (
                                                <TableRow key={examEvent.id + index}>
                                                    <TableCell>
                                                        <Link href={"/examevents/" + examEvent.id}>{examEvent.id}</Link>
                                                    </TableCell>
                                                    <TableCell>{examEvent.name}</TableCell>
                                                    <TableCell>{examEvent.state}</TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                }
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
