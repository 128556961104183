import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    DialogContent,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Link,
    Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { AlertContext } from 'App';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { DialogActions } from 'customized-components';
import { Alignment } from 'models/Alignments';
import { Badge, BadgeCreateRequest } from 'models/Badges';
import { Certificate } from 'models/Certificate';
import { ALIGNMENT_STATUS, BADGE_STATUS } from 'models/enums';
import React, { Fragment, useContext, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { badgesService } from 'services/badges/badgesService';

import { CertificateSelectModel } from '../../../models/context/CertificateSelectModel';
import { certificatesService } from '../../../services/certificates/certificates.service';
import { defaultBadgesFilters } from 'models/table-filters.ts/badges-table-filters';

interface EditBadgeFormProps {
    alignments: any;
    certificates: any;
    badge: Badge;
    cancel: () => void;
    createBadge?: boolean;
    onBadgeUpdate: (arg0?: any) => void;
    updateAlignments: (arg0?: any) => void;
}

const defaultRequest = {
    page: 0,
    rowsPerPage: 10,
    orderBy: 'createdAt',
    order: 'desc',
    filters: defaultBadgesFilters,
};

const EditBadgeForm = (props: EditBadgeFormProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [tags, setTags] = useState<any>(props.badge?.tags ? props.badge.tags : []);
    const [options, setOptions] = useState([]);

    const collectAlignmentObjects = (alignmentIds: string[]) => {
        let alignments: Alignment[] | [] = [];
        alignmentIds.forEach((id) => {
            const alignment = props.alignments?.find((e: Alignment) => e.id === id);
            alignments.push(alignment as never);
        });
        return alignments;
    };

    //@ts-ignore
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        defaultValues: props.createBadge
            ? {
                  alignments: [],
                  certificate: '',
                  title: '',
                  description: '',
                  image: '',
                  criteriaURL: '',
                  tags: [],
                  //   status: '',
                  issuer: '',
              }
            : {
                  ...props.badge,
                  alignments: props.badge.alignments?.map((alignment: Alignment) => alignment.id),
                  certificate: props.badge.certificate?.[0],
                  tags: props.badge?.tags ? props.badge.tags : [],
                  issuer: props.badge.issuer ? JSON.stringify(props.badge.issuer) : '',
                  description: props.badge.description,
              },
    });

    //const collectCertificate = (id: string) => props.certificates.find((certificate: Certificate) => certificate.id === id)

    const onSubmit = (data: any) => {
        setLoading(true);
        let body;
        if (props.createBadge) {
            body = {
                alignments: collectAlignmentObjects(data.alignments),
                certificate: data.certificate.id,
                //certificate: collectCertificate(data.certificate.id)?.id || '',
                title: data.title,
                description: data.description,
                image: data.image,
                criteriaURL: data.criteriaURL,
                tags: tags ? tags : [],
                status: BADGE_STATUS.NEW,
                issuer: data.issuer.trim() ? JSON.parse(data.issuer) : null,
            };

            badgesService
                .createBadge(body as BadgeCreateRequest)
                .then(() => {
                    handleAlert('Badge created successfully!', 'success');
                    props.cancel();
                    setLoading(false);
                    props.onBadgeUpdate && props.onBadgeUpdate(defaultRequest);
                    props.updateAlignments && props.updateAlignments();
                })
                .catch((error) => {
                    props.cancel();
                    handleAlert(error.message, error);
                });
        } else {
            body = {
                ...props.badge,
                alignments: collectAlignmentObjects(data.alignments),
                certificate: data.certificate.id,
                //certificate: collectCertificate(data.certificate.id).id || '',
                title: data.title,
                description: data.description,
                image: data.image,
                criteriaURL: data.criteriaURL,
                tags: tags,
                issuer: data.issuer.trim() ? JSON.parse(data.issuer) : null,
                // status: data.status,
            };

            badgesService
                .updateBadge(body, props.badge.id)
                .then((res) => {
                    if (res.status === 200 || res.message === 'OK') {
                        handleAlert('Badge updated successfully!', 'success');
                        props.cancel();
                        setLoading(false);
                        // const defaultRequest = {
                        //     page: 0,
                        //     rowsPerPage: 10,
                        //     orderBy: 'createdAt',
                        //     order: 'desc',
                        //     filters: defaultBadgesFilters,
                        // };
                        props.onBadgeUpdate(defaultRequest);
                        props.updateAlignments();
                    } else {
                        props.cancel();
                        handleAlert(res.type ? res.type : 'Ups! Something went wrong!', 'error');
                    }
                })
                .catch((error) => {
                    props.cancel();
                    handleAlert(
                        error?.response?.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    );
                });
        }
    };

    const renderAlignmentNames = (selected: string[]): string[] => {
        let names: string[] = [];
        selected.forEach((id) => {
            // const alignment = props.alignments?.find((e: Alignment) => e.id === id && e.status === BADGE_STATUS.NEW)
            const alignment = props.alignments?.find((e: Alignment) => e.id === id);
            names.push(alignment?.targetName as string);
        });
        return names;
    };

    const handleAlignmentChange = (event: any) => {
        if (event.target.value) {
            setValue('alignments', event.target.value);
        }
    };

    const handleStatusChange = (event: any) => {
        if (event.target.value) {
            setValue('status', event.target.value);
        }
    };

    const handleTagChange = (event) => {
        if (event.keyCode == 13) {
            const newTag = event.target.value.trim();
            if (newTag && newTag.length > 0 && !tags.includes(newTag)) setTags([...tags, newTag]);
        } else if (event.keyCode == 8) {
            const newTag = event.target.value.trim();
            if (newTag.length == 0) {
                setTags(tags.slice(0, -1));
            }
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags((tags) => tags.filter((tag) => tag !== tagToDelete));
    };

    const onDblTagClick = (tag) => {};

    const getData = (searchTerm: string) => {
        // TODO Mihajlo
        /* const previousController = useRef();
    if (previousController.current) {
      previousController.current.abort();
    }

    const controller = new AbortController();
    const signal = controller.signal; */

        const queryRequest: CertificateSelectModel = {
            query: searchTerm,
        };
        if (searchTerm.length > 3) {
            certificatesService.getCertificatesForSelectMenu(queryRequest).then(function (res: any) {
                let updatedOptions = res.data.rows.map((cert: Certificate) => ({
                    label: cert.computed ? cert.name + ' - (role cert)' : cert.name,
                    id: cert.id,
                    status: cert.status,
                }));
                updatedOptions = updatedOptions.filter((option) => option.status == 'active');
                setOptions(updatedOptions as any);
            });
        }
    };

    const onInputChange = (event, value, reason) => {
        if (value) {
            getData(value);
        } else {
            setOptions([]);
        }
    };

    const [isValidJSON, setIsValidJSON] = useState(true);
    const validateJSON = (value) => {
        // If the value is empty, consider it valid
        if (!value.trim()) {
            setIsValidJSON(true);
            return true;
        }

        try {
            JSON.parse(value);
            setIsValidJSON(true);
            return true;
        } catch (error) {
            setIsValidJSON(false);
            return false;
        }
    };

    // const certificateOptions = props.certificates.map((cert: Certificate) => ({
    //     label: cert.computed ? cert.name + ' - (role cert)' : cert.name,
    //     id: cert.id,
    // }));

    // let statusOptions;
    // if (props.badge?.status === BADGE_STATUS.NEW) {
    //     statusOptions = Object.values(BADGE_STATUS);
    // } else if (props.badge?.status === BADGE_STATUS.ACTIVE || props.badge?.status === BADGE_STATUS.PUBLISHED) {
    //     statusOptions = Object.values(BADGE_STATUS).filter((status) => status !== BADGE_STATUS.NEW);
    // } else if (props.badge?.status === BADGE_STATUS.INACTIVE) {
    //     statusOptions = [BADGE_STATUS.INACTIVE];
    // } else {
    //     statusOptions = []; // Default empty or add any other default logic
    // }

    // @ts-ignore
    return (
        // @ts-ignore
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent
                sx={{
                    borderLeft: '3px solid #4765A0',
                    borderRight: '3px solid #4765A0',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="title"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="title"
                                    id="title"
                                    size="small"
                                    label="Long name"
                                    variant="outlined"
                                    error={!!errors.title}
                                    helperText={errors.title && errors.title.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'title is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="alignments"
                                render={({ field }) => (
                                    <Fragment>
                                        <InputLabel htmlFor="alignments">Alignments</InputLabel>
                                        <Select
                                            {...field}
                                            data-testid="alignments"
                                            id="alignments"
                                            label="Alignments"
                                            multiple
                                            renderValue={(selected) =>
                                                (renderAlignmentNames(selected as string[]) as string[]).join(', ')
                                            }
                                            error={!!errors.alignments}
                                            onChange={handleAlignmentChange}
                                        >
                                            {props.alignments && props.alignments?.length >= 1 ? (
                                                props.alignments?.map((alignment: Alignment) => {
                                                    const id: never | string = alignment.id;
                                                    if (
                                                        alignment.status === ALIGNMENT_STATUS.NEW ||
                                                        alignment.status === ALIGNMENT_STATUS.ASSIGNED
                                                    ) {
                                                        return (
                                                            <MenuItem key={id} value={id}>
                                                                <Checkbox
                                                                    checked={field.value.indexOf(id as never) > -1}
                                                                />
                                                                <ListItemText primary={alignment.targetName} />
                                                                <ListItemText secondary={alignment.status} />
                                                            </MenuItem>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            ) : (
                                                <MenuItem disabled={true}>No data to show</MenuItem>
                                            )}
                                        </Select>
                                        <FormHelperText
                                            style={{
                                                color: errors.alignments ? 'red' : 'grey',
                                            }}
                                        >
                                            {errors.alignments && 'Alignments are required field'}
                                        </FormHelperText>
                                    </Fragment>
                                )}
                                control={control}
                                defaultValue={[]}
                                rules={
                                    {
                                        //required: 'Alignments One - are required field'
                                    }
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="description"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="description"
                                    id="description"
                                    label="Description"
                                    variant="outlined"
                                    placeholder="Badge description"
                                    minRows={4}
                                    multiline
                                    maxRows={10}
                                    error={!!errors.description}
                                    helperText={
                                        <>
                                            {errors.description && <span>{errors.description.message}</span>}
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: field.value || '',
                                                }}
                                            />
                                        </>
                                    }
                                    InputProps={
                                        {
                                            // disableUnderline: true // Remove underline for better visual integration
                                        }
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { display: 'block' }, // Ensure label is always visible
                                    }}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Description is required field',
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="image"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="image"
                                    id="image"
                                    size="small"
                                    label="Image"
                                    variant="outlined"
                                    error={!!errors.image}
                                    helperText={errors.image && errors.image.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Image is required field',
                                validate: {
                                    endsWithPngOrSvg: (value) =>
                                        value.toLowerCase().endsWith('.png') ||
                                        value.toLowerCase().endsWith('.svg') ||
                                        'Image must be either .png or .svg',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="criteriaURL"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="criteriaURL"
                                    id="criteriaURL"
                                    size="small"
                                    label="Criteria URL"
                                    variant="outlined"
                                    error={!!errors.criteriaURL}
                                    helperText={errors.criteriaURL && errors.criteriaURL.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Criteria URL is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="certificate"
                                render={(
                                    { field } // , fieldState
                                ) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            id="autocomplete-certificate"
                                            //options={certificateOptions}
                                            options={options}
                                            onInputChange={onInputChange}
                                            //getOptionLabel={(option) => option.title}
                                            getOptionLabel={(option) => option.name || option.label || ''}
                                            data-testid="test-certificate"
                                            value={field.value}
                                            isOptionEqualToValue={(option, value) => {
                                                return value === undefined || value === '' || option.id === value.id;
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        error={!!errors.certificate}
                                                        label="Certificate"
                                                        size="small"
                                                        placeholder="Type minimum 3 letters to search certificates"
                                                        //error={!!fieldState.error}
                                                        //helperText="Type minimum 3 letters to search certificates"
                                                    />
                                                );
                                            }}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    {option.label}
                                                </Box>
                                            )}
                                            autoHighlight
                                            onChange={(_, data) => field.onChange(data)}
                                        />
                                        <FormHelperText
                                            style={{
                                                color: errors.certificate ? 'red' : 'grey',
                                            }}
                                        >
                                            {errors.certificate && 'Certificates are required field'}
                                        </FormHelperText>
                                    </>
                                )}
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: 'Field required',
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="issuer"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="issuer"
                                    id="issuer"
                                    size="small"
                                    label="Issuer (JSON)"
                                    variant="outlined"
                                    multiline
                                    helperText={!isValidJSON && 'Invalid JSON format'}
                                    error={!isValidJSON}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{ validate: validateJSON }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="tags"
                            render={({ field: { value } }) => (
                                <>
                                    <Autocomplete
                                        multiple
                                        id="tags-filled"
                                        options={[]}
                                        value={tags}
                                        freeSolo
                                        disableClearable
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: string, index: number) => (
                                                <Chip
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({
                                                        index,
                                                    })}
                                                    onDelete={() => handleDeleteTag(option)}
                                                    clickable={true}
                                                    onDoubleClick={() => onDblTagClick(option)}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="tags"
                                                label="Tags"
                                                fullWidth
                                                value={tags}
                                                size="small"
                                                onKeyDown={handleTagChange}
                                            />
                                        )}
                                    />
                                </>
                            )}
                            control={control}
                        />
                        {/* <TextField
              id="tags"
              label="Tags"
              fullWidth
              defaultValue={tags}
              value={tags}
              onChange={handleTagChange}
            />*/}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button
                        disabled={
                            loading ||
                            props.badge?.status === BADGE_STATUS.PUBLISHED ||
                            props.badge?.status === BADGE_STATUS.ACTIVE ||
                            props.badge?.status === BADGE_STATUS.INACTIVE
                        }
                        data-testid=""
                        color="primary"
                        type="submit"
                        variant="contained"
                    >
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        //@ts-ignore
                        reset({
                            title: '',
                            alignments: [],
                            certificate: {},
                            description: '',
                            image: '',
                            criteriaURL: '',
                            tags: tags,
                            issuer: '',
                        });
                        props.cancel();
                    }}
                    data-testid=""
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};

export default EditBadgeForm;
