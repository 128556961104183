import React, {useContext} from 'react';
import {AlertContext} from "App";
import {Alignment} from "models/Alignments";
import {alignmentsService} from "services/alignments/alignmentsService";
import {
    DialogContent
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ButtonWrapper from "components/atoms/buttons/ButtonWrapper";
import Button from "@mui/material/Button";
import {DialogActions} from "customized-components";

interface DeleteAlignmentFormProps {
    alignment: Alignment;
    cancel: () => void;
    onAlignmentDelete: (arg0?: any) => void;
}

const DeleteAlignmentForm = (props: DeleteAlignmentFormProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const handleDelete = () => {
            alignmentsService.deleteAlignment(props.alignment.id).then(() => {
                handleAlert('Alignment deleted successfully!', 'success');
                props.cancel();
                props.onAlignmentDelete && props.onAlignmentDelete();
            }).catch((error) => {
                handleAlert('Ups! Something went wrong!', error)
                }
            );

        }

    // @ts-ignore
    return (
        // @ts-ignore
        <>
            <DialogContent sx={{ borderLeft: '3px solid #4765A0',
                borderRight: '3px solid #4765A0'}}>
                <Grid container spacing={3}>
                    <Grid item>
                        <h4>Are you sure you want to delete {props.alignment.targetName} alignment?</h4>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button data-testid="delete" onClick={handleDelete} color="primary" type="submit" variant="contained">
                        Delete
                    </Button>
                </ButtonWrapper>
                <Button
                    data-testid="cancel"
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default DeleteAlignmentForm;
