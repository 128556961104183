import { Fragment, useState, useContext, FunctionComponent } from 'react';
import ComplexTable from 'components/organisms/ComplexTable';
import { ParticipantRow } from '../participants/participant-row';
import { Participant } from '../../models/Participant';
import PersonAdd from '@mui/icons-material/PersonAdd';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from 'react';
import { participantsService } from '../../services/participants/participants.service';
import { organizationsService } from '../../services/organizations/organizations.service';
import { usersHeaders } from '../../models/table-headers/participants-headers';
import { membersHeaders } from '../../models/table-headers/organization-members-header';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { AlertContext } from '../../App';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import { defaultOrganizationsFilters as defaultUsersFilters } from '../../models/table-filters.ts/organizations-table-filters';
import { authenticationService } from '../../services/authentication/authentication.service';
import { UserContext } from '../../context/UserContext';
import { Organization } from '../../models/Organization';
import { useParams, useHistory } from 'react-router-dom';
import { OrganizationMember } from '../../models/Organization';
import { OrganizationMembersRow } from './organization-members-row';
import { mailsService } from '../../services/mails/mails.service';
import { Email } from '../../models/Email';
import { ModalDialog } from '../../customized-components/modal-dialog/modal-dialog';
import { MailManagementForm } from '../mail-management-dialog/mail-management-dialog';
import MailIcon from '@mui/icons-material/Mail';
import { MailReassignUserDialog } from '../mail-reassign-dialog/mail-reassign-users-dialog';
import { MailReassignOrganizationDialog } from '../mail-reassign-dialog/mail-reassign-organizations-dialog';
import { UserSetPermissionDialog } from '../user-set-permission-dialog/user-set-permission-dialog';
import { Grid } from '@mui/material';
import { OrdersSection } from '../orders/order-section'


export const OrganizationMembers: FunctionComponent = () => {
    //@ts-ignore
    const { id } = useParams();
    const history = useHistory();
    const defaultRequest: PaginationSortingFilteringRequest = {
        order: 'desc',
        orderBy: 'createdAt',
        page: 0,
        rowsPerPage: 10,
        filters: defaultUsersFilters()
    };

    const [participants, setParticipants] = useState<TablePageSortFilter>({
        ...defaultRequest,
        total: 10,
        results: [],
    });
    const [organization, setOrganization] = useState<Organization>();
    const [loading, setLoading] = useState<boolean>(false);
    const [dialogMailManagement, setDialogMailManagement] = useState<boolean>(false);
    const [dialogMailUserReassign, setDialogMailUserReassign] = useState<boolean>(false);
    const [dialogMailOrganizationReassign, setDialogMailOrganizationReassign] = useState<boolean>(false);
    const [dialogSetUserPermissions, setDialogSetUserPermissions] = useState<boolean>(false);
    const [selectedMailToReassign, setSelectedMailToReassign] = useState<string>('');
    const [emails, setEmails] = useState<Email[]>([]);
    const [currentUser, setCurrentUser] = useState<OrganizationMember>();


    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData, setUserData } = useContext(UserContext);

    useEffect(() => {
        if (!userData) {
            authenticationService.getLoggedInUserData().then((res: any) => {
                //@ts-ignore
                setUserData(res);
                loadParticipants(defaultRequest);
                loadOrganization();
            });
        } else {
            loadParticipants(defaultRequest);
            loadOrganization();
        }

    }, []);

    const handleToggleMailManagementDialog = () => {
        setDialogMailManagement(!dialogMailManagement);
    };

    const handleToggleUserPermissionDialog = () => {
        setDialogSetUserPermissions(!dialogSetUserPermissions);
    };


    const handleToggleMailReassignUserDialog = (id: string) => {
        setDialogMailUserReassign(!dialogMailUserReassign);
        if (id) {
            setSelectedMailToReassign(id);
        }
    };

    const handleToggleMailReassignOrganizationDialog = (id: string) => {
        setDialogMailOrganizationReassign(!dialogMailOrganizationReassign);
        if (id) {
            setSelectedMailToReassign(id);
        }
    };

    const updateEmail = (emailId: string, newState: string) => {
        mailsService
            .updateEmailStatus(emailId, newState)
            .then((res) => {
                const updatedList = [...emails];
                const emailIndex = updatedList.findIndex((e) => e.id === emailId);
                updatedList[emailIndex].state = newState;
                setEmails(updatedList);
                handleAlert('Email updated successfully', 'success');
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const addNewMail = (email: string) => {
        organizationsService.addNewMail({ email: email }, id).then((res) => {
            handleAlert('Email added successfully', 'success');
            loadOrganization();
        }).catch((error) =>
            handleAlert(
                error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                'error'
            )
        );
    };

    const loadParticipants = (data: PaginationSortingFilteringRequest) => {
        setLoading(true)
        participantsService
            .getNonMembersUsers(data, id)
            .then((res) => {
                setParticipants(res);
                setLoading(false)
            })
            .catch((error) => {
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                );
            });
    };

    const loadOrganization = () => {
        setLoading(true)
        organizationsService
            .getOrganization(id)
            .then((res) => {
                setOrganization(res);
                setEmails(res.emails)
                setLoading(false)
            })
            .catch((error) => {
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                );
            });
    };

    const handleAddMember = (reqBody: object, user: Participant) => {
        organizationsService
            .addMember(id, reqBody)
            .then((res) => {
                loadOrganization();

                //removing added member from users table
                const newParticipants = participants.results.filter((single) => single.id !== user.id)
                if (newParticipants.length === 0) {
                    loadParticipants(defaultRequest);
                } else
                    setParticipants({ ...defaultRequest, total: 10, results: newParticipants })

                handleAlert('User successfully added to organization!', 'success');
            })
            .catch((error) => {
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                );
            });


    };

    const handleRemoveMember = (user: OrganizationMember) => {

        let relationID = user.relationDetails.relationID;

        if (window.confirm("Delete?")) {
            organizationsService
                .removeMember(relationID)
                .then((res) => {
                    loadOrganization();
                    handleAlert('User successfully Removed from organization!', 'success');
                })
                .catch((error) => {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    );
                });
        }

    };

    const toggleEditUserPermissionDialog = (user: OrganizationMember) => {
        setDialogSetUserPermissions(!dialogSetUserPermissions);
        setCurrentUser(user);
    }

    const openParticipantDetailsPage = (id: string) => {
        history.push(`/participants/${id}`, { id });
    };

    const userActions = {
        onEditParticipant: {
            title: 'Edit Person',
            icon: EditIcon,
            onClick: openParticipantDetailsPage,
        },
        onCreateBookingOnExamEvent: undefined,
        onParticipantStatusChange: undefined,
        onAddMember: {
            title: 'Add member to organization',
            icon: PersonAdd,
            onClick: handleAddMember,
        },
    };

    const memberActions = {
        onRemoveMember: {
            title: 'Remove member from organization',
            icon: RemoveCircleIcon,
            onClick: handleRemoveMember,
        },
        onEditMember: {
            title: 'Edit',
            icon: EditIcon,
            onClick: toggleEditUserPermissionDialog,
        },
    };

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ComplexTable
                        tableData={{
                            order: 'asc',
                            orderBy: 'firstName',
                            page: 0,
                            rowsPerPage: 10,
                            total: 10,
                            results: organization?.users as any[],
                            filters: []
                        }}
                        headerCells={membersHeaders}
                        title={`${organization?.name} Members`}
                        addAction={handleToggleMailManagementDialog}
                        addIcon={MailIcon}
                        updateTableData={loadOrganization}
                        loading={loading}
                    >
                        {organization?.users
                            ? organization.users.map((single) => (
                                <OrganizationMembersRow key={single.id} row={single} actions={memberActions} />
                            ))
                            : null}
                    </ComplexTable>
                </Grid>
                <Grid item xs={12}>
                    <ComplexTable
                        tableData={participants as TablePageSortFilter}
                        headerCells={usersHeaders}
                        title="Users who are not members of current organization"
                        addAction={() => { }}
                        addIcon={undefined}
                        updateTableData={loadParticipants}
                        loading={loading}
                    >
                        {participants?.results
                            ? participants.results.map((r) => <ParticipantRow key={r.id} row={r} actions={userActions} />)
                            : null}
                    </ComplexTable>
                </Grid>
                <Grid item xs={12}>
                    <OrdersSection id={id} />
                </Grid>
            </Grid>

            {dialogSetUserPermissions ? (
                <ModalDialog
                    dialogIsOpen={dialogSetUserPermissions}
                    //@ts-ignore
                    setDialogOpen={handleToggleUserPermissionDialog}
                    title="User Set Permissions"
                >
                    <UserSetPermissionDialog
                        currentUser={currentUser as OrganizationMember}
                        updateOrganization={loadOrganization}
                        closeDialog={() => setDialogSetUserPermissions(false)}
                    />
                </ModalDialog>
            ) : null}
            {dialogMailManagement ? (
                <ModalDialog
                    dialogIsOpen={dialogMailManagement}
                    //@ts-ignore
                    setDialogOpen={handleToggleMailManagementDialog}
                    title="Organization Mail Management"
                >
                    <MailManagementForm
                        emails={emails as Email[]}
                        updateEmail={updateEmail}
                        addNewMail={addNewMail}
                        onMailReassignOrganizationModalToggle={handleToggleMailReassignOrganizationDialog}
                        onMailReassignUserModalToggle={handleToggleMailReassignUserDialog}
                        closeDialog={() => setDialogMailManagement(false)}
                        type={"organization"}
                    />
                </ModalDialog>
            ) : null}
            {dialogMailUserReassign ? (
                <ModalDialog
                    dialogIsOpen={dialogMailUserReassign}
                    //@ts-ignore
                    setDialogOpen={handleToggleMailReassignUserDialog}
                    title="Organization Mail Management"
                    fullscreen={true}
                >
                    <MailReassignUserDialog
                        closeDialog={() => setDialogMailUserReassign(false)}
                        onReassignSuccess={() => loadOrganization()}
                        emailId={selectedMailToReassign}
                    />
                </ModalDialog>
            ) : null}
            {dialogMailOrganizationReassign ? (
                <ModalDialog
                    dialogIsOpen={dialogMailOrganizationReassign}
                    //@ts-ignore
                    setDialogOpen={handleToggleMailReassignOrganizationDialog}
                    title="Organization Mail Management"
                    fullscreen={true}
                >
                    <MailReassignOrganizationDialog
                        closeDialog={() => setDialogMailOrganizationReassign(false)}
                        onReassignSuccess={() => loadOrganization()}
                        emailId={selectedMailToReassign}
                    />
                </ModalDialog>
            ) : null}
        </Fragment>
    );
};
