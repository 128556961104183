import { createTheme  } from '@mui/material/styles';
import { defaultTheme } from './defaultTheme';

export const msEduTheme = createTheme(({
    palette: {
        primary: {
            main: defaultTheme.palette.primary.main,
        },
        secondary: {
            main: defaultTheme.palette.secondary.main,
        },
    },
    typography: {
        fontFamily: [
            '"Open Sans"',
            'sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
}));
