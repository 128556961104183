import React, { FunctionComponent, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import {DialogTitleProps} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PropsWithChildren } from 'react';
import { IconButton } from '@mui/material';
import { classes, StyledContentComponent, StyledActionComponent, StyledDialog } from "./styles";


// const Transition = forwardRef(function Transition(
//     props: TransitionProps & { children: ReactElement<any, any> },
//     ref: Ref<unknown>
// ) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

export interface TitleProps extends DialogTitleProps {
    id: string;
    children: ReactNode;
    onClose: () => void;
    fullscreen?: boolean;
}

const DialogTitle = ((props: TitleProps) => {
    const { children, onClose, ...other } = props;
    return (
        <StyledDialog className={classes?.root3} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    //@ts-ignore
                    className={classes?.closeButton}
                    onClick={onClose}
                    size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </StyledDialog>
    );
});

export const DialogContent = StyledContentComponent;

export const DialogActions = StyledActionComponent;

export interface ModalDialogProps extends PropsWithChildren<any> {
    dialogIsOpen: boolean;
    setDialogOpen: () => void;
}

export const ModalDialog: FunctionComponent<ModalDialogProps> = (props: ModalDialogProps) => {

    const handleClose = () => {
        props.setDialogOpen();
    };

    return (
        <Dialog
            open={props.dialogIsOpen}
            // TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth="md"
            fullScreen={props.fullscreen}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {props.title}
            </DialogTitle>
            {props.children}
        </Dialog>
    );
};
