import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { classes } from 'components/organisms/ComplexTable/styles';

export interface HeadCell {
    disablePadding: boolean;
    id: keyof any;
    label: string;
    numeric: boolean;
    disableSorting: boolean;
}

type Order = 'asc' | 'desc';

export interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void;
    order: Order;
    orderBy: string;
    headerCells: HeadCell[];
    multiSelect?: any;
}

const ComplexTableHead = (props: EnhancedTableProps) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            props.multiSelect.onSelectAll(true)
        } else props.multiSelect.onSelectAll(false)
    }

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow>
                {props.multiSelect && <TableCell>
                    <Checkbox
                        indeterminate={props.multiSelect.numSelected > 0 && props.multiSelect.numSelected < props.multiSelect.rowCount}
                        checked={props.multiSelect.numSelected > 0 && props.multiSelect.numSelected === props.multiSelect.rowCount}
                        onChange={onSelectAll}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>}
                {props.headerCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {!headCell.disableSorting ? <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            style={{ fontWeight: 600, color: 'white' }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel> : <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: '0.9rem' }}>{headCell.label}</Typography>}
                    </TableCell>

                ))}
            </TableRow>
        </TableHead>
    );
};

export default ComplexTableHead;
