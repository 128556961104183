import React from 'react';
import { Link, Typography, Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import { withRouter } from 'react-router';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';



const Breadcrumbs = (props: any) => {
	const { history, location: { pathname } } = props;
	const pathNames = pathname.split('/').filter((x: any) => x)

	const showPath = (path: string, index: number) => {
		if (index === 0) {
			switch (pathNames[0]) {
				case 'participants':
					return 'Persons'
				case 'examevents':
					return 'Exam Events'
				case 'organizations':
					return 'Organizations'
				case 'profile':
					return 'Profile'
				case 'certificates':
					return 'Certificates'
				default:
					break;
			}
		} else if (index === 1) {
			switch (pathNames[0]) {
				case 'participants':
					return 'Details'
				case 'examevents':
					return 'Details'
				case 'organizations':
					return 'Members'
				default:
					break;
			}
		}
	}

	return (
		<MUIBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} style={{ cursor: 'pointer' }}>
			<Link color="inherit" onClick={() => history.push('/')}>Home</Link>
			{pathNames.map((path: string, index: any) => {
				const last = index === pathNames.length - 1;
				const goTo = `/${pathNames.slice(0, index + 1).join('/')}`

				return last ? (
					<Typography color="textPrimary" key={index}>
						{showPath(path, index)}
					</Typography>
				) : (
					<Link color="inherit" onClick={() => history.push(goTo)} key={index}>
						{showPath(path, index)}
					</Link>
				);

			})}
		</MUIBreadcrumbs>
	);
}

export default withRouter(Breadcrumbs)