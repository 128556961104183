import { FunctionComponent, useState, useEffect } from 'react';
import { DialogActions, DialogContent } from 'customized-components';
import { Grid, Button, FormControl, FormHelperText, TextField, Autocomplete, Box } from '@mui/material';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { Controller, useForm } from 'react-hook-form';
import { certificatesService } from 'services/certificates/certificates.service';
import { examsService } from 'services/exams/exams.service';
import { Certificate } from 'models/Certificate';

export interface EditCertificateFormProps {
    examId: string;
    cancel: () => void;
    onCertificateModified: () => void;
    selectedCert: any;
}

export const CertificateToExamForm: FunctionComponent<EditCertificateFormProps> = (props: EditCertificateFormProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [certificates, setCertificates] = useState<Certificate[]>([]);

    useEffect(() => {
        certificatesService
            .getCertificates()
            .then((res) => {
                let addObjectToArray = {
                    "id": "null",
                    "name": "Remove Certificate from this Exam",
                }
                res.unshift(addObjectToArray);
                setCertificates(res);
            })
            .catch((error) => {
                // Handle Error 
            });
    }, []);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm(
        {
            defaultValues: {
                certificateId: (props.selectedCert) ? { ...props.selectedCert } : null
            }
        }
    );


    const onSubmit = (data: object) => {
        examsService
            .setCertificateToExam(data['certificateId'].id, props.examId)
            .then((res) => {
                props.cancel();
                props.onCertificateModified();
            })
            .catch((error) => {

            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3} sx={{ height: '300px' }}>
                    <Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="certificateId"
                                render={({ field: { value } }) => (
                                    <>
                                        <Autocomplete
                                            disablePortal
                                            id="certificateId_auto"
                                            value={value}
                                            options={certificates.map((certificate: Certificate) => ({ name: certificate.name, id: certificate.id }))}
                                            renderInput={(params) => {
                                                return (<TextField {...params} error={!!errors.certificateId} label="Certificates" />)
                                            }}
                                            renderOption={(props, option) => (
                                                <Box {...props} key={option.id} component="li" >{option.name}</Box>
                                            )
                                            }
                                            autoHighlight
                                            getOptionLabel={(option) => option.name || ''}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.id === value.id || value === undefined || value === null
                                            }}
                                            onChange={(event: any, newValue: any) => {
                                                setValue("certificateId", newValue)
                                            }}
                                        />
                                        <FormHelperText style={{ color: errors.certificateId ? 'red' : 'grey' }}>
                                            {errors.certificateId && 'Certificates are required field'}
                                        </FormHelperText>
                                    </>
                                )}
                                control={control}
                                defaultValue={''}
                            // rules={{
                            //     required: 'Certificates are required field',
                            // }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
                        Create
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        //@ts-ignore
                        // reset({
                        //     computed: false,
                        //     examID: "",
                        //     certLayout: "",
                        //     backgroundID: "",
                        //     name: "",
                        //     startText: "",
                        //     middleText: "",
                        //     bottomText: "",
                        //     autoIssue: false,
                        //     showFooter: false,
                        //     showPersonalData: false,
                        // });
                        props.cancel();
                    }}
                    data-testid=""
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
