import React, {useContext} from 'react';
import {AlertContext} from "App";
import {Assertion} from "models/Assertions";
import {assertionsService} from "services/assertions/AssertionsService";
import {
    DialogContent
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ButtonWrapper from "components/atoms/buttons/ButtonWrapper";
import Button from "@mui/material/Button";
import {DialogActions} from "customized-components";

interface DeleteAssertionFormProps {
    assertion: Assertion;
    cancel: () => void;
    onAssertionDelete: (arg0?: any) => void;
}

const DeleteAssertionForm = (props: DeleteAssertionFormProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const handleDelete = () => {
            assertionsService.deleteAssertion(props.assertion.id).then(() => {
                handleAlert('Assertion deleted successfully!', 'success');
                props.cancel();
                props.onAssertionDelete && props.onAssertionDelete();
            }).catch((error) => {
                handleAlert('Ups! Something went wrong!', error)
                }
            );

        }

    // @ts-ignore
    return (
        // @ts-ignore
        <>
            <DialogContent sx={{ borderLeft: '3px solid #4765A0',
                borderRight: '3px solid #4765A0'}}>
                <Grid container spacing={3}>
                    <Grid item>
                        <h4>Are you sure you want to delete assertion?</h4>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button data-testid="delete" onClick={handleDelete} color="primary" type="submit" variant="contained">
                        Delete
                    </Button>
                </ButtonWrapper>
                <Button
                    data-testid="cancel"
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default DeleteAssertionForm;
