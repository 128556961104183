import { FunctionComponent, useEffect, useContext, useState } from 'react';
import { Organization } from '../../models/Organization';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '../../customized-components';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { organizationsService } from '../../services/organizations/organizations.service';
import { AlertContext } from '../../App';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';

export interface OrganizationFormProps {
    organization: Organization;
    updateOrganization: (id: String, organization: Organization) => void;
    cancel: () => void;
    onStatusChange: (orgId: string, newState: string) => void;
}

export const OrganizationForm: FunctionComponent<OrganizationFormProps> = (props: OrganizationFormProps) => {

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm<Organization>({ defaultValues: { ...props.organization } });

    useEffect(() => {
        console.log('update button state');
    }, [control])
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<string>('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result as string;
            setSelectedFile(base64String ?? ''); // ensure base64 is always a string
        };
        reader.readAsDataURL(file);
    };

    const onSubmit = (data: Organization) => {
        setLoading(true);
        data.logo = selectedFile
        data.id = props.organization.id;
        organizationsService
            .updateOrganization(data)
            .then((res: any) => {
                reset();
                props.updateOrganization(props.organization.id, data);
                handleAlert('Organization updated successfully!', 'success');
                setLoading(false);
                props.cancel();
            })
            .catch((error) => {
                props.cancel();
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                );
            });
    };
    const handleMenuItemClickStatus = (newState: string) => {
        props.onStatusChange(props.organization.id, newState);
    };
    useEffect(() => {
        reset({ ...props.organization });
    }, [props.organization]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="name"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="name"
                                    id="name"
                                    label="Name"
                                    size="small"
                                    variant="outlined"
                                    error={errors.name ? true : false}
                                    helperText={errors.name && errors.name.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'First name is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="organizationUrl"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="organizationUrl"
                                    id="organizationUrl"
                                    label="Organization Url"
                                    size="small"
                                    variant="outlined"
                                    error={errors.organizationUrl ? true : false}
                                    helperText={errors.organizationUrl && errors.organizationUrl.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Organization Url is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="shortName"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="shortName"
                                    id="shortName"
                                    label="Short Name"
                                    size="small"
                                    variant="outlined"
                                    error={errors.shortName ? true : false}
                                    helperText={errors.shortName && errors.shortName.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Short name is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="status"
                            render={({ field }) => (
                                <FormControl variant="outlined" style={{ minWidth: 100 + '%' }} size="small">
                                    <InputLabel id="select-label-status">Status</InputLabel>
                                    <Select
                                        fullWidth
                                        id="status"
                                        label="Status"
                                        defaultValue={field.value === true ? 'active' : 'inactive'}
                                        onChange={(value) => {
                                            handleMenuItemClickStatus(value.target.value);
                                        }}
                                    >
                                        <MenuItem value={'active'} >Active</MenuItem>
                                        <MenuItem value={'inactive'}>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            control={control}
                            defaultValue={true}
                            rules={{
                                required: 'Status is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="type"
                            render={({ field }) => (
                                <FormControl variant="outlined" style={{ minWidth: 100 + '%' }} size="small">
                                    <InputLabel id="select-label-type">Type</InputLabel>
                                    <Select
                                        {...field}
                                        fullWidth
                                        data-testid="test-type"
                                        labelId="select-label-type"
                                        id="type"
                                        label="Type"
                                        error={errors.type ? true : false}
                                        defaultValue=""
                                        onChange={(value) => {
                                            if (value.target.value)
                                                //@ts-ignore
                                                setValue('type', value.target.value);
                                        }}
                                    >
                                        <MenuItem value={'certification'}>Certification</MenuItem>
                                        <MenuItem value={'admin'}>Admin</MenuItem>
                                        <MenuItem value={'training'}>Training</MenuItem>
                                    </Select>
                                    <FormHelperText style={{ color: errors.type ? 'red' : 'grey' }}>
                                        {errors.type && errors.type.message}
                                    </FormHelperText>
                                </FormControl>
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Type is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="logo"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    // {...field}
                                    data-testid="logo"
                                    id="logo"
                                    //label="Logo"
                                    size="small"
                                    type="file"
                                    inputProps={{ accept: 'image/*' }}
                                    onChange={handleFileChange}
                                    variant="outlined"
                                    error={errors.logo ? true : false}
                                    helperText={errors.logo && errors.logo.message}
                                />
                            )}
                            control={control}
                            //defaultValue=null
                            rules={{
                                //required: 'Logo is required field',
                            }}
                        // [TODO] Mihajlo - we have a prblem or required error even is image is selected
                        />
                    </Grid>
                    {selectedFile && (
                        <Grid item xs={12} sm={6}>
                            <img src={selectedFile} alt="uploaded image" />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="saveupdateorganization" color="primary" type="submit" variant="contained">
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button disabled={loading} onClick={props.cancel} data-testid="cancelneworganization" color="primary" variant="contained">
                    Cancel
                </Button>
            </DialogActions>
        </form >
    );
};
