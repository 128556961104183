import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SvgIconTypeMap,
    TextField,
    Typography
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useEffect, useState } from 'react';
import TableFilter from 'models/table-models/TableFilter';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ToolbarRoot, classes } from './styles';

export interface EnhancedTableToolbarProps {
    title: string;
    filters: TableFilter[];
    addIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | undefined;
    addAction?: () => void | undefined;
    onFilterChange: (filters: TableFilter[]) => void;
    handleClearPage?: () => void;
}

const ComplexTableToolbar = (props: EnhancedTableToolbarProps) => {
    const [filters, setFilters] = useState<TableFilter[]>();

    useEffect(() => {
        setFilters([...(props.filters as TableFilter[])]);
    }, [props.filters]);

    const onChange = (event: any) => {
        const newFilters = [...(filters as TableFilter[])];

        newFilters.map((f) => {
            if (f.field === event.target.name) {
                f.value = event.target.value;
            }
            // else {
            //     f.value = '';
            // }
            return '';
        });


        if (event.target.value === '') {
            props.onFilterChange(newFilters);
        } else {
            setFilters([...newFilters]);
        }
    };

    const clearFilters = () => {
        filters?.map((f) => {
            f.value = '';
            return '';
        });

        props.handleClearPage?.();
    };

    const onKeyPressed = (event: any) => {
        if (event.key === 'Enter' && event.target.value !== '') {
            props.onFilterChange(filters as TableFilter[]);
        }
    };

    const onSearchClick = () => {
        if (filters?.find((f) => f.value)) {
            props.onFilterChange(filters as TableFilter[]);
        }
    };

    const handleCancelCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFilters = [...(filters as TableFilter[])];
        newFilters.map((f) => {
            if (f.field === event.target.name) {
                f.value = event.target.checked;
            }
            return '';
        });

        if (event.target.value === '') {
            props.onFilterChange(newFilters);
        } else {
            setFilters([...newFilters]);
        }
    };

    return (
        <ToolbarRoot className={classes.root}>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid item>
                    <div>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <Typography variant="h5" id="tableTitle" component="div">
                                    {props.title}
                                </Typography>
                            </Grid>
                            {props.addAction !== undefined && (
                                <Grid item>
                                    <IconButton
                                        style={{ paddingTop: 5 }}
                                        color="primary"
                                        onClick={props.addAction}
                                        size="large">
                                        {props.addIcon && <props.addIcon />}
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Grid>

                <Grid item className={classes.filter}>
                    <Box flexDirection="row-reverse" display="flex">
                        <Box p={1}>
                            {filters && filters?.length > 0 ? (
                                <Button
                                    onClick={clearFilters}
                                    variant="contained"
                                    color="primary"
                                >
                                    Clear filters
                                </Button>
                            ) : null}
                        </Box>
                        <Box p={1}>
                            {filters && filters?.length > 0 ? (
                                <Button
                                    onClick={onSearchClick}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SearchIcon />}
                                >
                                    Search
                                </Button>
                            ) : null}
                        </Box>
                        <Box p={1}>
                            <Grid container spacing={1}>
                                {filters && filters?.length > 0
                                    ? filters.map((f, index) => (
                                        //TODO: grid here should work ok for even number of filters, if it makes trouble for odd numbers
                                        // the xs sm and lg sizes can be added to table props and provided manually for each table
                                        //@ts-ignore
                                        <Grid
                                            key={index}
                                            item
                                            xs={12 / filters?.length}
                                            sm={12 / filters?.length}
                                            lg={12 / filters?.length}
                                        >
                                            {f.field === 'state' ? (
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    style={{ width: '190px' }}
                                                >
                                                    <InputLabel id="state-filter">{f.label}</InputLabel>
                                                    <Select
                                                        labelId="state-filter"
                                                        id="demo-simple-select"
                                                        value={f.value.toString()}
                                                        label={f.label}
                                                        name={f.field}
                                                        onChange={onChange}
                                                        fullWidth
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {
                                                            f.availableStates ? f.availableStates.map((s, index) => (
                                                                <MenuItem key={index} value={s}>{s}</MenuItem>
                                                            ))
                                                                : null
                                                        }
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                f.field === 'hideCanceled' ? (
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(f.value)}
                                                                    onChange={handleCancelCheckboxChange}
                                                                    name={f.field}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={f.label}
                                                        />
                                                    </FormGroup>
                                                ) : (
                                                    <TextField
                                                        onKeyDown={onKeyPressed}
                                                        id={f.field}
                                                        name={f.field}
                                                        label={f.label}
                                                        value={f.value}
                                                        onChange={onChange}
                                                        type="search"
                                                        size="small"
                                                        variant="outlined"
                                                    />
                                                )
                                            )}
                                        </Grid>
                                    ))
                                    : null}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ToolbarRoot>
    );
};

export default ComplexTableToolbar;
