export const mailStatusTransitions = new Map<string, string[]>([
    ['unverified', ['active']],
    ['active', ['not_use', 'pseudonymized']],
    ['not_use', ['active', 'pseudonymized']],
    ['pseudonymized', ['active', 'not_use']],
]);

export const allMailStates = ['unverified', 'active', 'not_use', 'pseudonymized'];

export const emailStatusOptionsGetter = (currentStatus: string): string[] => {
	return mailStatusTransitions.get(currentStatus) as string[];
}