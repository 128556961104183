import {TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {classes} from "./styles";
import {HeadCell} from "components/molecules/ComplexTableHead";

interface SimpleTableHeadProps {
    headerCells: HeadCell[];
}

const SimpleTableHead = (props: SimpleTableHeadProps) => {
    return (
        <TableHead className={classes.tableHeader}>
            <TableRow>
                {props.headerCells.map((headCell) => (
                    <TableCell
                        key={headCell.id as string}
                    >
                        <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: '0.9rem' }}>
                            {headCell.label}
                        </Typography>
                    </TableCell>

                ))}
            </TableRow>
        </TableHead>
    )
};

export default SimpleTableHead;

