import React, { FC, useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { authenticationService } from '../../services/authentication/authentication.service';
import { Controller, useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import StyledLink from 'components/atoms/StyledLink';
import { BaseLandingPage } from '../../customized-components';
import { AlertContext } from '../../App';

export interface SignUpFormModel {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    retypePassword: string;
}

export interface SignUpProps {
    history: any;
    location: any;
}

export const SignUpForm: FC<SignUpProps> = (props: SignUpProps) => {
    const {
        control,
        handleSubmit,
        getValues,
        setError,
        formState: { errors },
    } = useForm<SignUpFormModel>();
    const [registrationSuccessful, confirmRegistration] = useState<boolean>(false);
    const [registeredEmail, setRegisteredEmail] = useState<string>('');

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const onSubmit = (data: SignUpFormModel) => {
        if (data.email) {
            let reqBody: any = {
                email: data.email
            }
            if (data.password) {
                reqBody.password = data.password
            }
            if (data.firstName) {
                reqBody.firstName = data.firstName
            }
            if (data.lastName) {
                reqBody.lastName = data.lastName
            }
            authenticationService
                .register(reqBody)
                .then((user: any) => {
                    confirmRegistration(true);
                    setRegisteredEmail(data.email);
                })
                .catch((error) =>
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    )
                );
        }
    };

    const checkifEmailValid = (email: string) => {
        authenticationService
            .emailIsUnique(email)
            .then((res) => res)
            .catch((error) => {
                if (error.message === 'E-mail is already taken!') {
                    setError('email', {
                        type: 'server',
                        message: error.message,
                    });
                } else {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    );
                }
            });
    };

    const resendLink = () => {
        authenticationService.resendValidation(registeredEmail)
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    useEffect(() => {
        if (sessionStorage.getItem('user') as any) {
            props.history.push('/');
        }
    });

    return (
        <BaseLandingPage>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!registrationSuccessful && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        data-testid="email"
                                        onKeyUp={(event) => {
                                            //@ts-ignore
                                            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(event.target.value)) {
                                                //@ts-ignore
                                                checkifEmailValid(event.target.value);
                                            }
                                        }}
                                        onChange={onChange}
                                        value={value}
                                        id="email"
                                        label="Email"
                                        size="small"
                                        variant="outlined"
                                        error={errors.email ? true : false}
                                        helperText={errors.email && errors.email.message}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Email is required field',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="firstName"
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        data-testid="firstName"
                                        id="firstName"
                                        label="First Name"
                                        size="small"
                                        variant="outlined"
                                        error={errors.firstName ? true : false}
                                        helperText={errors.firstName && errors.firstName.message}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="lastName"
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        data-testid="lastName"
                                        id="lastName"
                                        label="Last Name"
                                        size="small"
                                        variant="outlined"
                                        error={errors.lastName ? true : false}
                                        helperText={errors.lastName && errors.lastName.message}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="password"
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        data-testid="password"
                                        id="password"
                                        label="Password"
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        error={errors.password ? true : false}
                                        helperText={errors.password && errors.password.message}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    validate: {
                                        isLongEnough: (value) => {
                                            if (value) {
                                                return /(?=.{8,})/.test(value) || 'Password must be at least 8 characters long.';
                                            }
                                        },
                                        hasUpper: (value) => {
                                            if (value) {
                                                return /(?=.*[A-Z])/.test(value) || 'Password must contain upper case letter.';
                                            }
                                        },
                                        hasLower: (value) => {
                                            if (value) {
                                                return /(?=.*[a-z])/.test(value) || 'Password must contain lower case letter.';
                                            }
                                        },
                                        hasDigit: (value) => {
                                            if (value) {
                                                return /(?=.*\d)/.test(value) || 'Password must contain digit.';
                                            }
                                        },
                                        hasSpecial: (value) => {
                                            if (value) {
                                                return /(?=.*[-+_!@#$%^&*.,?])/.test(value) || 'Password must contain special character.';
                                            }
                                        },
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="retypePassword"
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        data-testid="retypePassword"
                                        id="retypePassword"
                                        label="Retype Password"
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        error={errors.retypePassword ? true : false}
                                        helperText={errors.retypePassword && errors.retypePassword.message}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    validate: {
                                        matchesPreviousPassword: (value) => {
                                            const { password } = getValues();
                                            return password === value || 'Passwords must match!';
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                data-testid="signupButton"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Sign Up
                            </Button>
                        </Grid>
                        <Grid container justifyContent="center">
                            <StyledLink data-testid="signin" to="/login">
                                <Typography>Sign in</Typography>
                            </StyledLink>
                        </Grid>
                    </Grid>
                )}
                {registrationSuccessful && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ textAlign: 'center'}}>
                            <Typography >Check your inbox and click on confirmation link.</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center'}}>
                            <Typography>
                                <StyledLink to="/login">
                                    <Typography>Then go to Login</Typography>
                                </StyledLink>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center'}}>
                            <Typography >If you have not received the email check your spam/junk folder.</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center'}}>
                            <Typography>
                                <StyledLink to="/#" onClick={(event) => {
                                    event.preventDefault();
                                    resendLink();
                                }}>
                                    <Typography>Otherwise click here to receive the confirmation link</Typography>
                                </StyledLink>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </form>
        </BaseLandingPage>
    );
};
