import { Fragment, FunctionComponent, useContext, useEffect, useState, useRef } from 'react';
import { UserContext } from '../../context/UserContext';
import { AlertContext } from '../../App';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import ComplexTable from 'components/organisms/ComplexTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ModalDialog } from '../../customized-components';
import { OrderRow } from './order-row';
import { ordersHeaders } from '../../models/table-headers/orders-headers';
import { ordersService } from '../../services/orders/orders.service'
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { ReassignOrderForm } from './reassign-order-form'
import { defaultOrdersFilters } from '../../models/table-filters.ts/orders-table-filters';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { VoucherAddForm } from './voucher-add-form';
import Order from 'models/Order';
import DeleteIcon from "@mui/icons-material/Delete";


export interface OrderSectionProps {
	id: string | undefined;
}

export const OrdersSection: FunctionComponent<OrderSectionProps> = (
	props: OrderSectionProps
) => {
	const defaultRequest = { order: 'desc', orderBy: 'createdAt', page: 0, rowsPerPage: 10, filters: defaultOrdersFilters };
	const mounted = useRef(false);

	const [orders, setOrders] = useState<TablePageSortFilter>();
	const [selectedOrder, setSelectedOrder] = useState<Order>();
	const [reassignOrderModal, setReassignOrderModal] = useState<boolean>(false);
	const [voucherDialog, setVoucherDialog] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	//@ts-ignore
	const { handleAlert } = useContext(AlertContext);
	const { userData } = useContext(UserContext);

	const loadOrders = (request: PaginationSortingFilteringRequest) => {
		setLoading(true)
		ordersService.getOrders(props.id as string, request)
			.then((res) => {
				if (mounted.current) {
					setOrders(res)
					setLoading(false)
				}
			})
			.catch(error =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error')
			);
	};

	useEffect(() => {
		mounted.current = true;
		if (props.id && userData) {
			loadOrders(defaultRequest);
		}
		return () => {
			mounted.current = false;
		}

	}, [props.id, userData])

	const claimOrder = (id: string) => {
		ordersService.claimOrder(id).then(res => {
			handleAlert('Voucher claimed successfully!', 'success')
			loadOrders(defaultRequest);
		}).catch(error =>
			handleAlert(
				error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error')
		);
	}

	const reassignOrder = (order: Order) => {
		setSelectedOrder(order);
		setReassignOrderModal(true)
		loadOrders(defaultRequest);
	}

	const handleRemoveVoucher = (voucher: Order) => {

		let relationID = voucher.id;

		if (window.confirm("Delete?")) {
			ordersService
				.removeOrder(relationID)
				.then((res) => {
					loadOrders(defaultRequest);
					handleAlert('Voucher successfully removed!', 'success');
				})
				.catch((error) => {
					handleAlert(
						error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
						'error'
					);
				});
		}
	};

	const ordersActions = {
		onClaimOrder: {
			title: 'Claim voucher',
			icon: CheckCircleIcon,
			onClick: claimOrder,
		},
		onReassignOrder: {
			title: 'Reassign voucher',
			icon: AssignmentReturnIcon,
			onClick: reassignOrder,
		},
		onDeleteOrder: {
			title: 'Delete',
			icon: DeleteIcon,
			onClick: handleRemoveVoucher,
		}
	};



	const handleToggleVoucherDialog = () => {
		setVoucherDialog(!voucherDialog);
	};

	const onNewVoucherAdded = () => {
		const request = {
			order: orders?.order,
			orderBy: orders?.orderBy,
			page: orders?.page,
			rowsPerPage: orders?.rowsPerPage,
			filters: orders?.filters
		}
		loadOrders(request as PaginationSortingFilteringRequest);
	}

	const handleOnReassignOrder = () => {
		loadOrders(defaultRequest);
	}

	return (
		<Fragment>
			<ComplexTable
				tableData={orders as TablePageSortFilter}
				headerCells={ordersHeaders}
				title="Vouchers"
				addAction={handleToggleVoucherDialog}
				addIcon={AddBoxIcon}
				updateTableData={loadOrders}
				loading={loading}
			>
				{orders
					? orders.results.map((single) => <OrderRow key={single.id} order={single} actions={ordersActions} userId={userData.id as string} />)
					: null}
			</ComplexTable>
			<div>
				{reassignOrderModal ? (
					<ModalDialog
						dialogIsOpen={reassignOrderModal}
						setDialogOpen={() => setReassignOrderModal(!reassignOrderModal)}
						title="Reassign voucher"
					>
						<ReassignOrderForm
							userId={props.id as string}
							cancel={() => setReassignOrderModal(false)}
							onReassignOrder={handleOnReassignOrder}
							order={selectedOrder as Order}
						/>
					</ModalDialog>
				) : null}
			</div>
			<div>
				{voucherDialog ? (
					<ModalDialog
						dialogIsOpen={voucherDialog}
						setDialogOpen={handleToggleVoucherDialog}
						title="Add Voucher for User or Organization"
					>

						<VoucherAddForm
							onVoucherCreated={onNewVoucherAdded}
							cancel={() => { setVoucherDialog(false) }}
							userId={props.id}
						/>


					</ModalDialog>
				) : null}
			</div>
		</Fragment>
	);
};
