import React, { useState, useRef, useEffect, useContext } from 'react';
import { AlertContext } from 'App';
import { Assertion } from 'models/Assertions';
import { Badge } from 'models/Badges';
import { UserContext } from 'context/UserContext';
import { authenticationService } from 'services/authentication/authentication.service';
import { assertionsHeaders } from 'models/table-headers/assertions-headers';
import { assertionsService } from 'services/assertions/AssertionsService';
import { ModalDialog } from 'customized-components/modal-dialog/modal-dialog';
import { Button, Grid } from '@mui/material';
import { TableAction } from 'models/table-models/TableAction';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import EditIcon from '@mui/icons-material/Edit';
import RevokeAssertionForm from './RevokeAssertionForm';
import SimpleTable from 'components/organisms/SimpleTable/SimpleTable';
import { AssertionRow } from './AssertionRow';
import EditAssertionForm from './EditAssertionForm';
import DeleteAssertionForm from './DeleteAssertionForm';
import { badgesService } from 'services/badges/badgesService';

const defaultRequest = {
    orderBy: 'Id',
    page: 0,
    pageSize: 10000,
};

const AssertionsPage = () => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData, setUserData } = useContext(UserContext);
    const mounted = useRef(false);

    const [assertions, setAssertions] = useState<any>();
    const [badges, setBadges] = useState<Badge[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAssertion, setSelectedAssertions] = useState();
    const [assertionDialog, setAssertionDialog] = useState<boolean>(false);
    const [editAssertionDialog, setEditAssertionDialog] = useState<boolean>(false);
    const [deleteAssertionDialog, setDeleteAssertionDialog] = useState<boolean>(false);
    const [editRevokeAssertionDialog, setEditRevokeAssertionDialog] = useState<boolean>(false);

    const loadAssertions = (data: any = defaultRequest) => {
        setLoading(true);
        setAssertions([]);
        assertionsService
            .getAssertionsAndFilter(data)
            .then((res) => {
                if (mounted.current) {
                    setAssertions(res);
                    setLoading(false);
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const loadBadges = (data: any = defaultRequest) => {
        setLoading(true);
        badgesService
            .getBadgesAndFilter(data)
            .then((res) => {
                if (mounted.current) {
                    // @ts-ignore
                    setBadges(res.rows);
                    setLoading(false);
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const toggleRegularDialog = () => {
        setAssertionDialog(!assertionDialog);
    };

    const toggleEditDialog = (id: string) => {
        setSelectedAssertions(assertions?.rows?.find((row: Assertion) => row.id === id));
        setEditAssertionDialog(!editAssertionDialog);
    };
    const toggleRevokeDialog = (id: string) => {
        setSelectedAssertions(assertions?.rows?.find((row: Assertion) => row.id === id));
        setEditRevokeAssertionDialog(!editRevokeAssertionDialog);
    };

    const toggleDeleteDialog = (id: string) => {
        setSelectedAssertions(assertions?.rows?.find((row: Assertion) => row.id === id));
        setDeleteAssertionDialog(!deleteAssertionDialog);
    };

    const assertionActions: TableAction[] = [
        {
            title: 'Revoke',
            icon: KeyboardReturnIcon,
            onClick: toggleRevokeDialog,
        },
        {
            title: 'Edit',
            icon: EditIcon,
            onClick: toggleEditDialog,
        },
        {
            title: 'Delete',
            icon: DeleteIcon,
            onClick: toggleDeleteDialog,
        },
    ];

    const assertionFilters = {
        receipment_mail: { field: 'receipment_mail', label: 'Email', value: '' },
    };

    const handleFilter = (filter: any) => {
        const currentObj: any = Object.values(filter);
        const currentAssertions = assertions.rows.filter((assertion: Assertion) =>
            assertion.receipment_mail?.includes(currentObj[0].value)
        );
        setAssertions((prevState: Assertion[]) => {
            return {
                ...prevState,
                rows: currentAssertions,
            };
        });
    };

    useEffect(() => {
        mounted.current = true;
        if (!userData) {
            authenticationService.getLoggedInUserData().then((res: any) => {
                if (mounted.current) {
                    //@ts-ignore
                    setUserData(res);
                }
            });
            loadAssertions(defaultRequest);
            loadBadges();
        } else {
            loadAssertions(defaultRequest);
            loadBadges();
        }

        return () => {
            mounted.current = false;
        };
    }, []);

    return (
        <>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => setAssertionDialog(true)}>
                        Create Badge Assertion
                    </Button>
                </Grid>
            </Grid>

            <SimpleTable
                title="Assertions"
                loading={loading}
                addAction={() => {}}
                addIcon={undefined}
                headerCells={assertionsHeaders}
                updateTableData={loadAssertions}
                tableData={assertions}
                // sortArray={sortArray}
                onFilterChange={handleFilter}
                filters={assertionFilters}
            >
                {assertions
                    ? assertions.rows?.map((assertion: Assertion) => (
                          <AssertionRow key={assertion.id} row={assertion} badges={badges} actions={assertionActions} />
                      ))
                    : null}
            </SimpleTable>

            {editRevokeAssertionDialog ? (
                <ModalDialog
                    dialogIsOpen={editRevokeAssertionDialog}
                    //@ts-ignore
                    setDialogOpen={toggleRevokeDialog}
                    title="Create Assertion"
                >
                    <RevokeAssertionForm
                        assertion={selectedAssertion as any}
                        cancel={() => setEditRevokeAssertionDialog(false)}
                        onAssertionRevokeUpdate={loadAssertions}
                    />
                </ModalDialog>
            ) : (
                ''
            )}

            {assertionDialog ? (
                <ModalDialog
                    dialogIsOpen={assertionDialog}
                    //@ts-ignore
                    setDialogOpen={toggleRegularDialog}
                    title="Create Assertion"
                >
                    <EditAssertionForm
                        assertion={selectedAssertion as any}
                        badges={badges}
                        cancel={() => toggleRegularDialog()}
                        createAssertion
                        onAssertionUpdate={loadAssertions}
                    />
                </ModalDialog>
            ) : null}
            {editAssertionDialog ? (
                <ModalDialog
                    dialogIsOpen={editAssertionDialog}
                    //@ts-ignore
                    setDialogOpen={toggleEditDialog}
                    title="Edit Assertion"
                >
                    <EditAssertionForm
                        assertion={selectedAssertion as any}
                        badges={badges}
                        cancel={() => setEditAssertionDialog(false)}
                        onAssertionUpdate={loadAssertions}
                    />
                </ModalDialog>
            ) : null}
            {deleteAssertionDialog ? (
                <ModalDialog
                    dialogIsOpen={deleteAssertionDialog}
                    //@ts-ignore
                    setDialogOpen={toggleDeleteDialog}
                    title="Delete Assertion"
                >
                    <DeleteAssertionForm
                        assertion={selectedAssertion as any}
                        cancel={() => setDeleteAssertionDialog(false)}
                        onAssertionDelete={loadAssertions}
                    />
                </ModalDialog>
            ) : null}
        </>
    );
};

export default AssertionsPage;
