import { HeadCell } from 'components/molecules/ComplexTableHead';

export const certificateHeaderCells: HeadCell[] = [
    { id: '', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'name', numeric: false, label: 'Certificate Name', disablePadding: false, disableSorting: true },
    { id: 'issueDate', numeric: false, label: 'Valid From', disablePadding: false, disableSorting: true },
    { id: 'expirationDate', numeric: false, label: 'Valid Until', disablePadding: false, disableSorting: true },
    { id: '', numeric: false, label: 'State', disablePadding: false, disableSorting: true },
    { id: 'badgeClass', numeric: false, label: 'Badge Class', disablePadding: false, disableSorting: true }
];
