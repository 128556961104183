import axios from 'axios';
import authHeader from '../services/authentication/requestAuthHeader';

const { REACT_APP_BASE_API_URL } = process.env;

const get = (route: string, endpointType: string = '/api') => {
    return axios.get(REACT_APP_BASE_API_URL + endpointType + route, { headers: authHeader() });
};

const post = (route: string, body: any, endpointType: string = '/api') => {
    return axios.post(REACT_APP_BASE_API_URL + endpointType + route, body, { headers: authHeader() });
};

const patch = (route: string, body: any, endpointType: string = '/api') => {
    return axios.patch(REACT_APP_BASE_API_URL + endpointType + route, body, { headers: authHeader() });
};

const put = (route: string, body: any, endpointType: string = '/api') => {
    return axios.put(REACT_APP_BASE_API_URL + endpointType + route, body, { headers: authHeader() });
};

const remove = (route: string, endpointType: string = '/api') => {
    return axios.delete(REACT_APP_BASE_API_URL + endpointType + route, { headers: authHeader() });
};

export const callAxios = {
    get,
    post,
    patch,
    put,
    remove,
};
