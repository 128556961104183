import {styled} from "@mui/material/styles";
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';

const PREFIX = 'DialogContent';

export const classes = {
    root: `${PREFIX}-root`,
    root2: `${PREFIX}-root2`,
    root3: `${PREFIX}-root3`,
    closeButton: `${PREFIX}-closeButton`
};

export const StyledDialog = styled(MuiDialogTitle)(({theme}
) => ({
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: 'white',

    [`& .${classes.closeButton}`]: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    }
}));
//@ts-ignore
export const StyledContentComponent = styled(MuiDialogContent)(({theme}) => ({
    padding: theme.spacing(2),
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    borderRight: `3px solid ${theme.palette.primary.main}`,

    [`& .${classes.root2}`]: {
        margin: 0,
        padding: theme.spacing(1),
        borderRight: `3px solid ${theme.palette.primary.main}`,
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        borderBottom: `3px solid ${theme.palette.primary.main}`,
    }
}));
//@ts-ignore
export const StyledActionComponent = styled(MuiDialogActions)(({theme})  => ({
    margin: 0,
    padding: theme.spacing(1),
    borderRight: `3px solid ${theme.palette.primary.main}`,
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
}));
