import { DataForSoeProglongExam } from 'models/soeProlongExam';
import { callAxios } from '../../axios-wrapper/call-axios';
import { CreateExamEvent } from '../../models/ExamEvent';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import UrlFilterConstructor from '../../transformators/UrlFilterConstructor';

const getExamEvents = (r: PaginationSortingFilteringRequest): Promise<any> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(
            `/examevents/?orderBy=${r.orderBy.toString()}&sortOrder=${r.order}&page=${r.page + 1}&items=${r.rowsPerPage
            }${filter}`
        )
        .then((res) => {
            if (res.data.rows.length >= 0) {
                return { ...r, results: res.data.rows, total: res.data.total };
            } else {
                throw new Error('Loading failed');
            }
        });
};

const getAllExamEvents = (searchTerm: string): Promise<any> => {
    const search = searchTerm ? `&name=${searchTerm}` : '';
    return callAxios
        .get(`/examevents/?orderBy=createdAt&sortOrder=desc&page=1&items=50${search}&states[]=reg.opened`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.rows;
            } else {
                throw new Error('Loading failed');
            }
        });
};

const createExamEvent = (e: CreateExamEvent): Promise<any> => {
    const requestBody = {
        trainingOrgUID: e.ato.id,
        startDate: e.startDate,
        soeSettings: JSON.parse(e.soeSettings),
        examUIDs: e.exams,
    };

    return callAxios.post(`/examevents/`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Saving of exam event failed');
        }
    });
};

const prolongExamEvent = (e: DataForSoeProglongExam): Promise<any> => {
    const requestBody = {
        examEventId: e.examEventId,
        edumsID: e.edumsID,
        examID: e.examID,
        admin: e.admin
    };

    return callAxios.post(`/users/${requestBody.examID}/${requestBody.edumsID}/examevents/${requestBody.examEventId}`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Prolong of exam event failed');
        }
    });
};

const updateEventState = (eventid: string, newState: string) => {
    return callAxios.patch(`/examevents/${eventid}`, { state: newState }).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating stat of exam event failed');
        }
    });
};

const removeExamEventParticipant = (eventid: string, userid: string) => {
    return callAxios.remove(`/examevents/${eventid}/${userid}`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Removing participant of exam event failed');
        }
    });
};

export const examEventService = {
    getExamEvents,
    getAllExamEvents,
    createExamEvent,
    updateEventState,
    prolongExamEvent,
    removeExamEventParticipant
};
