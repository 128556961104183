import { TextField, Grid, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { FunctionComponent, useState, useEffect } from 'react';

export interface ExamEventFormSettingsProps {
	onInputUpdate: (name: string, value: any) => void;
	duration: number | undefined;
}

export const ExamEventSettingsForm: FunctionComponent<ExamEventFormSettingsProps> = (props: ExamEventFormSettingsProps) => {
	const [keyThreshold, setKeyThreshold] = useState(15);
	const [photoTimeInterval, setPhotoTimeInterval] = useState(15);
	const [photoDisplayTimeInterval, setPhotoDisplayTimeInterval] = useState(15);
	const [keyLogData, setKeyLogData] = useState(5);
	const [mouseExits, setMouseExits] = useState(5);
	const [secondsPerLeave, setSecondsPerLeave] = useState(10);
	const [monitorKeypress, setMonitorKeypress] = useState(false);
	const [mouseMovement, setMouseMovement] = useState(false);
	const [examDuration, setExamDuration] = useState(props.duration);

	useEffect(() => {
		setExamDuration(props.duration)
	}, [props.duration])

	const handleChange = (e: any) => {
		props.onInputUpdate(e.target.name, e.target.value);
		if (e.target.name === "notAllowedKeyCombinationThreshold") setKeyThreshold(e.target.value)
		if (e.target.name === "sendKeyLogDataToServerLimit") setKeyLogData(e.target.value)
		if (e.target.name === "numberOfMaxMouseExit") setMouseExits(e.target.value)
		if (e.target.name === "mouseAllowedSecondsPerLeave") setSecondsPerLeave(e.target.value)
		if (e.target.name === "ExamVersion_plannedDuration") setExamDuration(e.target.value)
		if (e.target.name === "Photo_Time_Interval") setPhotoTimeInterval(e.target.value)
		if (e.target.name === "photoDisplayTimeInterval") setPhotoDisplayTimeInterval(e.target.value)
	}

	const handleCheck = (e: any) => {
		props.onInputUpdate(e.target.name, e.target.checked);
		if (e.target.name === "monitoringKeyPresses") {
			if (e.target.checked === false) {
				changeValueOnToggleCheck("notAllowedKeyCombinationThreshold", 0);
				setKeyThreshold(0);
				changeValueOnToggleCheck("sendKeyLogDataToServerLimit", 0);
				setKeyLogData(0);
				setMonitorKeypress(true);
			} else setMonitorKeypress(false);
		} else if (e.target.name === "mouseMovement") {
			if (e.target.checked === false) {
				changeValueOnToggleCheck("numberOfMaxMouseExit", 0);
				setMouseExits(0);
				changeValueOnToggleCheck("mouseAllowedSecondsPerLeave", 0);
				setSecondsPerLeave(0);
				setMouseMovement(true);
			} else setMouseMovement(false);
		}

		if (e.target.name === 'cameraAndCognitoServices' && e.target.value === 3) {
			changeValueOnToggleCheck("Photo_Time_Interval", 0);
			setPhotoTimeInterval(0)
		}

		if (e.target.name === 'displayAndCognitoServices' && e.target.value === 3) {
			changeValueOnToggleCheck("photoDisplayTimeInterval", 0);
			setPhotoDisplayTimeInterval(0)
		}
	}

	const changeValueOnToggleCheck = (name: string, value: any) => {
		props.onInputUpdate(name, value);
	}

	return (
		<div>
			<Grid container spacing={3} style={{ marginBottom: "20px" }}>
				{/* <Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="settings-select">Front End - Endpoint</InputLabel>
						<Select
							labelId="settings-select-frontend"
							onChange={handleChange}
							label="Front End - endpoint"
							name="fe_endpoint"
							defaultValue={"https://soe-client-fe-developing.azurewebsites.net"}
						>
							<MenuItem value={"https://soe-client-fe-developing.azurewebsites.net"}>https://soe-client-fe-developing.azurewebsites.net</MenuItem>
							<MenuItem value={"http://localhost:3000"}>http://localhost:3000</MenuItem>
						</Select>
					</FormControl>
				</Grid> */}
				<Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="settings-select">Camera State and Cognito Services</InputLabel>
						<Select
							labelId="settings-select-camera"
							onChange={handleCheck}
							label="Camera State and Cognito Services"
							name="cameraAndCognitoServices"
							defaultValue={1}
						>
							<MenuItem value={1}>Use Camera and Cognito</MenuItem>
							<MenuItem value={2}>Use Camera but not Cognito</MenuItem>
							<MenuItem value={3}>Do not use Camera and Cognito</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} >
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="settings-select-display">Display Capture and Cognito Services</InputLabel>
						<Select
							labelId="settings-select-display"
							onChange={handleCheck}
							label="Display Capture and Cognito Services"
							name="displayAndCognitoServices"
							defaultValue={1}
						>
							<MenuItem value={1}>Use Display Capture and Cognito</MenuItem>
							<MenuItem value={2}>Use Display Capture but not Cognito</MenuItem>
							<MenuItem value={3}>Do not use Display Capture and Cognito</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='ExamVersion_plannedDuration' type={"number"} label="Exam Planned Duration (minutes)" variant="outlined" fullWidth onChange={handleChange} value={examDuration} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='tokenvalidfor' type={"number"} label="Token Valid (days)" variant="outlined" fullWidth onChange={handleChange} defaultValue={30} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='Stopping_Time_Photo_Interval' type={"number"} label="Stopping Time Photo Interval (seconds)" variant="outlined" fullWidth onChange={handleChange} defaultValue={2} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='Photo_Time_Interval' type={"number"} label="Photo Time Interval (seconds)" variant="outlined" fullWidth value={photoTimeInterval} onChange={handleChange} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='photoDisplayTimeInterval' type={"number"} label="Screen Capture Time Interval (seconds)" variant="outlined" value={photoDisplayTimeInterval} fullWidth onChange={handleChange} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='activeTabStopThreshold' type={"number"} label="Active Tab Stop Threshold" variant="outlined" fullWidth onChange={handleChange} defaultValue={15} />
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormControlLabel control={<Checkbox name="monitoringActiveTab" defaultChecked onChange={handleCheck} />} label="Monitoring Active Tab" />
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormControlLabel control={<Checkbox name="mouseMovement" defaultChecked onChange={handleCheck} />} label="Track Mouse Movement" />
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormControlLabel control={<Checkbox name="identificationProcedure" defaultChecked onChange={handleCheck} />} label="Identification Procedure" />
				</Grid>
				<Grid item xs={12} sm={3}>
					<FormControlLabel control={<Checkbox name="monitoringKeyPresses" defaultChecked onChange={handleCheck} />} label="Key Logging" />
				</Grid>
				<Grid item xs={12} sm={3}>
					<FormControlLabel control={<Checkbox name="fullScreenMode" defaultChecked onChange={handleCheck} />} label="Fullscreen Mode" />
				</Grid>

				<Grid item xs={12} sm={3}>
					<FormControlLabel control={<Checkbox name="shuffleQuestions" defaultChecked onChange={handleCheck} />} label="Shuffle Questions" />
				</Grid>
				<Grid item xs={12} sm={3}>
					<FormControlLabel control={<Checkbox name="shuffleAnswers" defaultChecked onChange={handleCheck} />} label="Shuffle Answers" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='notAllowedKeyCombinationThreshold' type={"number"} label="Not Allowed Keys Max Threshold" variant="outlined" fullWidth onChange={handleChange} value={keyThreshold} disabled={monitorKeypress} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='numberOfMaxMouseExit' type={"number"} label="Max Number Of Mouse Exit" variant="outlined" fullWidth onChange={handleChange} value={mouseExits} disabled={mouseMovement} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='sendKeyLogDataToServerLimit' type={"number"} label="Send KeyLog data (loop)" variant="outlined" fullWidth onChange={handleChange} value={keyLogData} disabled={monitorKeypress} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='mouseAllowedSecondsPerLeave' type={"number"} label="Allowed Seconds Per Leave" variant="outlined" fullWidth onChange={handleChange} value={secondsPerLeave} disabled={mouseMovement} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='allowRecreationExam' type={"number"} label="Allow User To Prolong Exam" variant="outlined" fullWidth onChange={handleChange} defaultValue={2} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField name='allowRecreationSampleExam' type={"number"} label="Allow User To Prolong Sample" variant="outlined" fullWidth onChange={handleChange} defaultValue={8} />
				</Grid>
			</Grid>
		</div>
	);
};
