import { Grid, Button } from '@mui/material';
import { FC, useState, useContext, useRef } from 'react';
import { useEffect } from 'react';
import ComplexTable from 'components/organisms/ComplexTable';
import { authenticationService } from '../../services/authentication/authentication.service';
import { UserContext } from '../../context/UserContext';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { certificateHeaderCells } from '../../models/table-headers/certificates-headers';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import { CertificateDetailsRow } from './certificate-details-row';
import { certificatesService } from '../../services/certificates/certificates.service';
import { ModalDialog } from 'customized-components';
import { RegularCertificateForm } from './forms/regular-certificate-form';
import { ComputedCertificateForm } from './forms/computed-certificate-form';
import { EditCertificateForm } from './forms/edit-certificate-from';
import { AlertContext } from '../../App';
import { defaultCertificatesFilters } from '../../models/table-filters.ts/certificates-table-filters';
import { organizationsService } from '../../services/organizations/organizations.service';
import { Organization } from '../../models/Organization';

export const Certificates: FC = () => {
	const defaultRequest: PaginationSortingFilteringRequest = {
		order: 'asc',
		orderBy: 'name',
		page: 0,
		rowsPerPage: 10,
		filters: defaultCertificatesFilters()
	};
	const mounted = useRef(false);

	const [certificates, setCertificates] = useState<TablePageSortFilter>();
	const [loading, setLoading] = useState<boolean>(false);
	const [regularCertificateDialog, setRegularCertificateDialog] = useState<boolean>(false);
	const [computedCertificateDialog, setComputedCertificateDialog] = useState<boolean>(false);
	const [editCertificateDialog, setEditCertificateDialog] = useState<boolean>(false);
	const [selectedCertificate, setSelectedCertificate] = useState();
	const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);
	const [organizations, setOrganizations] = useState<Organization>();
	//@ts-ignore
	const { handleAlert } = useContext(AlertContext);
	const { userData, setUserData } = useContext(UserContext);

	useEffect(() => {
		mounted.current = true;
		if (!userData) {
			authenticationService.getLoggedInUserData().then((res: any) => {
				if (mounted.current) {
					//@ts-ignore
					setUserData(res);
				}
			});
			getAtoBySearchTerm('');
			loadCertificates(paginationData);
		} else {
			getAtoBySearchTerm('');
			loadCertificates(paginationData);
		}

		return () => {
			mounted.current = false;
		}

	}, []);

	const getAtoBySearchTerm = (search: string) => {
		organizationsService.getAtoWithSearchInStatusTrueAddedTypes(search)
			.then((res) => {
				let sortedAtos = res.sort((a: any, b: any) => a.name.localeCompare(b.name))
				setOrganizations(sortedAtos);
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);
	}

	const loadCertificates = (data: PaginationSortingFilteringRequest) => {
		setLoading(true)
		certificatesService
			.getCertificatesWithPaginationAndFilter(data)
			.then((res) => {
				if (mounted.current) {
					setCertificates(res);
					setLoading(false);
				}
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);
	};

	const updateCertificates = () => {
		loadCertificates(defaultRequest)
	}
	const toggleRegularDialog = () => {
		setRegularCertificateDialog(!regularCertificateDialog)
	}

	const toggleComputedDialog = () => {
		setComputedCertificateDialog(!computedCertificateDialog)
	}

	const toggleEditDialog = (id: string) => {
		setSelectedCertificate(certificates?.results?.find((row) => row.id === id));
		setEditCertificateDialog(!editCertificateDialog)
	}

	const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
		setPaginationData(newValue)
	};

	const onHandleCertificateStatusChange = async (changeCertStatus: boolean, certId: string) => {
		try {
			let updated = await certificatesService.updateCertificate({ status: changeCertStatus === true ? 'active' : 'inactive' }, certId)
			if (updated) {
				let updatedCerts = certificates?.results.map(cert => {
					if (cert.id === certId) {
						cert.status = changeCertStatus === true ? 'active' : 'inactive'
					}
					return cert;
				})
				setCertificates(prevState => ({ ...prevState, rows: updatedCerts }) as TablePageSortFilter);
			} else {
				console.log("error occured while updating certificate status ", updated);
			}
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<>
			<Grid container spacing={2} style={{ marginBottom: 10 }}>
				<Grid item>
					<Button variant="contained" color="primary" onClick={() => setRegularCertificateDialog(true)}>Create Exam Certificate</Button>
				</Grid>
				<Grid item>
					<Button variant="contained" color="primary" onClick={() => setComputedCertificateDialog(true)}>Create Role Certificate</Button>
				</Grid>
			</Grid>
			<ComplexTable
				tableData={certificates as TablePageSortFilter}
				headerCells={certificateHeaderCells}
				title="Certificates Class"
				addAction={() => { }}
				addIcon={undefined}
				updateTableData={loadCertificates}
				onPaginationUpdate={trackPagination}
				loading={loading}
			>
				{certificates
					? certificates.results.map((c) =>
						<CertificateDetailsRow
							key={c.id}
							certificate={c}
							onEditCertificate={toggleEditDialog}
							onHandleCertificateStatusChange={(changeCertStatus, certId) => onHandleCertificateStatusChange(changeCertStatus, certId)}
						/>)
					: null}
			</ComplexTable>

			{regularCertificateDialog ? (
				<ModalDialog
					dialogIsOpen={regularCertificateDialog}
					//@ts-ignore
					setDialogOpen={toggleRegularDialog}
					title="Create Exam Certificate"
				>
					<RegularCertificateForm
						cancel={() => setRegularCertificateDialog(false)}
						onCertificateCreate={updateCertificates}
					/>
				</ModalDialog>
			) : null}
			{computedCertificateDialog ? (
				<ModalDialog
					dialogIsOpen={computedCertificateDialog}
					//@ts-ignore
					setDialogOpen={toggleComputedDialog}
					title="Create Role Certificate"
				>
					<ComputedCertificateForm
						cancel={() => setComputedCertificateDialog(false)}
						onCertificateCreate={updateCertificates}
					/>
				</ModalDialog>
			) : null}
			{editCertificateDialog ? (
				<ModalDialog
					dialogIsOpen={editCertificateDialog}
					//@ts-ignore
					setDialogOpen={toggleEditDialog}
					title="Edit Certificate"
				>
					<EditCertificateForm
						organizations={organizations}
						certificate={selectedCertificate as any}
						cancel={() => setEditCertificateDialog(false)}
						onCertificateUpdate={updateCertificates}
					/>
				</ModalDialog>
			) : null}
		</ >
	);
};
