import { callAxios } from 'axios-wrapper/call-axios';
import { AssertionRequest, AssertionResponse } from "models/Assertions";
import { AxiosResponse } from "axios";

const getAssertionsAndFilter = (r: AssertionRequest): Promise<AxiosResponse<AssertionResponse>> => {
    return callAxios
        .get(
            `/badgeAssertions/?orderBy=${r.orderBy.toString()}&page=${r.page + 1}&items=${r.pageSize}`
        )
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            } else {
                throw new Error('Getting Assertions failed');
            }
        });
};

const getAssertionsByUser = (r: AssertionRequest): Promise<AxiosResponse<AssertionResponse>> => {
    return callAxios
        .get(
            `/users/${r.id}/badges/?page=${r.page + 1}&items=${r.pageSize}&orderBy=${r.orderBy.toString()}&sortOrder=${r.sortOrder}`
        )
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            } else {
                throw new Error('Getting Assertions failed');
            }
        });
};

const updateAssertion = (body: any, id: string): Promise<any> => {
    return callAxios.patch(`/badgeAssertions/${id}`, body).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating assertion failed');
        }
    });
};
const revokeAssertion = (body: any, id: string): Promise<any> => {
    return callAxios.patch(`/badgeAssertions/revoke`, body).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating assertion failed');
        }
    });
};

const createAssertion = (body: any): Promise<any> => {
    return callAxios.post(`/badgeAssertions`, body).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating assertion failed');
        }
    });
};

const deleteAssertion = (id: string): Promise<any> => {
    return callAxios.remove(`/badgeAssertions/${id}`).then((res: any) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating assertion failed');
        }
    });
};

export const assertionsService = {
    getAssertionsAndFilter,
    updateAssertion,
    createAssertion,
    deleteAssertion,
    getAssertionsByUser,
    revokeAssertion
};
