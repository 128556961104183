import React, { useState, useContext } from 'react';
import { DialogContent, Typography, Grid } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { Fragment, FunctionComponent } from 'react';
import { OrganizationMember } from '../../models/Organization';
import { DialogActions } from "customized-components";
import ButtonWrapper from "components/atoms/buttons/ButtonWrapper";
import Button from "@mui/material/Button";
import CircularProgress from "components/atoms/StyledCircularProgress";
import { useForm, Controller } from "react-hook-form";
import { participantsService } from 'services/participants/participants.service'
import { AlertContext } from '../../App';

export interface UserProps {
    closeDialog: () => void;
    updateOrganization: () => void;
    currentUser: OrganizationMember;
}

export const UserSetPermissionDialog: FunctionComponent<UserProps> = (props: UserProps) => {
    const { currentUser } = props;
    const [loading, setLoading] = useState<boolean>(false);

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const {
        handleSubmit,
        control
    } = useForm({
        defaultValues: {
            userRole: currentUser.relationDetails.role,
        },
    })

    const onSubmit = (data: any) => {
        let relationID = currentUser.relationDetails.relationID;
        let role = data.userRole;
        participantsService.updateParticipantRoles(relationID, role).then((res) => {
            props.closeDialog();
            props.updateOrganization();
            handleAlert('User successfully updated!', 'success');
        }).catch((error) => {
            handleAlert(
                error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                'error'
            )
            props.closeDialog();
        }
        );
    };


    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent
                    sx={{
                        borderLeft: '3px solid #4765A0',
                        borderRight: '3px solid #4765A0'
                    }}
                >

                    <Typography variant="h6" gutterBottom component="div" sx={{ mt: 2 }}>
                        {currentUser.firstName} {currentUser.lastName}
                    </Typography>

                    <Grid container spacing={2} sx={{ px: 3 }}>
                        <Grid item xs={12} sm={6}>
                            Role
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl>
                                <Controller
                                    rules={{ required: true }}
                                    control={control}
                                    name="userRole"
                                    render={({ field }) => (
                                        <RadioGroup row {...field}>
                                            <FormControlLabel value="member" control={<Radio />} label="member" />
                                            <FormControlLabel value="owner" control={<Radio />} label="owner" />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ px: 3 }} justifyContent={"flex-start"}>
                        <Grid item xs={12} sm={6}>
                            Permissions
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {currentUser.relationDetails.permissions.length && currentUser.relationDetails.permissions.map(item => {
                                return (
                                    <div key={item}>{item}</div>
                                )
                            })}
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <ButtonWrapper>
                        <Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
                            Save
                        </Button>
                        {loading && <CircularProgress isCentered thickness={5} size={24} />}
                    </ButtonWrapper>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            props.closeDialog();
                        }}
                        data-testid=""
                        color="primary"
                        type="submit"
                        variant="contained"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Fragment >
    );
};


