import React, { FunctionComponent } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface AlertProps {
    isOpen: boolean;
    title?: string | undefined;
    content: string;
    handleClose: () => void;
    handleOkAction: () => void;
}

export const AlertDialog: FunctionComponent<AlertProps> = (props: AlertProps) => {
    const handleClose = () => {
        props.handleClose();
    };

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {props.title && <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{props.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleOkAction} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
