import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material'
import { msEduTheme } from 'ui-themes/msEduTheme'
import './i18n'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallbackComponent } from 'error-handling/error-fallback'


declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}


// if (process.env.NODE_ENV === 'development') {
// 	const { worker } = require('./mocks/browser')
// 	worker.start()
// }

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={msEduTheme}>
      <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
        <App />
      </ErrorBoundary>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
