import React, { useState, useRef, useEffect, useContext } from 'react';
import { AlertContext } from 'App';
import { Badge } from 'models/Badges';
import { UserContext } from 'context/UserContext';
import { authenticationService } from 'services/authentication/authentication.service';
import { badgesHeaders } from 'models/table-headers/badges-headers';
import { badgesService } from 'services/badges/badgesService';
import { ModalDialog } from 'customized-components/modal-dialog/modal-dialog';
import { Box, Button, Grid, CircularProgress } from '@mui/material';
import { TableAction } from 'models/table-models/TableAction';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BadgeRow } from './BadgeRow';
import EditBadgeForm from './EditBadgeForm';
import DeleteBadgeForm from './DeleteBadgeForm';
import { Alignment } from 'models/Alignments';
import { alignmentsService } from 'services/alignments/alignmentsService';

import ComplexTable from 'components/organisms/ComplexTable';
import TablePageSortFilter from 'models/table-models/TablePageSortFilter';
import { certificatesService } from '../../../services/certificates/certificates.service';
import { Certificate } from '../../../models/Certificate';
import TableFilter from 'models/table-models/TableFilter';
import { defaultBadgesFilters } from 'models/table-filters.ts/badges-table-filters';
import PaginationSortingFilteringRequest from 'models/table-models/PaginationSortingFilteringRequest';

import { defaultCertificatesFilters } from 'models/table-filters.ts/certificates-table-filters';

const BadgesPage = () => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData, setUserData } = useContext(UserContext);
    const mounted = useRef(false);

    const [badges, setBadges] = useState<any>();
    const [alignments, setAlignments] = useState<Alignment[]>();
    const [certificates, setCertificates] = useState<Certificate[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSyncBadges, setLoadingsyncBadges] = useState<boolean>(false);
    const [selectedBadge, setSelectedBadges] = useState();
    const [badgeDialog, setBadgeDialog] = useState<boolean>(false);
    const [editBadgeDialog, setEditBadgeDialog] = useState<boolean>(false);
    const [deleteBadgeDialog, setDeleteBadgeDialog] = useState<boolean>(false);

    const defaultRequest = {
        page: 0,
        rowsPerPage: 10,
        orderBy: 'createdAt',
        order: 'desc',
        filters: defaultBadgesFilters,
    };

    interface PaginationSortingFilteringRequestBadges {
        page: number;
        rowsPerPage: number;
        orderBy: string | number | symbol;
        order: string;
        filters: TableFilter[];
    }

    const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequestBadges>(defaultRequest);

    const loadBadges = (request: PaginationSortingFilteringRequestBadges) => {
        setLoading(true);
        badgesService
            .getBadgesAndFilterWithPagination(request)
            .then((res) => {
                if (mounted.current) {
                    setBadges(res);
                    setLoading(false);
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const syncBadges = () => {
        setLoadingsyncBadges(true);
        badgesService
            .syncBadges()
            .then((res) => {
                handleAlert('synced Badges successfully!', 'success');
                if (mounted.current) {
                    setLoadingsyncBadges(false);
                    console.log('load badges again');
                    location.reload();
                }
            })
            .catch((error) => {
                setLoadingsyncBadges(false);
                handleAlert(error.message, 'error');
            });
    };

    const loadAlignments = () => {
        const data = {
            orderBy: 'Id',
            page: 0,
            pageSize: 10000,
        };
        setLoading(true);
        alignmentsService
            .getAlignmentsAndFilter(data)
            .then((res) => {
                if (mounted.current) {
                    // @ts-ignore
                    setAlignments(res.results);
                    setLoading(false);
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const loadCertificates = () => {
        const defaultRequest: PaginationSortingFilteringRequest = {
            order: 'desc',
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 1000,
            filters: defaultCertificatesFilters(),
        };
        setLoading(true);
        certificatesService
            .getCertificatesWithPaginationAndFilter(defaultRequest)
            .then((res) => {
                if (mounted.current) {
                    setCertificates(res.results as Certificate[]);
                    setLoading(false);
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const toggleRegularDialog = () => {
        setBadgeDialog(!badgeDialog);
    };

    const toggleEditDialog = (id: string) => {
        setSelectedBadges(badges?.results?.find((row: Badge) => row.id === id));
        setEditBadgeDialog(!editBadgeDialog);
    };

    const toggleDeleteDialog = (id: string) => {
        setSelectedBadges(badges?.results?.find((row: Badge) => row.id === id));
        setDeleteBadgeDialog(!deleteBadgeDialog);
    };

    const badgeActions: TableAction[] = [
        {
            title: 'Edit',
            icon: EditIcon,
            onClick: toggleEditDialog,
        },
        // {
        //     title: 'Delete',
        //     icon: DeleteIcon,
        //     onClick: toggleDeleteDialog,
        // },
    ];

    // const sortArray = ['Name', 'Framework'];

    const badgeFilters = {
        title: { field: 'title', label: 'Title', value: '' },
    };

    // const handleFilter = (filter: any) => {
    //     const currentObj: any = Object.values(filter);
    //     const currentBadges = badges?.results.filter((badge: Badge) => badge.title.includes(currentObj[0].value));
    //     setBadges((prevState: Badge[]) => {
    //         return {
    //             ...prevState,
    //             rows: currentBadges,
    //         };
    //     });
    // };

    useEffect(() => {
        let reqObj = {
            page: badges?.page ?? 0,
            rowsPerPage: badges?.rowsPerPage ?? 10,
            orderBy: badges?.orderBy ?? 'createdAt',
            order: badges?.order ?? 'desc',
            filters: badges?.filters ?? defaultBadgesFilters,
        };
        mounted.current = true;
        if (!userData) {
            authenticationService.getLoggedInUserData().then((res: any) => {
                if (mounted.current) {
                    //@ts-ignore
                    setUserData(res);
                }
            });
            loadBadges(reqObj);
            loadAlignments();
            loadCertificates();
        } else {
            loadBadges(reqObj);
            loadAlignments();
            loadCertificates();
        }

        return () => {
            mounted.current = false;
        };
    }, []);

    const trackPagination = (newValue: PaginationSortingFilteringRequestBadges) => {
        setPaginationData(newValue);
    };

    return (
        <>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
                {/* <Grid item>
                    <Button variant="contained" color="primary" onClick={() => setBadgeDialog(true)}>
                        Create Badge Classes
                    </Button>
                </Grid> */}
                <Grid item>
                    <Button
                        onClick={syncBadges}
                        variant="contained"
                        color="primary"
                    // startIcon={<SearchIcon />}
                    >
                        <Box display="flex" alignItems="center">
                            Sync Badges
                            {loadingSyncBadges && (
                                <CircularProgress size={20} color="inherit" style={{ marginLeft: 10 }} />
                            )}
                        </Box>
                    </Button>
                </Grid>
            </Grid>

            <ComplexTable
                title="Badges Class"
                loading={loading}
                addAction={() => { }}
                addIcon={undefined}
                headerCells={badgesHeaders}
                updateTableData={loadBadges}
                tableData={badges as TablePageSortFilter}
                onPaginationUpdate={trackPagination}
            >
                {badges
                    ? badges?.results?.map((badge: Badge, index: number) => (
                        <BadgeRow key={index} row={badge} actions={badgeActions} />
                    ))
                    : null}
            </ComplexTable>

            {badgeDialog ? (
                <ModalDialog
                    dialogIsOpen={badgeDialog}
                    //@ts-ignore
                    setDialogOpen={toggleRegularDialog}
                    title="Create Badge Class"
                >
                    <EditBadgeForm
                        alignments={alignments}
                        certificates={certificates}
                        badge={selectedBadge as any}
                        cancel={() => toggleRegularDialog()}
                        createBadge
                        onBadgeUpdate={loadBadges}
                        updateAlignments={loadAlignments}
                    />
                </ModalDialog>
            ) : null}

            {editBadgeDialog ? (
                <ModalDialog
                    dialogIsOpen={editBadgeDialog}
                    //@ts-ignore
                    setDialogOpen={toggleEditDialog}
                    title="Edit Badge"
                >
                    <EditBadgeForm
                        alignments={alignments}
                        certificates={certificates}
                        badge={selectedBadge as any}
                        cancel={() => {
                            setEditBadgeDialog(false);
                            setSelectedBadges(undefined);
                        }}
                        onBadgeUpdate={() =>
                            loadBadges({
                                page: badges?.page ?? 0,
                                rowsPerPage: badges?.rowsPerPage ?? 10,
                                orderBy: badges?.orderBy ?? 'createdAt',
                                order: badges?.order ?? 'desc',
                                filters: badges?.filters ?? defaultBadgesFilters,
                            })
                        }
                        updateAlignments={loadAlignments}
                    />
                </ModalDialog>
            ) : null}
            {deleteBadgeDialog ? (
                <ModalDialog
                    dialogIsOpen={deleteBadgeDialog}
                    //@ts-ignore
                    setDialogOpen={toggleDeleteDialog}
                    title="Delete Badge"
                >
                    <DeleteBadgeForm
                        badge={selectedBadge as any}
                        cancel={() => setDeleteBadgeDialog(false)}
                        onBadgeDelete={loadBadges}
                    />
                </ModalDialog>
            ) : null}
        </>
    );
};

export default BadgesPage;
