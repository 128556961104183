import { DialogContent } from '@mui/material';
import { Fragment, FunctionComponent, useEffect, useContext, useState } from 'react';
import { AlertContext } from '../../App';
import ComplexTable from "components/organisms/ComplexTable";
import { participantsHeaders } from '../../models/table-headers/participants-headers';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { participantsService } from '../../services/participants/participants.service';
import { mailsService } from '../../services/mails/mails.service';
import { ParticipantRow } from '../participants/participant-row';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { defaultParticipantsFilters } from '../../models/table-filters.ts/participants-table-filters';

export interface MailReassignProps {
	closeDialog: () => void;
	onReassignSuccess: () => void;
	emailId: string;
}

export interface MailInput {
	email: string;
}


export const MailReassignUserDialog: FunctionComponent<MailReassignProps> = (props: MailReassignProps) => {

	//@ts-ignore
	const { handleAlert } = useContext(AlertContext);

	const defaultRequest: PaginationSortingFilteringRequest = {
		order: 'desc',
		orderBy: 'createdAt',
		page: 0,
		rowsPerPage: 10,
		filters: defaultParticipantsFilters,
	};

	const [participants, setParticipants] = useState<TablePageSortFilter>({
		...defaultRequest,
		total: 10,
		results: [],
	});
	const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);
	const [loading, setLoading] = useState<boolean>(false);

	const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
		setPaginationData(newValue)
	};



	useEffect(() => {
		loadParticipants(paginationData);


	}, []);

	const loadParticipants = (data: PaginationSortingFilteringRequest) => {
		setLoading(true)
		participantsService
			.getParticipantsWithPaginationAndFilter(data)
			.then((res) => {
				setParticipants(res);
				setLoading(false);
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);
	};

	const onReassignMail = (id: string) => {
		const data = {
			id: props.emailId,
			targetID: id,
			targetType: 'user'
		}
		mailsService.reassignEmail(data)
			.then(() => {
				handleAlert('Email successfully reassigned!', 'success')
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);
		props.onReassignSuccess();
		props.closeDialog();

	}

	const actions = {
		onReassignMail: {
			title: 'Reassign Mail',
			icon: TransferWithinAStationIcon,
			onClick: onReassignMail,
		},
	};

	return (
		<Fragment>
			<DialogContent
			>
				<ComplexTable
					tableData={participants as TablePageSortFilter}
					headerCells={participantsHeaders}
					title="Persons"
					addAction={() => undefined}
					addIcon={undefined}
					updateTableData={loadParticipants}
					onPaginationUpdate={trackPagination}
					loading={loading}

				>
					{participants?.results
						//@ts-ignore
						? participants.results.map((r) => <ParticipantRow key={r.id} row={r} actions={actions} />)
						: null}
				</ComplexTable>
			</DialogContent>
			{/* <DialogActions>
				<Button onClick={props.closeDialog} color="primary" type="submit" variant="contained">
					Close
				</Button>
			</DialogActions> */}
		</Fragment>
	);
};


