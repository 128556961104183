import { Grid } from '@mui/material';
import { Fragment, FunctionComponent, useState, useContext, useRef } from 'react';
import { ModalDialog } from '../../customized-components';
import { ParticipantForm } from '../participant-form/participant-form';
import { MailManagementForm } from '../mail-management-dialog/mail-management-dialog';
import { useEffect } from 'react';
import { ParticipantBaseDataCard } from './participant-base-data-card';
import { Participant } from '../../models/Participant';
import { participantsService } from '../../services/participants/participants.service';
import { useParams } from 'react-router-dom';
import { Email } from '../../models/Email';
import { mailsService } from '../../services/mails/mails.service';
import { authenticationService } from '../../services/authentication/authentication.service';
import { AlertContext } from '../../App';
import { UserContext } from '../../context/UserContext';
import { BookingsOnExamEventSection } from '../bookings-on-exam-event/bookings-on-exam-event-section';
import { OrdersSection } from '../orders/order-section'
import { CertificatesSection } from '../certificates/certificates-section';
import { MailReassignUserDialog } from '../mail-reassign-dialog/mail-reassign-users-dialog';
import { MailReassignOrganizationDialog } from '../mail-reassign-dialog/mail-reassign-organizations-dialog';
import AssertionsSection from "../../components/pages/AssertionsPage/AssertionsSection/AssertionsSection";
import ComplexTable from 'components/organisms/ComplexTable';
import { usersOrganizationsHeaders } from 'models/table-headers/usersOrganizationsHeaders';
import { UsersOrganizationsRow } from 'features/organizations/users-organization-row';
export interface ParticipantDetailsProps {
    participantId?: string;
}

export const ParticipantDetails: FunctionComponent<ParticipantDetailsProps> = (props: ParticipantDetailsProps) => {
    //@ts-ignore
    const { id } = useParams();
    const mounted = useRef(false);

    //#region STATE
    const [participant, setParticipant] = useState<Participant | undefined>();
    const [emails, setEmails] = useState<Email[]>([]);
    const [updateParticipantDialogIsOpen, setOpenUpdateParticipantDialog] = useState<boolean>(false);
    const [mailManagementDialogIsOpen, setOpenMailManagementDialog] = useState<boolean>(false);
    const [mailReassignUserDialogIsOpen, setOpenMailReassignUserDialog] = useState<boolean>(false);
    const [mailReassignOrganizationDialogIsOpen, setOpenMailReassignOrganizationDialog] = useState<boolean>(false);
    const [selectedMailToReassign, setSelectedMailToReassign] = useState<string>('');
    //#endregion

    //#region CONTEXT
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData, setUserData } = useContext(UserContext);
    //#endregion

    //#region DIALOGS VISIBILITY
    const handleClickOpenParticipantDialog = () => {
        setOpenUpdateParticipantDialog(!updateParticipantDialogIsOpen);
    };

    const handleClickOpenMailManagementDialog = () => {
        setOpenMailManagementDialog(!mailManagementDialogIsOpen);
    };

    const handleClickOpenMailReassignUserDialog = (emailId: string) => {
        setOpenMailReassignUserDialog(!mailReassignUserDialogIsOpen);
        if (emailId) {
            setSelectedMailToReassign(emailId);
        }
    };

    const handleClickOpenMailReassignOrganizationDialog = (emailId: string) => {
        setOpenMailReassignOrganizationDialog(!mailReassignOrganizationDialogIsOpen);
        if (emailId) {
            setSelectedMailToReassign(emailId);
        }
    };
    //#endregion

    //#region FETCH DATA
    const getParticipantData = (participantId: string) => {
        participantsService
            .getParticipant(participantId)
            .then((res) => {
                console.log(mounted.current);
                if (mounted.current) {
                    setParticipant(res);
                    setEmails(res.emails);
                }

            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };


    const resolveLoad = (currentUserId: string) => {
        if (id) {
            getParticipantData(id as string)
        } else {
            //@ts-ignore
            getParticipantData(currentUserId as string)
        }
    }

    useEffect(() => {
        mounted.current = true;
        if (userData) {
            //@ts-ignore
            resolveLoad(userData?.id);
        } else {
            authenticationService
                .getLoggedInUserData()
                .then((res: any) => {
                    if (mounted.current) {
                        //@ts-ignore
                        setUserData(res);
                        resolveLoad(res.id);
                    }
                })
                .catch((error) =>
                    handleAlert(error.message ? error.Message :
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    )
                );
        }
        return () => {
            mounted.current = false;
        }

    }, []);
    //#endregion

    //#region MANAGE PARTICIPANT DATA CHANGES
    const updateParticipant = (participant: Participant) => {
        setOpenUpdateParticipantDialog(false);
        setParticipant({ ...participant });
    };

    const updateParticipantState = () => {
        if (id) {
            getParticipantData(id)
            //@ts-ignore
        } else getParticipantData(userData.id)
    };
    //#endregion

    //#region MANAGE EMAIL DATA CHANGES
    const updateEmail = (emailId: string, newState: string) => {
        mailsService
            .updateEmailStatus(emailId, newState)
            .then((res) => {
                if (id) {
                    getParticipantData(id)
                    //@ts-ignore
                } else getParticipantData(userData.id)
                handleAlert('Email updated successfully', 'success');
            })
            .catch((error) => {
                getParticipantData(id)
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            }
            );
    };

    const addNewMail = (email: string) => {
        if (id) {
            addNewParticipantMail(email, id);
        } else {
            if (userData) {
                //@ts-ignore
                addNewParticipantMail(email, userData.id)
            }
        }

    };

    const addNewParticipantMail = (email: string, id: string) => {
        participantsService.addNewMail({ email: email }, id).then((res) => {
            handleAlert('Email added successfully', 'success');
            getParticipantData(id)
        })
            .catch((error) => {
                getParticipantData(id) //
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            }
            );
    };
    //#endregion


    const checkIfComplete = () => {
        if (participant?.firstName && participant?.lastName && participant?.emails &&
            participant?.dateOfBirth && participant?.birthCountry && participant?.gender && participant.state !== "blocked") {
            return true;
        } else return false;
    };

    const [createdAssertion, setCreatedAssertion] = useState(false);
    const onCreateAssertion = () => {
        setCreatedAssertion(true);
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ParticipantBaseDataCard
                        userData={participant as Participant}
                        onClickOpenMailManagementDialog={handleClickOpenMailManagementDialog}
                        onClickOpenParticipantDialog={handleClickOpenParticipantDialog}
                        onUpdateParticipantState={updateParticipantState}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BookingsOnExamEventSection id={participant?.id} />
                </Grid>
                <Grid item xs={12}>
                    <CertificatesSection id={participant?.id} isComplete={checkIfComplete()} onCreateAssertion={onCreateAssertion} />
                </Grid>
                <Grid item xs={12}>
                    <OrdersSection id={participant?.id} />
                </Grid>
                <Grid item xs={12}>
                    <AssertionsSection id={participant?.id} createdAssertion={createdAssertion} />
                </Grid>
                <Grid item xs={12}>
                    <ComplexTable
                        tableData={participant?.organisations as any}
                        headerCells={usersOrganizationsHeaders}
                        title="Organizations"
                        addAction={() => { }}
                        addIcon={undefined}
                        updateTableData={participant?.organisations}
                    //loading={loading}
                    >
                        {participant?.organisations
                            ? participant?.organisations.map((r) => <UsersOrganizationsRow key={r.id} row={r} />)
                            : null}
                    </ComplexTable>
                </Grid>
            </Grid>

            <div>
                {updateParticipantDialogIsOpen ? (
                    <ModalDialog
                        dialogIsOpen={updateParticipantDialogIsOpen}
                        setDialogOpen={handleClickOpenParticipantDialog}
                        title="Edit Person"
                    >
                        <ParticipantForm
                            participant={participant as Participant}
                            updateParticipant={updateParticipant}
                            cancel={() => setOpenUpdateParticipantDialog(false)}
                        />
                    </ModalDialog>
                ) : null}
            </div>
            <div>
                {mailManagementDialogIsOpen ? (
                    <ModalDialog
                        dialogIsOpen={mailManagementDialogIsOpen}
                        setDialogOpen={handleClickOpenMailManagementDialog}
                        title="Mail Management"
                    >
                        <MailManagementForm
                            emails={emails as Email[]}
                            updateEmail={updateEmail}
                            addNewMail={addNewMail}
                            onMailReassignUserModalToggle={handleClickOpenMailReassignUserDialog}
                            onMailReassignOrganizationModalToggle={handleClickOpenMailReassignOrganizationDialog}
                            closeDialog={() => setOpenMailManagementDialog(false)}
                            type={"user"}
                        />
                    </ModalDialog>
                ) : null}
            </div>
            <div>
                {mailReassignUserDialogIsOpen ? (
                    <ModalDialog
                        dialogIsOpen={mailReassignUserDialogIsOpen}
                        setDialogOpen={() => setOpenMailReassignUserDialog(!mailReassignUserDialogIsOpen)}
                        title="Reassign Mail"
                        fullscreen={true}
                    >
                        <MailReassignUserDialog
                            closeDialog={() => setOpenMailReassignUserDialog(false)}
                            onReassignSuccess={() => {
                                if (id) {
                                    getParticipantData(id)
                                    //@ts-ignore
                                } else getParticipantData(userData.id)
                            }}
                            emailId={selectedMailToReassign}
                        />
                    </ModalDialog>
                ) : null}
            </div>
            <div>
                {mailReassignOrganizationDialogIsOpen ? (
                    <ModalDialog
                        dialogIsOpen={mailReassignOrganizationDialogIsOpen}
                        setDialogOpen={() => setOpenMailReassignOrganizationDialog(!mailReassignOrganizationDialogIsOpen)}
                        title="Reassign Mail"
                        fullscreen={true}
                    >
                        <MailReassignOrganizationDialog
                            closeDialog={() => setOpenMailReassignOrganizationDialog(false)}
                            onReassignSuccess={() => {
                                if (id) {
                                    getParticipantData(id)
                                    //@ts-ignore
                                } else getParticipantData(userData.id)
                            }}
                            emailId={selectedMailToReassign}
                        />
                    </ModalDialog>
                ) : null}
            </div>

        </Fragment >
    );
};
