export const examBookingTransitions = new Map<string, string[]>([
    ['prepared', ['succeeded', 'failed', 'not_graded', 'cheated', 'not_participated', 'canceled']],
    ['succeeded', ['failed', 'not_graded', 'cheated', 'not_participated']],
    ['failed', ['succeeded', 'not_graded', 'cheated', 'not_participated']],
    ['not_graded', ['succeeded', 'failed', 'cheated', 'not_participated']],
    ['cheated', ['succeeded', 'failed', 'not_graded', 'not_participated']],
    ['not_participated', ['succeeded', 'failed', 'not_graded', 'prepared', 'cheated']],
]);

export const allExamBookingStates = ['not_participated', 'succeeded', 'prepared', 'failed', 'not_graded', 'cheated', 'canceled'];

export const examBookingStatusOptionsGetter = (currentStatus?: string): string[] => {
    return examBookingTransitions.get(currentStatus ? currentStatus : '') as string[];
};
