import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';

export const convert = (date: string, hours: boolean) => {
    if (date && !hours) {
        return format(parseISO(new Date(date).toISOString()), 'dd-MM-yyyy');
    } else if (date && hours) {
        return format(parseISO(new Date(date).toISOString()), 'dd-MM-yyyy HH:mm');
    } else return '';
};


export const convertOnlyDate = (date:string) => {
    return format((parse(date, 'dd-MM-yyyy', new Date())), 'yyyy-MM-dd')
}