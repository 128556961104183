import { Fragment, useState, useContext, useRef } from 'react';
import { ModalDialog } from '../../customized-components';
import ComplexTable from 'components/organisms/ComplexTable';
import { ParticipantRow } from './participant-row';
import EditIcon from '@mui/icons-material/Edit';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { useEffect } from 'react';
import { participantsService } from '../../services/participants/participants.service';
import { BookingsOnExamEventForm } from '../bookings-on-exam-event-form/bookings-on-exam-event-form';
import { AddParticipantForm } from '../participant-form/add-participant-form';
import { useHistory } from 'react-router-dom';
import { participantsHeaders } from '../../models/table-headers/participants-headers';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { AlertContext } from '../../App';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import { defaultParticipantsFilters } from '../../models/table-filters.ts/participants-table-filters';
import { authenticationService } from '../../services/authentication/authentication.service';
import { UserContext } from '../../context/UserContext';

export function Participants() {
    const defaultRequest: PaginationSortingFilteringRequest = {
        order: 'desc',
        orderBy: 'createdAt',
        page: 0,
        rowsPerPage: 10,
        filters: defaultParticipantsFilters,
    };
    const mounted = useRef(false);

    const [participants, setParticipants] = useState<TablePageSortFilter>({
        ...defaultRequest,
        total: 10,
        results: [],
    });
    const [participantDialogIsOpen, setOpenParticipantDialog] = useState<boolean>(false);
    const [bookingOnExamEventDialogIsOpen, setOpenBookingOnExamEventDialog] = useState<boolean>(false);
    const [participantForBooking, setParticipantForBooking] = useState<string>('');
    const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData, setUserData } = useContext(UserContext);

    useEffect(() => {
        mounted.current = true;
        if (!userData) {
            authenticationService.getLoggedInUserData().then((res: any) => {
                if (mounted.current) {
                    //@ts-ignore
                    setUserData(res);
                }
            });
            loadParticipants(paginationData);
        } else {
            loadParticipants(paginationData);
        }
        return () => {
            mounted.current = false;
        }

    }, []);

    const loadParticipants = (data: PaginationSortingFilteringRequest) => {
        setLoading(true)
        participantsService
            .getParticipantsWithPaginationAndFilter(data)
            .then((res) => {
                if (mounted.current) {
                    setParticipants(res);
                    setLoading(false)
                }

            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const handleClickOpenParticipantDialog = () => {
        setOpenParticipantDialog(!participantDialogIsOpen);
    };

    const addNewParticipant = () => {
        setOpenParticipantDialog(false);
        loadParticipants(paginationData);
    };

    const openParticipantDetailsPage = (id: string) => {
        history.push(`/participants/${id}`, { id });
    };


    const updateParticipantState = (updateData: any, participantId: string) => {
        setLoading(true)
        participantsService.changeParticipantState(updateData.newState, participantId)
            .then(() => {
                handleAlert('Person state updated successfully!', 'success');
                loadParticipants(paginationData);
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const handleClickOpenBookingOnExamEventDialog = (participantId: string) => {
        setParticipantForBooking(participantId);
        setOpenBookingOnExamEventDialog(!bookingOnExamEventDialogIsOpen);
    };

    const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
        setPaginationData(newValue)
    };

    const actions = {
        onEditParticipant: {
            title: 'Edit Person',
            icon: EditIcon,
            onClick: openParticipantDetailsPage,
        },
        onCreateBookingOnExamEvent: {
            title: 'Create booking on exam event',
            icon: LocalLibraryIcon,
            onClick: handleClickOpenBookingOnExamEventDialog,
        },
        onParticipantStatusChange: {
            title: 'Update person status',
            icon: LocalLibraryIcon,
            onClick: updateParticipantState,
        },
    };

    return (
        <Fragment>
            <ComplexTable
                tableData={participants as TablePageSortFilter}
                headerCells={participantsHeaders}
                title="Persons"
                addAction={handleClickOpenParticipantDialog}
                addIcon={PersonAdd}
                updateTableData={loadParticipants}
                onPaginationUpdate={trackPagination}
                loading={loading}

            >
                {participants?.results
                    ? participants.results.map((r) => <ParticipantRow key={r.id} row={r} actions={actions} />)
                    : null}
            </ComplexTable>
            <div>
                {participantDialogIsOpen ? (
                    <ModalDialog
                        dialogIsOpen={participantDialogIsOpen}
                        setDialogOpen={handleClickOpenParticipantDialog}
                        title="Add Person"
                    >
                        <AddParticipantForm
                            addNewparticipant={addNewParticipant}
                            cancel={handleClickOpenParticipantDialog}
                        />
                    </ModalDialog>
                ) : null}
            </div>
            <div>
                {bookingOnExamEventDialogIsOpen ? (
                    <ModalDialog
                        dialogIsOpen={bookingOnExamEventDialogIsOpen}
                        setDialogOpen={() => setOpenBookingOnExamEventDialog(!bookingOnExamEventDialogIsOpen)}
                        title="Booking On Exam Event"
                    >
                        <BookingsOnExamEventForm
                            participantId={participantForBooking}
                            onAddBookingSuccessful={() => setOpenBookingOnExamEventDialog(false)}
                            cancel={() => setOpenBookingOnExamEventDialog(false)}
                        />
                    </ModalDialog>
                ) : null}
            </div>
        </Fragment>
    );
}

export default Participants;
