import { callAxios } from 'axios-wrapper/call-axios';
import { BadgeRequest, BadgeResponse, BadgeCreateRequest } from 'models/Badges';
import { AxiosResponse } from 'axios';

import PaginationSortingFilteringRequest from 'models/table-models/PaginationSortingFilteringRequest';
import UrlFilterConstructor from 'transformators/UrlFilterConstructor';

const assignBadgeToUserCertificate = ({
    badgeClassId,
    certificateClassId,
    userId,
    userCertificate,
}: {
    badgeClassId: string;
    certificateClassId: string;
    userId: string;
    userCertificate: string;
}): Promise<any> => {
    return callAxios
        .post(`/badgeClasses/assignBadgeToUserCertificate`, {
            badgeClassId,
            certificateClassId,
            userId,
            userCertificate,
        })
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            } else {
                throw new Error('Assigning Badge to user certificate failed');
            }
        })
        .catch(function (error) {
            if (error?.response?.data?.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Creating Badge failed');
            }
        });
};

const getBadgesAndFilterWithPagination = (r: PaginationSortingFilteringRequest): Promise<any> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(`/badgeClasses/?orderBy=${r.orderBy.toString()}&page=${r.page + 1}&items=${r.rowsPerPage}${filter}`)
        .then((res) => {
            if (res.status === 200) {
                if (res.data.rows.length >= 0) {
                    return { ...r, results: res.data.rows, total: res.data.total };
                } else {
                    throw new Error('Loading failed');
                }
            } else {
                throw new Error('Getting Badges failed');
            }
        });
};

const getBadgesAndFilter = (r: BadgeRequest): Promise<AxiosResponse<BadgeResponse>> => {
    return callAxios
        .get(
            `/badgeClasses/?orderBy=${r.orderBy.toString()}&page=${r.page + 1}&items=${r.pageSize}`
            // `/badgeClasses/?orderBy=${r.orderBy.toString()}`
        )
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            } else {
                throw new Error('Getting Badges failed');
            }
        });
};

const updateBadge = (body: any, id: string): Promise<any> => {
    return callAxios.patch(`/badgeClasses/${id}`, body).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating Badge failed');
        }
    });
};

const createBadge = (body: BadgeCreateRequest): Promise<any> => {
    return callAxios
        .post(`/badgeClasses`, body)
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            } else {
                throw new Error('Creating Badge failed');
            }
        })
        .catch(function (error) {
            if (error?.response?.data?.message) {
                throw new Error(error?.response?.data?.message);
            } else {
                throw new Error('Creating Badge failed');
            }
        });
};

const syncBadges = (): Promise<any> => {
    return callAxios
        .get(`/badgeClasses/getDataFromSqms`)
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            } else {
                throw new Error('Sync Badges failed');
            }
        })
        .catch(function (error) {
            if (error?.response?.data?.message) {
                throw new Error(error?.response?.data?.message);
            } else {
                throw new Error('Sync Badges failed');
            }
        });
};

const updateBadgeStatus = (body: any, id: string): Promise<any> => {
    return callAxios
        .patch(`/badgeClasses/${id}/updateBadgeStatus`, body)
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            } else {
                throw new Error('Update Badges status failed');
            }
        })
        .catch(function (error) {
            if (error?.response?.data?.message) {
                throw new Error(error?.response?.data?.message);
            } else {
                throw new Error('Update Badges status failed');
            }
        });
};

const deleteBadge = (id: string): Promise<any> => {
    return callAxios.remove(`/badgeClasses/${id}`).then((res: any) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Creating Badge failed');
        }
    });
};

export const badgesService = {
    getBadgesAndFilter,
    updateBadge,
    createBadge,
    deleteBadge,
    syncBadges,
    updateBadgeStatus,
    getBadgesAndFilterWithPagination,
    assignBadgeToUserCertificate,
};
