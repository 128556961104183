import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '../../customized-components';
import { Fragment, FunctionComponent, useContext, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { InputLabel, Select, FormControl, FormHelperText, MenuItem, Box } from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { participantsService } from '../../services/participants/participants.service';
import { Participant } from '../../models/Participant';
import { countries, CountryType } from './countries';
import { AlertContext } from '../../App';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import Moment from "@date-io/moment";
const momentum = new Moment();

export interface ParticipantFormProps {
    participant: Participant;
    updateParticipant: (participant: Participant) => void;
    cancel: () => void;
}

export const ParticipantForm: FunctionComponent<ParticipantFormProps> = (props: ParticipantFormProps) => {
    const getBirthCountryFromOptions = (option: string) => {
        return countries.find((c) => c.label === option);
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<Participant>({
        defaultValues: {
            ...props.participant,
            firstName: props.participant.firstName ? props.participant.firstName : '',
            lastName: props.participant.lastName ? props.participant.lastName : '',
            name: props.participant.name ? props.participant.name : '',
            gender: props.participant.gender ? props.participant.gender : '',
            placeOfBirth: props.participant.placeOfBirth ? props.participant.placeOfBirth : '',
            birthCountry: getBirthCountryFromOptions(props.participant.birthCountry),
        },
    });


    const [loading, setLoading] = useState<boolean>(false);

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const onSubmit = (data: Participant) => {
        setLoading(true);

        if (data.dateOfBirth) {
            // data.dateOfBirth = format(parseISO(new Date(data.dateOfBirth).toISOString()), 'yyyy-MM-dd') + 'T00:00:00Z';
            // data.dateOfBirth = momentum.moment(data.dateOfBirth).format('YYYY-MM-DDTHH:mm:ss');

            var now = momentum.moment();
            data.dateOfBirth = momentum.moment(data.dateOfBirth).set({
                'hour': now.hour(),
                'minute': now.minute(),
                'second': now.second()
            }).format('YYYY-MM-DDTHH:mm:ss');

            console.log("data.dateOfBirth", data.dateOfBirth);

        }

        console.log("data", data);

        if (data.birthCountry) {
            data.birthCountry = data.birthCountry.label;
        }
        if (data.id) {
            data.id = props.participant.id;
        }
        participantsService
            .updateParticipant(data)
            .then((res: any) => {
                props.updateParticipant(data);
                handleAlert('Person updated successfully', 'success');
                props.cancel();
            }).catch((error) => {
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
                setLoading(false);
            }
            );
    };

    const restrictedCharacters = (value: string) => {
        // const valid = value.match(/[^a-zA-Z0-9äöüÄÖÜß']/g)
        const valid = value.match(/[^a-zA-Z0-9ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ' -]/g)
        return !valid;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="firstName"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="firstName"
                                    id="firstName"
                                    label="First Name"
                                    size="small"
                                    variant="outlined"
                                    error={!!errors.firstName}
                                    helperText={errors.firstName && errors.firstName.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'First name is required field',
                                validate: {
                                    hasSpecial: (value) => {
                                        return restrictedCharacters(value) || 'First name must not contain special character.'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="lastName"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="lastName"
                                    id="lastName"
                                    label="Last Name"
                                    size="small"
                                    variant="outlined"
                                    error={!!errors.lastName}
                                    helperText={errors.lastName && errors.lastName.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Last name is required field',
                                validate: {
                                    hasSpecial: (value) => {
                                        return restrictedCharacters(value) || 'Last name must not contain special character.'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller name='language' render={({ field }) =>
                                <Fragment>
                                    <InputLabel htmlFor="language">Language</InputLabel>
                                    <Select
                                        {...field}
                                        data-testid="test-language"
                                        id="language"
                                        label="Language"
                                        error={!!errors.language}
                                        onChange={(value) => {
                                            if (value.target.value)
                                                //@ts-ignore
                                                setValue("language", value.target.value);
                                        }}
                                    >
                                        {/* <option aria-label="None" value="" /> */}
                                        <MenuItem value={"EN"}>English</MenuItem>
                                        <MenuItem value={"DE"}>Deutsch</MenuItem>
                                    </Select>
                                    <FormHelperText style={{ color: errors.language ? 'red' : 'grey' }}>{errors.language && errors.language.message}</FormHelperText>
                                </Fragment>

                            }
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: "Language is required field"
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="gender"
                                render={({ field }) => (
                                    <Fragment>
                                        <InputLabel htmlFor="gender">Gender</InputLabel>
                                        <Select
                                            {...field}
                                            data-testid="test-gender"
                                            id="gender"
                                            label="gender"
                                            error={!!errors.gender}
                                            onChange={(value) => {
                                                if (value.target.value)
                                                    //@ts-ignore
                                                    setValue("gender", value.target.value);
                                            }}
                                        >
                                            <MenuItem value={'male'}>Male</MenuItem>
                                            <MenuItem value={'female'}>Female</MenuItem>
                                            <MenuItem value={'inter'}>Inter</MenuItem>
                                        </Select>
                                        <FormHelperText style={{ color: errors.gender ? 'red' : 'grey' }}>
                                            {errors.gender?.message}
                                        </FormHelperText>
                                    </Fragment>
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Gender is required field',
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Controller
                                name="dateOfBirth"
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        data-testid="date-of-birth"
                                        inputFormat="dd/MM/yyyy"
                                        label="Date of birth"
                                        value={field.value}
                                        onChange={(date: any | null) => {
                                            setValue('dateOfBirth', date as any, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }}
                                        renderInput={(params: any) => <TextField sx={{ width: '100%' }} {...params} />}
                                    />
                                )}
                                control={control}
                                defaultValue={undefined}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="placeOfBirth"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    data-testid="placeOfBirth"
                                    {...field}
                                    id="placeOfBirth"
                                    label="Place of birth"
                                    size="small"
                                    variant="outlined"
                                />
                            )}
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="birthCountry"
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    fullWidth
                                    onChange={(event, item) => {
                                        onChange(item);
                                    }}
                                    isOptionEqualToValue={(option, value) => value === undefined || option.id === value.id}
                                    value={value}
                                    // {...field}
                                    options={countries as CountryType[]}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props: any, option) => (
                                        <Box component='li' {...props} key={option.id}>
                                            {option.label} ({option.id}) +{option.phone}
                                        </Box>
                                    )}
                                    data-testid="countryOfBirth"
                                    id="countryOfBirth"
                                    size="small"
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country of birth"
                                            variant="outlined"
                                            error={!!errors.birthCountry}
                                            helperText={errors.birthCountry && errors.birthCountry.message}
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                        />
                                    )}
                                />
                            )}
                            control={control}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
						<Controller name='externalId' render={({ field }) =>
							<TextField fullWidth
								data-testid="test-externalId"
								{...field}
								id="externalId"
								label="External ID"
								size="small"
								variant="outlined"
							/>
						}
							control={control}
							defaultValue=''
						/>
					</Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="saveupdateparticipant" color="primary" type="submit" variant="contained">
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        reset();
                        props.cancel();
                    }}
                    data-testid="cancelnewparticipant"
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
