import { callAxios } from '../../axios-wrapper/call-axios';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';

import UrlFilterConstructor from '../../transformators/UrlFilterConstructor';

const getBookings = (userid: string, r: PaginationSortingFilteringRequest): Promise<any> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(
            `/users/${userid}/examevents/?orderBy=${r.orderBy.toString()}&sortOrder=${r.order}&page=${
                r.page + 1
            }&items=${r.rowsPerPage}${filter}`
        )
        .then((res) => {
            if (res.status === 200) {
                return { ...r, results: res.data.rows, total: res.data.total };
            } else {
                throw new Error('Loading failed');
            }
        });
};

const bookUsersOnExamEvent = (userIds: string[], examid: string): Promise<any> => {
    const requestBody = {
        users: userIds,
        proctors: [],
    };
    return callAxios.post(`/examevents/${examid}/add`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Booking failed');
        }
    });
};

const cancelUsersFromExamEvent = (userIds: string[], examid: string) => {
    const requestBody = {
        users: userIds,
        proctors: [],
    };
    return callAxios.post(`/examevents/${examid}/cancel`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Canceling failed');
        }
    });
};

const changeUsersExamBookingStatus = (userID: string, exameventID: string, examID: string, newState: any) => {
    return callAxios.post(`/users/${userID}/exam/${exameventID}/${examID}`, newState).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Changing user exam booking failed');
        }
    });
};

export const bookingsService = {
    getBookings,
    bookUsersOnExamEvent,
    cancelUsersFromExamEvent,
    changeUsersExamBookingStatus,
};
