import { withRouter } from 'react-router-dom';

const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const AuthVerifier = (props: any) => {
    props.history.listen(() => {
        const token = JSON.parse(sessionStorage.getItem('user') as string);

        if (token) {
            const decodedJwt = parseJwt(token);

            if (decodedJwt.exp * 1000 < Date.now()) {
                props.logOut();
            }
        }
    });

    return <div></div>;
};

export default withRouter(AuthVerifier);
