import { styled } from '@mui/material/styles';

const PREFIX = 'ComplexMTable';

export const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    visuallyHidden: `${PREFIX}-visuallyHidden`,
    tableHeader: `${PREFIX}-tableHeader`,
    pagination: `${PREFIX}-pagination`,
    spinner: `${PREFIX}-spinner`,
};

export const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        width: '100%',
        boxShadow: '0 0 1rem 0 rgba(0, 0, 0, 0.2)',
        '&:before': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            backdropFilter: 'blur(10px) saturate(100%) contrast(45%) brightness(130%)',
            content: '""',
            height: '100%',
            position: 'absolute',
            // width: '100%',
        },
        borderRadius: '10px 10px 10px 10px',
        border: `2px solid ${theme.palette.primary.main}`,
    },
    [`& .${classes.paper}`]: {
        width: '100%',
        borderRadius: '10px 10px 10px 10px',
    },
    [`& .${classes.table}`]: {
        minWidth: 750,
        backgroundColor: 'white',
    },
    [`& .${classes.visuallyHidden}`]: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    [`& .${classes.tableHeader}`]: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    [`& .${classes.pagination}`]: {
        backgroundColor: 'white',
        borderRadius: '0px 0px 10px 10px',
    },
    [`& .${classes.spinner}`]: {
        marginTop: '50px',
        textAlign: 'center',
        height: '100px',
    },
}));
