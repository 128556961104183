import {
  Box, Button, Collapse, Grid, Table, TableBody,
  TableCell, TableHead, TableRow, Tooltip, Typography,
  CardMedia, CardActionArea, Chip, Link, IconButton
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlaceholderImage from 'assets/placeholder-image.png'
import { Alignment } from 'models/Alignments'
import { ALIGNMENT_STATUS } from 'models/enums'
import { TableAction } from 'models/table-models/TableAction'
import { FunctionComponent, useState } from 'react'


export interface AlignmentRowProps {
  row: Alignment;
  actions?: TableAction[];
}

export const AlignmentRow: FunctionComponent<AlignmentRowProps> = (props: AlignmentRowProps) => {

  const [showBadges, setShowBadges] = useState<boolean>(false);

  return (
    <>
      <TableRow key={props.row.id} hover>
        <TableCell size="medium">
          <Grid container spacing={1}>
            {props.actions &&
              props.actions.map((single, index) => (
                <Grid item key={index}>
                  <Tooltip title={single.title}>
                    <span>
                      <Button
                        variant="outlined"
                        color="primary"
                        aria-label="filter list"
                        //@ts-ignore
                        onClick={() => single.onClick(props.row.id)}
                      //disabled={props.row.status !== ALIGNMENT_STATUS.NEW}
                      >
                        {<single.icon color="primary" />}
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              ))}
          </Grid>
        </TableCell>
        <TableCell size="medium">{props.row.targetName}</TableCell>
        <TableCell size="medium">{props.row.targetDescription}</TableCell>
        <TableCell size="medium">{props.row.targetFramework}</TableCell>
        <TableCell size="small">
          {props.row.targetUrl && <a target="_blank" href={props.row.targetUrl} rel="noreferrer">{props.row.targetUrl}</a>}
        </TableCell>
        <TableCell size="small">{props.row.status}
          {props.row.status && props.row.status === ALIGNMENT_STATUS.ASSIGNED ?
            <Chip style={{ marginLeft: '0.5rem' }} variant="outlined" label={props.row.assignedToBadges.length}></Chip>
            : <></>}
        </TableCell>
        <TableCell>
          {props.row.status && props.row.status === ALIGNMENT_STATUS.ASSIGNED ?
            <IconButton aria-label="expand row" size="small" onClick={() => setShowBadges(!showBadges)}>
              {showBadges ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton> : <div></div>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={showBadges} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Badges
              </Typography>
              <Table size="small" aria-label="exams">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Criteria URL</TableCell>
                    <TableCell>Tags</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.row.assignedToBadges?.map((single, index) => (
                    <TableRow key={single.id + index}>
                      <TableCell sx={{ width: '2rem' }}>{single.title}</TableCell>
                      <TableCell sx={{ width: '2rem' }}>
                        <CardActionArea href={single.image} target={'_blank'}>
                          <CardMedia
                            component="img"
                            image={single.image}
                            onError={(e: any) => {
                              e.target.src = PlaceholderImage
                            }}
                            sx={{
                              width: 60,
                              height: 60
                            }}
                          />
                        </CardActionArea>
                      </TableCell>
                      <TableCell sx={{ width: '2rem' }}>
                        <Link target="_blank" href={single.criteriaURL}>{single.criteriaURL}</Link>
                      </TableCell>
                      <TableCell sx={{ width: '2rem' }}>
                        {single.tags?.map((tag, index) =>
                          <span key={index}><Chip style={{ margin: '0.0625rem' }} variant="outlined" label={tag}></Chip>
                            {/* {props.row.tags?.length === index + 1 ? '' : ','} */}
                          </span>)
                        }
                      </TableCell>
                      <TableCell sx={{ width: '2rem' }}>
                        {single.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
