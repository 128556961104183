import { DialogContent } from '@mui/material';
import { Fragment, FunctionComponent, useEffect, useContext, useState } from 'react';
import { AlertContext } from '../../App';
import ComplexTable from "components/organisms/ComplexTable";
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { defaultOrganizationsFilters } from '../../models/table-filters.ts/organizations-table-filters';
import { TableAction } from "../../models/table-models/TableAction";
import { organizationsHeaders } from '../../models/table-headers/organizations-headers';
import { OrganizationRow } from '../organizations/organization-row';
import { organizationsService } from '../../services/organizations/organizations.service';
import { mailsService } from '../../services/mails/mails.service';

export interface MailReassignProps {
	closeDialog: () => void;
	onReassignSuccess: () => void;
	emailId: string;
}

export interface MailInput {
	email: string;
}


export const MailReassignOrganizationDialog: FunctionComponent<MailReassignProps> = (props: MailReassignProps) => {
	const defaultRequest = { order: 'desc', orderBy: 'createdAt', page: 0, rowsPerPage: 10, filters: defaultOrganizationsFilters() };

	const [organizations, setOrganizations] = useState<TablePageSortFilter>();
	const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);
	const [loading, setLoading] = useState<boolean>(false);

	//@ts-ignore
	const { handleAlert } = useContext(AlertContext);

	useEffect(() => {
		loadTable(paginationData);

	}, []);

	const loadTable = (data: PaginationSortingFilteringRequest) => {
		setLoading(true)
		organizationsService
			.getOrganizationsWithPaginationAndFilter(data)
			.then((res) => {
				setOrganizations(res);
				setLoading(false);
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);
	};

	const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
		setPaginationData(newValue)
	};

	const reassignMail = (id: string) => {
		const data = {
			id: props.emailId,
			targetID: id,
			targetType: 'organisation'
		}
		mailsService.reassignEmail(data)
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);
	};

	const actions: TableAction[] = [
		{
			title: 'Reassign',
			icon: TransferWithinAStationIcon,
			onClick: reassignMail,
		},
	];

	return (
		<Fragment>
			<DialogContent
			>
				<ComplexTable
					tableData={organizations as TablePageSortFilter}
					headerCells={organizationsHeaders}
					title="Organizations"
					addAction={() => { }}
					addIcon={undefined}
					updateTableData={loadTable}
					loading={loading}
					onPaginationUpdate={trackPagination}
				>
					{organizations
						? organizations.results.map((r) => <OrganizationRow key={r.id} row={r} actions={actions} />)
						: null}
				</ComplexTable>
			</DialogContent>
			{/* <DialogActions>
				<Button onClick={props.closeDialog} color="primary" type="submit" variant="contained">
					Close
				</Button>
			</DialogActions> */}
		</Fragment>
	);
};


