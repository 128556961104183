import { HeadCell } from 'components/molecules/ComplexTableHead';

export const usersOrganizationsHeaders: HeadCell[] = [

    { id: 'id', numeric: false, label: 'ID', disablePadding: false, disableSorting: true },
    { id: 'name', numeric: false, label: 'Name', disablePadding: false, disableSorting: false },
    { id: 'shortName', numeric: false, label: 'Short Name', disablePadding: false, disableSorting: false },
    { id: 'role', numeric: false, label: 'Role', disablePadding: false, disableSorting: false },
    { id: 'permissions', numeric: false, label: 'Permissions', disablePadding: false, disableSorting: false },
    { id: 'state', numeric: false, label: 'State', disablePadding: false, disableSorting: true },
];
