import { TableCell, TableRow } from '@mui/material';
import { FunctionComponent } from 'react';
import { ExamEvent, ExamEventUser } from '../../models/ExamEvent';
import { appShellStyles } from '../../App.css';
import { useHistory } from 'react-router-dom';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

// import { StatusButton } from '../../customized-components/status-button/status-button';
// import { bookingStatusOptionsGetter } from '../../status-transition-rules/booking.transitions';
// import StatusChangeInfo from '../../utils/StatusChangeInfo';

export interface ExamEventUsersRowProps {
	row: ExamEventUser;
	examEvent: ExamEvent;
	handleRemoveParticipant: (userId) => void;
}

export const ExamEventUsersRow: FunctionComponent<ExamEventUsersRowProps> = (
	props: ExamEventUsersRowProps
) => {
	const history = useHistory();
	const classes = appShellStyles();

	// const statusOptions = bookingStatusOptionsGetter(props.row.relationDetails.status);
	// const enabled = statusOptions.length > 0;

	// const handleMenuItemClickStatus = (updateData: StatusChangeInfo) => {
	// 	props.actions.onChangeParticipantBookingStatus.onClick(props.row.id, updateData.newState);
	// };

	const redirectToUser = () => {
		history.push(`/participants/${props.row.id}`)
	}

	return (
		<TableRow key={props.row.id} style={{ cursor: 'pointer' }} className={classes.rowHover}>

			<TableCell onClick={redirectToUser}>{props.row.firstName}</TableCell>
			<TableCell onClick={redirectToUser}>{props.row.lastName}</TableCell>
			<TableCell onClick={redirectToUser}>
				{/* <StatusButton
					enabled={enabled}
					status={props.row.relationDetails.status}
					statusOptions={statusOptions}
					onStatusChange={handleMenuItemClickStatus}
				/> */}
				{props.row.relationDetails.status}
			</TableCell>
			<TableCell>
				{props.row.details?.booked > 0 && <div>Booked - {props.row.details.booked}</div>}
				{props.row.details?.cheated > 0 && <div>Cheated - {props.row.details.cheated}</div>}
				{props.row.details?.expired > 0 && <div>Expired - {props.row.details.expired}</div>}
				{props.row.details?.failed > 0 && <div>Failed - {props.row.details.failed}</div>}
				{props.row.details?.not_graded > 0 && <div>Not graded - {props.row.details.not_graded}</div>}
				{props.row.details?.prepared > 0 && <div>Prepared - {props.row.details.prepared}</div>}
				{props.row.details?.succeeded > 0 && <div>Succeeded - {props.row.details.succeeded}</div>}
				{props.row.details?.canceled > 0 && <div>Cancelled - {props.row.details.canceled}</div>}
				{props.row.details?.not_participated > 0 && <div>Not participated - {props.row.details.not_participated}</div>}
			</TableCell>
			<TableCell>{props.examEvent && props.examEvent.state && props.examEvent.state === 'reg.opened' && <PersonRemoveIcon
				onClick={() => { props.handleRemoveParticipant(props.row.id) }}
				style={{ color: 'tomato' }} />}</TableCell>
		</TableRow>
	);
};
