import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '../../customized-components';
import { FunctionComponent, useState, useContext, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, debounce } from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { convert } from '../../dateConverter';
import { bookingsService } from '../../services/bookings/bookings.service';
import { examEventService } from '../../services/examevents/exam-event.service';
import { ExamEvent, BookingOnExamEvent } from '../../models/ExamEvent';
import { AlertContext } from '../../App';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';

export interface BookingsOnExamEventFormProps {
    participantId: string;
    onAddBookingSuccessful: () => void;
    cancel: () => void;
}

export const BookingsOnExamEventForm: FunctionComponent<BookingsOnExamEventFormProps> = (
    props: BookingsOnExamEventFormProps
) => {

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<BookingOnExamEvent>();
    const [examEvents, setExamEvents] = useState<ExamEvent[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const [openAC, setOpenAC] = useState(false);
    const [loadingAC, setLoadingAC] = useState(false);

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const onSubmit = (data: BookingOnExamEvent) => {
        bookingsService.bookUsersOnExamEvent([props.participantId], data.examEvent.id).then((res) => {
            reset({});
            handleAlert('User booked successfully!', 'success');
            setLoading(false);
            props.onAddBookingSuccessful();
        }).catch((error) =>
            handleAlert(
                error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                'error'
            )
        );
    };

    const getExamEventsBySearchTerm = (search: string) => {
        examEventService.getAllExamEvents(search)
            .then((res) => {
                setExamEvents(res);
                setLoadingAC(false);
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    }

    useEffect(() => {
        getExamEventsBySearchTerm('');

    }, [props.participantId]);

    const onSearchExamEvent = debounce((search: string) => {
        getExamEventsBySearchTerm(search);
    }, 1800)

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Controller
                            name="examEvent"
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    filterSelectedOptions={false}
                                    filterOptions={(x) => x}
                                    fullWidth
                                    open={openAC}
                                    onOpen={() => {
                                        setOpenAC(true);
                                    }}
                                    noOptionsText="No data to show"
                                    onClose={() => {
                                        setOpenAC(false);
                                    }}
                                    onKeyUp={(event) => {
                                        //@ts-ignore
                                        if (event.target.value === '' || event.target.value.length > 2) {
                                            setLoadingAC(true);
                                            //@ts-ignore
                                            onSearchExamEvent(event.target.value);
                                        }
                                    }}
                                    onChange={(event, item) => onChange(item)}
                                    isOptionEqualToValue={(option, value) => value === undefined || option.id === value.id}
                                    value={value}
                                    // {...field}
                                    options={examEvents as any}
                                    loading={loadingAC}
                                    getOptionLabel={(option) => option.name}
                                    //TODO change any type
                                    renderOption={(props: any, option) => (
                                        <Box component="li" {...props} key={option.name}>
                                            {option.name} ({convert(option.startDate, true)})
                                        </Box>
                                    )}
                                    data-testid="examEvent-test"
                                    id="examEvent"
                                    size="small"
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Exam Event"
                                            variant="outlined"
                                            error={!!errors.examEvent}
                                            helperText={errors.examEvent && errors.examEvent.message}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loadingAC && <CircularProgress color="inherit" size={20} />}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            )}
                            control={control}
                            rules={{
                                required: 'Exam event is required field',
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Controller
                                    name='participationDate'
                                    render={({ field }) =>
                                        <DatePicker
										{...field}
										fullWidth
										data-testid="test-participationDate"
										variant="inline"
										inputVariant="outlined"
										size="small"
										format="dd/MM/yyyy"
										margin="none"
										id="participationDate"
										label="ParticipationDate"
										value={field.value}
										onChange={(date: MaterialUiPickersDate) => {
											setValue('participationDate', date as any, { shouldValidate: true, shouldDirty: true});
										}}
										error={errors.participationDate ? true : false}
										helperText={errors.participationDate && errors.participationDate.message}
									/>
								}
								control={control}
								rules={{
									required: "Participation date is required field"
								}}
								defaultValue={undefined}
							/>
						</MuiPickersUtilsProvider>
					</Grid> */}
                    {/* <Grid item xs={12} sm={6}>
						<Controller name='percentage' render={({ field }) =>
							<TextField fullWidth
								{...field}
								data-testid="percentage"
								id="percentage"
								label="Exam Event Percentage"
								size="small"
								variant="outlined"
								error={errors.percentage ? true : false}
								helperText={errors.percentage && errors.percentage.message}
							/>
						}
							control={control}
							defaultValue={0}
							rules={{
								required: "First name is required field"
							}}
						/>
					</Grid> */}
                    {/* <Grid item xs={12} sm={6}>
						<Controller name='info' render={({ field }) =>
							<TextField fullWidth
								{...field}
								data-testid="info"
								id="info"
								label="Exam Event Info"
								size="small"
								variant="outlined"
							/>
						}
							control={control}
							defaultValue=''
						/>
					</Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button
                        disabled={loading}
                        data-testid="saveupdateparticipant"
                        color="primary"
                        type="submit"
                        variant="contained"
                    >
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={props.cancel}
                    data-testid="cancelnewparticipant"
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
