import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import ComplexTableHead, { HeadCell } from 'components/molecules/ComplexTableHead';
import { Paper, SvgIconTypeMap } from '@mui/material';
import ComplexTableToolbar from 'components/molecules/ComplexTableToolbar';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import TablePageSortFilter from 'models/table-models/TablePageSortFilter';
import TableFilter from 'models/table-models/TableFilter';
import PaginationSortingFilteringRequest from 'models/table-models/PaginationSortingFilteringRequest';
import EmptyTable from 'components/molecules/EmptyTable';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { Root, classes } from './styles';

export interface ComplexTableProps extends PropsWithChildren<any> {
    tableData?: TablePageSortFilter;
    headerCells: HeadCell[];
    title: string;
    addIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | undefined;
    addAction: () => void | undefined;
    updateTableData?: (paginationAndSortingInfo: PaginationSortingFilteringRequest) => void;
    onPaginationUpdate?: (newValue: PaginationSortingFilteringRequest) => void;
    multiSelect?: any;
    loading?: boolean;
}

const ComplexTable: FC<ComplexTableProps> = (props: ComplexTableProps) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof any>('id' as string);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    useEffect(() => {
        //@ts-ignore
        setOrder(props.tableData?.order || 'asc');
        setOrderBy(props.tableData?.orderBy || 'id');
        setPage(props.tableData?.page || 0);
        setRowsPerPage(props.tableData?.rowsPerPage || 10);
        setTotalRows(props.tableData?.total || 0);
    }, [props.tableData]);

    const reloadDataWithPaginationSortFilter = (reloadRequest: PaginationSortingFilteringRequest) => {
        if (props.updateTableData) {
            props.updateTableData(reloadRequest);
        }
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const reloadRequest: PaginationSortingFilteringRequest = {
            page: page,
            rowsPerPage: rowsPerPage,
            orderBy: property,
            order: isAsc ? 'desc' : 'asc',
            filters: props.tableData?.filters ? props.tableData?.filters : [],
        };
        if (props.onPaginationUpdate) {
            props.onPaginationUpdate(reloadRequest);
        }
        reloadDataWithPaginationSortFilter(reloadRequest);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        const reloadRequest: PaginationSortingFilteringRequest = {
            page: newPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order,
            filters: props.tableData?.filters ? props.tableData?.filters : [],
        };
        if (props.onPageChange) {
            props.onPageChange(reloadRequest);
        }
        if (props.onPaginationUpdate) {
            props.onPaginationUpdate(reloadRequest);
        }
        reloadDataWithPaginationSortFilter(reloadRequest);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        // if (props.onItemsPerPageChange) {
        //     props.onItemsPerPageChange(event.target.value)
        // }
        const reloadRequest: PaginationSortingFilteringRequest = {
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
            orderBy: orderBy,
            order: order,
            filters: props.tableData?.filters ? props.tableData?.filters : [],
        };
        if (props.onPaginationUpdate) {
            props.onPaginationUpdate(reloadRequest);
        }
        reloadDataWithPaginationSortFilter(reloadRequest);
    };

    const handleClearPage = () => {
        setPage(0);

        const reloadRequest: PaginationSortingFilteringRequest = {
            page: 0,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order,
            filters: props.tableData?.filters ? props.tableData?.filters : [],
        };
        if (props.onPaginationUpdate) {
            props.onPaginationUpdate(reloadRequest);
        }
        reloadDataWithPaginationSortFilter(reloadRequest);
    };

    const handleFilterChange = (filters: TableFilter[]) => {
        const reloadRequest: PaginationSortingFilteringRequest = {
            page: 0,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order,
            filters: filters,
        };
        if (props.onPaginationUpdate) {
            props.onPaginationUpdate(reloadRequest);
        }
        reloadDataWithPaginationSortFilter(reloadRequest);
    };

    return (
        <Root className={classes.root}>
            <Paper className={classes.paper}>
                <ComplexTableToolbar
                    filters={props.tableData?.filters || []}
                    onFilterChange={handleFilterChange}
                    handleClearPage={handleClearPage}
                    title={props.title}
                    addAction={props.addAction}
                    addIcon={props.addIcon}
                />
                {props.children && props.children.length > 0 ? (
                    <>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size="medium"
                                aria-label="enhanced table"
                            >
                                <ComplexTableHead
                                    classes={classes}
                                    order={order}
                                    //@ts-ignore
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={totalRows || 0}
                                    headerCells={props.headerCells}
                                    multiSelect={props.multiSelect}
                                />
                                <TableBody>{props.children}</TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : props.loading ? (
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                ) : (
                    <EmptyTable icon={DeleteOutlineIcon} />
                )}

                <TablePagination
                    rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
                    component="div"
                    count={totalRows || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ borderRadius: '10px 10px 0px 0px' }}
                />
            </Paper>
        </Root>
    );
};

type Order = 'asc' | 'desc';

export default ComplexTable;
