import React, { Suspense, useState, createContext } from 'react';
import clsx from 'clsx';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, List, CssBaseline, ListItem, ListItemIcon, ListItemText, Snackbar, Grid, Typography, Link } from '@mui/material';
import { Alert } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { Avatar, Button, CardMedia, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, SvgIcon } from '@mui/material';
import { LoginForm, NewPasswordForm, PrivateRoute, ResetPasswordForm, SignUpForm } from './authentication';
import { authenticationService } from './services/authentication/authentication.service';
import { Route, Router, Switch } from 'react-router-dom';
import { LoadingIndicator } from './customized-components';
import { history } from './services/authentication/history';
import { useEffect } from 'react';
import Participants from './features';
import background from './assets/ICO_slider2.jpg';
import { appShellStyles } from './App.css';
import { ParticipantDetails } from './features/participant-details/participant-details';
import { Organizations } from './features/organizations/organizations';
import { OrganizationMembers } from './features/organization-members/organization-members';
import ExamEvents from './features/exam-events/exam-events';
import { ExamEventDetails } from './features/exam-event-details/exam-event-details';
import { UserContext } from './context/UserContext';
import AuthVerifier from './authentication/auth-verifier';
import PersonIcon from '@mui/icons-material/Person';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Breadcrumbs from './customized-components/breadcrumbs/breadcrumbs';
import { Certificates } from './features/certificates-details/certificate-details';
import { styled } from "@mui/material/styles";
import AlignmentsPage from "./components/pages/AlignmentsPage";
import BadgesPage from "./components/pages/BadgesPage";
import AssertionsPage from "./components/pages/AssertionsPage";
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import BadgeIcon from '@mui/icons-material/Badge';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ExamsPage from 'components/pages/ExamsPage';
import MaintenancePage from 'components/pages/MaintenancePage/MaintenancePage';

export const AlertContext = createContext(undefined);

const drawerWidth = 240;
const PREFIX = 'App';

declare global {
    namespace React {
        interface DOMAttributes<T> {
            onResize?: ReactEventHandler<T> | undefined;
            onResizeCapture?: ReactEventHandler<T> | undefined;
            nonce?: string | undefined;
        }
    }
}

const classes = {
    color: `${PREFIX}-color`,
    root: `${PREFIX}-root`,
    menuButton: `${PREFIX}-menuButton`,
    hide: `${PREFIX}-hide`,
    drawer: `${PREFIX}-drawer`,
    drawerOpen: `${PREFIX}-drawerOpen`,
    drawerClose: `${PREFIX}-drawerClose`,
    toolbar: `${PREFIX}-toolbar`,
    content: `${PREFIX}-content`,
    avatar: `${PREFIX}-avatar`,
    avatarPoper: `${PREFIX}-avatarPoper`,
    background: `${PREFIX}-background`,
    rowHover: `${PREFIX}-rowHover`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.color}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${classes.root}`]: {
        display: 'flex',
        backgroundColor: '#ededed',
        minHeight: '100vh',
        height: '100%',
    },

    [`& .${classes.menuButton}`]: {
        marginRight: 36,
    },

    [`& .${classes.hide}`]: {
        display: 'none',
    },

    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },

    [`& .${classes.drawerOpen}`]: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginTop: theme.spacing(1),
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderRight: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '0px 5px 5px 0px',
        height: '98%',
        boxShadow: '0 0 1rem 0 rgba(0, 0, 0, 0.2)',
        '&:before': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            backdropFilter: 'blur(10px) saturate(100%) contrast(45%) brightness(130%)',
            content: '""',
            height: '100%',
            position: 'absolute',
            width: '100%',
        },
    },

    [`& .${classes.drawerClose}`]: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: parseInt(theme.spacing(7)) + 1,
        [theme.breakpoints.up('sm')]: {
            width: parseInt(theme.spacing(8)) + 1,
        },
        marginTop: theme.spacing(1),
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderRight: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '0px 5px 5px 0px',
        height: '98%',
    },

    [`& .${classes.toolbar}`]: {
        padding: theme.spacing(1, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },

    [`& .${classes.content}`]: {
        flexGrow: 1,
        padding: theme.spacing(1),
        height: '100%',
    },

    [`& .${classes.avatar}`]: {
        marginRight: theme.spacing(3),
    },

    [`& .${classes.avatarPoper}`]: {
        zIndex: 999999999,
    },

    [`& .${classes.background}`]: {
        // height: '100%',
        width: '100%',
        margin: '0px',
        padding: '0px',
    },

    [`& .${classes.rowHover}`]: {
        '&:hover': {
            backgroundColor: 'rgb(225,225,225)',
        },
    }
}));

export default function App() {
    const classes = appShellStyles();
    const [userData, setUserData] = useState<any>(undefined);

    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isAuthenticated, setAuthenticated] = useState<boolean>(sessionStorage.getItem('user') as any);
    const [alert, setAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [alertType, setAlertType] = useState<string>('');

    const toggleUserMenuState = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        if (event.currentTarget !== anchorEl) setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const logout = (): void => {
        setAuthenticated(false);
        authenticationService.logout();
        setAnchorEl(null);
        history.push('/login');
    };

    useEffect(() => {
        if (sessionStorage.getItem('user') as any) {
            setAuthenticated(true);
        } else {
            setAuthenticated(false)
        }
    }, [isAuthenticated]);

    const handleAlert = (msg: string, type: string) => {
        setMessage(msg);
        setAlertType(type);
        setAlert(true);
    };

    const handleCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert(false);
    };

    const alertContextValues: any = { handleAlert };

    const goTo = (path: string): void => {
        history.push(path);
    };

    const navigation = [
        { label: 'Profile', path: '/profile', icon: PersonIcon },
        { label: 'Persons', path: '/', icon: PeopleAltIcon },
        { label: 'Exam Events', path: '/examevents', icon: LocalLibraryIcon },
        { label: 'Exams', path: '/exams', icon: MenuBookIcon },
        { label: 'Organizations', path: '/organizations', icon: BusinessCenterIcon },
        { label: 'Certificates', path: '/certificates', icon: FileCopyIcon },
        { label: 'Alignments', path: '/alignments', icon: AlignHorizontalLeftIcon },
        { label: 'Badges', path: '/badges', icon: BadgeIcon },
        { label: 'Assertions', path: '/assertions', icon: LocalPoliceIcon },
        { label: 'Maintenance', path: '/maintenance', icon: EngineeringIcon },
    ];

    return (
        //@ts-ignore
        <UserContext.Provider value={{ userData: userData, setUserData: setUserData, isAuthenticated: isAuthenticated, setAuthenticated: setAuthenticated }}>
            <AlertContext.Provider value={alertContextValues}>
                <Suspense fallback={<LoadingIndicator />}>
                    <Router history={history}>
                        <Root className={classes.root} data-testid="appwrap">
                            <CssBaseline />
                            {!isAuthenticated && <CardMedia className={classes.background} image={background} style={{ overflow: "hidden" }} />}
                            {isAuthenticated && (
                                <Drawer
                                    variant="permanent"
                                    className={clsx(classes.drawer, {
                                        [classes.drawerOpen]: open,
                                        [classes.drawerClose]: !open,
                                    })}
                                    classes={{
                                        paper: clsx({
                                            [classes.drawerOpen]: open,
                                            [classes.drawerClose]: !open,
                                        }),
                                    }}
                                >
                                    <List>
                                        <ListItem
                                            className={classes.color}
                                            button
                                            key="01"
                                            onClick={() => setOpen(!open)}
                                        >
                                            <ListItemIcon>
                                                <MenuIcon className={classes.color} />
                                            </ListItemIcon>
                                            <ListItemText primary="EduMS" />
                                        </ListItem>
                                        {navigation.map((item) => (
                                            <ListItem
                                                className={classes.color}
                                                button
                                                key={item.label}
                                                onClick={() => goTo(item.path)}
                                            >
                                                <ListItemIcon>
                                                    <SvgIcon className={classes.color} component={item.icon} />
                                                </ListItemIcon>
                                                <ListItemText primary={item.label} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Drawer>
                            )}

                            <main className={classes.content}>
                                {isAuthenticated && (
                                    <div className={classes.toolbar}>
                                        <Grid container justifyContent="space-between" alignItems="center">
                                            <Grid item xs={'auto'}>
                                                <Breadcrumbs />
                                            </Grid>
                                            <Grid item xs={'auto'}>
                                                <Avatar
                                                    className={classes.color + ' ' + classes.avatar}
                                                    aria-controls={anchorEl ? 'menu-list-grow' : undefined}
                                                    aria-haspopup="true"
                                                >
                                                    <Button
                                                        onClick={toggleUserMenuState}
                                                        data-testid="avatar"
                                                        style={{ backgroundColor: 'transparent' }}
                                                    >
                                                        {userData?.lastName?.charAt(0)}
                                                        {userData?.firstName?.charAt(0)}
                                                    </Button>
                                                </Avatar>
                                                <Popper
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    role={undefined}
                                                    transition
                                                    disablePortal
                                                    className={classes.avatarPoper} onResize={undefined} onResizeCapture={undefined}                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            style={{
                                                                transformOrigin:
                                                                    placement === 'bottom'
                                                                        ? 'center top'
                                                                        : 'center bottom',
                                                                top: '3.2rem',
                                                            }}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener
                                                                    onClickAway={toggleUserMenuState as any}
                                                                >
                                                                    <MenuList
                                                                        autoFocusItem={Boolean(anchorEl)}
                                                                        id="menu-list-grow"
                                                                    >
                                                                        <MenuItem onClick={logout}>Logout</MenuItem>
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}

                                <Switch>
                                    <PrivateRoute exact path="/participants" component={Participants} />
                                    <PrivateRoute exact path="/profile/" key="profile" component={ParticipantDetails} />
                                    <PrivateRoute
                                        exact
                                        path="/participants/:id"
                                        key="participant"
                                        component={ParticipantDetails}
                                    />
                                    <PrivateRoute exact path="/organizations" component={Organizations} />
                                    <PrivateRoute exact path="/organizations/:id" component={OrganizationMembers} />
                                    <PrivateRoute exact path="/examevents" component={ExamEvents} />
                                    <PrivateRoute exact path="/examevents/:id" component={ExamEventDetails} />
                                    <PrivateRoute exact path="/certificates" component={Certificates} />
                                    <PrivateRoute exact path="/alignments" component={AlignmentsPage} />
                                    <PrivateRoute exact path="/exams" component={ExamsPage} />
                                    <PrivateRoute exact path="/badges" component={BadgesPage} />
                                    <PrivateRoute exact path="/assertions" component={AssertionsPage} />
                                    <PrivateRoute exact path="/maintenance" component={MaintenancePage} />
                                    <PrivateRoute exact path="/" component={Participants} />
                                    <Route exact path="/login" component={LoginForm} />
                                    <Route exact path="/signup" component={SignUpForm} />
                                    <Route exact path="/resetpassword" component={ResetPasswordForm} />
                                    <Route exact path="/newpassword" component={NewPasswordForm} />

                                </Switch>
                                <AuthVerifier logOut={logout} />
                                {isAuthenticated && <div style={{ marginTop: "10px" }}>
                                    <Typography variant="body2" color="textSecondary" align="right">Deployed from commit
                                        {' '}
                                        <Link
                                            target="_blank"
                                            style={{ display: 'inline' }}
                                            color="primary"
                                            href={'https://github.com/International-Certification-Org/EduMS-Admin-Panel/commit/' + process.env.REACT_APP_GITCOMMITHASH}
                                        >

                                            {" " + process.env.REACT_APP_GITCOMMITHASH + " "}
                                        </Link>
                                        via build
                                        <Link
                                            target="_blank"
                                            style={{ display: 'inline' }}
                                            color="primary"
                                            href={'https://dev.azure.com/bpmspace-com/SOE%20PipeLine/_build/results?buildId=' + process.env.BUILDID + '&view=results'}
                                        >

                                            {" " + process.env.REACT_APP_BUILDNUMBER + " "}
                                        </Link>
                                    </Typography>
                                </div>}
                            </main>
                        </Root>
                    </Router>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={alert}
                        autoHideDuration={2500}
                        onClose={(e) => handleCloseAlert(e)}
                    >
                        <Alert onClose={handleCloseAlert} severity={alertType === 'success' ? 'success' : 'error'}>
                            {message}
                        </Alert>
                    </Snackbar>
                </Suspense>
            </AlertContext.Provider>
        </UserContext.Provider>
    );
}
