import { HeadCell } from 'components/molecules/ComplexTableHead';

export const bookingsOnExamEventHeaders: HeadCell[] = [
    { id: '', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'id', numeric: false, label: 'Exam Event ID', disablePadding: false, disableSorting: true },
    { id: 'startDate', numeric: false, label: 'From Date', disablePadding: false, disableSorting: true },
    { id: 'trainingOrgUID', numeric: false, label: 'ATO', disablePadding: false, disableSorting: true },
    { id: 'name', numeric: false, label: 'Exam Event Name', disablePadding: false, disableSorting: false },
    { id: 'stateExamEvent', numeric: false, label: 'Exam Event State', disablePadding: false, disableSorting: true },
    { id: 'state', numeric: false, label: 'State', disablePadding: false, disableSorting: false },
];
