import { callAxios } from '../../axios-wrapper/call-axios';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { Participant } from '../../models/Participant';
import UrlFilterConstructor from '../../transformators/UrlFilterConstructor';

const getParticipant = (participantId: string): Promise<Participant> => {
    return callAxios.get(`/users/${participantId}`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading user failed');
        }
    });
};

const addParticipant = (participant: Participant): Promise<void> => {
    const requestBody = {
        // lastName: participant.lastName,
        // firstName: participant.firstName,
        // placeOfBirth: participant.placeOfBirth,
        // birthCountry: participant.birthCountry
    };
    return callAxios.patch(`/users/${participant.id}`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Adding user failed');
        }
    });
};

const updateParticipant = (participant: Participant): Promise<void> => {
    const requestBody = {
        lastName: participant.lastName,
        firstName: participant.firstName,
        placeOfBirth: participant.placeOfBirth,
        birthCountry: participant.birthCountry,
        gender: participant.gender,
        dateOfBirth: participant.dateOfBirth,
        language: participant.language,
    };
    return callAxios.patch(`/users/${participant.id}`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating user failed');
        }
    });
};

const changeParticipantState = (state: string, id: string): Promise<void> => {
    return callAxios.put(`/users/${id}`, { state: state }).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating user failed');
        }
    });
};

const confirmParticipant = (id: string) => {
    return callAxios.post(`/users/${id}/confirm`, {}).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating user failed');
        }
    });
};

const getParticipantsWithPaginationAndFilter = (r: PaginationSortingFilteringRequest): Promise<TablePageSortFilter> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(
            `/users/search?orderBy=${r.orderBy.toString()}&sortOrder=${r.order}&page=${r.page + 1}&items=${r.rowsPerPage}${filter}`
        )
        .then((res) => {
            if (res.status === 200) {
                return { ...r, results: res.data.rows, total: res.data.total };
            } else {
                throw new Error('Getting participants failed');
            }
        });
};

const getNonMembersUsers = (r: PaginationSortingFilteringRequest, orgID: string): Promise<TablePageSortFilter> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(
            `/users/search?orderBy=${r.orderBy.toString()}&sortOrder=${r.order}&page=${r.page + 1}&skipOrgID=${orgID}&items=${r.rowsPerPage}${filter}`
        )
        .then((res) => {
            if (res.status === 200) {
                return { ...r, results: res.data.rows, total: res.data.total };
            } else {
                throw new Error('Getting participants failed');
            }
        });
};

const addNewMail = (data: any, id: string): Promise<any> => {
    return callAxios.post(`/users/${id}/addmail`, data).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Getting participants failed');
        }
    });
};

const issueUserCertificate = (data: any, id?: string): Promise<any> => {
    return callAxios.post(`/users/${id}/certificates`, data).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Getting participants failed');
        }
    });
};

const changeUserCertificateStatus = (certID: string, action: string, userID?: string): Promise<any> => {
    return callAxios.patch(`/users/${userID}/certificates/${certID}`, { action }).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Getting participants failed');
        }
    });
};
const updateParticipantRoles = (relationID: string, role: string): Promise<any> => {
    return callAxios.patch(`/users/updaterole/${relationID}`, { role }).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Update participants failed');
        }
    });
};

export const participantsService = {
    getParticipant,
    addParticipant,
    updateParticipant,
    confirmParticipant,
    getParticipantsWithPaginationAndFilter,
    getNonMembersUsers,
    addNewMail,
    changeParticipantState,
    issueUserCertificate,
    changeUserCertificateStatus,
    updateParticipantRoles,
};
