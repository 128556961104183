import React, { Fragment, useContext, useState } from 'react';
import { AlertContext } from 'App';
import { Controller, useForm } from 'react-hook-form';
import { RevokedAssertion } from 'models/Assertions';
import Moment from '@date-io/moment';
import { assertionsService } from 'services/assertions/AssertionsService';
import {
    DialogContent,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Link,
    Select,
    TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import Button from '@mui/material/Button';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { DialogActions } from 'customized-components';

interface EditRevokeAssertionFormProps {
    assertion: RevokedAssertion;
    cancel: () => void;
    onAssertionRevokeUpdate: () => void;
}

const RevokeAssertionForm = (props: EditRevokeAssertionFormProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const momentum = new Moment();

    //@ts-ignore
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: { ...props.assertion },
    });
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = (data: any) => {
        setLoading(true);

        let body = {
            revoked: data.revoked === 'true' ? true : false,
            assertionId: props.assertion.id,
            revocationReason: data.revocationReason,
        };

        assertionsService
            .revokeAssertion(body as any, props.assertion.id as string)
            .then(() => {
                handleAlert('Assertion updated successfully!', 'success');
                props.cancel();
                setLoading(false);
                props.onAssertionRevokeUpdate && props.onAssertionRevokeUpdate();
            })
            .catch((error) => {
                props.cancel();
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    error
                );
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent
                sx={{
                    borderLeft: '3px solid #4765A0',
                    borderRight: '3px solid #4765A0',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="revocationReason"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="revocationreason"
                                    id="revocationreason"
                                    size="small"
                                    label="Revocation Reason"
                                    variant="outlined"
                                    error={!!errors.revocationReason}
                                    helperText={errors.revocationReason && errors.revocationReason.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Revocationreason is required field',
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="revoked"
                                render={({ field }) => (
                                    <>
                                        <InputLabel htmlFor="revoked">Revoked Status</InputLabel>
                                        <Select
                                            {...field}
                                            data-testid="revoked"
                                            id="revoked"
                                            label="Revoked status"
                                            error={!!errors.revoked}
                                            onChange={(event) =>
                                                field.onChange(event.target.value === 'true' ? 'true' : 'false')
                                            }
                                        >
                                            <MenuItem value="true">True</MenuItem>
                                            <MenuItem value="false">False</MenuItem>
                                        </Select>
                                        <FormHelperText style={{ color: errors.revoked ? 'red' : 'grey' }}>
                                            {errors.revoked && errors.revoked.message}
                                        </FormHelperText>
                                    </>
                                )}
                                control={control}
                                defaultValue={'false'}
                                rules={{
                                    required: 'Revoked is a required field',
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        //@ts-ignore
                        reset({
                            revocationReason: '',
                            revoked: 'false',
                        });
                        props.cancel();
                    }}
                    data-testid=""
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};

export default RevokeAssertionForm;
