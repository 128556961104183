import { Button, TableCell, TableRow, Tooltip, Grid, CardMedia, Link, CardActionArea } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { TableAction } from "models/table-models/TableAction";
import { Assertion } from 'models/Assertions';
import PlaceholderImage from 'assets/ico-cert_ICO_180px.png';
import { Badge } from "../../../models/Badges";
import Moment from "@date-io/moment";


export interface AssertionRowProps {
    row: Assertion;
    actions?: TableAction[];
    badges?: Badge[];
}

export const AssertionRow: FunctionComponent<AssertionRowProps> = (props: AssertionRowProps) => {
    const momentum = new Moment();
    const currentBadge: Badge | undefined = useMemo(() => {
        const filtered = props?.badges?.filter(badge => badge?.id === props?.row.badgeClass?.[0]?.id)
        return filtered?.[0] || undefined
    }, [props?.badges, props?.row])

    let dataLink = '';
    if (Array.isArray(props.row.badgeClass) && (props.row.badgeClass).length) {
        dataLink = `${process.env.REACT_APP_BASE_API_URL}/public/assertion/user/${props.row.userId}/badgeclass/${props.row?.badgeClass[0].id}/${props.row.id}/badge.json`;
    }

    return (
        <TableRow key={props.row.id} hover>
            <TableCell sx={{ width: '2rem' }}>
                <Grid container spacing={1}>
                    {props.actions &&
                        props.actions.map((single, index) => (
                            <Grid item key={index}>
                                <Tooltip title={single.title}>
                                    <span>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            aria-label="filter list"
                                            //@ts-ignore
                                            onClick={() => single.onClick(props.row.id)}
                                            disabled={false}
                                        >
                                            {<single.icon color="primary" />}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Grid>
                        ))}
                </Grid>
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>{props.row.receipment_mail}</TableCell>
            <TableCell sx={{ width: '2rem' }}>
                <Tooltip title={"AssertionID: " + props.row.id}>
                    <span>{currentBadge?.title}</span>
                </Tooltip>
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>
                <CardActionArea href={props.row.image} target={'_blank'}>
                    <CardMedia
                        component="img"
                        image={props.row.image}
                        onError={(e: any) => {
                            e.target.src = PlaceholderImage
                        }}
                        sx={{
                            width: 70,
                            height: 70
                        }}
                    />
                </CardActionArea>
            </TableCell>
            <TableCell sx={{ width: '2rem' }}><Link target="_blank" href={props.row.evidenceURL}>{props.row.evidenceURL}</Link></TableCell>
            <TableCell sx={{ width: '2rem' }}>{props.row.expires && momentum.moment(props.row.expires).format('ll')}</TableCell>
            <TableCell sx={{ width: '2rem' }}>
                <Tooltip title={(props.row.revocationReason) ? props.row.revocationReason : ""}>
                    <span>
                        {(props.row.revoked) ? "revoked" : ""}
                    </span>
                </Tooltip>
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>
                <Link target="_blank"
                    href={dataLink}>
                    link
                </Link>
            </TableCell>
        </TableRow>
    );
};
