import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { authenticationService } from '../../services/authentication/authentication.service';
import { Controller, useForm } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import { Typography } from '@mui/material';
import StyledLink from 'components/atoms/StyledLink';
import { BaseLandingPage } from '../../customized-components';
import { FunctionComponent } from 'react';
import { AlertContext } from '../../App';
import { UserContext } from '../../context/UserContext';

export interface ILoginForm {
    email: string;
    password: string;
}

export interface SignInProps {
    history: any;
    location: any;
}

export const LoginForm: FunctionComponent<SignInProps> = (props: SignInProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
    } = useForm<ILoginForm>();

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { setAuthenticated } = useContext(UserContext);

    const onSubmit = (data: ILoginForm) => {
        if (data.email && data.password) {
            authenticationService
                .login(data.email, data.password, true)
                .then(() => {
                    //@ts-ignore
                    setAuthenticated(true);
                    const { from } = props.location.state || { from: { pathname: '/' } };
                    props.history.push(from);
                })
                .catch((error) => {
                    if (error.response.data.message.includes('password')) {
                        setError('password', {
                            type: 'server',
                            message: error.response.data.message,
                        });
                    } else if (error.response.data.message.includes('email')) {
                        setError('email', {
                            type: 'server',
                            message: error.response.data.message,
                        });
                    } else {
                        handleAlert(
                            error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                            'error'
                        )
                    }
                });
        }
    };

    const trimOnBlur = (e: any) => {
        setValue("email", e.target.value.trim())
    }

    useEffect(() => {
        if (sessionStorage.getItem('user')) {
            props.history.push('/');
        }

    }, []);

    return (
        <BaseLandingPage>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    data-testid="email"
                                    {...field}
                                    id="email"
                                    label={'Email'} //t('email')
                                    size="small"
                                    variant="outlined"
                                    onBlur={trimOnBlur}
                                    error={errors.email ? true : false}
                                    helperText={errors.email && errors.email.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Email is required field', // t('emailrequired') as string,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address', // t('invalidemail')
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="password"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="password"
                                    id="password"
                                    label="Password"
                                    size="small"
                                    type="password"
                                    variant="outlined"
                                    error={errors.password ? true : false}
                                    helperText={errors.password && errors.password.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Password is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledLink sx={{ textAlign: 'center' }} to="/resetpassword">
                            <Typography>Forgot Password?</Typography>
                        </StyledLink>
                    </Grid>
                    <Grid item xs={12}>
                        <Button data-testid="login" color="primary" fullWidth type="submit" variant="contained">
                            Log in
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </BaseLandingPage>
    );
};
