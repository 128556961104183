export enum BADGE_STATUS {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    PUBLISHED = 'PUBLISHED',
    INACTIVE = 'INACTIVE',
}

export enum ALIGNMENT_STATUS {
    NEW = 'NEW',
    ASSIGNED = 'ASSIGNED',
    INACTIVE = 'INACTIVE',
}
