import { HeadCell } from 'components/molecules/ComplexTableHead';

export const membersHeaders: HeadCell[] = [
    // { id: 'birthCountry', numeric: false, label: 'Birth Country', disablePadding: false },
    { id: 'id', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'emails', numeric: false, label: 'Emails', disablePadding: false, disableSorting: true },
    { id: 'firstName', numeric: false, label: 'First Name', disablePadding: false, disableSorting: true },
    { id: 'lastName', numeric: false, label: 'Last Name', disablePadding: false, disableSorting: true },
    { id: 'role', numeric: false, label: 'Role', disablePadding: false, disableSorting: true },
    { id: 'permissions', numeric: false, label: 'Permissions', disablePadding: false, disableSorting: true },
    // { id: 'birthCountry', numeric: false, label: 'Birth Country', disablePadding: false },
];
