import React, { FC } from 'react';
import { CircularProgressProps } from '@mui/material';
import { StyledCircularProgress } from './styles'

interface ICircularProps extends CircularProgressProps {
    // condition if we want component centered with style
    isCentered?: boolean;
}

const CircularProgress: FC<ICircularProps> = (props) => {
    return (
        <StyledCircularProgress {...props}/>
    )
}

export default CircularProgress;
