import { Button, TableCell, TableRow, Tooltip, Grid, Chip, Typography, Checkbox } from '@mui/material';
import { FunctionComponent } from 'react';
import { TableAction } from 'models/table-models/TableAction';
import { Participant } from '../../models/Participant';
import { personStatusOptionsGetter } from '../../status-transition-rules/person-transitions';
import { StatusButton } from '../../customized-components/status-button/status-button';
import StatusChangeInfo from '../../utils/StatusChangeInfo';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { convert } from '../../dateConverter';
import { Email } from '../../models/Email';
export interface ParticipantRowProps {
    row: Participant;
    multiSelect?: any;
    actions: {
        onEditParticipant?: TableAction | undefined;
        onCreateBookingOnExamEvent?: TableAction | undefined;
        onParticipantStatusChange?: TableAction | undefined;
        onAddMember?: TableAction | undefined;
        onReassignMail?: TableAction | undefined;
    };
}

export const ParticipantRow: FunctionComponent<ParticipantRowProps> = (props: ParticipantRowProps) => {

    const handleMenuItemClickStatus = (updateData: StatusChangeInfo) => {
        props.actions.onParticipantStatusChange?.onClick(updateData, props.row.id);
    };

    const checkEmailStatuses = (): boolean => {
        let exists;
        if (props.row.emails) {
            exists = props.row.emails.find((e) => e.state === 'active');
        }
        return exists ? true : false;
    };

    const findActiveMailStatus = (emails: Email[]): any => {
        const activeMail = emails.find(single => single.state === "active")
        return activeMail ? true : false;
    }
    const findActiveMail = (emails: Email[]): any => {
        const activeMail = emails.find(single => single.state === "active")
        return activeMail?.email;
    }

    const checkIfParticipantCanBeCompleted = () => {
        if (props.row.firstName && props.row.lastName && props.row.emails &&
            props.row.dateOfBirth && props.row.birthCountry && props.row.gender) {
            return true;
        }
        return false;
    }

    const statusOptions = personStatusOptionsGetter(props.row.state);
    const checkEnabled = () => {

        switch (props.row.state) {
            case 'blocked':
                if (statusOptions?.length > 0 && checkIfParticipantCanBeCompleted())
                    return true
                else return false

            case 'pseudoanonymized':
                return true

            default:
                if (statusOptions?.length > 0 && checkEmailStatuses() && checkIfParticipantCanBeCompleted())
                    return true
                else return false
        }



    }

    const isParticipantSelected = (id: string) => {
        if (props.multiSelect) {
            return props.multiSelect.selectedParticipants.indexOf(id) !== -1
        }
    };

    return (
        <TableRow key={props.row.id} selected={isParticipantSelected(props.row.id)}>
            {props.multiSelect && <TableCell>
                <Checkbox
                    checked={isParticipantSelected(props.row.id)}
                    onChange={(e) => props.multiSelect.onChange(props.row.id, e.target.checked)}
                    inputProps={{ 'aria-labelledby': props.row.id }}
                />
            </TableCell>}
            <TableCell>
                <Grid container spacing={1}>
                    {props.actions.onEditParticipant && (
                        <Grid item>
                            <Tooltip title={props.actions.onEditParticipant.title}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    aria-label={props.actions.onEditParticipant.title}
                                    //@ts-ignore
                                    onClick={() => props.actions.onEditParticipant.onClick(props.row.id)}
                                >
                                    {<props.actions.onEditParticipant.icon color="primary" />}
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                    {props.actions.onCreateBookingOnExamEvent && (
                        <Grid item>
                            <Tooltip title={props.actions.onCreateBookingOnExamEvent.title}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    aria-label={props.actions.onCreateBookingOnExamEvent.title}
                                    //@ts-ignore
                                    onClick={() => props.actions.onCreateBookingOnExamEvent.onClick(props.row.id)}
                                >
                                    {<props.actions.onCreateBookingOnExamEvent.icon color="primary" />}
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                    {props.actions.onAddMember && (
                        <Grid item>
                            <Tooltip title={!findActiveMailStatus(props.row.emails) ? "No active email" : props.actions.onAddMember.title}>
                                <span>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        aria-label={props.actions.onAddMember.title}
                                        disabled={!findActiveMailStatus(props.row.emails)}
                                        //@ts-ignore
                                        onClick={() => props.actions.onAddMember.onClick({ user: findActiveMail(props.row.emails), permissions: ['all'] }, props.row)}
                                    >
                                        {<props.actions.onAddMember.icon color="primary" />}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    )}
                    {props.actions.onReassignMail && (
                        <Grid item>
                            <Tooltip title={props.actions.onReassignMail.title}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    aria-label={props.actions.onReassignMail.title}
                                    //@ts-ignore
                                    onClick={() => props.actions.onReassignMail.onClick(props.row.id)}
                                >
                                    {<props.actions.onReassignMail.icon color="primary" />}
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </TableCell>
            {/* <TableCell>{props.row.email}</TableCell>*/}
            <TableCell>
                <Grid container spacing={1}>
                    {props.row.emails &&
                        props.row.emails.map((e) => (
                            <Grid item key={e.id}>
                                <Chip color={e.state === 'active' ? 'primary' : 'default'} label={e.email}></Chip>
                            </Grid>
                        ))}
                </Grid>
            </TableCell>
            <TableCell>{props.row.firstName}</TableCell>
            <TableCell>{props.row.lastName}</TableCell>
            <TableCell>{props.row.language}</TableCell>
            <TableCell>{props.row.gender}</TableCell>
            <TableCell>{convert(props.row.dateOfBirth, false)}</TableCell>
            {!props.actions.onAddMember &&
                <TableCell>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <StatusButton
                                enabled={() => checkEnabled()}
                                status={props.row.state}
                                statusOptions={statusOptions}
                                onStatusChange={handleMenuItemClickStatus}
                            />
                        </Grid>
                        {!checkEnabled() && props.row.state === "incomplete" && <Grid item xs={1}>
                            <Tooltip placement="left" title={<Typography color="inherit">You must fill in the participant data before you can change his state.</Typography>}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        </Grid>}
                    </Grid>
                </TableCell>
            }
        </TableRow>
    );
};
