import { HeadCell } from 'components/molecules/ComplexTableHead';

export const ordersHeaders: HeadCell[] = [
    { id: '', numeric: false, label: '', disablePadding: false, disableSorting: false },
    { id: 'orderID', numeric: false, label: 'Order ID', disablePadding: false, disableSorting: true },
    { id: 'examName', numeric: false, label: 'Exam Name', disablePadding: false, disableSorting: true },
    { id: 'ato', numeric: false, label: 'ATO', disablePadding: false, disableSorting: true },
    { id: 'details', numeric: false, label: 'Details', disablePadding: false, disableSorting: true },
    { id: 'date', numeric: false, label: 'Created At', disablePadding: false, disableSorting: true },
    // { id: 'expiration', numeric: false, label: 'Expiration Date', disablePadding: false, disableSorting: true },
    { id: 'owner', numeric: false, label: 'Owner', disablePadding: false, disableSorting: true },
    { id: 'to', numeric: false, label: 'To', disablePadding: false, disableSorting: true },
    { id: 'state', numeric: false, label: 'State', disablePadding: false, disableSorting: false },
];
