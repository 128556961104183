import { HeadCell } from 'components/molecules/ComplexTableHead';

export const assertionsHeaders: HeadCell[] = [
    { id: 'Id', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'receipment_mail', numeric: false, label: 'Email', disablePadding: false, disableSorting: false },
    { id: 'badge_title', numeric: false, label: 'Badge', disablePadding: false, disableSorting: false },
    { id: 'image', numeric: false, label: 'Image', disablePadding: false, disableSorting: false },
    { id: 'evidenceURL', numeric: false, label: 'Evidence URL', disablePadding: false, disableSorting: false },
    { id: 'expires', numeric: false, label: 'Expires', disablePadding: false, disableSorting: false },
    { id: 'revoked', numeric: false, label: 'Revoked', disablePadding: false, disableSorting: false },
    { id: 'datalink', numeric: false, label: 'Data link', disablePadding: false, disableSorting: false },
];
