import { HeadCell } from 'components/molecules/ComplexTableHead';

export const participantsHeaders: HeadCell[] = [
    // { id: 'birthCountry', numeric: false, label: 'Birth Country', disablePadding: false },
    { id: 'id', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'email', numeric: false, label: 'Email', disablePadding: false, disableSorting: true },
    { id: 'firstName', numeric: false, label: 'First Name', disablePadding: false, disableSorting: false },
    { id: 'lastName', numeric: false, label: 'Last Name', disablePadding: false, disableSorting: false },
    { id: 'language', numeric: false, label: 'Language', disablePadding: false, disableSorting: false },
    { id: 'gender', numeric: false, label: 'Gender', disablePadding: false, disableSorting: false },
    { id: 'dateOfBirth', numeric: false, label: 'Date of Birth', disablePadding: false, disableSorting: false },
    { id: 'state', numeric: false, label: 'State', disablePadding: false, disableSorting: true },
    // { id: 'birthCountry', numeric: false, label: 'Birth Country', disablePadding: false },
];

export const participantsHeadersMultiSelect: HeadCell[] = [
    // { id: 'birthCountry', numeric: false, label: 'Birth Country', disablePadding: false },
    { id: 'id', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'email', numeric: false, label: 'Email', disablePadding: false, disableSorting: true },
    { id: 'firstName', numeric: false, label: 'First Name', disablePadding: false, disableSorting: false },
    { id: 'lastName', numeric: false, label: 'Last Name', disablePadding: false, disableSorting: false },
    { id: 'language', numeric: false, label: 'Language', disablePadding: false, disableSorting: false },
    { id: 'gender', numeric: false, label: 'Gender', disablePadding: false, disableSorting: false },
    { id: 'dateOfBirth', numeric: false, label: 'Date of Birth', disablePadding: false, disableSorting: false },
    { id: 'state', numeric: false, label: 'State', disablePadding: false, disableSorting: true },
    // { id: 'birthCountry', numeric: false, label: 'Birth Country', disablePadding: false },
];

export const usersHeaders: HeadCell[] = [
    // { id: 'birthCountry', numeric: false, label: 'Birth Country', disablePadding: false },
    { id: 'id', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'email', numeric: false, label: 'Email', disablePadding: false, disableSorting: true },
    { id: 'firstName', numeric: false, label: 'First Name', disablePadding: false, disableSorting: false },
    { id: 'lastName', numeric: false, label: 'Last Name', disablePadding: false, disableSorting: false },
    { id: 'language', numeric: false, label: 'Language', disablePadding: false, disableSorting: false },
    { id: 'gender', numeric: false, label: 'Gender', disablePadding: false, disableSorting: false },
    { id: 'dateOfBirth', numeric: false, label: 'Date of Birth', disablePadding: false, disableSorting: false },
    // { id: 'birthCountry', numeric: false, label: 'Birth Country', disablePadding: false },
];
