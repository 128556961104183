import { callAxios } from 'axios-wrapper/call-axios';
import { AlignmentRequest, AlignmentResponse, AlignmentCreateRequest } from "models/Alignments";
import { AxiosResponse } from "axios";
import PaginationSortingFilteringRequest from 'models/table-models/PaginationSortingFilteringRequest';
import UrlFilterConstructor from 'transformators/UrlFilterConstructor';

const getAlignmentsAndFilter = (r: AlignmentRequest): Promise<AxiosResponse<AlignmentResponse>> => {
    return callAxios
        .get(
            `/badgeAlignments/?orderBy=${r.orderBy.toString()}&page=${r.page + 1}&items=${r.pageSize}`
        )
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            } else {
                throw new Error('Getting Alignments failed');
            }
        });
};

const getAlignmentsAndFilterWithPagination = (r: PaginationSortingFilteringRequest): Promise<any> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(
            `/badgeAlignments/withpagination?orderBy=${r.orderBy.toString()}&page=${r.page + 1}&items=${r.rowsPerPage}${filter}`
        )
        .then((res) => {
            if (res.status === 200) {
                if (res.data.rows.length >= 0) {
                    return { ...r, results: res.data.rows, total: res.data.total };
                } else {
                    throw new Error('Loading failed');
                }
            } else {
                throw new Error('Getting Alignments failed');
            }
        });
};

const updateAlignment = (body: any, id: string): Promise<any> => {
    return callAxios.patch(`/badgeAlignments/${id}`, body).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating alignment failed');
        }
    });
};

const createAlignment = (body: AlignmentCreateRequest): Promise<any> => {
    return callAxios.post(`/badgeAlignments`, body).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating alignment failed');
        }
    });
};

const deleteAlignment = (id: string): Promise<any> => {
    return callAxios.remove(`/orders/${id}`).then((res: any) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Delete alignment failed');
        }
    });
};

export const alignmentsService = {
    getAlignmentsAndFilter,
    getAlignmentsAndFilterWithPagination,
    updateAlignment,
    createAlignment,
    deleteAlignment
};
