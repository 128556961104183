import { Button, DialogActions, DialogContent, Grid, IconButton, TextField } from '@mui/material';
import { Fragment, FunctionComponent, useEffect, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import BasicTable from 'components/organisms/BasicTable';
import { EmailRow } from './email-table-row';
import { Email } from '../../models/Email';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { authenticationService } from '../../services/authentication/authentication.service';
import { AlertContext } from '../../App';

export interface MailManagementProps {
    emails: Email[];
    updateEmail: (mailId: string, newState: string) => void;
    addNewMail: (email: string) => void;
    closeDialog: () => void;
    onMailReassignUserModalToggle: (emailId: string) => void;
    onMailReassignOrganizationModalToggle: (emailId: string) => void;
    type: string;
}

export interface MailInput {
    email: string;
}

export const MailManagementForm: FunctionComponent<MailManagementProps> = (props: MailManagementProps) => {

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const [emails, updateEmailList] = useState<Email[]>([]);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setError
    } = useForm<MailInput>();

    useEffect(() => {
        updateEmailList(props.emails);
    }, [props.emails]);

    const headerTitles: string[] = ['Mail', 'Status', 'Reassign'];

    const checkEmailStatuses = (): boolean => {
        const exists = props.emails?.find((e) => e.state === 'active');

        if (exists && props.emails.length === 1) {
            return false;
        } else if (!exists) {
            return true;
        } else return true;

    };

    const onSubmit = (data: any) => {
        if (data.email) {
            data.email = data.email.toLowerCase();
        }
        authenticationService
            .emailIsUnique(data.email)
            .then((res) => {
                props.addNewMail(data.email);
                reset({ email: '' });
            })
            .catch((error) => {
                if (error.message === 'E-mail is already taken!') {
                    setError('email', {
                        type: 'server',
                        message: error.message,
                    });
                } else {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    );
                }
                reset({ email: '' });
            });
    };

    const checkIfReassignPossible = () => {
        return props.emails.length > 1 ? true : false
    }


    // const [alertDialogIsOpen, toggleAlertDialogVisibility] = useState<boolean>(false);

    // const onPrivacyChange = (email: Email) => {
    // 	setEmailForUpdate(email);
    // 	toggleAlertDialogVisibility(true);
    // }

    // const confirmPrivacyStatusChange = () => {
    // 	if (emailForUpdate?.privacy !== emails.filter(e => e.email === emailForUpdate?.email)[0].privacy) {
    // 		const updatedEmails = [...emails];
    // 		const index = updatedEmails.findIndex(e => e.email === emailForUpdate?.email);
    // 		updatedEmails[index].privacy = emailForUpdate?.privacy as string;
    // 		setEmailForUpdate(undefined);
    // 		toggleAlertDialogVisibility(false);
    // 		updateEmailList(updatedEmails);
    // 	} else {
    // 		throw new Error("Something went wrong with the update of the email privacy.")
    // 	}
    // }

    return (
        <Fragment>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ paddingTop: '20px'}}>
                        <Grid item xs={6}>
                            <Controller name='email' render={({ field }) =>
                                <TextField fullWidth
                                    {...field}
                                    data-testid="email"
                                    id="email"
                                    label="Email"
                                    size="small"
                                    variant="outlined"
                                    error={!!errors.email}
                                    helperText={errors.email && errors.email.message}
                                />
                            }
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: "Email is required field",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton
                                color="primary"
                                aria-label="add an alarm"
                                type="submit"
                                style={{ padding: '4px' }}
                                size="large">
                                <AddBoxIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <BasicTable headerColumnTitles={headerTitles}>
                                {emails
                                    ? emails.map((r) => (
                                        <EmailRow
                                            key={r.email}
                                            emailData={r}
                                            onStatusChange={props.updateEmail}
                                            canChangeStatus={() => checkEmailStatuses()}
                                            onMailReassignUserModalToggle={props.onMailReassignUserModalToggle}
                                            onMailReassignOrganizationModalToggle={props.onMailReassignOrganizationModalToggle}
                                            reassignPossible={checkIfReassignPossible}
                                        // onPrivacyChange={onPrivacyChange}
                                        />
                                    ))
                                    : null}
                            </BasicTable>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.closeDialog} color="primary" type="submit" variant="contained">
                    Close
                </Button>
            </DialogActions>
            {/* <AlertDialog
				isOpen={alertDialogIsOpen}
				handleClose={() => toggleAlertDialogVisibility(false)}
				content={"Are you sure you want to change email privacy setting?"}
				title={undefined}
				handleOkAction={confirmPrivacyStatusChange}
			/> */}
        </Fragment>
    );
};
