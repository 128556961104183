import { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { ModalDialog } from '../../customized-components';
import ComplexTable from 'components/organisms/ComplexTable';
import { ExamEvent } from '../../models/ExamEvent';
import { examEventGridHeaderCells } from '../../models/table-headers/exam-event-grid-headers';
import { ExamEventRow } from './exam-event-row';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ExamEventForm } from '../exam-event-form/exam-event-form';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { examEventService } from '../../services/examevents/exam-event.service';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { AlertContext } from '../../App';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import { UserContext } from '../../context/UserContext';
import { authenticationService } from '../../services/authentication/authentication.service';
import { defaultExamEventsFilters } from '../../models/table-filters.ts/exam-events-table-filters';
import { history } from '../../services/authentication/history';

/* eslint-disable-next-line */
export interface ExamEventsProps { }

export default function ExamEvents(props: ExamEventsProps) {
    const mounted = useRef(false);

    const defaultRequest = { order: 'desc', orderBy: 'startDate', page: 0, rowsPerPage: 10, filters: defaultExamEventsFilters };
    const [examEventRows, setExamEventRows] = useState<TablePageSortFilter>();
    const [examEventDialogIsOpen, setOpenExamEventDialog] = useState<boolean>(false);
    const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);
    const [loading, setLoading] = useState<boolean>(false);

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData, setUserData } = useContext(UserContext);

    useEffect(() => {
        mounted.current = true;
        if (!userData) {
            authenticationService
                .getLoggedInUserData()
                .then((res: any) => {
                    if (mounted.current) {
                        //@ts-ignore
                        setUserData(res);
                        loadExamEvents(paginationData);
                    }
                })
                .catch((error) =>
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    )
                );
        } else {
            loadExamEvents(paginationData);
        }
        return () => {
            mounted.current = false;
        }

    }, []);

    const loadExamEvents = (request: PaginationSortingFilteringRequest) => {
        setLoading(true)
        examEventService
            .getExamEvents(request)
            .then((res) => {
                if (mounted.current) {
                    setExamEventRows(res)
                    setLoading(false)
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const handleClickOpenExamEventDialog = () => {
        setOpenExamEventDialog(!examEventDialogIsOpen);
    };

    const onNewExamEventAdded = () => {
        const request = {
            order: examEventRows?.order,
            orderBy: examEventRows?.orderBy,
            page: examEventRows?.page,
            rowsPerPage: examEventRows?.rowsPerPage,
            filters: examEventRows?.filters
        }

        loadExamEvents(request as PaginationSortingFilteringRequest);
    }

    const updateExamEventStatus = (examEventId: string, newState: string) => {
        setLoading(true)
        examEventService
            .updateEventState(examEventId, newState)
            .then((res) => {
                const rows = [...(examEventRows?.results as ExamEvent[])];
                const index = rows.findIndex((r) => r.id === examEventId);
                rows[index].state = newState;
                //@ts-ignore
                setExamEventRows({ ...examEventRows, results: rows });
                handleAlert('Exam event status updated successfully!', 'success');
                setLoading(false)

            })
            .catch((error) => {
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
                setLoading(false)
            });
    };

    const openExamEventPage = (id: string) => {
        history.push(`/examevents/${id}`, { id });
    };

    const actions = {
        onExamEventView: {
            title: 'Edit exam event',
            icon: VisibilityIcon,
            onClick: openExamEventPage,
        },
        onExamEventStatusChange: {
            title: 'Update exam event status',
            icon: EditIcon,
            onClick: updateExamEventStatus,
        },
    };

    const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
        setPaginationData(newValue)
    };

    const ExamCancelFilteredRows = (event: any) => {

        if (defaultRequest.filters[0].value === true) {
            if (event.state !== 'canceled') {
                return (<ExamEventRow key={event.id} loading={loading} actions={actions} examEventrow={event} />);
            }
        } else {
            return (<ExamEventRow key={event.id} loading={loading} actions={actions} examEventrow={event} />);
        }
    };

    return (
        <Fragment>
            <ComplexTable
                tableData={examEventRows as TablePageSortFilter}
                headerCells={examEventGridHeaderCells}
                title="Exam Events"
                addAction={handleClickOpenExamEventDialog}
                addIcon={AddBoxIcon}
                updateTableData={loadExamEvents}
                onPaginationUpdate={trackPagination}
                loading={loading}
            >
                {examEventRows?.results
                    ? examEventRows.results.map((e) => {
                        return ExamCancelFilteredRows(e);
                    })
                    : null}
            </ComplexTable>
            <div>
                {examEventDialogIsOpen ? (
                    <ModalDialog
                        dialogIsOpen={examEventDialogIsOpen}
                        setDialogOpen={handleClickOpenExamEventDialog}
                        title="Create exam event"
                    >
                        <ExamEventForm onExamCreated={onNewExamEventAdded} cancel={() => setOpenExamEventDialog(false)} />
                    </ModalDialog>
                ) : null}
            </div>
        </Fragment>
    );
}
