import TableFilter from "../models/table-models/TableFilter";

export default class UrlFilterConstructor {
	public static constructUrlConditionFromFilter = (filters: TableFilter[]): string => {
		let filter = '';

		filters.forEach(f => {
			if (f.value !== '' && f.field !== 'state') {
				let condition: string = '&' + f.field.toString() + '=' + f.value.toString();
				filter = filter + condition;
			}
			if (f.value !== '' && f.field === 'state') {
				let condition: string = '&' + f.field.toString() + 's[]=' + f.value.toString();
				filter = filter + condition;
			}

		});

		return filter;
	}
}