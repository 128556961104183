export const examEventTransitions = new Map<string, string[]>([
    ['unconfirmed', ['reg.opened', 'canceled']],
    ['reg.opened', ['reg.closed', 'prepared', 'canceled']],
    ['reg.closed', ['reg.opened', 'prepared', 'canceled']],
    ['prepared', ['canceled']],
]);

export const allExamEventStates = ['unconfirmed', 'reg.opened', 'prepared', 'canceled', 'reg.closed'];

export const examEventStatusOptionsGetter = (currentStatus?: string): string[] => {
    return currentStatus ? (examEventTransitions.get(currentStatus) as string[]) : [];
};
