import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { authenticationService } from '../../services/authentication/authentication.service';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BaseLandingPage } from '../../customized-components';
import { FunctionComponent } from 'react';
import { useState, useContext } from 'react';
import { AlertContext } from '../../App';


const PREFIX = 'ResetPasswordForm';

const classes = {
    links: `${PREFIX}-links`
};

const StyledBaseLandingPage = styled(BaseLandingPage)((
    {
        theme
    }
) => ({
    [`& .${classes.links}`]: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
    }
}));

export interface ResetPasswordFormModel {
    email: string;
}

export interface ResetPasswordProps {
    history: any;
    location: any;
}

export const ResetPasswordForm: FunctionComponent<ResetPasswordProps> = (props: ResetPasswordProps) => {


    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const [linkIsSent, sendLink] = useState<boolean>(false);
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordFormModel>();

    const onSubmit = (data: ResetPasswordFormModel) => {
        if (data.email) {
            authenticationService.forgotPassword(data.email).then((result: any) => {
                if (result) {
                    sendLink(true);
                }
            })
                .catch((error) =>
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    )
                );
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem('user') as any) {
            props.history.push('/');
        }

    }, []);

    return (
        <StyledBaseLandingPage>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    data-testid="email"
                                    {...field}
                                    id="email"
                                    label="Email"
                                    size="small"
                                    variant="outlined"
                                    error={errors.email ? true : false}
                                    helperText={errors.email && errors.email.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Email is required field',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address',
                                },
                            }}
                        />
                    </Grid>
                    {linkIsSent && (
                        <Grid item xs={12}>
                            <p>
                                Check your email for reset password link and go to{' '}
                                <Link data-testid="navToLoginFromReset" className={classes.links} to="/login">
                                    Login
                                </Link>
                            </p>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button
                            data-testid="forgotPasswordButton"
                            color="primary"
                            fullWidth
                            type="submit"
                            variant="contained"
                        >
                            Submit reset password request
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </StyledBaseLandingPage>
    );
};
