import {styled} from "@mui/material/styles";
import {CircularProgress as MuiCircularProgress, CircularProgressProps} from "@mui/material";

interface StyledCircularProgressProps extends CircularProgressProps {
    isCentered?: boolean;
}

export const StyledCircularProgress = styled(MuiCircularProgress, {
    shouldForwardProp: (prop) => prop !== "isCentered"
})<StyledCircularProgressProps>(({ isCentered, theme}) => ({
    ...(isCentered && {
        color: 'theme.palette.primary.main',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    })
}));
