import { callAxios } from '../../axios-wrapper/call-axios';

const register = (data: any) => {
    return callAxios.post('/auth/register', data).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Registration failed');
        }
    });
};
const getMaintenaceStatus = () => {
    return callAxios.get('/auth/maintenance-status').then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('getMaintenaceStatus failed');
        }
    });
};

const setMaintenaceStatus = (data: any) => {
    return callAxios.put('/auth/maintenance', data).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('setMaintenaceStatus failed');
        }
    });
};

const login = (email: string, password: string, adminPanel: boolean) => {
    return callAxios
        .post('/auth/login', {
            email,
            password,
            adminPanel,
        })
        .then((response) => {
            if (response.data.message === 'Success!' && response.data.token) {
                sessionStorage.setItem('user', JSON.stringify(response.data.token));
            } else {
                throw new Error('Login failed');
            }
        });
};

const logout = () => {
    sessionStorage.removeItem('user');
};

const emailIsUnique = (email: string) => {
    return callAxios
        .post(
            '/checkMail',
            {
                email: email,
            },
            '/public'
        )
        .then((response) => {
            if (response.status === 200) {
                return true;
            } else {
                throw new Error('Login failed');
            }
        });
};

const forgotPassword = (email: string) => {
    return callAxios
        .post('/auth/password/reset', {
            email,
        })
        .then((response) => {
            if (response.data.message === 'Success!') {
                return response.data;
            } else {
                throw new Error('Registration failed');
            }
        });
};

const newPassword = (email: string, code: string, password: string) => {
    return callAxios
        .post('/auth/password/update', {
            email,
            code,
            password,
        })
        .then((response) => {
            if (response.data.message === 'Success!') {
                return response.data;
            } else {
                throw new Error('Password reset failed');
            }
        });
};

const resendValidation = (email: string) => {
    return callAxios
        .post('/auth/email/resend-validation', {
            email,
        })
        .then((response) => {
            if (response.data.message === 'Success!') {
                return response.data;
            } else {
                throw new Error('Resending email validation failed');
            }
        });
};

const getLoggedInUserData = () => {
    return callAxios.get('/users/me').then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Fetching user data failed');
        }
    });
};

export const authenticationService = {
    register,
    login,
    logout,
    emailIsUnique,
    forgotPassword,
    newPassword,
    resendValidation,
    getLoggedInUserData,
    getMaintenaceStatus,
    setMaintenaceStatus
};
