import { createTheme  } from '@mui/material/styles';

export const defaultTheme = createTheme(({
    palette: {
        primary: {
            main: '#4765A0',
        },
        secondary: {
            main: '#808080',
        },
    },
}));
