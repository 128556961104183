import { FunctionComponent, useContext } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Participant } from '../../models/Participant';
import { Grid } from '@mui/material';
import { participantCardStyles } from './participant-base-data-card.css';
import { participantsService } from '../../services/participants/participants.service';
import { AlertContext } from '../../App';
import { StatusButton } from '../../customized-components/status-button/status-button';
import { personStatusOptionsGetter } from '../../status-transition-rules/person-transitions';
import EmptyTable from 'components/molecules/EmptyTable';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Email } from '../../models/Email';
import { convert } from '../../dateConverter';


export interface BaseDataCardProps {
    userData: Participant;
    onClickOpenParticipantDialog: () => void;
    onClickOpenMailManagementDialog: () => void;
    onUpdateParticipantState: () => void;
}

export const ParticipantBaseDataCard: FunctionComponent<BaseDataCardProps> = (props: BaseDataCardProps) => {
    const classes = participantCardStyles();

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const handleMenuItemClickStatus = (updateData: any) => {
        participantsService
            .changeParticipantState(updateData.newState, props.userData.id)
            .then((res) => {
                props.onUpdateParticipantState();
                handleAlert('Person state updated successfully', 'success');
                location.reload()
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const checkEmailStatuses = (): boolean => {
        const exists = props.userData.emails?.find((e) => e.state === 'active');
        return exists ? true : false;
    };

    const checkIfparticipantCanBeCompleted = () => {
        if (props.userData.firstName && props.userData.lastName && props.userData.emails &&
            props.userData.dateOfBirth && props.userData.birthCountry && props.userData.gender) {
            return true;
        }
        return false;
    }

    const showActiveMail = (emails: Email[]): string => {
        const active = emails.find(email => email.state === 'active')
        return active?.email || 'No active mails'
    }

    const statusOptions = personStatusOptionsGetter(props.userData?.state);
    const checkEnabled = () => {
        switch (props.userData.state) {
            case 'blocked':
                if (statusOptions?.length > 0 && checkIfparticipantCanBeCompleted())
                    return true
                else return false

            case 'pseudoanonymized':
                return true

            default:
                if (statusOptions?.length > 0 && checkEmailStatuses() && checkIfparticipantCanBeCompleted())
                    return true
                else return false
        }
    }

    const capitalize = (text: string) => {
        let res;
        if (text) {
            res = text.charAt(0).toUpperCase() + text.slice(1);
            return res
        } else return text
    }

    return (
        <Card className={classes.root} variant="outlined">
            {props.userData ? <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            User name
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {props.userData?.lastName} {props.userData?.firstName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Email
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {showActiveMail(props.userData?.emails)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Birth Country
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {props.userData?.birthCountry}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Place of Birth
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {props.userData?.placeOfBirth}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Date of Birth
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {convert(props.userData.dateOfBirth, false)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Gender
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {capitalize(props.userData.gender)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Language
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {props.userData?.language}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="space-between" style={{ marginTop: "30px" }}>
                    <Grid item xs={12} sm={12} md={"auto"}>
                        <CardActions>
                            <div>
                                <Button
                                    // disabled={props.userData?.state === 'confirmed'}
                                    variant="contained"
                                    color="primary"
                                    onClick={props.onClickOpenParticipantDialog}
                                >
                                    Edit Person
                                </Button>
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={props.onClickOpenMailManagementDialog}
                                >
                                    Mail Management
                                </Button>
                            </div>
                        </CardActions>
                    </Grid>
                    <Grid item xs={12} sm={12} md={"auto"}>
                        <StatusButton
                            enabled={() => checkEnabled()}
                            status={props.userData?.state}
                            statusOptions={statusOptions}
                            onStatusChange={handleMenuItemClickStatus}
                        />
                    </Grid>
                </Grid>
            </div>
                :
                <EmptyTable icon={PermIdentityIcon} text="User does not exist" />
            }
        </Card >
    );
};
