import { callAxios } from '../../axios-wrapper/call-axios';

const updateEmailStatus = (emailId: string, newState: string): Promise<void> => {
    return callAxios.patch(`/emails/${emailId}`, { status: newState }).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating user mail failed');
        }
    });
};

const addEmail = (email: string): Promise<void> => {
    return callAxios.post(`/users/addmail`, email).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Adding user mail failed');
        }
    });
};

const reassignEmail = (data: any): Promise<void> => {
    return callAxios.put(`/emails/reassign/${data.id}`, data).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Adding user mail failed');
        }
    });
};

export const mailsService = {
    updateEmailStatus,
    addEmail,
    reassignEmail,
};
