import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '../../customized-components';
import { FunctionComponent, useState, useContext } from 'react';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { AlertContext } from '../../App';
import { ordersService } from '../../services/orders/orders.service';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import Select from "@mui/material/Select";
import { Checkbox, FormControlLabel, FormHelperText, MenuItem } from '@mui/material';
import Order from 'models/Order';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker/DatePicker';
import Moment from "@date-io/moment";
import { format } from 'date-fns';

export interface ReassignOrderFormProps {
	order: Order;
	userId: string;
	cancel: () => void;
	onReassignOrder: () => void;
}

export const ReassignOrderForm: FunctionComponent<ReassignOrderFormProps> = (
	props: ReassignOrderFormProps
) => {
	const languages = [
		{
			value: 'EN',
			label: 'English',
		},
		{
			value: 'DE',
			label: 'German',
		}
	];

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setValue
	} = useForm();

	const [loading, setLoading] = useState<boolean>(false);

	const [disableForwarding, setDisableForwarding] = useState<boolean>(false);
	const [numOfDaysToClaim, setNumOfDaysToClaim] = useState<any>(10);
	const [numOfDaysToClaimMsg, setNumOfDaysToClaimMsg] = useState<any>(null);
	const [validFrom, setValidFrom] = useState<any>(format(new Date(), 'yyyy-MM-dd'));

	const momentum = new Moment();

	const handleDisableForwardingChange = (event) => {
		setDisableForwarding(event.target.checked);
	};

	const handleNumOfDaysToClaimChange = (event) => {
		const value = Number(event.target.value);
		if (value == null || value < 0 || value > 180)
			setNumOfDaysToClaimMsg("Invalid input!")
		else {
			setNumOfDaysToClaim(value)
			setNumOfDaysToClaimMsg(null)
		}
	}

	const handleValidFromChange = (newValue) => {
		const updatedAtValidFrom = momentum.moment(newValue).toDate();
		setValidFrom(updatedAtValidFrom);
	};

	//@ts-ignore
	const { handleAlert } = useContext(AlertContext);

	const onSubmit = (data: any) => {
		setLoading(true);
		data.selectedUserId = props.userId;
		data.adminFE = true;
		data.disableForwarding = disableForwarding;
		data.numOfDaysToClaim = numOfDaysToClaim;
		data.validFrom = validFrom;
		ordersService.reassignOrder(props.order && props.order.id, data).then((res) => {
			reset({});
			setDisableForwarding(false);
			setNumOfDaysToClaim(10);
			setValidFrom(format(new Date(), 'yyyy-MM-dd'));
			handleAlert('Voucher reassigned successfully!', 'success');
			setLoading(false);
			props.cancel();
			props.onReassignOrder();
		}).catch((error) =>
			handleAlert(
				error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
				'error'
			)
		);
	};

	// useEffect(() => {
	// 	examEventService.getAllExamEvents().then((res) => setExamEvents(res.results));
	// }, [props.participantId]);

	return (
		<form onSubmit={handleSubmit((data) => onSubmit(data))}>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Controller
							name="targetUser"
							render={({ field }) => (
								<TextField
									fullWidth
									{...field}
									data-testid="targetUser"
									id="targetUser"
									label="User email"
									size="small"
									variant="outlined"
									error={!!errors.email}
									helperText={errors.email && errors.email.message}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'First name is required field',
							}}
						/>
						<Controller
							name="targetLanguage"
							render={({ field }) => (
								<Select
									sx={{ mt: 2 }}
									id="targetLanguage"
									fullWidth
									name="targetLanguage"
									defaultValue="DE"
									label="Select language"
									onChange={e => setValue('targetLanguage', e.target.value)}
								>
									{languages.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</Select>
							)}
							control={control}
							defaultValue="DE"
							rules={{
								required: 'Language is required field',
							}}
						/>
						<FormControlLabel
							sx={{ mt: 2 }}
							label="Block further Assignment"
							control={
								<Checkbox
									name="disableFrwd"
									checked={disableForwarding}
									onChange={handleDisableForwardingChange}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							}
						/>

						<TextField
							type="number"
							sx={{ mt: 2 }}
							id="numOfDaysToClaim"
							variant="outlined"
							name={"numOfDaysToClaim"}
							fullWidth
							label="Return to sender when not claimed (0-180 days)"
							onChange={handleNumOfDaysToClaimChange}
							InputProps={{ inputProps: { min: 0, max: 180 } }}
							defaultValue={10}
						/>
						{numOfDaysToClaimMsg && <FormHelperText error={true} variant="outlined">
							{numOfDaysToClaimMsg}
						</FormHelperText>}
						<Grid item xs={12} sm={6} sx={{ mt: 2 }}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									label="Valid from"
									inputFormat="dd/MM/yyyy"
									minDate={new Date()}
									value={validFrom || new Date()}
									onChange={(handleValidFromChange)}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<ButtonWrapper>
					<Button disabled={loading} data-testid="saveupdateparticipant" color="primary" type="submit" variant="contained">
						Save
					</Button>
					{loading && <CircularProgress isCentered thickness={5} size={24} />}
				</ButtonWrapper>
				<Button
					disabled={loading}
					onClick={props.cancel}
					data-testid="cancelnewparticipant"
					color="primary"
					type="submit"
					variant="contained"
				>
					Cancel
				</Button>
			</DialogActions>
		</form>
	);
};
