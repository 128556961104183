import Button from '@mui/material/Button'
import { useForm } from 'react-hook-form'
import { DialogActions, DialogContent } from 'customized-components'
import {
    FunctionComponent,
    useEffect,
    useState
} from 'react'
import {
    DialogContentText,
    FormHelperText
} from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import LoadingButton from "@mui/lab/LoadingButton";
import { Email } from 'models/Email'

interface IFormInput {
    receipment_mail: string;
    badgeClass: string;
    evidenceURL: string;
    expires: string;
}

export interface BadgeAssertionProps {
    cancel: () => void;
    emails: Email[];
    createAssertion: (data: any) => void;
}

export const CreateBadgeAssertion: FunctionComponent<BadgeAssertionProps> = (props: BadgeAssertionProps) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm<IFormInput>({
        defaultValues: {
            receipment_mail: '',
            badgeClass: '',
            evidenceURL: 'https://ico-cert.org',
            expires: '',
        },
    });

    useEffect(() => {

    }, [])

    const [loading, setLoading] = useState(false);

    const onSubmit = (data) => {
        props.createAssertion(data);
    };

    const renderValidationMessage = (prop) => {
        if (errors[prop] && errors[prop].message) {
            let msg = errors[prop].message;
            return (
                <FormHelperText error={true} variant="outlined">
                    {msg}
                </FormHelperText>
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{ marginBottom: '20px' }}>
                    Select email of the user
                </DialogContentText>
                <FormControl
                    size="small"
                    variant="outlined"
                    fullWidth
                >
                    <Autocomplete
                        size="small"
                        options={props.emails.filter(email => email.state === 'active')}
                        //disableClearable
                        getOptionLabel={(option) =>
                            option.email
                        }
                        onChange={(e, option) => {
                            if (option === null) {
                                setValue('receipment_mail', '')
                            } else {
                                setValue('receipment_mail', option?.email)
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                label="Email *"
                                {...params}
                                variant="outlined"
                                //name={"receipment_mail"}
                                fullWidth
                                {...register("receipment_mail", {
                                    required: {
                                        value: true,
                                        message: "This field is required!",
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid e-mail!",
                                    },
                                })}
                                id="receipment_mail"
                            />
                        )}
                    />
                </FormControl>
                {renderValidationMessage("receipment_mail")}
            </DialogContent>




            <DialogActions>
                <Button onClick={() => {
                    reset()
                    props.cancel()
                }}>Cancel</Button>
                <LoadingButton loading={loading} type={"submit"}>Create</LoadingButton>
            </DialogActions>
        </form>
    )
}
