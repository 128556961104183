import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '../../customized-components';
import { FunctionComponent, } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import { ExamForBooking } from '../../models/ExamEvent';
import Moment from "@date-io/moment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


export interface ExamForBookingFormProps {
    cancel: () => void;
    onExamForBookingSubmit: (body: any) => void;
    selectedExamForBooking: any;
}

export const ExamForBookingForm: FunctionComponent<ExamForBookingFormProps> = (
    props: ExamForBookingFormProps,
) => {

    const momentum = new Moment();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm<ExamForBooking>({
        defaultValues: {
            percent: props.selectedExamForBooking.values.percent ? props.selectedExamForBooking.values.percent.replace(/%/g, '') : 0,
            participationDate: props.selectedExamForBooking.values.finishDate ? props.selectedExamForBooking.values.finishDate : undefined,
            status: props.selectedExamForBooking.values.status ? props.selectedExamForBooking.values.status : '',
            info: props.selectedExamForBooking.values.info ? props.selectedExamForBooking.values.info : '',
            examSetId: props.selectedExamForBooking.values.edumsID ? props.selectedExamForBooking.values.edumsID : '',
            hideResults: props.selectedExamForBooking.values.hideResults ? props.selectedExamForBooking.values.hideResults : false
        },
    });

    const onSubmit = (data: ExamForBooking) => {

        let formatDate = momentum.moment(data.participationDate).set('hour', momentum.moment().get('hour')).format("YYYY-MM-DD HH:mm");

        const body = {
            //@ts-ignore
            percent: data.percent ? parseInt(data.percent) : null,
            participationDate: formatDate,
            status: data.status,
            info: data.info,
            examSetId: data.examSetId,
            noCertIssue: data.changeWithoutIssuingCertificate,
            hideResults: data.hideResults,
        }

        props.onExamForBookingSubmit(body)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="percent"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    fullWidth
                                    data-testid="percent"
                                    onChange={(e) => {
                                        console.log(value)
                                        // Ensure that only numbers between 0 and 100 are accepted, or an empty string
                                        const newValue = e.target.value;
                                        console.log(newValue)
                                        if (newValue === "" || (/^\d+$/.test(newValue) && parseInt(newValue) >= 0 && parseInt(newValue) <= 100)) {
                                            onChange(newValue);
                                        }
                                    }}
                                    value={value ? value : ""}
                                    id="percent"
                                    label="Percent"
                                    size="small"
                                    InputProps={{
                                        inputProps: {
                                            max: 100,
                                            min: 0,
                                            type: 'number'
                                        }
                                    }}
                                    variant="outlined"
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                            control={control}
                            defaultValue={undefined}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Controller
                                name="participationDate"
                                control={control}
                                defaultValue={undefined}
                                rules={{
                                    required: 'Participation date is required',
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        {...field}
                                        inputFormat="dd/MM/yyyy"
                                        label="Participation date"
                                        value={field.value ? field.value : null}
                                        onChange={(date: any | null) => {
                                            setValue('participationDate', date as any, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            sx={{ width: '100%' }}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="status"
                            render={({ field }) => (
                                <FormControl variant="outlined" style={{ minWidth: 100 + '%' }} size="small">
                                    <InputLabel id="select-label-status">Status</InputLabel>
                                    <Select
                                        {...field}
                                        fullWidth
                                        data-testid="test-status"
                                        labelId="select-label-status"
                                        id="status"
                                        label="Status"
                                        error={!!errors.status}
                                        defaultValue=""
                                        onChange={(value) => {
                                            if (value.target.value)
                                                //@ts-ignore
                                                setValue('status', value.target.value);
                                        }}
                                    >
                                        {props.selectedExamForBooking.values.status === "prepared" && <MenuItem value={'prepared'} disabled>Prepared</MenuItem>}
                                        <MenuItem value={'succeeded'}>Succeeded</MenuItem>
                                        <MenuItem value={'failed'}>Failed</MenuItem>
                                        <MenuItem value={'not_graded'}>Not graded</MenuItem>
                                        <MenuItem value={'cheated'}>Cheated</MenuItem>
                                    </Select>
                                    <FormHelperText style={{ color: errors.status ? 'red' : 'grey' }}>
                                        {errors.status && errors.status.message}
                                    </FormHelperText>
                                </FormControl>
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Status is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="info"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    data-testid="info"
                                    onChange={onChange}
                                    value={value}
                                    id="info"
                                    label="Exam information"
                                    size="small"
                                    variant="outlined"
                                    error={!!errors.info}
                                    helperText={errors.info && errors.info.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                // required: 'Information is required field'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="changeWithoutIssuingCertificate"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={onChange}
                                            color="primary"
                                        />
                                    }
                                    label="Change data without issuing certificate"
                                />
                            )}
                            control={control}
                            defaultValue={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="hideResults"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={onChange}
                                            color="primary"
                                        />
                                    }
                                    label="Hide results"
                                />
                            )}
                            control={control}
                            defaultValue={false}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button
                        data-testid="saveupdateparticipant"
                        color="primary"
                        type="submit"
                        variant="contained"
                    >
                        Save
                    </Button>
                </ButtonWrapper>
                <Button
                    onClick={props.cancel}
                    data-testid="cancelnewparticipant"
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
