import {styled} from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    SvgIconTypeMap,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {Alignment, SortObject} from "../../../models/Alignments";
import SearchIcon from '@mui/icons-material/Search';
import {Badge} from "../../../models/Badges";
import {useState} from "react";

const PREFIX = 'SimpleToolbar';

export const classes = {
    root: `${PREFIX}-root`,
    filter: `${PREFIX}-filter`,
    select: `${PREFIX}-select`
}

export const ToolbarRoot = styled(Toolbar)(({ theme }) => ({
    [`&.${classes.root}`]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        borderRadius: '10px 10px 0px 0px',
    },
    [`&.${classes.filter}`]: {
        paddingLeft: '15px',
    },
    [`&.${classes.select}`]: {
        "&.MuiSelect-outlined": {
            color: "red",
        },
    }
}));

export interface TableToolbarProps {
    tableData: Alignment | Badge;
    title: string;
    addIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | undefined;
    addAction?: () => void | undefined;
    sortArray?: SortObject[];
    updateTableData?: (arg0: any) => void;
    onFilterChange?: (arg0?: any) => void;
    filters?: any;
}

const TableToolbar = (props: TableToolbarProps) => {
    const [filters, setFilters] = useState(props.filters);

    const handleSortChange = (event: SelectChangeEvent) => {
        const request = {
            orderBy: event.target.value,
            page: 0,
            pageSize: 0
        }
        props.updateTableData && props.updateTableData(request);
    };

    const handleChange = (e: any, object: any) => {
        setFilters((prevState: any) => {
            const currentFilter = {
                field: object.field,
                label: object.label,
                value: e.target.value
            }
            return {
                ...prevState,
                [object.field]: currentFilter
            };
        })
    }

    const onSearchClick = () => {
        props.onFilterChange && props.onFilterChange(filters)
    };

    const clearFilters = () => {
        setFilters(props.filters);
        const request = {
            orderBy: 'id',
            page: 0,
            pageSize: 0
        }
        props.updateTableData && props.updateTableData(request);
    }

    return (
        <ToolbarRoot className={classes.root}>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid item>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                            <Typography variant="h5" id="tableTitle" component="div">
                                {props.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.filter}>
                    <Box flexDirection="row" display="flex">
                        {props.sortArray && (
                            <Box p={1} sx={{ minWidth: 200}}>
                                <FormControl fullWidth>
                                    <InputLabel sx={{position: 'absolute', top: -5}} id="sort-filter">Sort Alignment</InputLabel>
                                    <Select
                                        sx={{
                                            height: 41,
                                            boxSizing: 'border-box'
                                        }}
                                        className={classes.select}
                                        labelId="sort-filter"
                                        id="simple-select"
                                        label="Sort Alignment"
                                        onChange={handleSortChange}
                                        defaultValue={''}
                                    >
                                        {props.sortArray &&
                                            props.sortArray.map((sort: SortObject, index) =>
                                                <MenuItem key={index} sx={{height: 42}} value={sort.value}>{sort.label}</MenuItem>)}
                                        <MenuItem value="id">None</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                        <Box p={1}>
                            <Grid container spacing={1}>
                                {Object.values(filters).map((f: any, index: number) => (
                                        //TODO: grid here should work ok for even number of filters, if it makes trouble for odd numbers
                                        // the xs sm and lg sizes can be added to table props and provided manually for each table
                                        //@ts-ignore
                                        <Grid
                                            key={index}
                                            item
                                        >
                                            <TextField
                                                onKeyDown={(e) => handleChange(e, f)}
                                                id={f.field}
                                                name={f.field}
                                                label={f.label}
                                                value={f.value}
                                                onChange={(e) => handleChange(e, f)}
                                                type="search"
                                                size="small"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Box>
                        <Box p={1}>
                            <Button
                                onClick={onSearchClick}
                                variant="contained"
                                color="primary"
                                startIcon={<SearchIcon />}
                            >
                                Search
                            </Button>
                        </Box>
                        <Box p={1}>
                            <Button
                                onClick={clearFilters}
                                variant="contained"
                                color="primary"
                            >
                                Clear filters
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ToolbarRoot>
    )
};

export default TableToolbar;
