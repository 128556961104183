import { HeadCell } from 'components/molecules/ComplexTableHead';

export const badgesHeaders: HeadCell[] = [
    { id: 'Id', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'title', numeric: false, label: 'Title', disablePadding: false, disableSorting: false },
    { id: 'image', numeric: false, label: 'Image', disablePadding: false, disableSorting: false },
    { id: 'criteriaURL', numeric: false, label: 'Criteria URL', disablePadding: false, disableSorting: false },
    { id: 'certificate', numeric: false, label: 'Certificate', disablePadding: false, disableSorting: false },
    { id: 'alignments', numeric: false, label: 'Alignments', disablePadding: false, disableSorting: false },
    { id: 'tags', numeric: false, label: 'Tags', disablePadding: false, disableSorting: false },
    { id: 'status', numeric: false, label: 'Status', disablePadding: false, disableSorting: false }
];
