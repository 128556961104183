import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '../../../customized-components';
import { FC, useEffect, useState, useContext, Fragment } from 'react';
import {
    FormControlLabel,
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Autocomplete,
    Box,
    ListItemText,
} from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
// import { debounce } from '@mui/material/utils';
import { Exam } from '../../../models/Exam';
import { examsService } from '../../../services/exams/exams.service';
import { certificatesService } from '../../../services/certificates/certificates.service';
import { AlertContext } from '../../../App';

export interface RegularCertificateFormProps {
    cancel: () => void;
    onCertificateCreate: () => void;
}

export const RegularCertificateForm: FC<RegularCertificateFormProps> = (props: RegularCertificateFormProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    let multiExams: string[] = [];
    //@ts-ignore
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        defaultValues: {
            computed: false,
            //examID: "",
            multiExams: multiExams,
            certLayout: '',
            backgroundID: '',
            name: '',
            startText: '',
            middleText: '',
            bottomText: '',
            autoIssue: false,
            showFooter: false,
            showPersonalData: false,
            status: '',
        },
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [exams, setExams] = useState<Exam[]>([]);
    const [layouts, setLayouts] = useState<string[]>([]);
    const [autoIssue, setAutoIssue] = useState<boolean>(false);
    const [showFooter, setShowFooter] = useState<boolean>(false);
    const [showPersonalData, setShowPersonalData] = useState<boolean>(false);

    useEffect(() => {
        examsService
            .getExamsActiveAndNotAssignedToCert()
            .then((res) => {
                let sortedExams = res.sort((a: any, b: any) => a.externalName.localeCompare(b.externalName));
                setExams(sortedExams);
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );

        certificatesService
            .getCertificateLayouts()
            .then((res) => {
                setLayouts(res);
            })
            .catch((error) => {
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                );
            });
    }, []);

    const onSubmit = (data: any) => {
        setLoading(true);
        data.autoIssue = autoIssue;
        data.showFooter = showFooter;
        data.showPersonalData = showPersonalData;
        console.log('mihajlo DATA ', data);
        //data.examID = (exams.find((exam) => exam.externalID === data.examID.id))?.id;
        certificatesService
            .createRegularCertificate(data)
            .then(() => {
                handleAlert('Certificate created successfully!', 'success');
                setLoading(false);
                props.cancel();
                props.onCertificateCreate();
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const renderExamNames = (selected: string[]): string[] => {
        let externalNames: string[] = [];
        selected.forEach((id) => {
            const exam = exams.find((e) => e.id === id);
            externalNames.push(exam?.externalName as string);
        });
        return externalNames;
    };
    // const renderExamName = (selected: string): string => {
    // 	let name: string = "";
    // 	const exam = exams.find((e) => e.id === selected);
    // 	name = exam?.externalName as string;
    // 	return name;
    // };

    const handleCheckChange = (type: string, event: React.ChangeEvent<HTMLInputElement>) => {
        switch (type) {
            case 'auto':
                setAutoIssue(event.target.checked);
                break;
            case 'footer':
                setShowFooter(event.target.checked);
                break;
            case 'data':
                setShowPersonalData(event.target.checked);
                break;
            default:
                break;
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="multiExams"
                                render={({ field }) => (
                                    <Fragment>
                                        <InputLabel htmlFor="multiExams">Exams</InputLabel>
                                        <Select
                                            {...field}
                                            data-testid="test-exams"
                                            id="multiExams"
                                            label="Exams"
                                            multiple
                                            renderValue={(selected) =>
                                                (renderExamNames(selected as string[]) as string[]).join(', ')
                                            }
                                            error={errors.multiExams ? true : false}
                                            onChange={(value) => {
                                                if (value.target.value)
                                                    //@ts-ignore
                                                    setValue('multiExams', value.target.value);
                                            }}
                                        >
                                            {exams.length >= 1 ? (
                                                exams.map((exam) => (
                                                    <MenuItem key={exam.id} value={exam.id}>
                                                        <Checkbox checked={field.value.indexOf(exam.id) > -1} />
                                                        <ListItemText
                                                            primary={exam.externalName + ' ' + exam.fixedID}
                                                        />
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled={true}>No data to show</MenuItem>
                                            )}
                                        </Select>
                                        <FormHelperText style={{ color: errors.multiExams ? 'red' : 'grey' }}>
                                            {errors.multiExams && 'Exams are required field'}
                                        </FormHelperText>
                                    </Fragment>
                                )}
                                control={control}
                                defaultValue={[]}
                                rules={{
                                    required: 'Exams are required field',
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
						<FormControl variant="outlined" size="small" fullWidth>
							<Controller
								name="examID"
								render={({ field }) => (
									<>
										<Autocomplete
											disablePortal
											id="examID"
											options={exams.map((exam: Exam) => ({ label: exam.externalName, id: exam.id }))}
											data-testid="test-exams"
											renderInput={(params) => <TextField {...params} error={!!errors.examID} label="Exams" />}
											renderOption={(props, option) => (
												<Box component="li" {...props}>{option.label}</Box>
											)}
											autoHighlight
											getOptionLabel={(option) => option.label}
											onChange={(event: any, newValue: any) => {
												setValue('examID', newValue.id);
											}}
										/>
										<FormHelperText style={{ color: errors.examID ? 'red' : 'grey' }}>
											{errors.examID && 'Exams are required field'}
										</FormHelperText>
									</>
								)}
								control={control}
								defaultValue={""}
								rules={{
									required: 'Exams are required field',
								}}
							/>
						</FormControl>
					</Grid> */}
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="name"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="name"
                                    id="name"
                                    size="small"
                                    label="Name"
                                    variant="outlined"
                                    error={!!errors.name}
                                    helperText={errors.name && errors.name.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Name is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="certLayout"
                                render={({ field }) => (
                                    <>
                                        <InputLabel htmlFor="certLayout">Layout</InputLabel>
                                        <Select
                                            {...field}
                                            data-testid="test-certLayout"
                                            id="certLayout"
                                            label="Layout"
                                            error={!!errors.certLayout}
                                            onChange={(event) => field.onChange(event.target.value)}
                                        >
                                            {layouts?.map((single, index) => (
                                                <MenuItem value={single} key={index}>
                                                    {single}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText style={{ color: errors.certLayout ? 'red' : 'grey' }}>
                                            {errors.certLayout && errors.certLayout.message}
                                        </FormHelperText>
                                    </>
                                )}
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: 'Layout is required field',
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="backgroundID"
                                render={({ field }) => (
                                    // <TextField
                                    // 	fullWidth
                                    // 	{...field}
                                    // 	data-testid="backgroundID"
                                    // 	id="backgroundID"
                                    // 	size="small"
                                    // 	label="Background ID"
                                    // 	variant="outlined"
                                    // 	error={!!errors.backgroundID}
                                    // 	helperText={errors.backgroundID && errors.backgroundID.message}
                                    // />
                                    <>
                                        <InputLabel htmlFor="backgroundID">Background ID</InputLabel>
                                        <Select
                                            {...field}
                                            data-testid="backgroundID"
                                            id="backgroundID"
                                            label="Background ID"
                                            error={!!errors.backgroundID}
                                            onChange={(event) => field.onChange(event.target.value)}
                                        >
                                            <MenuItem value={'1'}>1 - ICO</MenuItem>
                                            <MenuItem value={'2'}>2 - ICO / exam certificate</MenuItem>
                                            <MenuItem value={'7'}>7 - ITEMO / role certificate</MenuItem>
                                            <MenuItem value={'8'}>8 - ITEMO</MenuItem>
                                            <MenuItem value={'14'}>14 - IT Sicherheitscluster</MenuItem>
                                        </Select>
                                        <FormHelperText style={{ color: errors.backgroundID ? 'red' : 'grey' }}>
                                            {errors.backgroundID && errors.backgroundID.message}
                                        </FormHelperText>
                                    </>
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Background ID is required field',
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="startText"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="startText-test"
                                    id="startText"
                                    label="Start text"
                                    size="small"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    error={!!errors.startText}
                                    helperText={errors.startText && errors.startText.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Start text is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="middleText"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="middleText-test"
                                    id="middleText"
                                    label="Middle text"
                                    size="small"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    error={!!errors.middleText}
                                    helperText={errors.middleText && errors.middleText.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Middle text is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="bottomText"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="bottomText-test"
                                    id="bottomText"
                                    label="Bottom text"
                                    size="small"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    error={!!errors.bottomText}
                                    helperText={errors.bottomText && errors.bottomText.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Bottom text is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="status"
                                render={({ field }) => (
                                    <>
                                        <InputLabel htmlFor="status">Status</InputLabel>
                                        <Select
                                            {...field}
                                            data-testid="test-status"
                                            id="status"
                                            label="status"
                                            error={!!errors.status}
                                            onChange={(event) => field.onChange(event.target.value)}
                                        >
                                            <MenuItem value={'active'}>Active</MenuItem>
                                            <MenuItem value={'inactive'}>Inactive</MenuItem>
                                            <MenuItem value={'new'}>New</MenuItem>
                                        </Select>
                                        <FormHelperText style={{ color: errors.status ? 'red' : 'grey' }}>
                                            {errors.status && errors.status.message}
                                        </FormHelperText>
                                    </>
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Status is required field',
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-around">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={autoIssue}
                                        onChange={(e) => handleCheckChange('auto', e)}
                                        name="jason"
                                    />
                                }
                                label="Auto Issue"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showFooter}
                                        onChange={(e) => handleCheckChange('footer', e)}
                                        name="jason"
                                    />
                                }
                                label="Show Footer"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showPersonalData}
                                        onChange={(e) => handleCheckChange('data', e)}
                                        name="jason"
                                    />
                                }
                                label="Show Personal Data"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
                        Create
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        //@ts-ignore
                        reset({
                            computed: false,
                            //examID: "",
                            certLayout: '',
                            backgroundID: '',
                            name: '',
                            startText: '',
                            middleText: '',
                            bottomText: '',
                            autoIssue: false,
                            showFooter: false,
                            showPersonalData: false,
                        });
                        props.cancel();
                    }}
                    data-testid=""
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
