import React, { Fragment, useContext, useState } from 'react';
import { AlertContext } from "App";
import { Controller, useForm } from "react-hook-form";
import { Assertion } from "models/Assertions";
import Moment from "@date-io/moment";
import { assertionsService } from "services/assertions/AssertionsService";
import {
    DialogContent,
    FormControl, FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Link,
    Select,
    TextField,
    Autocomplete,
    Box
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ButtonWrapper from "components/atoms/buttons/ButtonWrapper";
import Button from "@mui/material/Button";
import CircularProgress from "components/atoms/StyledCircularProgress";
import { DialogActions } from "customized-components";
import { Badge } from "models/Badges";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


interface EditAssertionFormProps {
    assertion: Assertion;
    badges: any;
    cancel: () => void;
    createAssertion?: boolean;
    onAssertionUpdate: (arg0?: any) => void;
}

const EditAssertionForm = (props: EditAssertionFormProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const momentum = new Moment();

    //@ts-ignore
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm({
        defaultValues: props.createAssertion ? {
            badgeClass: "",
            receipment_mail: '',
            // image: '',
            evidenceURL: '',
            // expires: momentum.moment(new Date()).utcOffset(0).format()
            expires: new Date()
        } : {
            ...props.assertion,
            badgeClass: props.assertion.badgeClass?.[0],
        }
    });
    const [loading, setLoading] = useState<boolean>(false);

    const badgeOptions = props.badges.map((badge: Badge) => ({ title: badge.title, id: badge.id }))

    const onSubmit = (data: any) => {
        setLoading(true);
        let body;
        if (data.expires) {
            data.expires = momentum.moment(data.expires).utcOffset(0).format();
        }

        if (props.createAssertion) {
            body = {
                badgeClass: data.badgeClass.id,
                receipment_mail: data.receipment_mail,
                // image: data.image,
                evidenceURL: data.evidenceURL,
                expires: momentum.moment(data.expires).toDate()
            };

            assertionsService.createAssertion(body as any).then(() => {
                handleAlert('Assertion created successfully!', 'success');
                props.cancel();
                setLoading(false);
                props.onAssertionUpdate();
            }).catch((error) => {
                props.cancel();
                handleAlert(error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', error)
            }
            );

        } else {
            body = {
                ...props.assertion,
                badgeClass: data.badgeClass.id,
                receipment_mail: data.receipment_mail,
                // image: data.image,
                evidenceURL: data.evidenceURL,
                expires: momentum.moment(data.expires).toDate()
            };

            assertionsService.updateAssertion(body, props.assertion.id).then(() => {
                handleAlert('Assertion updated successfully!', 'success');
                props.cancel();
                setLoading(false);
                props.onAssertionUpdate();
            }).catch((error) =>
                handleAlert(error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error')
            );
        }
    };

    const renderBadgeTitles = (selected: string): string => {
        const currentBadge = props.badges?.find((e: Badge) => e.id === selected);
        return currentBadge.title;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent sx={{
                borderLeft: '3px solid #4765A0',
                borderRight: '3px solid #4765A0'
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="badgeClass"
                                render={({ field }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            id="badgeClass"
                                            options={badgeOptions}
                                            data-testid="badgeClass"
                                            value={field.value}
                                            isOptionEqualToValue={(option, value) => {
                                                return value === undefined || value === "" || option.id === value.id
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        error={!!errors.badgeClass}
                                                        label="Badge"
                                                        size="small"
                                                    />
                                                )
                                            }}
                                            renderOption={(props, option) => {
                                                return <Box component="li" {...props}>{option.title}</Box>
                                            }}
                                            autoHighlight
                                            getOptionLabel={(option) => option.title || ''}
                                            onChange={(_, data) => {
                                                field.onChange(data)
                                            }}
                                        />
                                        <FormHelperText style={{ color: errors.badgeClass ? 'red' : 'grey' }}>
                                            {errors.badgeClass && 'Badges are required field'}
                                        </FormHelperText>
                                    </>
                                )}
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: 'Field required'
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="receipment_mail"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="receipment_mail"
                                    id="receipment_mail"
                                    size="small"
                                    label="Email"
                                    variant="outlined"
                                    error={!!errors.receipment_mail}
                                    helperText={errors.receipment_mail && errors.receipment_mail.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Email is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="evidenceURL"
                            render={({ field }) => (
                                <Fragment><TextField
                                    fullWidth
                                    {...field}
                                    data-testid="evidenceURL"
                                    id="evidenceURL"
                                    size="small"
                                    label="Evidence URL"
                                    variant="outlined"
                                    error={!!errors.evidenceURL}
                                    helperText={errors.evidenceURL && errors.evidenceURL.message}
                                />
                                    <FormHelperText id="component-helper-text">
                                        <Link target="_blank"
                                            href={"https://www.imsglobal.org/sites/default/files/Badges/OBv2p0Final/index.html#Assertion"}>
                                            evidenceURL details
                                        </Link>
                                    </FormHelperText>
                                </Fragment>
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Evidence URL is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Controller
                                name="expires"
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        data-testid="expires"
                                        inputFormat="dd/MM/yyyy"
                                        label="Expiration Date"
                                        value={field.value}
                                        onChange={(date: any | null) => {
                                            setValue('expires', date as any, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }}
                                        renderInput={(params: any) => <TextField size="small" sx={{ width: '100%' }} {...params} />}
                                    />
                                )}
                                control={control}
                                defaultValue={undefined}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        //@ts-ignore
                        reset({
                            receipment_mail: '',
                            // image: '',
                            evidenceURL: '',
                            expires: ''
                        });
                        props.cancel();
                    }}
                    data-testid=""
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};

export default EditAssertionForm;
