import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from 'customized-components';
import { FunctionComponent, useEffect, useState, useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

import DateFnsUtils from '@date-io/date-fns';
import { CreateExamEvent } from 'models/ExamEvent';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    debounce,
    FormControl,
    FormHelperText,
    TextField,
    Typography
} from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { Exam } from 'models/Exam';
import { examsService } from 'services/exams/exams.service';
import { AlertContext } from 'App';
import { Autocomplete } from '@mui/material';
import { organizationsService } from 'services/organizations/organizations.service';
import { Organization } from 'models/Organization';
import { defaultSOESettings } from 'utils/defaultSOEsettings';
import StyledExpandMoreIcon from 'components/atoms/StyledExpandMoreIcon';
import { ExamEventSettingsForm } from './exam-settings-form';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { examEventService } from 'services/examevents/exam-event.service';

export interface ExamEventFormProps {
    cancel: () => void;
    onExamCreated: () => void;
}

export const ExamEventForm: FunctionComponent<ExamEventFormProps> = (props: ExamEventFormProps) => {

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    //@ts-ignore
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<CreateExamEvent>({
        defaultValues: {
            exams: [],
            ato: undefined,
            //@ts-ignore
            startDate: undefined, //otherwise datepicker won't show empty date field on load
            startTime: undefined,
            soeSettings: JSON.stringify(defaultSOESettings, null, 4),
        },
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [exams, setExams] = useState<Exam[]>([]);
    const [selectedExamDuration, setSelectedExamDuration] = useState<number | undefined>(45);
    const [atos, setAtos] = useState<Organization[]>();

    const [openAC, setOpenAC] = useState(false);
    const [loadingAC, setLoadingAC] = useState(false);

    useEffect(() => {
        examsService
            .getExamsActive()
            .then((res) => {
                let sortedExams = res.sort((a: any, b: any) => a.externalName.localeCompare(b.externalName))
                setExams(sortedExams);
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );

        getAtoBySearchTerm('');
        setValue('startDate', new Date().toString())

    }, []);

    // Atos = Organisation
    const getAtoBySearchTerm = (search: string) => {
        organizationsService.getAtoWithSearchInStatusTrue(search)
            .then((res) => {
                let sortedAtos = res.sort((a: any, b: any) => a.name.localeCompare(b.name))
                setAtos(sortedAtos);
                setLoadingAC(false);
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    }

    const onSubmit = (examEvent: CreateExamEvent) => {
        setLoading(true);

        //COMBINE DATE AND TIME FIELDS INTO ONE VARIABLE
        const target = examEvent.startDate.toString().split(" ");
        const time = examEvent.startTime;
        target[4] = time + ':00';
        examEvent.startDate = target.join(' ');
        //@ts-ignore
        delete examEvent.startTime;

        examEventService.createExamEvent(examEvent).then((res) => {
            handleAlert('Exam event created successfully!', 'success');
            setLoading(false);
            props.cancel();
            props.onExamCreated();
        }).catch((error) => {
            handleAlert(error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error');
            setLoading(false);
        });
    };

    const onSettingsUpdate = (name: string, value: any) => {
        defaultSOESettings[name] = value;
        if (name === 'cameraAndCognitoServices' && value === 3) defaultSOESettings["Photo_Time_Interval"] = 0;
        if (name === 'displayAndCognitoServices' && value === 3) defaultSOESettings["photoDisplayTimeInterval"] = 0;
        setValue("soeSettings", JSON.stringify(defaultSOESettings, null, 4))
    }


    const renderExamNames = (selected: any[]): string[] => {
        let names: string[] = [];
        selected.forEach((id) => {
            const exam = exams.find((e) => e.id === id.id);
            names.push(exam?.externalName as string);
            onSettingsUpdate("ExamVersion_plannedDuration", exam?.details.plannedDuration)
            onSettingsUpdate("examObjectLanguage", exam?.details.examObjectLanguage)
            setSelectedExamDuration(exam?.details.plannedDuration);
        });
        return names;
    };

    const onSearchAto = debounce((search: string) => {
        getAtoBySearchTerm(search);
    }, 1800)

    const nowDate = Date.now();

    const handleChange = (date: any | null) => {
        setValue('startDate', date as any, {
            shouldValidate: true,
            shouldDirty: true,
        })
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="exams"
                                render={({ field: { value } }) => (
                                    <>
                                        <Autocomplete
                                            disablePortal
                                            id="examsId_auto"
                                            multiple
                                            options={exams.map((exam: Exam) => {
                                                let fixedID = exam.fixedID;
                                                let SQMS2_ExamSet_ID = (exam.details?.SQMS2_ExamSet_ID) ? exam.details.SQMS2_ExamSet_ID + " - " + exam.fixedID : '';
                                                let showNumber = (!SQMS2_ExamSet_ID) ? " : " + fixedID : " : " + SQMS2_ExamSet_ID;
                                                return {
                                                    name: exam.externalName + showNumber,
                                                    id: exam.id
                                                }
                                            })}
                                            renderInput={(params) => {
                                                return (<TextField {...params} error={!!errors.exams} label="Exams" />)
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props} key={option.id}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </li>
                                            )}
                                            autoHighlight
                                            getOptionLabel={(option) => option.name || ''}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.id === value.id || value === undefined || value === null
                                            }}
                                            onChange={(event: any, newValue: any) => {
                                                setValue('exams', newValue.map((item: { id: any; }) => item.id));
                                                renderExamNames(newValue)
                                            }}
                                        />
                                        <FormHelperText style={{ color: errors.exams ? 'red' : 'grey' }}>
                                            {errors.exams && 'Certificates are required field'}
                                        </FormHelperText>
                                    </>
                                )}
                                control={control}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Controller
                                name="startDate"
                                render={({ field }) => (
                                    <DatePicker
                                        inputFormat="dd/MM/yyyy"
                                        label="Start Date"
                                        value={field.value}
                                        onChange={handleChange}
                                        minDate={nowDate}
                                        renderInput={(params: any) => <TextField sx={{ width: '100%' }} {...params} />}
                                    />
                                )}
                                control={control}
                                rules={{
                                    required: 'Start date is required field',
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Controller
                                name="startTime"
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        id="time"
                                        size="small"
                                        label="Start Time"
                                        type="time"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            setValue('startTime', e.target.value, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }}
                                    />
                                )}
                                control={control}
                                rules={{
                                    required: 'Start time is required field',
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {atos &&
                            <Controller
                                name="ato"
                                render={({ field: { value } }) => (
                                    <>
                                        <Autocomplete
                                            disablePortal
                                            id="atoId_auto"
                                            options={atos.map((oneAto: Organization) => ({ name: oneAto.name, id: oneAto.id }))}
                                            renderInput={(params) => {
                                                return (<TextField {...params} error={!!errors.ato} label="ATO's" />)
                                            }}
                                            renderOption={(props, option) => (
                                                <Box {...props} key={option.id} component="li" >{option.name}</Box>
                                            )
                                            }
                                            autoHighlight
                                            getOptionLabel={(option) => option.name || ''}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.id === value.id || value === undefined || value === null
                                            }}
                                            onChange={(event: any, newValue: any) => {
                                                setValue("ato", newValue)
                                            }}
                                        />
                                        <FormHelperText style={{ color: errors.ato ? 'red' : 'grey' }}>
                                            {errors.ato && 'Certificates are required field'}
                                        </FormHelperText>
                                    </>
                                )}
                                control={control}
                                defaultValue={undefined}
                            />
                        }
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="soeSettings"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="soeSettings-test"
                                    id="soeSettings"
                                    label="SOE settings"
                                    size="small"
                                    variant="outlined"
                                    multiline
                                    rows={25}
                                    error={errors.soeSettings ? true : false}
                                    helperText={errors.soeSettings && errors.soeSettings.message}
                                />
                            )}
                            control={control}
                            rules={{
                                required: 'SOE settings is required field'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<StyledExpandMoreIcon />}
                                style={{ backgroundColor: "#3f51b5", color: "white", }}
                            >
                                <Typography>Settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ marginTop: "20px" }}>
                                <ExamEventSettingsForm onInputUpdate={onSettingsUpdate} duration={selectedExamDuration} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        //@ts-ignore
                        reset({
                            exams: [],
                            name: '',
                            //@ts-ignore
                            startDate: null, //otherwise datepicker won't show empty date field on load
                            soeSettings: '',
                        });
                        props.cancel();
                    }}
                    data-testid=""
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
