import {
    Button,
    CardMedia,
    Chip,
    Grid,
    Link,
    TableCell,
    TableRow,
    Tooltip,
    CardActionArea,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import PlaceholderImage from 'assets/no-image-icon-23485.png';
import { Certificate } from 'models/Certificate';
import { Badge } from 'models/Badges';
import { BADGE_STATUS } from 'models/enums';
import { TableAction } from 'models/table-models/TableAction';
import { FunctionComponent, useContext, useState } from 'react';
import { badgesService } from 'services/badges/badgesService';
import { AlertContext } from 'App';
import CircularProgress from 'components/atoms/StyledCircularProgress/StyledCircularProgress';

export interface BadgeRowProps {
    row: Badge;
    actions?: TableAction[];
}

export const BadgeRow: FunctionComponent<BadgeRowProps> = (props: BadgeRowProps) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [newStatus, setNewStatus] = useState(undefined);
    const [loadingButton, setLoadingButton] = useState(false);
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const statusOptions = {
        [BADGE_STATUS.NEW]: 'New',
        [BADGE_STATUS.ACTIVE]: 'Active',
        [BADGE_STATUS.PUBLISHED]: 'Published',
        [BADGE_STATUS.INACTIVE]: 'Inactive',
    };

    const handleChange = (event) => {
        setNewStatus(event.target.value);
        setOpenDialog(true);
    };

    const handleConfirm = () => {
        const originalStatus = status; // Save the current status
        setOpenDialog(false);
        setLoadingButton(true);

        //console.log(newStatus, props.row.certificate?.[0]?.id, props.row?.id);
        badgesService
            .updateBadgeStatus({ status: newStatus, certId: props.row.certificate?.[0]?.id }, props.row.id)
            .then((res) => {
                if (res.badgeClass) {
                    props.row.status = res.badgeClass.status;
                    handleAlert('Badge status updated successfully!', 'success');
                    setLoadingButton(false);
                } else {
                    throw new Error('Update failed'); // Force catch block execution if response doesn't meet the condition
                }
            })
            .catch((error) => {
                handleAlert(error?.message ? error?.message : 'Ups! Something went wrong', 'error');
                setLoadingButton(false);
                console.log(error);
            });
    };

    const handleCancel = () => {
        setOpenDialog(false);
    };

    let filteredOptions = Object.values(BADGE_STATUS);
    if (props.row?.status === BADGE_STATUS.PUBLISHED || props.row?.status === BADGE_STATUS.ACTIVE) {
        filteredOptions = filteredOptions.filter((statusItem) => statusItem !== BADGE_STATUS.NEW);
    } else if (props.row?.status === BADGE_STATUS.INACTIVE) {
        filteredOptions = [BADGE_STATUS.INACTIVE];
    }

    return (
        <TableRow key={props.row.id} hover>
            <TableCell sx={{ width: '2rem' }}>
                <Grid container spacing={1}>
                    {props.actions &&
                        props.actions.map((single, index) => (
                            <Grid item key={index}>
                                <Tooltip title={single.title}>
                                    <span>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            aria-label="filter list"
                                            //@ts-ignore
                                            onClick={() => single.onClick(props.row.id)}
                                            // disabled={props.row.status !== BADGE_STATUS.NEW}
                                        >
                                            {<single.icon color="primary" />}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Grid>
                        ))}
                </Grid>
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>
                <Tooltip title={'BcId: ' + props.row.id}>
                    <span>{props.row.title}</span>
                </Tooltip>
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>
                <CardActionArea href={props.row.image} target={'_blank'}>
                    <CardMedia
                        component="img"
                        image={props.row.image}
                        onError={(e: any) => {
                            e.target.src = PlaceholderImage;
                        }}
                        sx={{
                            width: 70,
                            height: 70,
                        }}
                    />
                </CardActionArea>
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>
                <Link target="_blank" href={props.row.criteriaURL}>
                    {props.row.criteriaURL}
                </Link>
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>
                <Tooltip title={'CertId: ' + (props.row.certificate?.[0] as Certificate)?.id}>
                    <span>{(props.row.certificate?.[0] as Certificate)?.name}</span>
                </Tooltip>
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>
                {props.row.alignments?.map((alignment, index) => (
                    <span key={index}>
                        {' '}
                        {alignment.targetName}
                        {props.row.alignments?.length === index + 1 ? '' : ','}
                    </span>
                ))}
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>
                {props.row.tags?.map((tag, index) => (
                    <span key={index}>
                        <Chip style={{ margin: '0.0625rem' }} variant="outlined" label={tag}></Chip>
                        {/* {props.row.tags?.length === index + 1 ? '' : ','} */}
                    </span>
                ))}
            </TableCell>
            <TableCell sx={{ width: '2rem' }}>
                {!loadingButton ? (
                    <Select value={props.row.status} onChange={handleChange}>
                        {filteredOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {statusOptions[option]}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <CircularProgress />
                )}
            </TableCell>
            <Dialog open={openDialog} onClose={handleCancel}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>Are you sure you want to change the status?</DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </TableRow>
    );
};
