import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '../../customized-components';
import { FunctionComponent, useContext, useState, Fragment, useEffect } from 'react';
import { FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { CreateParticipant } from '../../models/Participant';
import { authenticationService } from '../../services/authentication/authentication.service';
import { AlertContext } from '../../App';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import { defaultOrganizationsFilters } from 'models/table-filters.ts/organizations-table-filters';
import { organizationsService } from 'services/organizations/organizations.service';
import TablePageSortFilter from 'models/table-models/TablePageSortFilter';
import PaginationSortingFilteringRequest from 'models/table-models/PaginationSortingFilteringRequest';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// import { KeyboardDatePicker } from '@material-ui/pickers';

export interface AddParticipantFormProps {
    addNewparticipant: (participant: CreateParticipant) => void;
    cancel: () => void;
}

export const AddParticipantForm: FunctionComponent<AddParticipantFormProps> = (props: AddParticipantFormProps) => {
    const defaultRequest = { order: 'asc', orderBy: 'name', page: 0, rowsPerPage: 100, filters: defaultOrganizationsFilters() };
    const [organizations, setOrganizations] = useState<TablePageSortFilter>();

    const loadTable = (data: PaginationSortingFilteringRequest) => {
        setLoading(true)
        organizationsService
            .getOrganizationsWithPaginationAndFilter(data)
            .then((res) => {
                setOrganizations(res);
                setLoading(false);
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    useEffect(() => {
        loadTable(defaultRequest)
    }, []);

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const [loading, setLoading] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue
    } = useForm<CreateParticipant>({
        defaultValues: {
            email: '',
            firstName: '',
            lastName: '',
            gender: '',
            language: 'DE',
            origin: 'admin',
            memberOfOrganization: '',
            dateOfBirth: undefined,
        },
    });

    const onSubmit = (participant: CreateParticipant) => {
        if (participant.email) {
            setLoading(true);
            let data: any = {
                email: participant.email
            }

            if (participant.firstName) {
                data.firstName = participant.firstName
            }
            if (participant.lastName) {
                data.lastName = participant.lastName
            }
            if (participant.gender) {
                data.gender = participant.gender
            }
            if (participant.dateOfBirth) {
                data.dateOfBirth = participant.dateOfBirth
            }
            if (participant.language) {
                data.language = participant.language
            }
            if (participant.memberOfOrganization) {
                data.memberOfOrganization = participant.memberOfOrganization
                data.userRoleInOrg = participant.userRoleInOrg
            }
            if (participant.email) {
                data.email = data.email.toLowerCase();
            }

            data.origin = participant.origin;

            authenticationService
                .register(data)
                .then((user: any) => {
                    reset({
                        email: '',
                        firstName: '',
                        lastName: '',
                        language: "DE",
                        dateOfBirth: undefined,
                        gender: '',
                        memberOfOrganization: '',
                        origin: 'admin'
                    });
                    props.addNewparticipant(participant);
                    handleAlert('Participant registered successfully', 'success');
                    setLoading(false);
                    props.cancel();
                }).catch((error) => {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    )
                    setLoading(false);
                    // props.cancel();
                });
        } else {
            window.alert('Something went wrong, refresh and retry');
        }
    };

    const checkifEmailValid = (email: string) => {
        authenticationService
            .emailIsUnique(email)
            .then((res) => res)
            .catch((error) => {
                if (error.message === 'E-mail is already taken!') {
                    setError('email', {
                        type: 'server',
                        message: error.message,
                    });
                } else {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    );
                }
            });
    };

    const restrictedCharacters = (value: string) => {
        // const valid = value.match(/[^a-zA-Z0-9äöüÄÖÜß' -]/g)
        const valid = value.match(/[^a-zA-Z0-9ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ' -]/g)
        return !valid;
    };
    const [isOrganizationSelected, setIsOrganizationSelected] = useState(false);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    {/* <Grid item xs={12} sm={6}>
                        <Controller
                            name="password"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="password"
                                    id="password"
                                    label="Password"
                                    size="small"
                                    type="password"
                                    variant="outlined"
                                    error={errors.password ? true : false}
                                    helperText={errors.password && errors.password.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                validate: {
                                    isLongEnough: (value) => {
                                        if (value) {
                                            return /(?=.{8,})/.test(value) || 'Password must be at least 8 characters long.'
                                        }
                                    },
                                    hasUpper: (value) => {
                                        if (value) {
                                            return /(?=.*[A-Z])/.test(value) || 'Password must contain upper case letter.'
                                        }
                                    },
                                    hasLower: (value) => {
                                        if (value) {
                                            return /(?=.*[a-z])/.test(value) || 'Password must contain lower case letter.'
                                        }
                                    },
                                    hasDigit: (value) => {
                                        if (value) {
                                            return /(?=.*\d)/.test(value) || 'Password must contain digit.'
                                        }
                                    },
                                    hasSpecial: (value) => {
                                        if (value) {
                                            return /(?=.*[-+_!@#$%^&*.,?])/.test(value) || 'Password must contain special character.'
                                        }
                                    },
                                }
                            }}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="email"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    data-testid="email"
                                    onKeyUp={(event) => {
                                        //@ts-ignore
                                        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(event.target.value)) {
                                            //@ts-ignore
                                            checkifEmailValid(event.target.value);
                                        }
                                    }}
                                    onChange={onChange}
                                    value={value}
                                    id="email"
                                    label="Email"
                                    size="small"
                                    variant="outlined"
                                    error={!!errors.email}
                                    helperText={errors.email && errors.email.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Email is required field',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="firstName"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="firstName"
                                    id="firstName"
                                    label="First Name"
                                    size="small"
                                    variant="outlined"
                                    error={!!errors.firstName}
                                    helperText={errors.firstName && errors.firstName.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'First name is required field',
                                validate: {
                                    hasSpecial: (value) => {
                                        return restrictedCharacters(value) || 'First name must not contain special character.'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="lastName"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="lastName"
                                    id="lastName"
                                    label="Last Name"
                                    size="small"
                                    variant="outlined"
                                    error={!!errors.lastName}
                                    helperText={errors.lastName && errors.lastName.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Last name is required field',
                                validate: {
                                    hasSpecial: (value) => {
                                        return restrictedCharacters(value) || 'Last name must not contain special character.'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller name='gender' render={({ field }) =>
                                <Fragment>
                                    <InputLabel htmlFor="gender">Gender</InputLabel>
                                    <Select
                                        {...field}
                                        data-testid="test-gender"
                                        id="gender"
                                        label="Gender"
                                        error={!!errors.gender}
                                        onChange={(value) => {
                                            if (value.target.value)
                                                //@ts-ignore
                                                setValue("gender", value.target.value);
                                        }}
                                    >
                                        <MenuItem value={'male'}>Male</MenuItem>
                                        <MenuItem value={'female'}>Female</MenuItem>
                                        <MenuItem value={'inter'}>Inter</MenuItem>

                                    </Select>
                                    <FormHelperText style={{ color: errors.gender ? 'red' : 'grey' }}>{errors.gender && errors.gender.message}</FormHelperText>
                                </Fragment>

                            }
                                control={control}
                                defaultValue=''
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller name='language' render={({ field }) =>
                                <Fragment>
                                    <InputLabel htmlFor="language">Language</InputLabel>
                                    <Select
                                        {...field}
                                        data-testid="test-language"
                                        id="language"
                                        label="Language"
                                        error={!!errors.language}
                                        onChange={(value) => {
                                            if (value.target.value)
                                                //@ts-ignore
                                                setValue("language", value.target.value);
                                        }}
                                    >
                                        <MenuItem value={"EN"}>English</MenuItem>
                                        <MenuItem value={"DE"}>Deutsch</MenuItem>

                                    </Select>
                                    <FormHelperText style={{ color: errors.language ? 'red' : 'grey' }}>{errors.language && errors.language.message}</FormHelperText>
                                </Fragment>

                            }
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="memberOfOrganization"
                                render={({ field }) => (
                                    <Fragment>
                                        <InputLabel htmlFor="memberOfOrganization">Add to organization</InputLabel>
                                        <Select
                                            {...field}
                                            data-testid="test-memberOfOrganization"
                                            id="memberOfOrganization"
                                            label="Member of organization"
                                            error={!!errors.memberOfOrganization}
                                            onChange={(value) => {
                                                if (value.target.value) {
                                                    //@ts-ignore
                                                    setValue("memberOfOrganization", value.target.value);
                                                    setIsOrganizationSelected(true);
                                                } else {
                                                    setValue("memberOfOrganization", "");
                                                    setIsOrganizationSelected(false);
                                                }

                                            }}
                                        >
                                            {organizations?.results.map((organization) => (
                                                <MenuItem key={organization.Name} value={`${organization.id}:${organization.name}`}>
                                                    {organization.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText style={{ color: errors.memberOfOrganization ? 'red' : 'grey' }}>
                                            {errors.memberOfOrganization && errors.memberOfOrganization.message}
                                        </FormHelperText>
                                    </Fragment>
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>

                    </Grid>
                    {isOrganizationSelected &&
                        <Grid container spacing={2} sx={{ px: 3 }}>
                            <Grid item xs={12} sm={8}></Grid>
                            <Grid item xs={12} sm={4} >
                                <FormControl>
                                    <Controller
                                        rules={{ required: true }}
                                        control={control}
                                        name="userRoleInOrg"
                                        render={({ field }) => (
                                            <RadioGroup row {...field}>
                                                <FormControlLabel value="member" control={<Radio />} label="member" />
                                                <FormControlLabel value="owner" control={<Radio />} label="owner" />
                                            </RadioGroup>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                    {/* <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Controller
                                name="dateOfBirth"
                                render={({ field }) => (
                                    <KeyboardDatePicker
                                        {...field}
                                        fullWidth
                                        data-testid="date-of-birth"
                                        variant="inline"
                                        inputVariant="outlined"
                                        size="small"
                                        format="dd/MM/yyyy"
                                        margin="none"
                                        id="birthday"
                                        label="Date of birth"
                                        value={field.value ? field.value : null}
                                        onChange={(date: MaterialUiPickersDate) => {
                                            setValue('dateOfBirth', date as any, {
                                                shouldValidate: true,
                                                shouldDirty: true,
                                            });
                                        }}
                                        error={errors.dateOfBirth ? true : false}
                                        helperText={
                                            errors.dateOfBirth && errors.dateOfBirth.message
                                        }
                                    />
                                )}
                                control={control}
                                defaultValue={undefined}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={6}>
						<Controller name='placeOfBirth' render={({ field }) =>
							<TextField fullWidth
								data-testid="placeOfBirth"
								{...field}
								id="placeOfBirth"
								label="Place of birth"
								size="small"
								variant="outlined"
							/>
						}
							control={control}
							defaultValue=''
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name='birthCountry'
							render={({ field: { onChange, value } }) =>
								<Autocomplete fullWidth
									onChange={(event, item) => {
										onChange(item);
									}}
									getOptionSelected={(option, value) =>
										value === undefined || option.id === value.id
									  }
									  value={value}
									// {...field}
									options={countries as CountryType[]}
									getOptionLabel={(option) => option.label}
									renderOption={(option) => (
										<Fragment>
										  {option.label} ({option.id}) +{option.phone}
										</Fragment>
									  )}
									data-testid="countryOfBirth"
									id="countryOfBirth"
									size="small"
									autoHighlight
									renderInput={(params) => <TextField
										{...params}
										label="Country of birth"
										variant="outlined"
										error={errors.birthCountry ? true : false}
										helperText={errors.birthCountry && errors.birthCountry.message}
										inputProps={{
											...params.inputProps
										}}
									/>}
								/>
							}
							control={control}
							rules={{
								required: "Country is required field"
							}}
						/>
					</Grid> */}
                    {/* <Grid item xs={12} sm={6}>
						<Controller name='externalId' render={({ field }) =>
							<TextField fullWidth
								data-testid="test-externalId"
								{...field}
								id="externalId"
								label="External ID"
								size="small"
								variant="outlined"
							/>
						}
							control={control}
							defaultValue=''
						/>
					</Grid> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="savenewparticipant" color="primary" type="submit" variant="contained">
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        reset();
                        props.cancel();
                    }}
                    data-testid="cancelnewparticipant"
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
