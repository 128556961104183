import { Fragment, FunctionComponent, useContext, useEffect, useState, useRef } from 'react';
import { UserContext } from '../../context/UserContext';
import { AlertContext } from '../../App';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { bookingsService } from '../../services/bookings/bookings.service';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import ComplexTable from 'components/organisms/ComplexTable';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { BookingOnExamEventRow } from './bookings-on-exam-event-row';
import { bookingsOnExamEventHeaders } from '../../models/table-headers/bookings-on-exam-event-headers';
import { ModalDialog } from '../../customized-components';
import { BookingsOnExamEventForm } from '../bookings-on-exam-event-form/bookings-on-exam-event-form';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { defaultBookingsFilters } from '../../models/table-filters.ts/bookings-table-filters';
import { ExamForBookingForm } from '../exam-for-booking/exam-for-booking-form';
import { DataForSoeProglongExam } from 'models/soeProlongExam';
import { examEventService } from '../../services/examevents/exam-event.service';
import DialogView from 'views/DialogView';

export interface BookingsOnExamEventSectionProps {
    id: string | undefined;
}

export const BookingsOnExamEventSection: FunctionComponent<BookingsOnExamEventSectionProps> = (
    props: BookingsOnExamEventSectionProps
) => {
    const defaultRequest = { order: 'desc', orderBy: 'createdAt', page: 0, rowsPerPage: 10, filters: defaultBookingsFilters };
    const mounted = useRef(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [accessTokenAndExamId, setAccessTokenAndExamId] = useState<DataForSoeProglongExam>();
    const [bookings, setBookings] = useState<TablePageSortFilter>();
    const [participantId, setParticipantId] = useState<string>();
    const [bookingOnExamEventDialogIsOpen, setOpenBookingOnExamEventDialog] = useState<boolean>(false);
    const [examBookingDialogIsOpen, setOpenExamBookingDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);
    const [selectedExamForBooking, setSelectedExamFormBooking] = useState({
        values: {
            examEventID: '',
            examID: '',
            edumsID: '',
            info: '',
            finishDate: '',
            percent: '',
            status: '',
            hideResults: false
        }
    })


    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData } = useContext(UserContext);

    const loadBookings = (request: PaginationSortingFilteringRequest) => {
        setLoading(true)
        bookingsService.getBookings(props.id as string, request)
            .then((res) => {
                if (mounted.current) {
                    setBookings(res)
                    setLoading(false)
                }
            })
            .catch((error) => {
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            }
            );
    };

    useEffect(() => {
        mounted.current = true;
        if (props.id && userData) {
            if (participantId !== props.id) {
                setParticipantId(props.id);
                loadBookings(paginationData);
            }
        }
        return () => {
            mounted.current = false;
        }

    }, [props.id, userData])

    const addBookingSuccessful = () => {
        setOpenBookingOnExamEventDialog(false);
        loadBookings(paginationData);
    };

    const handleClickOpenBookingOnExamEventDialog = () => {
        setOpenBookingOnExamEventDialog(!bookingOnExamEventDialogIsOpen);
    };

    const updateParticipantBookingStatus = (bookingId: string, newState: string) => {
        if (newState === 'canceled') {
            bookingsService.cancelUsersFromExamEvent([props.id as string], bookingId).then((res) => {
                loadBookings(paginationData);
            });
        }
    };

    const updateParticipantExamBookingStatus = (examEventID: string, examID: string, newState: any, examSetId: string) => {
        const body = {
            status: newState,
            examSetId
        };
        if (props.id) {
            examForBookingCall(props.id, examEventID, examID, body)
        } else {
            //@ts-ignore
            examForBookingCall(userData?.id, examEventID, examID, body)
        }
    };

    const examForBookingSubmit = (body: any) => {
        if (props.id) {
            examForBookingCall(props.id, selectedExamForBooking.values.examEventID, selectedExamForBooking.values.examID, body)
        } else {
            //@ts-ignore
            examForBookingCall(userData?.id, selectedExamForBooking.values.examEventID, selectedExamForBooking.values.examID, body)
        }
    };

    const handleSubmitDialog = () => {
        if (accessTokenAndExamId) {
            examEventService.prolongExamEvent(accessTokenAndExamId)
                .then((res) => {
                    handleAlert('State updated successfully', 'success')
                    console.log('res from prolong ', res);
                })
                .catch((error) => {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    )
                    console.log('err from prolong ', error);
                }
                );
        }
        setOpenDialog(false)
    }

    const handleClickOpenDialog = (dataForSoe: DataForSoeProglongExam) => {
        setOpenDialog(true)
        setAccessTokenAndExamId({ examEventId: dataForSoe.examEventId, edumsID: dataForSoe.edumsID, examID: dataForSoe.examID, admin: dataForSoe.admin })
    }
    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false)
    }

    const examForBookingCall = (id: string, examEventID: string, examID: string, body: any) => {
        bookingsService.changeUsersExamBookingStatus(id, examEventID, examID, body).then((res) => {
            handleAlert('State updated successfully', 'success')
            setOpenExamBookingDialog(false)
            loadBookings(paginationData);
        }).catch(error => {
            handleAlert(
                error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                'error'
            )
            setOpenExamBookingDialog(false)
        })
    }

    const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
        setPaginationData(newValue)
    };

    const toggleExamForBookingDialog = (examEventID: string, examID: string, edumsID: string, info: string, finishDate: string, percent: string, status: string, hideResults: boolean) => {
        setOpenExamBookingDialog(true);
        setSelectedExamFormBooking({ ...selectedExamForBooking, values: { examEventID: examEventID, examID: examID, edumsID: edumsID, info: info, finishDate: finishDate, percent: percent, status: status, hideResults: hideResults } });
    };

    useEffect(() => { }, [selectedExamForBooking]) //workaround for updating selectedExamForBooking

    const bookingActions = {
        //TODO: ALEX PLEASE HIDE THIS BUTTON FOR NOW AS IT SERVES NO PURPOSE
        oneEditParticipantBooking: {
            title: 'Issue certificate',
            icon: InsertDriveFileIcon,
            onClick: () => alert('Issue certificate'),
        },
        onChangeParticipantBookingStatus: {
            title: 'Create booking on exam event',
            icon: InsertDriveFileIcon,
            onClick: updateParticipantBookingStatus,
        },
        onChangeParticipantExamBookingStatus: {
            title: 'Create booking on exam event',
            icon: InsertDriveFileIcon,
            onClick: updateParticipantExamBookingStatus,
        },
        onExamBookingDetails: {
            title: 'Insert exam results',
            icon: ListAltIcon,
            onClick: toggleExamForBookingDialog,
        },
    };

    return (
        <Fragment>
            <ComplexTable
                tableData={bookings as TablePageSortFilter}
                headerCells={bookingsOnExamEventHeaders}
                title="Bookings On Exam Event"
                // @ts-ignore
                addAction={handleClickOpenBookingOnExamEventDialog}
                addIcon={AddBoxIcon}
                updateTableData={loadBookings}
                onPaginationUpdate={trackPagination}
                loading={loading}
            >
                {(bookings?.results && (participantId !== undefined))
                    ? bookings.results.map((b) => (
                        <BookingOnExamEventRow key={b.id} booking={b} participantId={participantId} actions={bookingActions} handleClickOpenDialog={handleClickOpenDialog} />
                    ))
                    : null}
            </ComplexTable>
            <div>
                {bookingOnExamEventDialogIsOpen ? (
                    <ModalDialog
                        dialogIsOpen={bookingOnExamEventDialogIsOpen}
                        setDialogOpen={() => setOpenBookingOnExamEventDialog(!bookingOnExamEventDialogIsOpen)}
                        title="Booking On Exam Event"
                    >
                        <BookingsOnExamEventForm
                            participantId={props.id as string}
                            onAddBookingSuccessful={addBookingSuccessful}
                            cancel={() => setOpenBookingOnExamEventDialog(false)}
                        />
                    </ModalDialog>
                ) : null}
            </div>
            <div>
                {examBookingDialogIsOpen ? (
                    <ModalDialog
                        dialogIsOpen={examBookingDialogIsOpen}
                        setDialogOpen={() => setOpenExamBookingDialog(!examBookingDialogIsOpen)}
                        title="Exam For Booking On Exam Event"
                    >
                        <ExamForBookingForm
                            cancel={() => setOpenExamBookingDialog(false)}
                            onExamForBookingSubmit={examForBookingSubmit}
                            selectedExamForBooking={selectedExamForBooking}
                        />
                    </ModalDialog>
                ) : null}
            </div>
            {openDialog && <DialogView
                handleCloseDialog={handleCloseDialog}
                handleSubmitDialog={handleSubmitDialog}
                openDialogStatus={openDialog}
                message='You extend the validity of your exam link for additional 30 days.' title='Prolong exam' />
            }
        </Fragment>
    );
};
