import { styled } from '@mui/material/styles';
import { Toolbar } from '@mui/material';

const PREFIX = 'ComplexToolbar';

export const classes = {
    root: `${PREFIX}-root`,
    filter: `${PREFIX}-root`,
}

export const ToolbarRoot = styled(Toolbar)(({ theme }) => ({
    [`&.${classes.root}`]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        borderRadius: '10px 10px 0px 0px',
    },
    [`&.${classes.root}`]: {
        paddingLeft: '15px',
    },
}));
