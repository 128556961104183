import { useState, useEffect, useContext } from 'react';
import { AlertContext } from 'App';
import { UserContext } from 'context/UserContext';
import { authenticationService } from 'services/authentication/authentication.service';
import { examsHeaders } from "models/table-headers/exams-headers";
import { examsService } from 'services/exams/exams.service';
import { ExamRow } from "./ExamsRow";
import { Exam } from 'models/Exam';
import { Certificate as CertificateModel } from 'models/Certificate';
import { ModalDialog } from 'customized-components';
import { CertificateToExamForm } from 'features/certificate-to-exam/certificate-to-exam-from';
import PaginationSortingFilteringRequest from 'models/table-models/PaginationSortingFilteringRequest';
import { defaultExamFilters } from 'models/table-filters.ts/exam-table-filters';
import ComplexTable from 'components/organisms/ComplexTable';
import TablePageSortFilter from 'models/table-models/TablePageSortFilter';

const ExamsPage = () => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData, setUserData } = useContext(UserContext);
    const [examId, setExamId] = useState<string>('');
    const [exams, setExams] = useState<TablePageSortFilter>();
    const [loading, setLoading] = useState<boolean>(false);
    const [editCertificateDialog, setEditCertificateDialog] = useState<boolean>(false);
    const [getSelectedCertificate, setSelectedCertificate] = useState();

    const defaultRequest = { order: 'desc', orderBy: 'updatedAt', page: 0, rowsPerPage: 10, filters: defaultExamFilters };
    const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);

    const loadExams = (request: PaginationSortingFilteringRequest) => {
        setLoading(true)
        examsService
            .getExamsWithCertificates(request)
            .then((res) => {
                setExams(res);
                setLoading(false);
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };

    const onHandleExamStatusChange = async (changeExamStatus: boolean, examId: string) => {
        try {
            let updated = await examsService.updateExamStatus({ status: changeExamStatus }, examId)
            if (updated) {
                let updatedExams = exams?.results.map(exam => {
                    if (exam.id === examId) {
                        exam.status = changeExamStatus
                    }
                    return exam;
                })
                setExams(prevState => ({ ...prevState, rows: updatedExams }) as TablePageSortFilter);
            } else {
                console.log("error occured while updating exam ", updated);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!userData) {
            authenticationService.getLoggedInUserData().then((res: any) => {
                //@ts-ignore
                setUserData(res);
                loadExams(paginationData);
            })
        } else {
            loadExams(paginationData);
        }
    }, []);

    const handleOnEditExamBooking = (examId: string) => {
        setEditCertificateDialog(!editCertificateDialog)
        setExamId(examId)
        setSelectedCertificate(exams && exams.results?.find((item: CertificateModel) => item.id === examId).examCertificate);
    }

    const toggleEditDialog = () => {
        setEditCertificateDialog(!editCertificateDialog)
    }

    const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
        setPaginationData(newValue)
    };

    return (
        <>
            <ComplexTable
                title="Exams"
                loading={loading}
                addAction={() => { }}
                addIcon={undefined}
                headerCells={examsHeaders}
                updateTableData={loadExams}
                tableData={exams as TablePageSortFilter}
                onPaginationUpdate={trackPagination}
            >
                {exams?.results
                    ? exams.results.map((exam: Exam) => {
                        return <ExamRow
                            onEditExamBooking={handleOnEditExamBooking}
                            key={exam.id} row={exam}
                            onHandleExamStatusChange={(changeExamStatus, examId) => onHandleExamStatusChange(changeExamStatus, examId)}
                        />
                    }
                    )
                    : ""

                }
            </ComplexTable>

            {editCertificateDialog ? (
                <ModalDialog
                    dialogIsOpen={editCertificateDialog}
                    //@ts-ignore
                    setDialogOpen={toggleEditDialog}
                    title="Edit Exam Booking"
                >
                    <CertificateToExamForm
                        examId={examId}
                        cancel={toggleEditDialog}
                        selectedCert={getSelectedCertificate as any}
                        onCertificateModified={() => loadExams(paginationData)}
                    />
                </ModalDialog>
            ) : null}

        </ >
    )
};

export default ExamsPage;
