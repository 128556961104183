import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

export interface PrivateRouteProps {
    component: React.Component | React.FunctionComponent;
    exact: boolean;
    path: string;
}

export const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => (
	<UserContext.Consumer>
		{(({isAuthenticated}) =>
			<Route
				{...rest}
				render={(props) => {
					if (!isAuthenticated) {
						return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
					}
		
					// @ts-ignore
					return <Component {...props} />;
				}}
			/>
			
		)}
	</UserContext.Consumer>
);
