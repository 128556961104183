import { callAxios } from '../../axios-wrapper/call-axios';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { CertificateSelectModel } from '../../models/context/CertificateSelectModel';
import UrlFilterConstructor from '../../transformators/UrlFilterConstructor';

const getCertificates = (): Promise<any> => {
    return callAxios.get(`/certificates/all`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading certificates failed');
        }
    });
};

const getCertificatesForSelectMenu = (requestDataMap: CertificateSelectModel): Promise<any> => {

    return callAxios
        .get(
            `/certificates/filter?name=${requestDataMap.query}`
        )
        .then((res) => {
            if (res.status === 200) {

                return { ...res };
            } else {
                throw new Error('Loading certificates failed');
            }
        });
};

const getCertificatesWithPaginationAndFilter = (r: PaginationSortingFilteringRequest): Promise<TablePageSortFilter> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(
            `/certificates/?orderBy=${r.orderBy.toString()}&sortOrder=${r.order}&page=${r.page + 1}&items=${r.rowsPerPage
            }${filter}`
        )
        .then((res) => {
            if (res.status === 200) {
                return { ...r, results: res.data.rows, total: res.data.total };
            } else {
                throw new Error('Loading certificates failed');
            }
        });
};

const getUsersCertificates = (userID: string, r: PaginationSortingFilteringRequest): Promise<TablePageSortFilter> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(
            `/users/${userID}/certificates/?orderBy=${r.orderBy.toString()}&sortOrder=${r.order}&page=${r.page + 1
            }&items=${r.rowsPerPage}${filter}`
        )
        .then((res) => {
            if (res.status === 200) {
                return { ...r, results: res.data.rows, total: res.data.total };
            } else {
                throw new Error('Loading users certificates failed');
            }
        });
};

const createRegularCertificate = (req: any): Promise<any> => {
    return callAxios.post(`/certificates/`, req).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Creating certificates failed');
        }
    });
};

const createComputedCertificate = (req: any): Promise<any> => {
    return callAxios.post(`/certificates/`, req).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Creating certificates failed');
        }
    });
};

const getCertificateLayouts = (): Promise<any> => {
    return callAxios.get(`/certificates/layouts`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading certificates layouts failed');
        }
    });
};

const issueCertificate = (id: string): Promise<any> => {
    return callAxios.post(`/certificates/${id}`, { force: true }).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Issuing certificates failed');
        }
    });
};

const updateCertificate = (body: any, id: string): Promise<any> => {
    return callAxios.patch(`/certificates/${id}`, body).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating certificates failed');
        }
    });
};

export const certificatesService = {
    getCertificates,
    getCertificatesWithPaginationAndFilter,
    getCertificatesForSelectMenu,
    getUsersCertificates,
    createRegularCertificate,
    createComputedCertificate,
    getCertificateLayouts,
    issueCertificate,
    updateCertificate,
};
