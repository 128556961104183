import PaginationSortingFilteringRequest from 'models/table-models/PaginationSortingFilteringRequest';
import UrlFilterConstructor from 'transformators/UrlFilterConstructor';
import { callAxios } from '../../axios-wrapper/call-axios';

const getExams = (): Promise<any> => {
    return callAxios.get(`/exams/`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading failed');
        }
    });
};

const getExamsWithCertificates = (r: PaginationSortingFilteringRequest): Promise<any> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios.get(`/exams/examswithcertificates?orderBy=${r.orderBy.toString()}&sortOrder=${r.order}&page=${r.page + 1}&items=${r.rowsPerPage
        }${filter}`).then((res) => {
            if (res.status === 200) {
                if (res.data.rows.length >= 0) {
                    return { ...r, results: res.data.rows, total: res.data.total };
                } else {
                    throw new Error('Loading failed');
                }
            } else {
                throw new Error('Loading failed');
            }
        });
};
const getExamsActive = (): Promise<any> => {
    return callAxios.get(`/exams/?status=true`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading failed');
        }
    });
};

const getExamsActiveAndNotAssignedToCert = (): Promise<any> => {
    return callAxios.get(`/exams/?status=true&assignedToCert=true`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading failed');
        }
    });
};



const updateExamStatus = (examStatus: any, id: string): Promise<any> => {
    return callAxios.patch(`/exams/${id}`, examStatus).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating exam failed');
        }
    });
};

const getExamById = (examEventId: string): Promise<any> => {
    return callAxios.get(`/examevents/${examEventId}`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading failed');
        }
    });
};

const setCertificateToExam = (certId: string, examId: string): Promise<any> => {
    return callAxios.patch(`/exams/certonexam`, { certId, examId }).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading failed');
        }
    });
};

export const examsService = {
    getExams,
    getExamsActive,
    getExamById,
    updateExamStatus,
    setCertificateToExam,
    getExamsWithCertificates,
    getExamsActiveAndNotAssignedToCert
};
