import { FunctionComponent, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AlertContext } from '../../App';
import { CreateOrganization } from '../../models/Organization';
import { DialogActions, DialogContent } from '../../customized-components';
import { InputLabel, Select, FormHelperText, FormControl, MenuItem, Grid } from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { organizationsService } from '../../services/organizations/organizations.service';
import { authenticationService } from '../../services/authentication/authentication.service';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';


export interface OrganizationFormProps {
    addOrganization: (organization: CreateOrganization) => void;
    cancel: () => void;
}

export const AddOrganizationForm: FunctionComponent<OrganizationFormProps> = (props: OrganizationFormProps) => {

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm<CreateOrganization>();

    const [loading, setLoading] = useState<boolean>(false);

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    const onSubmit = (data: CreateOrganization) => {
        if (data.email) {
            data.email = data.email.toLowerCase();
        }
        setLoading(true);
        organizationsService.registerOrganization(data).then((res: any) => {
            reset({
                name: '',
                shortName: '',
                type: '',
                // logo: '',
                email: '',
            });
            props.addOrganization(data);
            handleAlert('Organizations added successfully', 'success');
            setLoading(false);
            props.cancel();
        }).catch((error) => {
            handleAlert(error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error');
        });
    };

    const checkifEmailValid = (email: string) => {
        authenticationService
            .emailIsUnique(email)
            .then((res) => res)
            .catch((error) => {
                if (error.message === 'E-mail is already taken!') {
                    setError('email', {
                        type: 'server',
                        message: error.message,
                    });
                } else {
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    );
                }
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="name"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="name"
                                    id="name"
                                    label="Name"
                                    size="small"
                                    variant="outlined"
                                    error={errors.name ? true : false}
                                    helperText={errors.name && errors.name.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Name is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="shortName"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="shortName"
                                    id="shortName"
                                    label="Short Name"
                                    size="small"
                                    variant="outlined"
                                    error={errors.shortName ? true : false}
                                    helperText={errors.shortName && errors.shortName.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Short name is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="type"
                            render={({ field }) => (
                                <FormControl variant="outlined" style={{ minWidth: 100 + '%' }} size="small">
                                    <InputLabel id="select-label-type">Type</InputLabel>
                                    <Select
                                        {...field}
                                        fullWidth
                                        data-testid="test-type"
                                        labelId="select-label-type"
                                        id="type"
                                        label="Type"
                                        error={errors.type ? true : false}
                                        defaultValue=""
                                        onChange={(value) => {
                                            if (value.target.value)
                                                //@ts-ignore
                                                setValue('type', value.target.value);
                                        }}
                                    >
                                        <MenuItem value={'certification'}>Certification</MenuItem>
                                        <MenuItem value={'admin'}>Admin</MenuItem>
                                        <MenuItem value={'training'}>Training</MenuItem>
                                    </Select>
                                    <FormHelperText style={{ color: errors.type ? 'red' : 'grey' }}>
                                        {errors.type && errors.type.message}
                                    </FormHelperText>
                                </FormControl>
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Type is required field',
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <Controller
                            name="logo"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="logo"
                                    id="logo"
                                    label="Logo"
                                    size="small"
                                    variant="outlined"
                                    error={errors.logo ? true : false}
                                    helperText={errors.logo && errors.logo.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Logo name is required field',
                            }}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="email"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    fullWidth
                                    data-testid="email"
                                    onKeyUp={(event) => {
                                        //@ts-ignore
                                        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(event.target.value)) {
                                            //@ts-ignore
                                            checkifEmailValid(event.target.value);
                                        }
                                    }}
                                    onChange={onChange}
                                    value={value}
                                    id="email"
                                    label="Email"
                                    size="small"
                                    variant="outlined"
                                    error={errors.email ? true : false}
                                    helperText={errors.email && errors.email.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Email is required field',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email',
                                },
                            }}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="savenewparticipant" color="primary" type="submit" variant="contained">
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24}/>}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        reset();
                        props.cancel();
                    }}
                    data-testid="cancelnewparticipant"
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
