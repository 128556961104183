import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from 'customized-components';
import { FunctionComponent, useEffect, useState, useContext } from 'react';
import {
    Checkbox,
    Dialog,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    TextField,
} from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { Exam } from 'models/Exam';
import { examsService } from 'services/exams/exams.service';
import { AlertContext } from 'App';
import { Autocomplete } from '@mui/material';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import Order from 'models/Order';
import { ordersService } from 'services/orders/orders.service';
import { DatePicker } from '@mui/x-date-pickers/DatePicker/DatePicker';
import Moment from "@date-io/moment";
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


export interface VoucherFormProps {
    cancel: () => void;
    onVoucherCreated: () => void;
    userId;
}

export const VoucherAddForm: FunctionComponent<VoucherFormProps> = (props: VoucherFormProps) => {

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    //@ts-ignore
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<any>({
        defaultValues: {
            exam: undefined,
            invoiceNumber: undefined,
            numberOfVouchers: 1
        },
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [exams, setExams] = useState<Exam[]>([]);
    const [disableForwarding, setDisableForwarding] = useState<boolean>(false);
    const [assignForOrganisation, setAssignForOrganisation] = useState<boolean>(false);
    const [validFrom, setValidFrom] = useState<any>(format(new Date(), 'yyyy-MM-dd'));
    const momentum = new Moment();

    useEffect(() => {
        examsService
            .getExamsActive()
            .then((res) => {
                let sortedExams = res.sort((a: any, b: any) => a.externalName.localeCompare(b.externalName))
                setExams(sortedExams);
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    }, []);


    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [numberOfVouchers, setNumberOfVouchers] = useState<number>(1);
    const [orderToSubmit, setOrderToSubmit] = useState<any>();


    const confirmAddVouchers = () => {
        setShowConfirmationDialog(false);
        ordersService.createOrder(orderToSubmit).then((res) => {
            handleAlert('Voucher created successfully!', 'success');
            setLoading(false);
            props.cancel();
            props.onVoucherCreated();
        }).catch((error) => {
            handleAlert(error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error');
            setLoading(false);
        });
    };

    const cancelConfirmation = () => {
        setShowConfirmationDialog(false);
        setLoading(false);
    };

    const onSubmit = (order: any) => {
        setLoading(true);
        setShowConfirmationDialog(true);

        order.targetID = props.userId;
        order.ownerID = props.userId;
        order.disableForwarding = disableForwarding;
        order.validFrom = validFrom;
        let voucherNo = Number(order.numberOfVouchers);
        order.numberOfVouchers = voucherNo;
        order.purchaseForOrganisation = assignForOrganisation;
        order.adminVoucherCreationForOrganisation = true;
        setNumberOfVouchers(voucherNo);
        console.log(order);
        setOrderToSubmit(order);

    };

    const handleDisableForwardingChange = (event) => {
        setDisableForwarding(event.target.checked);
    };
    const handleDisableAssignForOrganisation = (event) => {
        setAssignForOrganisation(event.target.checked);
    };

    const handleValidFromChange = (newValue) => {
        const updatedAtValidFrom = momentum.moment(newValue).toDate();
        setValidFrom(updatedAtValidFrom);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <Controller
                                name="exam"
                                render={({ field: { value } }) => (
                                    <>
                                        <Autocomplete
                                            //disablePortal
                                            id="examsId_auto"
                                            //multiple
                                            //options={exams && exams.map((exam: Exam) => ({ name: exam.externalName, id: exam.id }))}

                                            options={exams && exams.map((exam: Exam) => {
                                                let fixedID = exam.fixedID;
                                                let SQMS2_ExamSet_ID = (exam.details?.SQMS2_ExamSet_ID) ? exam.details.SQMS2_ExamSet_ID + " - " + exam.fixedID : '';
                                                let showNumber = (!SQMS2_ExamSet_ID) ? " : " + fixedID : " : " + SQMS2_ExamSet_ID;
                                                return {
                                                    name: exam.externalName + showNumber,
                                                    id: exam.id
                                                }
                                            })}

                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        helperText={errors.exam && errors.exam.message}
                                                        error={!!errors.exam}
                                                        label="Exam" />)
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props} key={option.id}>{option.name}</li>
                                            )}
                                            autoHighlight
                                            getOptionLabel={(option) => option.name || ''}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.id === value.id || value === undefined || value === null
                                            }}
                                            onChange={(event: any, newValue: any) => {
                                                setValue('exam', newValue);
                                            }}
                                        />
                                        {/* <FormHelperText style={{ color: errors.exam ? 'red' : 'grey' }}>
                                            {errors.exam && 'Exam is required field'}
                                        </FormHelperText> */}
                                    </>
                                )}
                                defaultValue={''}
                                rules={{
                                    required: 'Exam is required field',
                                }}
                                control={control}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            name="invoiceNumber"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="invoiceNumber-test"
                                    id="invoiceNumber"
                                    label="Invoice Number"
                                    size="small"
                                    variant="outlined"
                                    //multiline
                                    //rows={5}
                                    error={errors.invoiceNumber ? true : false}
                                    helperText={errors.invoiceNumber && errors.invoiceNumber.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Invoice Number is required field',

                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <Controller
                            name="numberOfVouchers"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="invoiceNumber-test"
                                    id="numberOfVouchers"
                                    label="Number Of Vouchers"
                                    size="small"
                                    variant="outlined"
                                    type="number"
                                    error={errors.numberOfVouchers ? true : false}
                                    helperText={errors.numberOfVouchers && errors.numberOfVouchers.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Number Of Vouchers Number is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <FormControlLabel
                            label="Block further Assignment"
                            control={
                                <Checkbox
                                    name="disableFrwd"
                                    checked={disableForwarding}
                                    onChange={handleDisableForwardingChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <FormControlLabel

                            label="Assign to organisation"
                            control={
                                <Checkbox
                                    name="assignForOrganisation"
                                    checked={assignForOrganisation}
                                    onChange={handleDisableAssignForOrganisation}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Valid from"
                                inputFormat="dd/MM/yyyy"
                                minDate={new Date()}
                                value={validFrom || new Date()}
                                onChange={(handleValidFromChange)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        //@ts-ignore
                        reset({
                            exam: undefined,
                            //name: '',
                        });
                        props.cancel();
                    }}
                    data-testid=""
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
            <Dialog
                open={showConfirmationDialog}
                onClose={cancelConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Voucher Addition"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Please confirm that you want to add ${numberOfVouchers} vouchers.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelConfirmation}>No</Button>
                    <Button onClick={confirmAddVouchers} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};
