import { TableCell, TableRow, Grid, Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { Email } from '../../models/Email';
import { StatusButton } from '../../customized-components/status-button/status-button';
import { emailStatusOptionsGetter } from '../../status-transition-rules/mail-transitions';
import StatusChangeInfo from '../../utils/StatusChangeInfo';

export interface EmailRowProps {
	emailData: Email;
	canChangeStatus: () => boolean;
	onStatusChange: (mailId: string, newState: string) => void;
	onMailReassignUserModalToggle: (emailId: string) => void;
	onMailReassignOrganizationModalToggle: (emailId: string) => void;
	reassignPossible: () => boolean;
	// onPrivacyChange: (emailWithUpdatedPrivacy: Email) => void;
}

export const EmailRow: FunctionComponent<EmailRowProps> = (props: EmailRowProps) => {

	const statusOptions = emailStatusOptionsGetter(props.emailData.state);

	const handleMenuItemClickStatus = (updateData: StatusChangeInfo) => {
		props.onStatusChange(props.emailData.id, updateData.newState);
	};


	//#region Privacy logic

	// const privacyOptions = [ 'Pseudonymized', 'Canceled' ];

	// const [ openPrivacy, setOpenPrivacy ] = React.useState(false);
	// const anchorRefPrivacy = React.useRef<HTMLDivElement>(null);
	// const [ selectedIndexPrivacy, setSelectedIndexPrivacy ] = React.useState(privacyOptions.indexOf(props.emailData.privacy));

	// const handleMenuItemClickPrivacy = (
	// 	event: React.MouseEvent<HTMLLIElement, MouseEvent>,
	// 	index: number,
	// ) => {
	// 	// setSelectedIndexPrivacy(index);
	// 	const email = {...props.emailData, privacy: privacyOptions[index] }
	// 	props.onPrivacyChange(email);
	// 	setOpenPrivacy(false);
	// };

	// const handleTogglePrivacy = () => {
	// 	setOpenPrivacy((prevOpen) => !prevOpen);
	// };
	// const handleClosePrivacy = (event: React.MouseEvent<Document, MouseEvent>) => {
	// 	if (anchorRefPrivacy.current && anchorRefPrivacy.current.contains(event.target as HTMLElement)) {
	// 		return;
	// 	}

	// 	setOpenPrivacy(false);
	// };

	//#endregion

	return (
		<TableRow key={props.emailData.email}>
			<TableCell component="th" scope="row">
				{props.emailData.email}
			</TableCell>
			<TableCell>
				<StatusButton
					enabled={() => props.canChangeStatus()}
					status={props.emailData.state}
					statusOptions={statusOptions}
					onStatusChange={handleMenuItemClickStatus}
				/>
			</TableCell>
			<TableCell>
				<Grid container spacing={1} alignItems={'center'}>
					<Grid item>
						{props.reassignPossible() && <Button variant="contained" color="primary" onClick={() => props.onMailReassignUserModalToggle(props.emailData.id)}>
							to user
						</Button>}
					</Grid>
					<Grid item>
						{props.reassignPossible() && <Button variant="contained" color="primary" onClick={() => props.onMailReassignOrganizationModalToggle(props.emailData.id)}>
							to organisation
						</Button>}
					</Grid>
				</Grid>
			</TableCell>

			{/* <TableCell>
			<ButtonGroup variant="contained" color="secondary" ref={anchorRefPrivacy} aria-label="split button">
					<Button>{props.emailData.privacy}</Button>
					<Button
						size="small"
						aria-controls={openPrivacy ? 'split-button-menu' : undefined}
						aria-expanded={openPrivacy ? 'true' : undefined}
						aria-label="select merge strategy"
						aria-haspopup="menu"
						onClick={handleTogglePrivacy}
					>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>
				<Popper style={{zIndex: 1000000}} open={openPrivacy} anchorEl={anchorRefPrivacy.current} role={undefined} transition disablePortal>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClosePrivacy}>
									<MenuList id="split-button-menu">
										{privacyOptions.map((option, index) => (
											<MenuItem
												key={option}
												disabled={index === selectedIndexPrivacy}
												selected={index === selectedIndexPrivacy}
												onClick={(event) => handleMenuItemClickPrivacy(event, index)}
											>
												{option}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
				</TableCell> */}
			{/* {props.emailData.privacy} */}
			{/* <FormControl fullWidth variant="outlined" size="small">
					<InputLabel id="usage">Usage</InputLabel>
					<Select
						labelId="usage"
						id="usage-select"
						value={props.emailData.status}
						onChange={() => {}}
						label="Usage"
					>
						<MenuItem value={"use"}>Cancel</MenuItem>
						<MenuItem value={"not use"}>Pseudonymize</MenuItem>
					</Select>
				</FormControl> */}
		</TableRow>
	);
};
