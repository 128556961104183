import { HeadCell } from 'components/molecules/ComplexTableHead';

export const organizationsHeaders: HeadCell[] = [
    { id: '', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: '', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'emails', numeric: false, label: 'Emails', disablePadding: false, disableSorting: true },
    { id: 'name', numeric: false, label: 'Name', disablePadding: false, disableSorting: false },
    { id: 'shortName', numeric: false, label: 'Short Name', disablePadding: false, disableSorting: false },
    { id: 'organizationUrl', numeric: false, label: 'Organization Url', disablePadding: false, disableSorting: false },
    { id: 'type', numeric: false, label: 'Type', disablePadding: false, disableSorting: false },
    { id: 'logo', numeric: false, label: 'Logo', disablePadding: false, disableSorting: true },
    { id: 'status', numeric: false, label: 'Status', disablePadding: false, disableSorting: true },
];
