import React, { FC, useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { authenticationService } from '../../services/authentication/authentication.service';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { BaseLandingPage } from '../../customized-components';
import StyledLink from 'components/atoms/StyledLink';
import { AlertContext } from '../../App';

export interface NewPasswordFormModel {
    password: string;
    retypePassword: string;
}

export interface NewPasswordProps {
    history: any;
    location: any;
}

export const NewPasswordForm: FC<NewPasswordProps> = (props: NewPasswordProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const [paswordResetDone, resetPassword] = useState<boolean>(false);
    const {
        control,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<NewPasswordFormModel>();
    const search = useLocation().search;

    const onSubmit = (data: NewPasswordFormModel) => {
        const code = new URLSearchParams(search).get('code');
        const email = new URLSearchParams(search).get('email');
        if (code && email && data.password && data.retypePassword && data.password === data.retypePassword) {
            authenticationService.newPassword(email as string, code as string, data.password).then((result: any) => {
                if (result) {
                    resetPassword(true);
                }
            })
                .catch((error) =>
                    handleAlert(
                        error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                        'error'
                    )
                );
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem('user') as any) {
            props.history.push('/');
        }

    }, []);

    return (
        <BaseLandingPage>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Controller
                            name="password"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="password"
                                    id="password"
                                    label="Password"
                                    size="small"
                                    type="password"
                                    variant="outlined"
                                    error={errors.password ? true : false}
                                    helperText={errors.password && errors.password.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Password is required field',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="retypePassword"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="retypePassword"
                                    id="retypePassword"
                                    label="Retype Password"
                                    size="small"
                                    type="password"
                                    variant="outlined"
                                    error={errors.retypePassword ? true : false}
                                    helperText={errors.retypePassword && errors.retypePassword.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Password is required field!',
                                validate: {
                                    matchesPreviousPassword: (value) => {
                                        const { password } = getValues();
                                        return password === value || 'Passwords must match!';
                                    },
                                },
                            }}
                        />
                    </Grid>
                    {paswordResetDone && (
                        <Grid item xs={12}>
                            <p>
                                Your new password is set. Go to{' '}
                                <StyledLink data-testid="navToLoginFromNewPassword" to="/login">
                                    Login
                                </StyledLink>
                            </p>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button data-testid="resetPassword" color="primary" fullWidth type="submit" variant="contained">
                            Reset Password
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </BaseLandingPage>
    );
};
