export const personStatusTransitions = new Map<string, string[]>([
    ['incomplete', ['confirmed', 'blocked']],
    ['blocked', ['pseudoanonymized', 'unblocked']],
    ['confirmed', ['blocked', 'verified', 'incomplete']],
    ['verified', ['blocked']],
    ['pseudoanonymized', ['incomplete', 'confirmed', 'verified', 'blocked']],
]);

export const allPersonStates = ['incomplete', 'confirmed', 'blocked', 'pseudoanonymized', 'verified'];

export const personStatusOptionsGetter = (currentStatus: string): string[] => {
    return personStatusTransitions.get(currentStatus) as string[];
};
