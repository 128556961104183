import { FunctionComponent } from 'react';

export const LoadingIndicator: FunctionComponent = () => {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                justifyContent: 'center',
                paddingTop: '20em',
                backgroundColor: 'transparent',
            }}
        >
            <h2>Loading...</h2>
        </div>
    );
};
