import { useState, useEffect, useContext } from 'react';
import { AlertContext } from 'App';
import { authenticationService } from 'services/authentication/authentication.service';
import { Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';

const MaintenancePage = () => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [maintenaceStatus, setMaintenaceStatus] = useState<boolean>(false);


    const getMaintenaceStatus = () => {
        setLoading(true)
        authenticationService
            .getMaintenaceStatus()
            .then((res) => {
                setMaintenaceStatus(res);
                console.log('maintenaceStatus', maintenaceStatus)
                setLoading(false);
            })
            .catch((error) =>
                handleAlert(
                    'Ups! Error occured while getting maintenance status',
                    'error'
                )
            );
    };
    const onHandleMaintenaceStatusChange = async (event) => {
        try {
            let updated = await authenticationService.setMaintenaceStatus({ status: event.target.checked.toString() })
            if (updated) {
                setMaintenaceStatus(updated.status)
            } else {
                handleAlert(
                    'Ups! Error occured while updateing maintenance status',
                    'error'
                )
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getMaintenaceStatus();
    }, []);

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Operation</b></TableCell>
                            <TableCell><b>Description</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                            <TableCell><b>Toggle operation</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>
                                Maintenance mode
                            </TableCell>
                            <TableCell>Here you can toggle Client Front End maintenance mode</TableCell>
                            <TableCell>{maintenaceStatus === true ? "ACTIVE" : "INACTIVE"}</TableCell>
                            <TableCell>
                                <Switch checked={maintenaceStatus} onChange={onHandleMaintenaceStatusChange} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default MaintenancePage;
