export const examEventBookingTransitions = new Map<string, string[]>([
    ['booked', ['canceled']],
    ['prepared', ['canceled']],
    ['taken', []],
    ['canceled', []],
]);

export const allBookingStates = ['booked', 'prepared', 'canceled'];

export const bookingStatusOptionsGetter = (currentStatus?: string): string[] => {
    return examEventBookingTransitions.get(currentStatus ? currentStatus : '') as string[];
};
