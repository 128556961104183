import { useState, useEffect, Fragment, useContext, useRef } from 'react';
import { FunctionComponent } from 'react';
import ComplexTable from 'components/organisms/ComplexTable';
import { CreateOrganization, Organization } from '../../models/Organization';
import { OrganizationRow } from './organization-row';
import { TableAction } from 'models/table-models/TableAction';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ModalDialog } from '../../customized-components';
import { OrganizationForm } from '../organization-form/organization-form';
import { AddOrganizationForm } from '../organization-form/add-organization-form';
import { organizationsService } from '../../services/organizations/organizations.service';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { organizationsHeaders } from '../../models/table-headers/organizations-headers';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import { UserContext } from '../../context/UserContext';
import { authenticationService } from '../../services/authentication/authentication.service';
import { useHistory } from 'react-router-dom';
import { AlertContext } from '../../App';
import { defaultOrganizationsFilters } from '../../models/table-filters.ts/organizations-table-filters';

/* eslint-disable-next-line */
export interface OrganizationsProps { }

export const Organizations: FunctionComponent<OrganizationsProps> = (props: OrganizationsProps) => {
    const defaultRequest = { order: 'asc', orderBy: 'name', page: 0, rowsPerPage: 10, filters: defaultOrganizationsFilters() };
    const mounted = useRef(false);

    const [organizations, setOrganizations] = useState<TablePageSortFilter>();
    const [dialogOrganizationUpdate, setDialogOrganizationUpdate] = useState<boolean>(false);
    const [dialogOrganizationCreate, setDialogOrganizationCreate] = useState<boolean>(false);
    const [currentOrganization, setCurrentOrganization] = useState<Organization | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);
    const { userData, setUserData } = useContext(UserContext);

    useEffect(() => {
        mounted.current = true;
        if (!userData) {
            authenticationService.getLoggedInUserData().then((res: any) => {
                if (mounted.current) {
                    //@ts-ignore
                    setUserData(res);
                }
                loadTable(defaultRequest);
            });
        } else {
            loadTable(defaultRequest);
        }

        return () => {
            mounted.current = false;
        }

    }, []);

    const loadTable = (data: PaginationSortingFilteringRequest) => {
        setLoading(true)
        organizationsService
            .getOrganizationsWithPaginationAndFilter(data)
            .then((res) => {
                if (mounted.current) {
                    setOrganizations(res);
                    setLoading(false);
                }
            })
            .catch((error) =>
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                )
            );
    };
    const toggleUpdateDialog = (id: string) => {
        //primi ceo row
        setCurrentOrganization(organizations?.results.find((row) => row.id === id));
        setDialogOrganizationUpdate(!dialogOrganizationUpdate);
    };

    const handleToggleCreateDialog = () => {
        setDialogOrganizationCreate(!dialogOrganizationCreate);
    };

    const updateOrganizationStatus = async (orgId: string, newState: string) => {
        try {
            let updated = await organizationsService.updateOrganizationStatus(orgId, newState);
            if (updated) {
                updateOrganization(orgId, updated.organisation)
            } else {
                console.log("error occured while updating exam ", updated);
            }
        }
        catch (error) {

        }
    };
    const updateOrganization = (id: String, organization: Organization) => {
        const newOrganizations = [...(organizations?.results as Organization[])];
        const index = newOrganizations.findIndex((org) => org.id === id);
        newOrganizations[index].name = organization.name;
        newOrganizations[index].shortName = organization.shortName;
        newOrganizations[index].logo = organization.logo;
        newOrganizations[index].status = organization.status;
        newOrganizations[index].type = organization.type;
        //@ts-ignore
        setOrganizations({ results: newOrganizations, ...organizations });
    };

    const addOrganization = (data: CreateOrganization) => {
        const newOrg = {
            id: '12345',
            name: data.name,
            shortName: data.shortName,
            type: data.type,
            logo: data.logo,
            emails: [{ id: '1', email: data.email, state: 'active' }],
            users: [],
            status: true,
            organizationUrl: data.organizationUrl,
            details: { oldID: "1237164837" }
        };
        const newOrganizations = [...(organizations?.results as Organization[])];
        newOrganizations.push(newOrg);
        //@ts-ignore
        setOrganizations({ ...organizations, results: newOrganizations });
        loadTable(defaultRequest);
    };

    const openOrganizationPage = (id: string) => {
        history.push(`/organizations/${id}`, { id });
    };

    const actions: TableAction[] = [
        {
            title: 'Edit',
            icon: EditIcon,
            onClick: toggleUpdateDialog,
        },
        {
            title: 'View',
            icon: VisibilityIcon,
            onClick: openOrganizationPage,
        },
    ];

    return (
        <Fragment>
            <ComplexTable
                tableData={organizations as TablePageSortFilter}
                headerCells={organizationsHeaders}
                title="Organizations"
                addAction={handleToggleCreateDialog}
                addIcon={AddBoxIcon}
                updateTableData={loadTable}
                loading={loading}
            >
                {organizations
                    ? organizations.results.map((r) => <OrganizationRow key={r.id} row={r} actions={actions} />)
                    : null}
            </ComplexTable>
            {dialogOrganizationUpdate ? (
                <ModalDialog
                    dialogIsOpen={dialogOrganizationUpdate}
                    //@ts-ignore
                    setDialogOpen={toggleUpdateDialog}
                    title="Edit Organization"
                >
                    <OrganizationForm
                        organization={currentOrganization as Organization}
                        updateOrganization={updateOrganization}
                        cancel={() => setDialogOrganizationUpdate(false)}
                        onStatusChange={updateOrganizationStatus}
                    />
                </ModalDialog>
            ) : null}
            {dialogOrganizationCreate ? (
                <ModalDialog
                    dialogIsOpen={dialogOrganizationCreate}
                    //@ts-ignore
                    setDialogOpen={handleToggleCreateDialog}
                    title="Add New Organization"
                >
                    <AddOrganizationForm
                        addOrganization={addOrganization}
                        cancel={() => setDialogOrganizationCreate(false)}
                    />
                </ModalDialog>
            ) : null}

        </Fragment>
    );
};
