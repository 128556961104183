import { TableCell, TableRow, Grid, Card, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { Fragment, FunctionComponent, useState, useContext, useRef } from 'react';
import { useEffect } from 'react';
import { examsService } from '../../services/exams/exams.service';
import ComplexTable from 'components/organisms/ComplexTable';
import { useParams } from 'react-router-dom';
import { AlertContext } from '../../App';
import { ExamEvent } from '../../models/ExamEvent';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { examEventUsers } from '../../models/table-headers/exam-event-details-headers';
import { examEventExams } from '../../models/table-headers/exam-event-details-headers';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import { ExamEventUsersRow } from './exam-event-users-row';
import { participantCardStyles } from '../participant-details/participant-base-data-card.css';
import { StatusButton } from '../../customized-components/status-button/status-button';
import { examEventStatusOptionsGetter } from '../../status-transition-rules/exam-event-transitions';
import StatusChangeInfo from '../../utils/StatusChangeInfo';
import { examEventService } from '../../services/examevents/exam-event.service';
import { ParticipantRow } from '../participants/participant-row';
import { participantsHeadersMultiSelect } from '../../models/table-headers/participants-headers';
import { participantsService } from '../../services/participants/participants.service';
import { defaultParticipantsFilters } from '../../models/table-filters.ts/participants-table-filters';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { bookingsService } from '../../services/bookings/bookings.service';


export interface ParticipantDetailsProps {
	participantId?: string;
}

export const ExamEventDetails: FunctionComponent<ParticipantDetailsProps> = (props: ParticipantDetailsProps) => {
	//@ts-ignore
	const { id } = useParams();
	const mounted = useRef(false);

	const classes = participantCardStyles();


	const defaultRequest: PaginationSortingFilteringRequest = {
		order: 'desc',
		orderBy: 'createdAt',
		page: 0,
		rowsPerPage: 10,
		filters: defaultParticipantsFilters
	};

	const [participants, setParticipants] = useState<TablePageSortFilter>({
		...defaultRequest,
		total: 10,
		results: [],
	});
	const [selectedParticipants, setSelectedParticipants] = useState<string[]>([])
	const [paginationData, setPaginationData] = useState<PaginationSortingFilteringRequest>(defaultRequest);
	const [examEvent, setExamEvent] = useState<ExamEvent>();
	const [loading, setLoading] = useState<boolean>(false);


	//#region CONTEXT
	//@ts-ignore
	const { handleAlert } = useContext(AlertContext);
	//#endregion

	const statusOptions = examEventStatusOptionsGetter(examEvent?.state);

	//#region FETCH DATA
	const getExamEventData = (examEventId: string) => {
		setLoading(true)
		examsService
			.getExamById(examEventId)
			.then((res) => {
				setExamEvent(res);
				setLoading(false);
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);
	};

	const loadParticipants = (data: PaginationSortingFilteringRequest) => {
		setLoading(true)
		participantsService
			.getParticipantsWithPaginationAndFilter(data)
			.then((res) => {
				if (mounted.current) {
					setParticipants(res);
					setLoading(false)
				}
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);
	};

	const handleMenuItemClickStatus = (updateData: StatusChangeInfo) => {
		// props.actions.onExamEventStatusChange.onClick(props.examEventrow.id, updateData.newState);
		setLoading(true)
		examEventService
			//@ts-ignore
			.updateEventState(examEvent?.id, updateData.newState)
			.then((res) => {
				handleAlert('Exam event status updated successfully!', 'success');
				setLoading(false);
				resolveLoad();
			})
			.catch((error) => {
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
				setLoading(false)
			});
	};

	const bookMultipleUsersOnExamEvent = () => {
		if (selectedParticipants.length > 0) {
			bookingsService.bookUsersOnExamEvent(selectedParticipants, id).then((res) => {
				handleAlert('Users booked successfully!', 'success');
				setSelectedParticipants([]);
				getExamEventData(id as string);
			}).catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);
		}
	}

	const trackPagination = (newValue: PaginationSortingFilteringRequest) => {
		setPaginationData(newValue)
	};


	const resolveLoad = () => {
		if (id) {
			getExamEventData(id as string)
			loadParticipants(paginationData)
		}
	}

	const multiSelectData: any = (key: string) => {
		switch (key) {
			case "row":
				const rowData: any = {
					selectedParticipants,
					onChange: onSelectParticipantChange
				}
				return rowData;

			case "table":
				const tableData: any = {
					numSelected: selectedParticipants.length,
					rowCount: participants.results.length,
					onSelectAll: onSelectAllParticipantChange
				}
				return tableData;

			default:
				break;
		}

	}

	const onSelectParticipantChange = (id: string, checked: boolean) => {
		checked ? setSelectedParticipants([...selectedParticipants, id]) : unselectParticipant(id);
	}

	const onSelectAllParticipantChange = (checked: boolean) => {
		if (checked) {
			const newSelectedParticipants = participants.results.map(single => single.id)
			setSelectedParticipants(newSelectedParticipants);
		} else setSelectedParticipants([])
	}

	const unselectParticipant = (id: string) => {
		let newSelectedParticipants = [...selectedParticipants];
		let unselectedParticipant = newSelectedParticipants.indexOf(id);
		if (unselectedParticipant !== -1) {
			newSelectedParticipants.splice(unselectedParticipant, 1)
		}
		setSelectedParticipants(newSelectedParticipants);
	}

	useEffect(() => {
		mounted.current = true;
		resolveLoad();

		return () => {
			mounted.current = false;
		}

	}, []);
	//#endregion

	const handleRemoveParticipant = (userId) => {
		examEventService.removeExamEventParticipant(id, userId).then((res) => {
			handleAlert('Participant removed successfully!', 'success');
			setLoading(false);
			getExamEventData(id);
		}).catch((error) => {
			handleAlert(error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error');
			setLoading(false);
		});
	}

	return (
		<Fragment>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Card className={classes.root} variant="outlined">
						<Grid container justifyContent='space-between' alignItems='center'>
							<Grid item>
								<Typography variant="h5" component="div">
									{examEvent?.name}
								</Typography>
							</Grid>
							<Grid item>
								<StatusButton
									enabled={() => true}
									status={examEvent?.state}
									statusOptions={statusOptions}
									onStatusChange={handleMenuItemClickStatus}
								/>
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<ComplexTable
						// tableData={participants as TablePageSortFilter}
						headerCells={examEventUsers}
						title="Participants"
						addAction={() => { }}
						addIcon={undefined}
						loading={loading}
					>
						{examEvent?.users ?
							examEvent.users.map((single) =>
								<ExamEventUsersRow
									key={single.id}
									row={single}
									examEvent={examEvent as ExamEvent}
									handleRemoveParticipant={handleRemoveParticipant}
								/>)
							: null}
					</ComplexTable>
				</Grid >
				<Grid item xs={12} md={6}>
					<ComplexTable
						// tableData={participants as TablePageSortFilter}
						headerCells={examEventExams}
						title="Exams"
						addAction={() => { }}
						addIcon={undefined}
						loading={loading}
					>
						{examEvent?.exams ?
							examEvent.exams.map((single) =>
								<TableRow key={single.id}>
									<TableCell>
										<Tooltip title={single.id}>
											<span>{single.externalName}</span>
										</Tooltip>
									</TableCell>
									<TableCell>{single.details.sampleSet ? "True" : "False"}</TableCell>
									<TableCell>{single.details.plannedDuration}</TableCell>
								</TableRow>)
							: null}
					</ComplexTable>
				</Grid >
				<Grid item xs={12}>
					<ComplexTable
						tableData={participants as TablePageSortFilter}
						headerCells={participantsHeadersMultiSelect}
						title="Persons"
						addAction={bookMultipleUsersOnExamEvent}
						addIcon={selectedParticipants.length > 0 ? LocalLibraryIcon : undefined}
						updateTableData={loadParticipants}
						onPaginationUpdate={trackPagination}
						multiSelect={multiSelectData("table")}
						loading={loading}
					>
						{participants?.results
							? participants.results.map((r) => <ParticipantRow key={r.id} row={r} multiSelect={multiSelectData("row")} actions={{}} />)
							: null}
					</ComplexTable>
				</Grid>
			</Grid>
		</Fragment >
	);
};
