import { CreateOrganization, Organization } from '../../models/Organization';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import { callAxios } from '../../axios-wrapper/call-axios';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import UrlFilterConstructor from '../../transformators/UrlFilterConstructor';

// const getAllOrganizations = (): Promise<{
//   message: string;
//   organizations: Organization[];
// }> => {
//   return axios
//     .get(API_URL + "/organisation/", { headers: authHeader() })
//     .then((res) => {
//       if (res.data.results) {
//         return res.data.results;
//       } else {
//         throw new Error("Loading organizations failed");
//       }
//     });
// };

const getOrganization = (orgId: string): Promise<Organization> => {
    return callAxios.get(`/organisations/${orgId}`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading user failed');
        }
    });
};

const getExamEventsPerOrganization = (orgId: string): Promise<any> => {
    return callAxios.get(`/organisations/${orgId}/examEvents`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading exam events per organization');
        }
    });
};

const updateOrganization = (organization: Organization): Promise<void> => {

    const requestBody = {
        name: organization.name,
        organizationUrl: organization.organizationUrl,
        shortName: organization.shortName,
        type: organization.type,
        //logo: organization.logo ? organization.logo : 'orgLogo',
        logo: organization.logo,
    };
    return callAxios.patch(`/organisations/${organization.id}`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating organization failed');
        }
    });
};

const updateOrganizationStatus = (orgId: string, newState: string): Promise<any> => {
    const requestBody = {
        id: orgId,
        status: newState,
    };
    return callAxios.patch(`/organisations/${orgId}/updateStatus`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating organization status failed');
        }
    });
};

const registerOrganization = (organization: CreateOrganization): Promise<void> => {
    const requestBody = {
        name: organization.name,
        shortName: organization.shortName,
        type: organization.type,
        logo: organization.logo,
        email: organization.email,
    };
    return callAxios.post(`/organisations/register`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Registering organization failed');
        }
    });
};

const addMember = (orgId: string, req: any): Promise<Organization> => {
    return callAxios.post(`/organisations/${orgId}/add`, req).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading user failed');
        }
    });
};
const removeMember = (relationID: string): Promise<Organization> => {
    return callAxios.remove(`/organisations/${relationID}/remove`).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Loading user failed');
        }
    });
};

const getOrganizationsWithPaginationAndFilter = (
    r: PaginationSortingFilteringRequest
): Promise<TablePageSortFilter> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(
            `/organisations/?orderBy=${r.orderBy.toString()}&sortOrder=${r.order}&page=${r.page + 1}&items=${r.rowsPerPage
            }${filter}`
        )
        .then((res) => {
            if (res.status === 200) {
                return { ...r, results: res.data.rows, total: res.data.total };
            } else {
                throw new Error('Getting Organisations failed');
            }
        });
};

const getAtoWithSearch = (searchTerm: string): Promise<any> => {
    return callAxios
        .get(`/organisations/?orderBy=name&sortOrder=desc&page=1&items=50&name=${searchTerm}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.rows;
            } else {
                throw new Error('Getting Organisations failed');
            }
        });
};
const getAtoWithSearchInStatusTrue = (searchTerm: string): Promise<any> => {
    return callAxios
        .get(`/organisations/?status=true&orderBy=name&sortOrder=desc&page=1&items=50&name=${searchTerm}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.rows;
            } else {
                throw new Error('Getting Organisations failed');
            }
        });
};

const getAtoWithSearchInStatusTrueAddedTypes = (searchTerm: string): Promise<any> => {
    return callAxios
        .get(`/organisations/?status=true&orderBy=name&sortOrder=desc&page=1&items=50&name=${searchTerm}&types=admin&types=certification`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.rows;
            } else {
                throw new Error('Getting Organisations failed');
            }
        });
};

const addNewMail = (data: any, id: string): Promise<any> => {
    return callAxios.post(`/organisations/${id}/addmail`, data).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Getting participants failed');
        }
    });
};

export const organizationsService = {
    // getAllOrganizations,
    updateOrganization,
    updateOrganizationStatus,
    registerOrganization,
    getOrganizationsWithPaginationAndFilter,
    getOrganization,
    addMember,
    removeMember,
    getAtoWithSearch,
    getAtoWithSearchInStatusTrue,
    getAtoWithSearchInStatusTrueAddedTypes,
    addNewMail,
    getExamEventsPerOrganization
};
