import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from 'customized-components';
import { FunctionComponent, useState, useContext } from 'react';
import { TextField } from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { AlertContext } from 'App';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import { badgesService } from 'services/badges/badgesService';

export interface VoucherFormProps {
    cancel: () => void;
    onAssignmentCreated: () => void;
    userId: string;
    connectedBadgeClass: any;
    certificateClass: any;
    userCertificate: string;
}

export const BadgeToCertificateForm: FunctionComponent<VoucherFormProps> = (props: VoucherFormProps) => {
    //@ts-ignore
    const { handleAlert } = useContext(AlertContext);

    //@ts-ignore
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<any>({
        defaultValues: {
            badgeClassId: props.connectedBadgeClass.data[0].id,
            certificateClassId: props.certificateClass.id,
            userId: props.userId,
            userCertificate: props.userCertificate,
        },
    });
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = (dataObject: any) => {
        setLoading(true);

        console.log(dataObject);

        badgesService
            .assignBadgeToUserCertificate(dataObject)
            .then((res) => {
                handleAlert('Badge created successfully!', 'success');
                setLoading(false);
                props.cancel();
                props.onAssignmentCreated();
            })
            .catch((error) => {
                handleAlert(
                    error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
                    'error'
                );
                setLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={5} sm={5}>
                        <Controller
                            name="badgeClassId"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="invoiceNumber-test"
                                    id="badgeClassId"
                                    label="Badge ClassId"
                                    size="small"
                                    variant="outlined"
                                    disabled={true}
                                    error={errors.badgeClassId ? true : false}
                                    helperText={errors.badgeClassId && errors.badgeClassId.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Badge ClassId is required field',
                            }}
                        />
                        {props.connectedBadgeClass.data[0].title}
                    </Grid>
                    <Grid item xs={2} sm={2} style={{ textAlign: 'center' }}>
                        to
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <Controller
                            name="certificateClassId"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    data-testid="invoiceNumber-test"
                                    id="certificateClassId"
                                    label="Certificate ClassId"
                                    size="small"
                                    variant="outlined"
                                    disabled={true}
                                    error={errors.certificateClassId ? true : false}
                                    helperText={errors.certificateClassId && errors.certificateClassId.message}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Certificate ClassId is required field',
                            }}
                        />
                        {props.certificateClass.name}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWrapper>
                    <Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
                        Save
                    </Button>
                    {loading && <CircularProgress isCentered thickness={5} size={24} />}
                </ButtonWrapper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        //@ts-ignore
                        reset({
                            exam: undefined,
                            //name: '',
                        });
                        props.cancel();
                    }}
                    data-testid=""
                    color="primary"
                    type="submit"
                    variant="contained"
                >
                    Cancel
                </Button>
            </DialogActions>
        </form>
    );
};
