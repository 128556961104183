import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '../../../customized-components';
import { Fragment, FunctionComponent, useEffect, useState, useContext } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import { certificatesService } from '../../../services/certificates/certificates.service';
import { AlertContext } from '../../../App';
import { Certificate } from '../../../models/Certificate';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';

export interface ComputedCertificateFormProps {
	cancel: () => void;
	onCertificateCreate: () => void;
}

export const ComputedCertificateForm: FunctionComponent<ComputedCertificateFormProps> = (props: ComputedCertificateFormProps) => {

	const [loading, setLoading] = useState<boolean>(false);
	const [certificates, setCertificates] = useState<Certificate[]>([]);
	const [layouts, setLayouts] = useState<string[]>([]);
	const [autoIssue, setAutoIssue] = useState<boolean>(false);
	const [showFooter, setShowFooter] = useState<boolean>(false);
	const [showPersonalData, setShowPersonalData] = useState<boolean>(false);

	//@ts-ignore
	const { handleAlert } = useContext(AlertContext);

	let preRequisiteExams: string[] = [];

	//@ts-ignore
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		defaultValues: {
			computed: true,
			preRequisiteExams: preRequisiteExams,
			certLayout: "",
			backgroundID: "",
			name: "",
			startText: "",
			middleText: "",
			bottomText: "",
			autoIssue: false,
			showFooter: false,
			showPersonalData: false,
			status: ""
		},
	});


	useEffect(() => {
		certificatesService
			.getCertificates()
			.then((res) => {
				setCertificates(res);
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);

		certificatesService.getCertificateLayouts().then((res) => {
			setLayouts(res)
		}).catch((error) => {
			handleAlert(
				error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
				'error'
			)
		});

	}, []);

	const onSubmit = (data: any) => {
		setLoading(true);
		data.autoIssue = autoIssue;
		data.showFooter = showFooter;
		data.showPersonalData = showPersonalData;
		certificatesService.createComputedCertificate(data).then((res) => {
			handleAlert('Computed certificate created successfully!', 'success');
			setLoading(false);
			props.cancel();
			props.onCertificateCreate();
		}).catch((error) =>
			handleAlert(error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error')
		);
	};

	const renderExamNames = (selected: string[]): string[] => {
		let names: string[] = [];
		selected.forEach((id) => {
			const certificate = certificates.find((e) => e.id === id);
			names.push(certificate?.name as string);
		});
		return names;
	};

	const handleCheckChange = (type: string, event: React.ChangeEvent<HTMLInputElement>) => {
		switch (type) {
			case 'auto':
				setAutoIssue(event.target.checked);
				break;
			case 'footer':
				setShowFooter(event.target.checked);
				break;
			case 'data':
				setShowPersonalData(event.target.checked);
				break;
			default:
				break;
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" size="small" fullWidth>
							<Controller
								name="preRequisiteExams"
								render={({ field }) => (
									<Fragment>
										<InputLabel htmlFor="preRequisiteExams">Certificates</InputLabel>
										<Select
											{...field}
											data-testid="test-exams"
											id="preRequisiteExams"
											label="Certificates"
											multiple
											renderValue={(selected) =>
												(renderExamNames(selected as string[]) as string[]).join(', ')
											}
											error={errors.preRequisiteExams ? true : false}
											onChange={(value) => {
												if (value.target.value)
													//@ts-ignore
													setValue('preRequisiteExams', value.target.value);
											}}
										>
											{certificates.length >= 1 ?
												certificates.map((certificate) => (
													<MenuItem key={certificate.id} value={certificate.id}>
														<Checkbox checked={field.value.indexOf(certificate.id) > -1} />
														<ListItemText primary={certificate.name} />
													</MenuItem>
												))
												:
												<MenuItem disabled={true}>No data to show</MenuItem>
											}
										</Select>
										{/* <FormHelperText style={{ color: errors.preRequisiteExams ? 'red' : 'grey' }}>
											{errors.preRequisiteExams && 'Exams are required field'}
										</FormHelperText> */}
									</Fragment>
								)}
								control={control}
								defaultValue={[]}
							// rules={{
							// 	required: 'Exams are required field',
							// }}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="name"
							render={({ field }) => (
								<TextField
									fullWidth
									{...field}
									data-testid="firstName"
									id="name"
									size="small"
									label="Name"
									variant="outlined"
									error={errors.name ? true : false}
									helperText={errors.name && errors.name.message}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'Name is required field',
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="certLayout"
							render={({ field }) => (
								<FormControl variant="outlined" style={{ minWidth: 100 + '%' }} size="small">
									<InputLabel id="select-label-certLayout">Certificate Layout</InputLabel>
									<Select
										{...field}
										fullWidth
										data-testid="test-certLayout"
										labelId="select-label-certLayout"
										id="certLayout"
										label="Certificate Layout"
										error={errors.certLayout ? true : false}
										defaultValue=""
										onChange={(value) => {
											if (value.target.value)
												//@ts-ignore
												setValue('certLayout', value.target.value);
										}}
									>
										{layouts?.map((single, index) => (
											<MenuItem value={single} key={index}>{single}</MenuItem>
										))}
									</Select>
									<FormHelperText style={{ color: errors.certLayout ? 'red' : 'grey' }}>
										{errors.certLayout && errors.certLayout.message}
									</FormHelperText>
								</FormControl>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'Certificate layout is required field',
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" size="small" fullWidth>
							<Controller
								name="backgroundID"
								render={({ field }) => (
									// <TextField
									// 	fullWidth
									// 	{...field}
									// 	data-testid="backgroundID"
									// 	id="backgroundID"
									// 	size="small"
									// 	label="Background ID"
									// 	variant="outlined"
									// 	error={errors.backgroundID ? true : false}
									// 	helperText={errors.backgroundID && errors.backgroundID.message}
									// />
									<>
										<InputLabel htmlFor="backgroundID">Background ID</InputLabel>
										<Select
											{...field}
											data-testid="backgroundID"
											id="backgroundID"
											label="Background ID"
											error={!!errors.backgroundID}
											onChange={(event) => field.onChange(event.target.value)}
										>
											<MenuItem value={"1"}>1 - ICO</MenuItem>
											<MenuItem value={"2"}>2 - ICO / exam certificate</MenuItem>
											<MenuItem value={"7"}>7 - ITEMO / role certificate</MenuItem>
											<MenuItem value={"8"}>8 - ITEMO</MenuItem>
											<MenuItem value={"14"}>14 - IT Sicherheitscluster</MenuItem>
										</Select>
										<FormHelperText style={{ color: errors.backgroundID ? 'red' : 'grey' }}>
											{errors.backgroundID && errors.backgroundID.message}
										</FormHelperText>
									</>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: 'Background ID is required field',
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="startText"
							render={({ field }) => (
								<TextField
									fullWidth
									{...field}
									data-testid="startText-test"
									id="startText"
									label="Start text"
									size="small"
									variant="outlined"
									multiline
									rows={5}
									error={errors.startText ? true : false}
									helperText={errors.startText && errors.startText.message}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'Start text is required field',

							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="middleText"
							render={({ field }) => (
								<TextField
									fullWidth
									{...field}
									data-testid="middleText-test"
									id="middleText"
									label="Middle text"
									size="small"
									variant="outlined"
									multiline
									rows={5}
									error={errors.middleText ? true : false}
									helperText={errors.middleText && errors.middleText.message}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'Middle text is required field'
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="bottomText"
							render={({ field }) => (
								<TextField
									fullWidth
									{...field}
									data-testid="bottomText-test"
									id="bottomText"
									label="Bottom text"
									size="small"
									variant="outlined"
									multiline
									rows={5}
									error={errors.bottomText ? true : false}
									helperText={errors.bottomText && errors.bottomText.message}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'Bottom text is required field'
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" size="small" fullWidth>
							<Controller
								name="status"
								render={({ field }) => (
									<Fragment>
										<InputLabel htmlFor="status">Status</InputLabel>
										<Select
											{...field}
											data-testid="test-status"
											id="status"
											label="status"
											error={errors.status ? true : false}
											onChange={(event) => field.onChange(event.target.value)}
										>
											<MenuItem value={'active'}>Active</MenuItem>
											<MenuItem value={'inactive'}>Inactive</MenuItem>
											<MenuItem value={'new'}>New</MenuItem>
										</Select>
										<FormHelperText style={{ color: errors.status ? 'red' : 'grey' }}>
											{errors.status && errors.status.message}
										</FormHelperText>
									</Fragment>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: 'Status is required field',
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Grid container justifyContent='space-around'>
							<FormControlLabel
								control={<Checkbox checked={autoIssue} onChange={(e) => handleCheckChange('auto', e)} name="jason" />}
								label="Auto Issue"
							/>
							<FormControlLabel
								control={<Checkbox checked={showFooter} onChange={(e) => handleCheckChange('footer', e)} name="jason" />}
								label="Show Footer"
							/>
							<FormControlLabel
								control={<Checkbox checked={showPersonalData} onChange={(e) => handleCheckChange('data', e)} name="jason" />}
								label="Show Personal Data"
							/>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<ButtonWrapper>
					<Button disabled={loading} data-testid="savenewparticipant" color="primary" type="submit" variant="contained">
						Save
					</Button>
					{loading && <CircularProgress isCentered thickness={5} size={24} />}
				</ButtonWrapper>
				<Button
					disabled={loading}
					onClick={() => {
						reset();
						props.cancel();
					}}
					data-testid="cancelnewparticipant"
					color="primary"
					type="submit"
					variant="contained"
				>
					Cancel
				</Button>
			</DialogActions>
		</form>
	);
};
