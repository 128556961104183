import { TableCell, TableRow, Link, Tooltip, IconButton, Grid, Typography, Button } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { CertificateIssuing } from 'models/Certificate';
import { StatusButton } from 'customized-components/status-button/status-button';
import { certIssueStatusOptionsGetter } from 'status-transition-rules/certificate-issuing.transitions';
import StatusChangeInfo from '../../utils/StatusChangeInfo';
import { convert } from 'dateConverter';
import GetAppIcon from '@mui/icons-material/GetApp';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useState } from 'react';
import { ModalDialog } from 'customized-components';
import { BadgeToCertificateForm } from './add-badge-to-certificate-form';

export interface CertificateIssuingRowProps {
    certificate: CertificateIssuing;
    onCertificateStatusChange: (updatedState: string, id: string) => void;
    loading?: boolean;
    isComplete: boolean;
    handleSelectedCertificate: (certificate: CertificateIssuing) => void;
    handleToggleCreateAssertionDialog: () => void;
    handlerOnAssignmentCreated: () => void;
}

const CertificateRow: FunctionComponent<CertificateIssuingRowProps> = (props: CertificateIssuingRowProps) => {
    const [badgeDialog, setBadgeDialog] = useState<boolean>(false);
    const [badgeDialogData, setBadgeDialogData] = useState<any>(false);

    const checkEnabled = () => {
        // if (props.certificate.details.status === 'can_issue')
        //     return true
        // else return false
        return statusOptions?.length > 0 && props.isComplete;
    };
    const statusOptions = certIssueStatusOptionsGetter(props.certificate.details.status);

    const handleMenuItemClickStatus = (updateData: StatusChangeInfo) => {
        props.onCertificateStatusChange(updateData.newState, props.certificate.id);
    };

    const onDownloadBadge = (image) => {
        fetch(image).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = image;
                a.click();
            });
        });
    };

    const handleClickBadgeDialogData = useMemo(
        () => (badgeData, status) => {
            setBadgeDialog(status);
            setBadgeDialogData(badgeData);
        },
        []
    );

    const addButtonToIssueCertOrBadgeInfo = (badgeClassObject: any) => {
        if (badgeClassObject?.details?.badgeClass[0]?.id) {
            return (
                <span>
                    {props.certificate?.details?.badgeClass[0]?.title ?? ''}{' '}
                    {props.certificate?.details?.badgeClass[0]?.status
                        ? '(' + props.certificate?.details?.badgeClass[0]?.status + ')'
                        : ''}
                </span>
            );
        } else if (
            badgeClassObject?.details?.connectedBadgeClass?.status === true &&
            badgeClassObject.details.status === 'issued'
        ) {
            return (
                <Button
                    color="primary"
                    onClick={() => handleClickBadgeDialogData(badgeClassObject?.details, true)}
                    variant="contained"
                >
                    Assign Badge
                </Button>
            );
        } else if (
            //badgeClassObject?.details?.connectedBadgeClass?.status === true &&
            badgeClassObject.details.status === 'revoked'
        ) {
            return <span></span>;
        } else if (badgeClassObject?.details?.connectedBadgeClass?.status === false) {
            return (
                <span>
                    {badgeClassObject?.details?.connectedBadgeClass?.message ??
                        'Badgeclass status either NEW or INACTIVE'}
                </span>
            );
        } else if (
            badgeClassObject?.details?.connectedBadgeClass?.status === true &&
            badgeClassObject.details.status !== 'issued'
        ) {
            return (
                <Tooltip
                    title={
                        badgeClassObject?.details?.connectedBadgeClass?.data &&
                        badgeClassObject.details.connectedBadgeClass.data[0]?.id
                    }
                >
                    {badgeClassObject?.details?.connectedBadgeClass?.message ??
                        'Badgeclass status either ACTIVE or PUBLISHED'}
                </Tooltip>
            );
        } else {
            return <span>Something weird ?</span>;
        }
    };

    const toggleRegularDialog = () => {
        setBadgeDialog(!badgeDialog);
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    {props.certificate.details.status === 'issued' ? (
                        <Tooltip title="Download PDF">
                            <Link href={props.certificate.details.file} target={'_blank'}>
                                <IconButton size="large">
                                    <GetAppIcon />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    ) : null}
                    {props.certificate.details.status === 'issued' &&
                        props.certificate.details.badgeClass &&
                        props.certificate.details.badgeClass.length > 0 &&
                        props.certificate.details.badgeClass[0]?.assertion &&
                        props.certificate.details.badgeClass[0]?.assertion.length > 0 ? (
                        <Tooltip title="Badge">
                            <Link
                                onClick={() =>
                                    onDownloadBadge(props.certificate.details.badgeClass[0].assertion[0].image)
                                }
                            >
                                <IconButton>
                                    <DownloadIcon />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    ) : props.certificate.details.status === 'issued' &&
                        props.certificate.details.badgeClass &&
                        props.certificate.details.badgeClass.length > 0 &&
                        (props.certificate.details.badgeClass[0]?.status == 'PUBLISHED' ||
                            props.certificate.details.badgeClass[0]?.status == 'ACTIVE') ? (
                        <Tooltip title="Create badge">
                            <IconButton
                                onClick={() => {
                                    props.handleSelectedCertificate(props.certificate);
                                    props.handleToggleCreateAssertionDialog();
                                }}
                            >
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </TableCell>
                <TableCell>
                    <Tooltip
                        title={'UserCertID: ' + props.certificate.id + '  CertId:' + props.certificate.sourceID}
                        placement="bottom-start"
                    >
                        <span>{props.certificate.details.cert.name}</span>
                    </Tooltip>
                    {/* <Tooltip title={'Download Badge Assertion'}> */}
                    {/*TODO omoguci da se skida PDF*/}
                    {/* <IconButton disabled={props.certificate.details.status !== 'ISSUED'}>
                        <DownloadIcon />
                    </IconButton>
                </Tooltip> */}
                </TableCell>
                <TableCell>
                    {props.certificate.details.issueDate
                        ? convert(props.certificate.details.issueDate, false)
                        : convert(props.certificate.details.createdAt, false)}
                </TableCell>
                <TableCell>
                    {' '}
                    {props.certificate.details.expirationDate
                        ? convert(props.certificate.details.expirationDate, false)
                        : null}
                </TableCell>
                <TableCell>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <StatusButton
                                enabled={() => checkEnabled()}
                                status={props.certificate.details.status}
                                statusOptions={statusOptions}
                                onStatusChange={handleMenuItemClickStatus}
                                loading={props.loading}
                            />
                        </Grid>
                        {!checkEnabled() &&
                            props.certificate.details.status !== 'revoked' &&
                            props.certificate.details.status !== 'cancelled' && (
                                <Grid item xs={1}>
                                    <Tooltip
                                        placement="left"
                                        title={
                                            <Typography color="inherit">
                                                Incomplete user data or user is blocked
                                            </Typography>
                                        }
                                    >
                                        <HelpOutlineIcon />
                                    </Tooltip>
                                </Grid>
                            )}
                    </Grid>
                </TableCell>
                <TableCell>
                    <Tooltip title={props.certificate?.details?.badgeClass[0]?.id}>
                        {addButtonToIssueCertOrBadgeInfo(props.certificate)}
                    </Tooltip>
                </TableCell>
            </TableRow>
            {badgeDialog ? (
                <ModalDialog
                    dialogIsOpen={badgeDialog}
                    //@ts-ignore
                    setDialogOpen={toggleRegularDialog}
                    title="Assign Badge"
                >
                    <BadgeToCertificateForm
                        onAssignmentCreated={props.handlerOnAssignmentCreated}
                        cancel={() => {
                            setBadgeDialog(false);
                        }}
                        userId={props.certificate.targetID}
                        connectedBadgeClass={badgeDialogData.connectedBadgeClass}
                        certificateClass={badgeDialogData.cert}
                        userCertificate={props.certificate.id}
                    />
                </ModalDialog>
            ) : null}
        </>
    );
};

export default React.memo(CertificateRow);
