import React, { Fragment, FunctionComponent, useRef, useState, useCallback, MouseEvent } from 'react';
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StatusChangeInfo from '../../utils/StatusChangeInfo';
import { AlertDialog } from '../alert-dialog/alert-dialog';

declare global {
    namespace React {
        interface DOMAttributes<T> {
            onResize?: ReactEventHandler<T> | undefined;
            onResizeCapture?: ReactEventHandler<T> | undefined;
            nonce?: string | undefined;
        }
    }
}
export interface StatusButtonProps {
    enabled: () => boolean;
    statusOptions: string[];
    status?: string;
    onStatusChange: (updateData: StatusChangeInfo) => void;
    loading?: boolean;
    ID?: string;
    examSetId?: string;
}

// TODO: use this to replace status buttons everywhere in app
export const StatusButton: FunctionComponent<StatusButtonProps> = (props: StatusButtonProps) => {

    const [openStatus, setOpenStatus] = useState(false);
    // const [selectedIndexStatus, setSelectedIndexStatus] = useState(statusOptions.indexOf(props.status));
    const [canceledStatusType, setCanceledStatusType] = useState<{ newState: string, indexOfNewStateInCurrentStatusOptions: number }>();
    const anchorRefStatus = useRef<HTMLDivElement>(null);

    const handleMenuItemClickStatus = (event: MouseEvent, index: number) => {
        if (props.statusOptions[index] === 'canceled' || props.statusOptions[index] === 'cancel') {
            setCanceledStatusType({ newState: props.statusOptions[index], indexOfNewStateInCurrentStatusOptions: index });
            toggleAlertDialogVisibility(true);
        } else {
            props.onStatusChange({ props: props, newState: props.statusOptions[index], examSetId: props.examSetId as string, indexOfNewStateInCurrentStatusOptions: index });
        }
        handleCloseStatus();
    };

    const handleToggleStatus = () => {
        setOpenStatus((prevOpen) => !prevOpen);
    };
    const handleCloseStatus = useCallback((event?: MouseEvent<Document, MouseEvent>) => {
        if (anchorRefStatus.current && anchorRefStatus.current.contains(event?.target as HTMLElement)) {
            return;
        }
        setOpenStatus(false);
    }, []);

    const confirmCancelation = () => {
        props.onStatusChange({
            props: props,
            examSetId: props.examSetId as string,
            newState: canceledStatusType?.newState as string,
            indexOfNewStateInCurrentStatusOptions: canceledStatusType?.indexOfNewStateInCurrentStatusOptions as number,
        });
        toggleAlertDialogVisibility(false);
    };

    const renameStatus = (status: any) => {
        if (status === "can_issue") {
            return 'requested'
        } else return status
    };

    const color = (status: any) => {
        return status === "active" || status === "confirmed" || status === "verified" ||
            status === "prepared" || status === "reg.opened" || status === "reg.closed" || status === "issued";
    };

    const [alertDialogIsOpen, toggleAlertDialogVisibility] = useState<boolean>(false);

    return (
        <Fragment>
            <ButtonGroup
                variant="contained"
                color="primary"
                ref={anchorRefStatus}
                aria-label="split button"
            >

                <Button sx={{ backgroundColor: color(props.status) ? 'green' : 'orange' }}>
                    {renameStatus(props.status)}
                    {props.loading && <CircularProgress
                        isCentered
                        thickness={5}
                        size={24}
                    />}
                </Button>

                <Button
                    disabled={!props.enabled()}
                    sx={{ backgroundColor: color(props.status) ? 'green' : 'orange' }}
                    size="small"
                    aria-controls={openStatus ? 'split-button-menu' : undefined}
                    aria-expanded={openStatus ? 'true' : undefined}
                    aria-label="select status"
                    aria-haspopup="menu"
                    onClick={handleToggleStatus}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                style={{ zIndex: 1000000 }}
                open={openStatus}
                anchorEl={anchorRefStatus.current}
                role={undefined}
                transition
                disablePortal onResize={undefined} onResizeCapture={undefined}            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => handleCloseStatus()}>
                                <MenuList id="split-button-menu">
                                    {props.statusOptions?.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            onClick={(event) => handleMenuItemClickStatus(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <AlertDialog
                isOpen={alertDialogIsOpen}
                handleClose={() => toggleAlertDialogVisibility(false)}
                content={"Are you sure you want to cancel?"}
                title="Confirmation"
                handleOkAction={confirmCancelation}
            />
        </Fragment>
    );
};
