import {
    Link,
    TableCell,
    TableRow,
} from '@mui/material';
import { FunctionComponent, useContext, useState } from 'react';
import { AlertContext } from '../../App';

export interface OrganizationRowProps {
    row: any;
}

export const UsersOrganizationsRow: FunctionComponent<OrganizationRowProps> = (props: OrganizationRowProps) => {
    return (
        <>
            <TableRow key={props.row.id} hover>
                <TableCell>
                    <Link href={`/organizations/${props.row.id}`}>
                        {props.row.id}
                    </Link>
                </TableCell>
                <TableCell>
                    {props.row.name}
                </TableCell>
                <TableCell>
                    {props.row.shortName}
                </TableCell>
                <TableCell>{props.row.relationDetails.role}</TableCell>
                <TableCell>{props.row.relationDetails.permissions}</TableCell>
                <TableCell>{props.row.relationDetails.state}</TableCell>
            </TableRow>
        </>
    );
};
