import Order from 'models/Order';
import { callAxios } from '../../axios-wrapper/call-axios';
import PaginationSortingFilteringRequest from '../../models/table-models/PaginationSortingFilteringRequest';
import TablePageSortFilter from '../../models/table-models/TablePageSortFilter';
import UrlFilterConstructor from '../../transformators/UrlFilterConstructor';

const getOrders = (userId: string, r: PaginationSortingFilteringRequest): Promise<TablePageSortFilter> => {
    const filter = UrlFilterConstructor.constructUrlConditionFromFilter(r.filters);
    return callAxios
        .get(
            `/users/${userId}/orders?orderBy=${r.orderBy.toString()}&sortOrder=${r.order}&page=${r.page + 1}&items=${r.rowsPerPage
            }${filter}`
        )
        .then((res) => {
            if (res.data.rows) {
                return { ...r, results: res.data.rows, total: res.data.total };
            } else {
                throw new Error('Loading user failed');
            }
        });
};

const claimOrder = (id: string): Promise<void> => {
    return callAxios.post(`/orders/${id}/claim`, {}).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Updating organization failed');
        }
    });
};

const reassignOrder = (id: string, data: any): Promise<void> => {
    return callAxios.post(`/orders/${id}/reassign`, data).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Reassigning order organization failed');
        }
    });
};

const prolongToken = (id: string): Promise<void> => {
    return callAxios.post(`/examevents/bookings/extend/${id}`, {}).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Reassigning order organization failed');
        }
    });
};

const createOrder = (order: Order): Promise<any> => {
    const requestBody = {
        order: order,

    };
    return callAxios.post(`/orders/create`, requestBody).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Saving of order failed');
        }
    });
};

const removeOrder = (id: string): Promise<any> => {
    return callAxios.remove(`/orders/${id}`).then((res: any) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Delete order failed');
        }
    });
};

export const ordersService = {
    getOrders,
    claimOrder,
    reassignOrder,
    prolongToken,
    createOrder,
    removeOrder
};
