import { TableCell, TableRow, Tooltip, Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { TableAction } from 'models/table-models/TableAction';
import { ExamEvent } from '../../models/ExamEvent';
import { StatusButton } from '../../customized-components/status-button/status-button';
import { examEventStatusOptionsGetter } from '../../status-transition-rules/exam-event-transitions';
import StatusChangeInfo from '../../utils/StatusChangeInfo';
import { convert } from '../../dateConverter';

export interface ExamEventRowProps {
    examEventrow: ExamEvent;
    actions: {
        onExamEventView: TableAction;
        onExamEventStatusChange: TableAction;
    };
    loading?: boolean;
}

export const ExamEventRow: FunctionComponent<ExamEventRowProps> = (props: ExamEventRowProps) => {
    const statusOptions = examEventStatusOptionsGetter(props.examEventrow.state);
    const checkEnabled = () => {
        if (statusOptions?.length > 0)
            return true
        else return false
    }

    const handleMenuItemClickStatus = (updateData: StatusChangeInfo) => {
        props.actions.onExamEventStatusChange.onClick(props.examEventrow.id, updateData.newState);
    };

    return (
        <TableRow key={props.examEventrow.id}>
            <TableCell>
                {
                    props.actions.onExamEventView &&
                    <Tooltip title={props.actions.onExamEventView.title}>
                        <Button variant='outlined' color='primary' aria-label={props.actions.onExamEventView.title} onClick={() => props.actions.onExamEventView.onClick(props.examEventrow.id)}>
                            {<props.actions.onExamEventView.icon color='primary' />}
                        </Button>
                    </Tooltip>
                }
            </TableCell>
            <TableCell>{props.examEventrow.id}</TableCell>
            <TableCell>{props.examEventrow.trainingOrg[0].name} ({props.examEventrow.trainingOrg[0].shortName})</TableCell>
            <TableCell>{props.examEventrow.name}</TableCell>
            <TableCell>
                {props.examEventrow.startDate
                    ? convert(props.examEventrow.startDate, true)
                    : ''}
            </TableCell>
            <TableCell>
                {props.examEventrow.details.booked > 0 && <div>Booked - {props.examEventrow.details.booked}</div>}
                {props.examEventrow.details.cheated > 0 && <div>Cheated - {props.examEventrow.details.cheated}</div>}
                {props.examEventrow.details.expired > 0 && <div>Expired - {props.examEventrow.details.expired}</div>}
                {props.examEventrow.details.failed > 0 && <div>Failed - {props.examEventrow.details.failed}</div>}
                {props.examEventrow.details.not_graded > 0 && <div>Not graded - {props.examEventrow.details.not_graded}</div>}
                {props.examEventrow.details.prepared > 0 && <div>Prepared - {props.examEventrow.details.prepared}</div>}
                {props.examEventrow.details.succeeded > 0 && <div>Succeeded - {props.examEventrow.details.succeeded}</div>}
                {props.examEventrow.details.canceled > 0 && <div>Cancelled - {props.examEventrow.details.canceled}</div>}
                {props.examEventrow.details.not_participated > 0 && <div>Not participated - {props.examEventrow.details.not_participated}</div>}
            </TableCell>
            <TableCell>
                {props.examEventrow.createdAt
                    ? convert(props.examEventrow.createdAt, false)
                    : ''}
            </TableCell>
            <TableCell>
                <StatusButton
                    enabled={() => checkEnabled()}
                    status={props.examEventrow.state}
                    statusOptions={statusOptions}
                    onStatusChange={handleMenuItemClickStatus}
                    loading={props.loading}
                />
            </TableCell>
        </TableRow>
    );
};
