import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';

export interface BasicTableProps {
    headerColumnTitles: string[];
    children: any;
}

const BasicTable: FC<BasicTableProps> = (props: BasicTableProps) => {

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="table">
                <TableHead>
                    <TableRow>
                        {props.headerColumnTitles &&
                            props.headerColumnTitles.map((column, index) => (
                                <TableCell key={index}>{column}</TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>{props.children}</TableBody>
            </Table>
        </TableContainer>
    );
};

export default BasicTable;
