import { HeadCell } from 'components/molecules/ComplexTableHead';

export const certificateHeaderCells: HeadCell[] = [
    { id: '', numeric: false, label: '', disablePadding: false, disableSorting: true },
    { id: 'name', numeric: false, label: 'Certificate Name', disablePadding: false, disableSorting: false },
    { id: 'id', numeric: false, label: 'ID', disablePadding: false, disableSorting: true },
    { id: 'computed', numeric: false, label: 'Role Certificate', disablePadding: false, disableSorting: true },
    { id: 'autoIssue', numeric: false, label: 'Auto Issue', disablePadding: false, disableSorting: true },
    {
        id: 'iRequireFollowingExams',
        numeric: false,
        label: 'Required certificates',
        disablePadding: false,
        disableSorting: true,
    },
    {
        id: 'examsThatDependOnMe',
        numeric: false,
        label: 'Parent Certificates',
        disablePadding: false,
        disableSorting: true,
    },
    {
        id: 'exam',
        numeric: false,
        label: 'Dependent Exams',
        disablePadding: false,
        disableSorting: true,
    },
    { id: 'status', numeric: false, label: 'Status', disablePadding: false, disableSorting: false },
    { id: 'organization', numeric: false, label: 'Organization', disablePadding: false, disableSorting: false },
    { id: 'checkStatus', numeric: false, label: '', disablePadding: false, disableSorting: false }
];
