import { Button, Chip, Grid, TableCell, TableRow, Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';
import { TableAction } from 'models/table-models/TableAction';
import { OrganizationMember } from '../../models/Organization';

export interface OrganizationMembersRowProps {
	row: OrganizationMember;
	actions: {
		onRemoveMember: TableAction;
		onEditMember: TableAction;
	};
}

export const OrganizationMembersRow: FunctionComponent<OrganizationMembersRowProps> = (props: OrganizationMembersRowProps) => {

	return (
		<TableRow key={props.row.id}>
			<TableCell>
				{props.actions.onRemoveMember && (
					<Tooltip title={props.actions.onRemoveMember.title}>
						<Button
							variant="outlined"
							color="primary"
							aria-label={props.actions.onRemoveMember.title}
							//@ts-ignore
							onClick={() => props.actions.onRemoveMember.onClick(props.row)}
						>
							{<props.actions.onRemoveMember.icon color="primary" />}
						</Button>
					</Tooltip>
				)}
				{props.actions.onEditMember && (
					<Tooltip title={props.actions.onEditMember.title}>
						<Button
							sx={{ ml: 1 }}
							variant="outlined"
							color="primary"
							aria-label={props.actions.onEditMember.title}
							//@ts-ignore
							onClick={() => props.actions.onEditMember.onClick(props.row)}
						>
							{<props.actions.onEditMember.icon color="primary" />}
						</Button>
					</Tooltip>
				)}
			</TableCell>
			<TableCell>
				<Grid container spacing={1}>
					{props.row.userMails &&
						props.row.userMails.map((e) => (
							<Grid item key={e.id}>
								<Chip color={e.state === 'active' ? 'primary' : 'default'} label={e.email}></Chip>
							</Grid>
						))}
				</Grid>
			</TableCell>
			<TableCell>{props.row.firstName}</TableCell>
			<TableCell>{props.row.lastName}</TableCell>
			<TableCell>{props.row.relationDetails.role}</TableCell>
			<TableCell>{props.row.relationDetails.permissions}</TableCell>
		</TableRow >
	);
};
