import React, { ChangeEvent, PropsWithChildren, useEffect, useState } from 'react';
import { Root, classes } from './styles';
import { Paper, SvgIconTypeMap, TablePagination } from '@mui/material';
import TableToolbar from './TableToolbar';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import SimpleTableHead from './SimpleTableHead';
import TableBody from '@mui/material/TableBody';
import CircularProgress from '../../atoms/StyledCircularProgress';
import EmptyTable from '../../molecules/EmptyTable';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SortObject } from '../../../models/Alignments';

export interface SimpleTableProps extends PropsWithChildren<any> {
    title: string;
    addIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | undefined;
    addAction: () => void | undefined;
    loading?: boolean;
    tableData: any;
    sortArray?: SortObject[];
    updateTableData: (arg0: any) => void;
    onSearchChange?: (arg0: any) => void;
    filters: any;
}

const SimpleTable = (props: SimpleTableProps) => {
    const [orderBy, setOrderBy] = useState<keyof any>('id' as string);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const { tableData, children, title, headerCells, loading } = props;

    useEffect(() => {
        //@ts-ignore
        setOrderBy(props.tableData?.orderBy || 'id');
        setPage(props.tableData?.page || 1);
        setPageSize(props.tableData?.pageSize || 10);
    }, [props.tableData]);

    const reloadDataWithPagination = (reloadRequest: any) => {
        if (props.updateTableData) {
            props.updateTableData(reloadRequest);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        const reloadRequest = {
            page: newPage,
            pageSize,
            orderBy,
        };

        if (props.onPaginationUpdate) {
            props.onPaginationUpdate(reloadRequest);
        }
        reloadDataWithPagination(reloadRequest);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(1);
        const reloadRequest = {
            page: 1,
            pageSize: parseInt(event.target.value, 10),
            orderBy: orderBy,
        };
        if (props.onPaginationUpdate) {
            props.onPaginationUpdate(reloadRequest);
        }
        reloadDataWithPagination(reloadRequest);
    };

    return (
        <Root className={classes.root}>
            <Paper>
                <TableToolbar
                    updateTableData={props.updateTableData}
                    title={title}
                    sortArray={props.sortArray}
                    tableData={props.tableData}
                    filters={props.filters}
                    onFilterChange={props.onFilterChange}
                />
                {children && children.length > 0 ? (
                    <>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size="medium"
                                aria-label="enhanced table"
                            >
                                <SimpleTableHead headerCells={headerCells} />
                                <TableBody>{children}</TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : loading ? (
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                ) : (
                    <EmptyTable icon={DeleteOutlineIcon} />
                )}

                <TablePagination
                    rowsPerPageOptions={[2, 5, 10, 20, 50, 100]}
                    component="div"
                    count={tableData?.total || 0}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPage={pageSize}
                    style={{ borderRadius: '10px 10px 0px 0px' }}
                />
            </Paper>
        </Root>
    );
};

export default SimpleTable;
