import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent } from '../../../customized-components';
import { Fragment, FunctionComponent, useEffect, useState, useContext } from 'react';
import {
	FormControlLabel, Checkbox, FormControl, FormHelperText, Autocomplete,
	InputLabel, ListItemText, MenuItem, Select, TextField, Box
} from '@mui/material';
import CircularProgress from 'components/atoms/StyledCircularProgress';
// import { debounce } from '@mui/material/utils';
import { Exam } from '../../../models/Exam';
import { examsService } from '../../../services/exams/exams.service';
import { certificatesService } from '../../../services/certificates/certificates.service';
import { Certificate } from '../../../models/Certificate';
import { AlertContext } from '../../../App';
import ButtonWrapper from 'components/atoms/buttons/ButtonWrapper';
import { Organization } from 'models/Organization';

export interface EditCertificateFormProps {
	certificate: any;
	organizations: any;
	cancel: () => void;
	onCertificateUpdate: () => void;
}

export const EditCertificateForm: FunctionComponent<EditCertificateFormProps> = (props: EditCertificateFormProps) => {

	//@ts-ignore
	const { handleAlert } = useContext(AlertContext);

	//@ts-ignore
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		defaultValues: { ...props.certificate }
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [certificates, setCertificates] = useState<Certificate[]>([]);
	const [exams, setExams] = useState<Exam[]>([]);
	const [layouts, setLayouts] = useState<string[]>([]);
	const [autoIssue, setAutoIssue] = useState<boolean>(props.certificate.autoIssue);
	const [showFooter, setShowFooter] = useState<boolean>(props.certificate.showFooter);
	const [showPersonalData, setShowPersonalData] = useState<boolean>(props.certificate.showPersonalData);

	const organizationOptions = props.organizations.map((org: Organization) => ({ label: org.name + " - " + org.id, id: org.id }))

	useEffect(() => {
		examsService
			.getExamsActive()
			.then((res) => {
				setExams(res);
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);

		certificatesService
			.getCertificates()
			.then((res) => {
				setCertificates(res);
			})
			.catch((error) =>
				handleAlert(
					error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
					'error'
				)
			);

		certificatesService.getCertificateLayouts().then((res) => {
			setLayouts(res)
		}).catch((error) => {
			handleAlert(
				error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!',
				'error'
			)
		});

		if (props.certificate.computed) {
			setValue('preRequisiteExams', mapPreSelected());
		} else {
			setValue('selectedExams', mapPreSelectedExams());
		}


	}, []);

	const mapPreSelectedExams = () => {
		let res: string[] = [];
		props.certificate.exam?.map((single: any) => {
			res.push(single.id)
			return null;
		})
		return res;
	}
	const mapPreSelected = () => {
		let res: string[] = [];
		props.certificate.iRequireFollowingExams?.map((single: any) => {
			res.push(single.id)
			return null;
		})
		return res;
	}

	const onSubmit = (data: any) => {

		setLoading(true);

		const body = {
			...props.certificate,
			autoIssue: autoIssue ? true : false,
			showFooter: showFooter ? true : false,
			showPersonalData: showPersonalData ? true : false,
			certLayout: data.certLayout,
			backgroundID: data.backgroundID,
			organization: data.organization.id,
			name: data.name,
			startText: data.startText,
			middleText: data.middleText,
			bottomText: data.bottomText,
			status: data.status,
			preRequisiteExams: props.certificate.computed ? data.preRequisiteExams : data.selectedExams
		}

		certificatesService.updateCertificate(body, props.certificate.id).then((res) => {
			handleAlert('Certificate updated successfully!', 'success');
			setLoading(false);
			props.cancel();
			props.onCertificateUpdate();
		}).catch((error) => {
			props.cancel();
			handleAlert(error.response.data?.message ? error.response.data.message : 'Ups! Something went wrong!', 'error')
		});
	};

	/* const renderExamName = (selected: string): string => {
		let name: string = "";
		const exam = exams.find((e) => e.id === selected);
		name = exam?.externalName as string;
		return name;
	}; */

	const renderExamNameArray = (selected: any[]): string[] => {
		let names: string[] = [];
		selected.forEach((oneExam) => {
			const exam = exams.find((e) => e.id === oneExam);
			names.push(exam?.externalName as string);
		});
		return names;
	};

	const renderCertificateNames = (selected: string[]): string[] => {
		let names: string[] = [];
		selected.forEach((id) => {
			const certificate = certificates.find((e) => e.id === id);
			names.push(certificate?.name as string);
		});
		return names;
	};

	const handleCheckChange = (type: string, event: React.ChangeEvent<HTMLInputElement>) => {
		switch (type) {
			case 'auto':
				setAutoIssue(Boolean(event.target.checked));
				break;
			case 'footer':
				setShowFooter(Boolean(event.target.checked));
				break;
			case 'data':
				setShowPersonalData(Boolean(event.target.checked));
				break;
			default:
				break;
		}
	};

	function renderExamName(examId) {
		const exam = exams.find((exam) => exam.id === examId);
		return exam ? exam.externalName : '';
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<DialogContent>
				<Grid container spacing={3}>
					{props.certificate.computed ?
						<Grid item xs={12} sm={6}>
							<FormControl variant="outlined" size="small" fullWidth>
								<Controller
									name="preRequisiteExams"
									render={({ field: { value } }) => (
										<>
											<Autocomplete
												disablePortal
												id="preRequisiteExams_auto"
												multiple
												options={certificates.filter((certificate) => certificate.id !== props.certificate.id)}
												getOptionLabel={(certificate) => certificate?.name || ''}
												renderInput={(params) => (
													<TextField {...params} error={!!errors.preRequisiteExams} label="Certificates" />
												)}
												renderOption={(props, certificate, { selected }) => (
													<li {...props} key={certificate.id}>
														<Checkbox
															style={{ marginRight: 8 }}
															checked={selected}
														/>
														{certificate?.name}
													</li>
												)}
												autoHighlight
												value={value.map((id) => certificates.find((certificate) => certificate.id === id))}
												onChange={(event, newValue) => {
													setValue('preRequisiteExams', newValue.map((item) => item.id));
													renderCertificateNames(newValue);
												}}
											/>
											<FormHelperText style={{ color: errors.preRequisiteExams ? 'red' : 'grey' }}>
												{errors.preRequisiteExams && 'Certificates are required field'}
											</FormHelperText>
										</>
									)}
									control={control}
									defaultValue={[]}
								/>
							</FormControl>
						</Grid>
						:
						<Grid item xs={12} sm={6}>
							<FormControl variant="outlined" size="small" fullWidth>
								<Controller
									name="selectedExams"
									render={({ field: { value } }) => (
										<>
											<Autocomplete
												disablePortal
												id="examsId_auto"
												multiple
												options={exams.map((exam) => ({
													name: exam.externalName + ' (' + exam.fixedID + ')',
													id: exam.id,
												}))}
												renderInput={(params) => (
													<TextField
														{...params}
														error={!!errors.examID}
														label="Exams"
													/>
												)}
												renderOption={(props, option, { selected }) => (
													<li {...props} key={option.id}>
														<Checkbox
															style={{ marginRight: 8 }}
															checked={selected}
														/>
														{option.name}
													</li>
												)}
												autoHighlight
												getOptionLabel={(option) => option.name || ''}
												isOptionEqualToValue={(option, value) => {
													return option.id === value.id || value === undefined || value === null;
												}}
												filterOptions={(options, state) => {
													const inputValue = state.inputValue.toLowerCase();
													return options.filter((option) =>
														option.name.toLowerCase().includes(inputValue)
													);
												}}
												onChange={(event, newValue) => {
													setValue('selectedExams', newValue.map((item) => item.id));
												}}
												value={value.map((id) => ({
													id: id,
													name: renderExamName(id), // Assuming you have a function to render the exam name based on its ID
												}))}
											/>
											<FormHelperText style={{ color: errors.examID ? 'red' : 'grey' }}>
												{errors.examID && 'Exams are required field'}
											</FormHelperText>
										</>
									)}
									control={control}
									defaultValue={[]}
								/>
							</FormControl>
						</Grid>

					}
					<Grid item xs={12} sm={6}>
						<Controller
							name="name"
							render={({ field }) => (
								<TextField
									fullWidth
									{...field}
									data-testid="name"
									id="name"
									size="small"
									label="Name"
									variant="outlined"
									error={errors.name ? true : false}
									helperText={errors.name && errors.name.message}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'Name is required field',
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" size="small" fullWidth>
							<Controller
								name="certLayout"
								render={({ field }) => (
									<Fragment>
										<InputLabel htmlFor="certLayout">Layout</InputLabel>
										<Select
											{...field}
											data-testid="test-certLayout"
											id="certLayout"
											label="Layout"
											error={errors.certLayout ? true : false}
										>
											{layouts?.map((single) => (
												<MenuItem key={single} value={single}>{single}</MenuItem>
											))}
										</Select>
										<FormHelperText style={{ color: errors.certLayout ? 'red' : 'grey' }}>
											{errors.certLayout && errors.certLayout.message}
										</FormHelperText>
									</Fragment>
								)}
								control={control}
								defaultValue={""}
								rules={{
									required: 'Layout is required field',
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" size="small" fullWidth>
							<Controller
								name="backgroundID"
								render={({ field }) => (
									<>
										<InputLabel htmlFor="backgroundID">Background ID</InputLabel>
										<Select
											{...field}
											data-testid="backgroundID"
											id="backgroundID"
											label="Background ID"
											error={!!errors.backgroundID}
											onChange={(event) => field.onChange(event.target.value)}
										>
											<MenuItem value={"1"}>1 - ICO</MenuItem>
											<MenuItem value={"2"}>2 - ICO / exam certificate</MenuItem>
											<MenuItem value={"7"}>7 - ITEMO / role certificate</MenuItem>
											<MenuItem value={"8"}>8 - ITEMO</MenuItem>
											<MenuItem value={"14"}>14 - IT Sicherheitscluster</MenuItem>
										</Select>
										<FormHelperText style={{ color: errors.backgroundID ? 'red' : 'grey' }}>
											{errors.backgroundID && errors.backgroundID.message}
										</FormHelperText>
									</>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: 'Background ID is required field',
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="startText"
							render={({ field }) => (
								<TextField
									fullWidth
									{...field}
									data-testid="startText-test"
									id="startText"
									label="Start text"
									size="small"
									variant="outlined"
									multiline
									rows={5}
									error={errors.startText ? true : false}
									helperText={errors.startText && errors.startText.message}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'Start text is required field',

							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="middleText"
							render={({ field }) => (
								<TextField
									fullWidth
									{...field}
									data-testid="middleText-test"
									id="middleText"
									label="Middle text"
									size="small"
									variant="outlined"
									multiline
									rows={5}
									error={errors.middleText ? true : false}
									helperText={errors.middleText && errors.middleText.message}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'Middle text is required field'
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							name="bottomText"
							render={({ field }) => (
								<TextField
									fullWidth
									{...field}
									data-testid="bottomText-test"
									id="bottomText"
									label="Bottom text"
									size="small"
									variant="outlined"
									multiline
									rows={5}
									error={errors.bottomText ? true : false}
									helperText={errors.bottomText && errors.bottomText.message}
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: 'Bottom text is required field'
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" size="small" fullWidth>
							<Controller
								name="status"
								render={({ field }) => (
									<Fragment>
										<InputLabel htmlFor="status">Status</InputLabel>
										<Select
											{...field}
											data-testid="test-status"
											id="status"
											label="status"
											error={errors.status ? true : false}
										>
											<MenuItem value={'active'}>Active</MenuItem>
											<MenuItem value={'inactive'}>Inactive</MenuItem>
											<MenuItem value={'new'}>New</MenuItem>
										</Select>
										<FormHelperText style={{ color: errors.status ? 'red' : 'grey' }}>
											{errors.status && errors.status.message}
										</FormHelperText>
									</Fragment>
								)}
								control={control}
								defaultValue=""
								rules={{
									required: 'Status is required field',
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl variant="outlined" size="small" fullWidth>
							<Controller
								name="organization"
								render={({ field }) => (  // , fieldState
									<>
										<Autocomplete
											{...field}
											id="organization"
											options={organizationOptions}
											data-testid="test-organization"
											value={
												(props.certificate.organization[0]) ?
													organizationOptions.find((v: { id: string; }) => {
														return v.id === props.certificate.organization[0].id
													}) : ''
											}
											isOptionEqualToValue={(option, value) => {
												return value === undefined || value === "" || option.id === value.id
											}}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														error={!!errors.organization}
														label="Organization"
														size="small"
													/>
												)
											}}
											renderOption={(props, option) => (
												<Box component="li" {...props}>{option.label}</Box>
											)}
											autoHighlight
											getOptionLabel={(option) => option.name || option.label || ''}
											onChange={(_, data) => field.onChange(data)}
										/>
										<FormHelperText style={{ color: errors.organization ? 'red' : 'grey' }}>
											{errors.organization && 'Organization are required field'}
										</FormHelperText>
									</>
								)}
								control={control}
								//defaultValue={{ id: '5502bb8e-18ad-45ea-a315-ae39d44646ca', name: "Nemanja Organization" }}
								rules={{
									required: 'Field required'
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Grid container justifyContent='space-around'>
							<FormControlLabel
								control={<Checkbox checked={Boolean(autoIssue)} onChange={(e) => handleCheckChange('auto', e)} name="jason" />}
								label="Auto Issue"
							/>
							<FormControlLabel
								control={<Checkbox checked={Boolean(showFooter)} onChange={(e) => handleCheckChange('footer', e)} name="jason" />}
								label="Show Footer"
							/>
							<FormControlLabel
								control={<Checkbox checked={Boolean(showPersonalData)} onChange={(e) => handleCheckChange('data', e)} name="jason" />}
								label="Show Personal Data"
							/>
						</Grid>
					</Grid>
				</Grid>


			</DialogContent>
			<DialogActions>
				<ButtonWrapper>
					<Button disabled={loading} data-testid="" color="primary" type="submit" variant="contained">
						Save
					</Button>
					{loading && <CircularProgress isCentered thickness={5} size={24} />}
				</ButtonWrapper>
				<Button
					disabled={loading}
					onClick={() => {
						//@ts-ignore
						reset({
							computed: false,
							examID: "",
							certLayout: "",
							backgroundID: "",
							name: "",
							startText: "",
							middleText: "",
							bottomText: "",
							autoIssue: false,
							showFooter: false,
							showPersonalData: false,
						});
						props.cancel();
					}}
					data-testid=""
					color="primary"
					type="submit"
					variant="contained"
				>
					Cancel
				</Button>
			</DialogActions>
		</form>
	);
};
