import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import { FunctionComponent } from 'react';
import { PropsWithChildren } from 'react';
import logo from '../../assets/ico-cert_ICO_180px.png';

const PREFIX = 'BaseLandingPage';

const classes = {
    container: `${PREFIX}-container`,
    ico: `${PREFIX}-ico`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`&.${classes.container}`]: {
        margin: 'auto',
        padding: theme.spacing(8),
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '10px',
        boxShadow: '0 0 1rem 0 rgba(0, 0, 0, 0.2)',
        '&:before': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            backdropFilter: 'blur(10px) saturate(100%) contrast(45%) brightness(130%)',
            content: '""',
            height: '100%',
            position: 'absolute',
            // width: '100%',
        },
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
    },

    [`& .${classes.ico}`]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(4),
        height: '71px',
        width: '90px',
    }
}));

export const BaseLandingPage: FunctionComponent<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {


    return (
        <StyledContainer maxWidth="sm" className={classes.container}>
            <CardMedia className={classes.ico} image={logo} />
            {props.children}
        </StyledContainer>
    );
};
